import { shade } from 'polished';
import styled from 'styled-components';

export const Container = styled.div``;

export const Header = styled.header`
  padding: 32px 0;
  background: #28262e;
`;

export const HeaderContent = styled.div`
  max-width: 1120px;
  margin: 0 auto;
  display: flex;
  align-items: center;

  > img {
    height: 50px;
  }

  button {
    margin-left: auto;
    background: transparent;
    border: 0;

    svg {
      color: #999591;
      width: 20px;
      height: 20px;
    }
  }
`;

export const Title = styled.div`
  display: flex;
  justify-content: space-between;

  h1 {
    color: #525f7f;
  }

  a {
    display: flex;
    flex-direction: row;
    justify-content: center;
    max-width: 15%;
    max-height: 35px;
    display: flex;
    text-align: center;
    background: #1171ef;
    height: 56px;
    border-radius: 10px;
    border: 0;
    padding: 0 16px;
    color: #ffffff;
    text-decoration: none;
    width: 100%;
    font-weight: 500;
    margin-left: 10px;
    transition: background-color 0.2s;

    &:hover {
      background: ${shade(0.2, '#1171ef')};
    }

    > * {
      margin: auto 0;
    }
  }
`;

export const Profile = styled.div`
  display: flex;
  align-items: center;
  margin-left: 80px;

  > svg {
  }

  div {
    display: flex;
    flex-direction: column;
    margin-left: 16px;
    line-height: 24px;

    span {
      color: #f4ede8;
    }

    strong {
      color: #ff9000;
    }
  }
`;

export const Content = styled.main`
  max-width: 1120px;
  margin: 64px auto;
  display: flex;
`;
export const Schedule = styled.div`
  flex: 1;
  /* margin-right: 120px; */

  h1 {
    font-size: 36px;
  }

  p {
    margin-top: 8px;
    color: #ff9000;
    display: flex;
    align-items: center;
    font-weight: 500;

    span {
      display: flex;
      align-items: center;
    }
    span + span::before {
      content: '';
      width: 1px;
      height: 12px;
      background: #ff9000;
      margin: 0 8px;
    }
  }

  Form {
    margin-top: 28px;
  }
`;

export const Search = styled.div`
  display: flex;
  margin-bottom: 8px;

  > div {
    max-width: 100%;
  }

  > a {
    max-width: 20%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    text-align: center;
    background: #1171ef;
    height: 56px;
    border-radius: 10px;
    border: 0;
    padding: 0 16px;
    color: #ffffff;
    text-decoration: none;
    width: 100%;
    font-weight: 500;
    margin-left: 10px;
    transition: background-color 0.2s;

    &:hover {
      background: ${shade(0.2, '#1171ef')};
    }
  }
`;

export const ItemContainer = styled.div`
  margin-top: 20px;
  width: 100%;
  display: flex;
  flex-direction: row;
  gap: 10px;
  flex-wrap: wrap;
`;

export const Item = styled.div`
  position: relative;
  flex: 1 1 auto;
  width: 270px;

  > div {
    flex-direction: column;
    gap: 10px;
    display: flex;
    margin: 0 12px;

    > div {
      position: relative;
      max-width: 100%;
      max-height: 100%;
      min-width: 16.4rem;
      border-radius: 5px;
      overflow: hidden;
      transition: opacity 0.2s ease 0s;

      > div {
        position: absolute;
        right: 8px;
        top: 8px;
        z-index: 2;

        > button {
        }

        > a {
          opacity: 1;
          transition: opacity 0.2s ease 0s;
          text-decoration: none;
          color: #1171ef;

          > span {
            box-sizing: border-box;
            display: block;
            overflow: hidden;
            width: initial;
            height: initial;
            background: none;
            opacity: 1;
            border: 0px;
            margin: 0px;
            padding: 0px;
            position: relative;

            > span {
              box-sizing: border-box;
              display: block;
              width: initial;
              height: initial;
              background: none;
              opacity: 1;
              border: 0px;
              margin: 0px;
              padding: 56.34% 0px 0px;

              > img {
                position: absolute;
                inset: 0px;
                box-sizing: border-box;
                padding: 0px;
                border: none;
                margin: auto;
                display: block;
                width: 0px;
                height: 0px;
                min-width: 100%;
                max-width: 100%;
                min-height: 100%;
                max-height: 100%;
              }
            }
          }
        }
      }
    }
  }
`;

export const ItemTitle = styled.div`
  gap: 12px;
  display: flex;
  flex-direction: column;

  > a {
    text-decoration: none;
    color: #1171ef;
    transition: color 0.2s ease 0s;

    > h2 {
      min-height: 51px;
      margin: 0px;
      font-size: 1rem;
      line-height: 160%;
      color: #1171ef;
    }
  }
`;

export const Pagination = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
`;

interface PageProps {
  selected?: boolean;
}

export const Page = styled.div<PageProps>`
  display: flex;

  align-items: center;
  background-color: ${(props) => (props.selected ? '#0357c6' : '#1171ef')}; // rgb(41, 41, 46);
  min-width: 40px;
  height: 40px;
  border-radius: 6px;
  padding-left: 10px;
  padding-right: 10px;
  cursor: pointer;
  color: #fff;

  &:hover {
    background-color: #0357c6;
  }

  > div {
    margin: auto;
  }

  & + div {
    margin-left: 10px;
  }
`;

export const ModalContent = styled.div`
  strong {
    font-size: 20px;
    font-weight: 600;
    color: black;
  }
`;
