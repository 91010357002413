import { FormHandles } from '@unform/core';
import { Form } from '@unform/web';
import React, { ChangeEventHandler, Fragment, useEffect, useRef, useState } from 'react';
import { FiUserPlus, FiSearch, FiEdit, FiBook, FiMenu } from 'react-icons/fi';
import { Link, useParams } from 'react-router-dom';
import Checkbox from '../../../components/Checkbox/V2';
import Input from '../../../components/Input';
import Spinner from '../../../components/Spinner';
import { useToast } from '../../../hooks/toast';
import api from '../../../services/api';
import {
  Container,
  Content,
  RowContainer,
  Row,
  Schedule,
  Search,
  Filter,
  Title,
  Super,
  Novo,
  Icon,
  SubDetails,
  InputAreaStyled,
} from './styles';
import Pagination from '../../../components/Pagination';
import parq from '../../../assets/icons/clipboard.svg';
import anamnese from '../../../assets/icons/form.svg';
import radar from '../../../assets/icons/radar.svg';
import imc from '../../../assets/icons/bmi.svg';
import objetivo from '../../../assets/icons/progress-report.svg';
import atestado from '../../../assets/icons/stethoscope.svg';
import Modal from '../../../components/Modal';
import { useAuth } from '../../../hooks/auth';
import { formtarData } from '../../../utils/converter';

const AlunosList: React.FC = () => {
  const { prof = 0 } = useParams();
  const { addToast } = useToast();
  const { role } = useAuth();
  const formRef = useRef<FormHandles>(null);
  const [loading, setLoading] = useState(false);
  const [alunos, setAlunos] = useState<any>([]);
  const [pages, setPages] = useState(1);
  const [currentPage, setCurrentPage] = useState(1);
  const [text, setText] = useState<string>('');
  const [my, setMy] = useState(false);
  const [pendentes, setPendentes] = useState(false);
  const [bloqueados, setBloqueados] = useState(false);
  const [obs, setObs] = useState('');
  const [modalobs, setModalObs] = useState(false);
  const [currentAluno, setCurrentAluno] = useState<any>(null);
  const [professor, setProfessor] = useState<any>(null);

  useEffect(() => {
    if (prof === 0) return;
    api.get(`professores/${prof}`).then((resp) => {
      setProfessor(resp.data);
    });
  }, [prof]);

  useEffect(() => {
    setLoading(true);
    api
      .get(`alunos?professor=${Number(prof)}&My=${my}&Pendentes=${pendentes}&Bloqueados=${bloqueados}`)
      .then((response) => {
        setAlunos(response.data.list);
        setCurrentPage(response.data.currentPage);
        setPages(response.data.pages);
        setLoading(false);
      })
      .catch((error) => {
        setLoading(false);
        addToast({
          type: 'error',
          title: error.message,
        });
      });
  }, [addToast, prof, my, pendentes, bloqueados]);

  const handleGotoPage = (_page: number) => {
    if (_page > pages) return;
    setLoading(true);
    const query = text ? `&Text=${text}` : '';
    api
      .get(`alunos?professor=${Number(prof)}&PageNumber=${_page}${query}&My=${my}&Pendentes=${pendentes}&Bloqueados=${bloqueados}`)
      .then((resp) => {
        setAlunos(resp.data.list);
        setCurrentPage(resp.data.currentPage);
        setPages(resp.data.pages);
        setLoading(false);
      })
      .catch((error) => {
        setLoading(false);
      });
  };

  const handleFilter = (e: React.ChangeEvent<HTMLInputElement>) => {
    setText(e.target.value || '');
  };

  const handleSearch = (e: any) => {
    if (e.key === 'Enter') {
      setLoading(true);
      const query = text ? `&Text=${text}` : '';
      api
        .get(`alunos?professor=${Number(prof)}&PageNumber=${1}${query}&My=${my}&Pendentes=${pendentes}&Bloqueados=${bloqueados}`)
        .then((resp) => {
          setAlunos(resp.data.list);
          setCurrentPage(resp.data.currentPage);
          setPages(resp.data.pages);
          setLoading(false);
        })
        .catch((error) => {
          setLoading(false);
        });
    }
  };

  const myAlunos: ChangeEventHandler<HTMLInputElement> = (e) => {
    setMy(e.target.checked);
  };

  const FilterPendentes: ChangeEventHandler<HTMLInputElement> = (e) => {
    setPendentes(e.target.checked);
  };

  const FilterBloqueados: ChangeEventHandler<HTMLInputElement> = (e) => {
    setBloqueados(e.target.checked);
  };
  const handleSubmit = () => ({});

  const exibir = (item: any) => {
    const newList = alunos.map((aluno: any) => {
      if (aluno.id === item.id) {
        const updatedItem = {
          ...aluno,
          exibir: !aluno.exibir,
        };
        return updatedItem;
      }
      return aluno;
    });
    setAlunos(newList);
  };

  function handleObservacoes(item: any): void {
    setCurrentAluno(item);
  }

  useEffect(() => {
    if (currentAluno == null) return;

    api.get(`alunos/observacoes/${currentAluno.id}`).then((response) => {
      const { observacoes } = response.data;
      observacoes ? setObs(observacoes) : setObs('');
      setModalObs(true);
    });
  }, [currentAluno]);

  const savarObs = () => {
    console.log(currentAluno, obs);
    const data = {
      id: currentAluno.id,
      observacoes: obs,
    };
    api
      .put(`alunos/observacoes/${currentAluno.id}`, data)
      .then(() => {
        addToast({
          type: 'success',
          title: 'Atualizado',
        });
        setModalObs(false);
        setCurrentAluno(null);
      })
      .catch((error) => {
        setLoading(false);
        addToast({
          type: 'error',
          title: error.message,
        });
      });
  };

  return (
    <>
      <Container>
        {/* <Header /> */}
        <Content>
          <Schedule>
            <Title>
              <h1>Clientes</h1>
              {role !== 'Professor' && Number(prof) > 0 && (
                <p>
                  <span>Vagas: {`${professor?.ocupacao || 0}/${professor?.vagas || 0}`}</span>
                </p>
              )}
              <Link to={'/cliente/cadastrar'}>
                <FiUserPlus size={20} />
                <span>Novo</span>
              </Link>
            </Title>
            <Form
              ref={formRef}
              onSubmit={handleSubmit}
              placeholder={undefined}
              onPointerEnterCapture={undefined}
              onPointerLeaveCapture={undefined}
            >
              <Search>
                <Input name="nome" placeholder="Buscar" icon={FiSearch} onChange={handleFilter} onKeyDown={handleSearch} />
              </Search>
              <Filter>
                <Checkbox name={'my'} label="Meus clientes" onChange={myAlunos} defaultChecked={false} />
                <Checkbox name={'pendentes'} label="Pendentes" onChange={FilterPendentes} defaultChecked={false} />
                <Checkbox name={'bloqueados'} label="Bloqueados" onChange={FilterBloqueados} defaultChecked={false} />
              </Filter>
            </Form>
            {!loading ? (
              <>
                <RowContainer>
                  {alunos
                    .filter((a: any) => a.nomeCompleto.toLowerCase().includes(text.toLowerCase()))
                    .map((a: any) => (
                      <Fragment key={a.id}>
                        <Row sexo={a.sexo} className="md:mb-2.5 sm:mb-0">
                          <div className="flex">
                            <div className="flex-none w-14"></div>
                            <div className="flex-1">
                              <strong>
                                <Link to={`/cliente/${a.id}/questionarios`}>{a.nomeCompleto}</Link>
                              </strong>
                              {a.role === 'SuperAluno' && <Super>Super</Super>}
                              {a.pendente && <Novo>Novo</Novo>}
                            </div>
                            <div className="hidden sm:flex flex-1">
                              <div className="has-tooltip">
                                <span className="tooltip rounded shadow-lg p-1 bg-gray-100 text-red-500 -mt-8">
                                  {formtarData(a.objetivoVencido)}
                                </span>
                                <Icon src={objetivo} vencido={a.objetivoVencido} />
                              </div>
                              <div className="has-tooltip">
                                <span className="tooltip rounded shadow-lg p-1 bg-gray-100 text-red-500 -mt-8">
                                  {formtarData(a.parqVencido)}
                                </span>
                                <Icon src={parq} vencido={a.parqVencido} />
                              </div>
                              {!a.parqOk && (
                                <div className="has-tooltip">
                                  <span className="tooltip rounded shadow-lg p-1 bg-gray-100 text-red-500 -mt-8">
                                    {formtarData(a.atestadoVencido)}
                                  </span>
                                  <Icon src={atestado} vencido={a.atestadoVencido} />
                                </div>
                              )}
                              <div className="has-tooltip">
                                <span className="tooltip rounded shadow-lg p-1 bg-gray-100 text-red-500 -mt-8">
                                  {formtarData(a.anamneseVencido)}
                                </span>
                                <Icon src={anamnese} vencido={a.anamneseVencido} />
                              </div>
                              <div className="has-tooltip">
                                <span className="tooltip rounded shadow-lg p-1 bg-gray-100 text-red-500 -mt-8">
                                  {formtarData(a.radarVencido)}
                                </span>
                                <Icon src={radar} vencido={a.radarVencido} />
                              </div>
                              <div className="has-tooltip">
                                <span className="tooltip rounded shadow-lg p-1 bg-gray-100 text-red-500 -mt-8">
                                  {formtarData(a.imcVencido)}
                                </span>
                                <Icon src={imc} vencido={a.imcVencido} />
                              </div>
                              <FiBook size={30} onClick={() => handleObservacoes(a)} />
                            </div>
                            <div className="flex">
                              <FiMenu size={25} color="#9d9c9c" className="block sm:hidden" onClick={() => exibir(a)} />
                              <Link to={`/cliente/${a.id}/editar`} style={{ marginLeft: 15 }}>
                                <FiEdit size={25} color={a.bloqueado ? '#ef1111' : '#2dce89'} />
                              </Link>
                            </div>
                          </div>
                        </Row>
                        {a.exibir && (
                          <SubDetails className="flex sm:hidden">
                            <Icon src={parq} vencido={a.parqVencido} />
                            {!a.parqOk && <Icon src={atestado} vencido={a.atestadoVencido} />}
                            <Icon src={anamnese} vencido={a.anamneseVencido} />
                            <Icon src={radar} vencido={a.radarVencido} />
                            <Icon src={imc} vencido={a.imcVencido} />
                            <Icon src={objetivo} vencido={a.objetivoVencido} />
                            <FiBook size={30} onClick={() => handleObservacoes(a)} />
                          </SubDetails>
                        )}
                      </Fragment>
                    ))}
                </RowContainer>
                <Pagination currentPage={currentPage} pages={pages} handleGotoPage={handleGotoPage} />
                <Modal visible={modalobs} close={() => setModalObs(false)}>
                  <p>Cliente: {currentAluno?.nomeCompleto}</p>
                  Observações
                  <InputAreaStyled rows={8} value={obs} onChange={(e) => setObs(e.target.value)} />
                  <button
                    onClick={savarObs}
                    className="pointer-events-auto w-40 rounded-md bg-blue-600 px-3 py-2 text-[0.8125rem] font-semibold leading-5 text-white hover:bg-blue-500"
                  >
                    Gravar
                  </button>
                </Modal>
              </>
            ) : (
              <>
                <br />
                <Spinner />
              </>
            )}
          </Schedule>
        </Content>
      </Container>
    </>
  );
};

export default AlunosList;
