import React, { InputHTMLAttributes, useEffect, useRef } from 'react';
import { useField } from '@unform/core';

import { Container } from './styles';

interface InputProps extends InputHTMLAttributes<HTMLInputElement> {
  name: string;
  options: {
    id: string;
    value: string | number;
    label?: string;
  }[];
}

const Option: React.FC<InputProps> = ({ name, options, ...rest }) => {
  const inputRefs = useRef<HTMLInputElement[]>([]);
  const { fieldName, defaultValue = '', error, registerField } = useField(name);
  useEffect(() => {
    registerField({
      name: fieldName,
      ref: inputRefs.current,
      getValue: (refs: HTMLInputElement[]) => {
        return refs.find((ref) => ref.checked)?.value || '';
      },
      setValue: (refs: HTMLInputElement[], value: string | number) => {
        const inputRef = refs.find((ref) => ref.value === value?.toString());
        if (inputRef) inputRef.checked = true;
      },
      clearValue: (refs: HTMLInputElement[]) => {
        const inputRef = refs.find((ref) => ref.checked === true);
        if (inputRef) inputRef.checked = false;
      },
    });
  }, [defaultValue, fieldName, registerField]);

  return (
    <>
      {options.map((option, index) => (
        <Container key={option.id} id={`OP${option.id}`}>
          <label htmlFor={option.id}>
            <input
              ref={(ref) => ref && (inputRefs.current[index] = ref)}
              id={name + '-' + option.id}
              type="radio"
              name={name}
              defaultChecked={defaultValue.includes(option.id)}
              value={option.value}
              {...rest}
            />
            {option.label}
          </label>
        </Container>
      ))}
      <p>{error}</p>
    </>
  );
};

export default Option;
