export const estados = [
  {
    id: '3',
    value: '3',
    label: 'Amapá',
    name: 'AP',
  },
  {
    id: '4',
    value: '4',
    label: 'Amazonas',
    name: 'AM',
  },
  {
    id: '5',
    value: '5',
    label: 'Bahia',
    name: 'BA',
  },
  {
    id: '6',
    value: '6',
    label: 'Ceará',
    name: 'CE',
  },
  {
    id: '7',
    value: '7',
    label: 'Distrito Federal',
    name: 'DF',
  },
  {
    id: '8',
    value: '8',
    label: 'Espírito Santo',
    name: 'ES',
  },
  {
    id: '9',
    value: '9',
    label: 'Goiás',
    name: 'GO',
  },
  {
    id: '10',
    value: '10',
    label: 'Maranhão',
    name: 'MA',
  },
  {
    id: '11',
    value: '11',
    label: 'Mato Grosso',
    name: 'MT',
  },
  {
    id: '12',
    value: '12',
    label: 'Mato Grosso do Sul',
    name: 'MS',
  },
  {
    id: '13',
    value: '13',
    label: 'Minas Gerais',
    name: 'MG',
  },
  {
    id: '14',
    value: '14',
    label: 'Pará',
    name: 'PA',
  },
  {
    id: '15',
    value: '15',
    label: 'Paraíba',
    name: 'PB',
  },
  {
    id: '16',
    value: '16',
    label: 'Paraná',
    name: 'PR',
  },
  {
    id: '17',
    value: '17',
    label: 'Pernambuco',
    name: 'PE',
  },
  {
    id: '18',
    value: '18',
    label: 'Piauí',
    name: 'PI',
  },
  {
    id: '19',
    value: '19',
    label: 'Rio de Janeiro',
    name: 'RJ',
  },
  {
    id: '20',
    value: '20',
    label: 'Rio Grande do Norte',
    name: 'RN',
  },
  {
    id: '21',
    value: '21',
    label: 'Rio Grande do Sul',
    name: 'RS',
  },
  {
    id: '22',
    value: '22',
    label: 'Rondônia',
    name: 'RO',
  },
  {
    id: '23',
    value: '23',
    label: 'Roraima',
    name: 'RR',
  },
  {
    id: '24',
    value: '24',
    label: 'Santa Catarina',
    name: 'SC',
  },
  {
    id: '25',
    value: '25',
    label: 'São Paulo',
    name: 'SP',
  },
  {
    id: '26',
    value: '26',
    label: 'Sergipe',
    name: 'SE',
  },
  {
    id: '27',
    value: '27',
    label: 'Tocantins',
    name: 'TO',
  },
  {
    id: '28',
    value: '28',
    label: 'Nacional',
    name: 'BR',
  },
];

export const escolaridade = [
  {
    id: 1,
    value: 1,
    name: 'Educação infantil ',
  },
  {
    id: 2,
    value: 2,
    name: 'Ensino Fundamental ',
  },
  {
    id: 3,
    value: 3,
    name: 'Ensino Médio ',
  },
  {
    id: 4,
    value: 4,
    name: 'Superior (Graduação) ',
  },
  {
    id: 5,
    value: 5,
    name: 'Pós-graduação ',
  },
  {
    id: 6,
    value: 6,
    name: 'Mestrado ',
  },
  {
    id: 7,
    value: 7,
    name: 'Doutorado',
  },
];
