import React, { useEffect, useState } from 'react';
import cobre from '../../assets/medalhas/cobre.png';
import bronze from '../../assets/medalhas/bronze.png';
import prata from '../../assets/medalhas/prata.png';
import ouro from '../../assets/medalhas/ouro.png';

import Pie from '../Pie';
import api from '../../services/api';
import { FaArrowLeft, FaArrowRight } from 'react-icons/fa';

type SummaryMonth = {
  ano: number;
  mes: number;
  quantidade: number;
  completo: number;
};

interface IProps {
  aluno: number;
}

const months = [
  'Janeiro',
  'Fevereiro',
  'Março',
  'Abril',
  'Maio',
  'Junho',
  'Julho',
  'Agosto',
  'Setembro',
  'Outubro',
  'Novembro',
  'Dezembro',
];

const Medal: React.FC<IProps> = ({ aluno }) => {
  const [summaryMonth, setSummaryMonth] = useState<SummaryMonth>({} as SummaryMonth);
  const [percent, setPercent] = useState(0);
  const [classificacao, setClassificacao] = useState('');
  const [totalTasks, setTotalTasks] = useState(0);
  const [currentDate, setCurrentDate] = useState(() => {
    return new Date();
  });

  useEffect(() => {
    api
      .get(`habitos/summary-month/${aluno}`, {
        params: {
          dia: currentDate.toISOString(),
        },
      })
      .then((response) => {
        setSummaryMonth(response.data);
      });
  }, [currentDate]);

  useEffect(() => {
    api
      .get(`habitos/summary-total/${aluno}`, {
        params: {
          dia: currentDate.toISOString(),
        },
      })
      .then((response) => {
        setTotalTasks(response.data);
      });
  }, [currentDate]);

  useEffect(() => {
    const quantidade = totalTasks;
    const completo = summaryMonth.completo;
    const calculo = (completo / quantidade) * 100;
    setPercent(Math.round(calculo));
    if (calculo < 25) {
      setClassificacao('Cobre');
    } else if (calculo >= 25 && calculo <= 50) {
      setClassificacao('Bronze');
    } else if (calculo > 50 && calculo <= 75) {
      setClassificacao('Prata');
    } else if (calculo > 75) {
      setClassificacao('Ouro');
    } else {
      setClassificacao('Sem classificação');
    }
  }, [summaryMonth, totalTasks]);

  const changeNextMonth = () => {
    const dia = currentDate.getDate();
    let mes = currentDate.getMonth();
    let ano = currentDate.getFullYear();
    if (mes == 12) {
      mes = 1;
      ano = ano + 1;
    } else {
      mes = mes + 1;
    }
    setCurrentDate(new Date(ano, mes, dia));
  };

  const changePreviusMonth = () => {
    const dia = currentDate.getDate();
    let mes = currentDate.getMonth();
    let ano = currentDate.getFullYear();
    if (mes == 1) {
      mes = 12;
      ano = ano - 1;
    } else {
      mes = mes - 1;
    }
    setCurrentDate(new Date(ano, mes, dia));
  };

  return (
    <div>
      <div></div>
      <div className="h-[625px] md:h-[550px] py-4 px-8 bg-slate-100 rounded-xl text-slate-800 leading-none lg:gap-12 dark:bg-slate-800 dark:text-slate-400">
        <div>
          <div className="flex justify-between">
            <div className="pointer">
              <FaArrowLeft size={25} onClick={changePreviusMonth} />
            </div>
            <div className="font-semibold text-center h-min text-2xl">
              {months[currentDate.getMonth()]} {currentDate.getFullYear()}
            </div>
            <div className="pointer">
              <FaArrowRight size={25} onClick={changeNextMonth} />
            </div>
          </div>
          <div className="font-semibold text-center h-min text-2xl">Atividades realizadas</div>
          <div className="grid place-content-center relative">
            <Pie percentage={percent} colour="blue" />
          </div>
          <div className="flex gap-8 justify-center mt-auto font-medium text-lg xl:text-2xl">
            <div className="flex items-center gap-2">
              <span className="flex items-center gap-2">Planejadas</span>
              <span>{totalTasks}</span>
            </div>
            <div className="item">
              <span>Realizadas </span>
              <span>{summaryMonth.completo || 0}</span>
            </div>
          </div>
        </div>
        <div className="flex flex-col justify-center items-center p-10">
          <div>
            {percent < 25 && <img src={cobre} className="h-44 w-44" alt="cobre" />}
            {percent >= 25 && percent <= 50 && <img src={bronze} className="h-44 w-44" alt="bronze" />}
            {percent > 50 && percent <= 75 && <img src={prata} className="h-44 w-44" alt="prata" />}
            {percent > 75 && <img src={ouro} className="h-44 w-44" alt="ouro" />}
          </div>
          <span className="flex items-center mt-2 font-medium text-2xl">{classificacao}</span>
        </div>
      </div>
    </div>
  );
};

export default Medal;
