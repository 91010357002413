import React from 'react';

import { AuthProvider } from './auth';
import { ToastProvider } from './toast';

type Props = {
  children: JSX.Element;
};

const AppProvider: React.FC<Props> = ({ children }) => {
  return (
    <AuthProvider>
      <ToastProvider>{children}</ToastProvider>
    </AuthProvider>
  );
};

export default AppProvider;
