import { FormHandles } from '@unform/core';
import { Form } from '@unform/web';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { FiChevronLeft, FiPercent } from 'react-icons/fi';
import { Link, useNavigate, useParams } from 'react-router-dom';
import * as Yup from 'yup';
import Input from '../../components/Input';
import { useToast } from '../../hooks/toast';
import api from '../../services/api';

import { Container, Content, Esforco, Flex, Indicadores, InputGroup, Intensidade, Section, Td, TdLast, Th, Title } from './styles';
import Button from '../../components/Button';
import { Aluno } from '../../model/Aluno';
import _ from 'lodash';

const CardioRespiratoria: React.FC = () => {
  const { alunoid, id } = useParams();
  const navigate = useNavigate();
  const { addToast } = useToast();
  const formRef = useRef<FormHandles>(null);
  const [loading, setLoading] = useState(false);
  const [aluno, setAluno] = useState<Aluno>({} as Aluno);

  useEffect(() => {
    api
      .get('alunos/' + alunoid)
      .then((response) => {
        setAluno(response.data);
      })
      .then(() => {
        if (Number(id) !== 0) {
          api.get('cardiorespiratoria/' + id).then((response) => {
            formRef.current?.setData(response.data);
            calc5();
            calc6();
          });
        }
      })
      .catch((e) => {
        addToast({
          type: 'error',
          title: 'error',
        });
      });
  }, [id, alunoid, addToast]);

  useEffect(() => {
    calc0();
  }, [aluno]);

  const calc0 = () => {
    const valor = 208 - 0.7 * aluno.idade;
    formRef.current?.setFieldValue('fcmax', Math.round(valor));
    formRef.current?.setFieldValue('fcreserva', Math.round(valor));
  };

  const calc1 = (e: React.ChangeEvent<HTMLInputElement>) => {
    const fcrepouso = Number(e.target.value);
    const valor = 208 - 0.7 * aluno.idade;
    formRef.current?.setFieldValue('fcmax', Math.round(valor));
    formRef.current?.setFieldValue('fcreserva', Math.round(valor - fcrepouso));
  };

  const calc2 = (e: React.ChangeEvent<HTMLInputElement>) => {
    const valor = Number(e.target.value) / 100;
    const fcmax = Number(formRef.current?.getFieldValue('fcmax'));
    const fcrepouso = Number(formRef.current?.getFieldValue('fcrepouso'));
    const fcreserva = Number(formRef.current?.getFieldValue('fcreserva'));
    const min = fcreserva * valor + (fcrepouso || fcmax);

    formRef.current?.setFieldValue('zonaalvo.valormin', Math.round(min));
  };

  const calc3 = (e: React.ChangeEvent<HTMLInputElement>) => {
    const valor = Number(e.target.value) / 100;
    const fcmax = Number(formRef.current?.getFieldValue('fcmax'));
    const fcrepouso = Number(formRef.current?.getFieldValue('fcrepouso'));
    const fcreserva = Number(formRef.current?.getFieldValue('fcreserva'));
    const max = fcreserva * valor + (fcrepouso || fcmax);

    formRef.current?.setFieldValue('zonaalvo.valormax', Math.round(max));
  };

  const calc4 = (e: React.ChangeEvent<HTMLInputElement>) => {
    const valor = Number(e.target.value);
    const fcrepouso = Number(formRef.current?.getFieldValue('fcrepouso'));
    const fcmax = Number(formRef.current?.getFieldValue('fcmax'));
    const vo2max = ((valor - fcrepouso) / (fcmax - fcrepouso)) * 100;

    formRef.current?.setFieldValue('vo2max', Math.round(vo2max));
    calc5();
  };

  const calc5 = () => {
    const fcmax = Number(formRef.current?.getFieldValue('fcmax'));
    let fcrepouso = Number(formRef.current?.getFieldValue('fcrepouso'));
    const fcreserva = Number(formRef.current?.getFieldValue('fcreserva'));
    if (fcrepouso === 0) fcrepouso = fcmax;

    const var1 = fcreserva * 0.3 + fcrepouso;
    const var2 = fcreserva * 0.39 + fcrepouso;
    const var3 = (var1 + var2) / 2;
    const var4 = fcreserva * 0.4 + fcrepouso;
    const var5 = fcreserva * 0.59 + fcrepouso;
    const var6 = (var4 + var5) / 2;
    const var7 = fcreserva * 0.6 + fcrepouso;
    const var8 = fcreserva * 0.89 + fcrepouso;
    const var9 = (var7 + var8) / 2;
    const var10 = fcreserva * 0.9 + fcrepouso;

    formRef.current?.setFieldValue('zonatreino.muitoleve.max', Math.round(var1));
    formRef.current?.setFieldValue('zonatreino.leve.min', Math.round(var1));
    formRef.current?.setFieldValue('zonatreino.leve.max', Math.round(var2));
    formRef.current?.setFieldValue('zonatreino.levemoderado.min', Math.round(var3));
    formRef.current?.setFieldValue('zonatreino.levemoderado.max', Math.round(var6));
    formRef.current?.setFieldValue('zonatreino.moderado.min', Math.round(var4));
    formRef.current?.setFieldValue('zonatreino.moderado.max', Math.round(var5));
    formRef.current?.setFieldValue('zonatreino.moderadovigoroso.min', Math.round(var6));
    formRef.current?.setFieldValue('zonatreino.moderadovigoroso.max', Math.round(var9));
    formRef.current?.setFieldValue('zonatreino.vigoroso.min', Math.round(var7));
    formRef.current?.setFieldValue('zonatreino.vigoroso.max', Math.round(var8));
    formRef.current?.setFieldValue('zonatreino.proximomax.max', Math.round(var10));
  };

  const calc6 = () => {
    const tempo = Number(formRef.current?.getFieldValue('tempo'));
    const valor = Number(formRef.current?.getFieldValue('esforco'));
    const resultado = tempo * valor;
    formRef.current?.setFieldValue('cargainterna', resultado);
    let classificacao = '';
    if (valor < 3) {
      classificacao = 'Muito Leve';
    } else if (valor > 2 && valor < 5) {
      classificacao = 'Leve';
    } else if (valor > 4 && valor < 7) {
      classificacao = 'Moderado';
    } else if (valor > 6 && valor < 9) {
      classificacao = 'Vigoroso';
    } else {
      classificacao = 'Próximo ao Máximo';
    }

    formRef.current?.setFieldValue('intensidade', classificacao);
  };

  const handleSubmit = useCallback(
    async (data: any) => {
      try {
        formRef.current?.setErrors({});
        const schema = Yup.object().shape({});
        await schema.validate(data, {
          abortEarly: false,
        });
        setLoading(true);

        const _data = _.mapValues(data, (v) => (_.isEmpty(v) ? null : v));
        _data.zonaalvo = _.mapValues(data.zonaalvo, (v) => (_.isEmpty(v) ? null : v));
        _data.zonatreino = _.mapValues(data.zonatreino, (v) => (_.isEmpty(v) ? null : v));
        console.log(_data);

        if (Number(id) === 0) {
          await api.post('cardiorespiratoria', _data);
        } else {
          await api.put('cardiorespiratoria/' + id, _data);
        }
        setLoading(false);

        addToast({
          type: 'success',
          title: 'Gravado com sucesso!',
        });
        navigate(`/cliente/${alunoid}/questionarios`);
      } catch (error: any) {
        console.log(error);
        const msg = error.response.data.message;
        setLoading(false);
        addToast({
          type: 'error',
          title: 'Verifique o formulário ' + msg,
        });
      }
    },
    [alunoid, id, addToast, navigate]
  );

  return (
    <>
      {/* <Header /> */}
      <Container>
        <Content>
          <Title>
            <h1>CARDIO RESPIRATÓRIAS</h1>
            <Link to={`/cliente/${alunoid}/questionarios`}>
              <FiChevronLeft size={20} />
              <span>Voltar</span>
            </Link>
          </Title>
          <Form
            ref={formRef}
            onSubmit={handleSubmit}
            initialData={{ aluno: alunoid, id: id }}
            placeholder={undefined}
            onPointerEnterCapture={undefined}
            onPointerLeaveCapture={undefined}
          >
            <Section>
              <strong>APTIDÕES CARDIORRESPIRATÓRIAS</strong>
              <InputGroup>
                <div>
                  <label htmlFor="">FCrepouso</label>
                </div>
                <Input name="fcrepouso" onChange={calc1} />
              </InputGroup>
              <InputGroup>
                <div>
                  <label htmlFor="">FCmáx</label>
                </div>
                <Input name="fcmax" readOnly />
              </InputGroup>
              <InputGroup>
                <div>
                  <label htmlFor="">FCreserva</label>
                </div>
                <Input name="fcreserva" readOnly />
              </InputGroup>
              <InputGroup>
                <div>
                  <label htmlFor="">Zona alvo</label>
                </div>
                <Flex>
                  <div>
                    <div>Min</div>
                    <Input name="zonaalvo.min" subicon={FiPercent} onChange={calc2} />
                    <Input name="zonaalvo.valormin" disabled />
                  </div>
                  <div>
                    <div>Max</div>
                    <Input name="zonaalvo.max" subicon={FiPercent} onChange={calc3} />
                    <Input name="zonaalvo.valormax" disabled />
                  </div>
                </Flex>
              </InputGroup>
              <InputGroup>
                <div>
                  <label htmlFor="">FCesforço</label>
                </div>
                <Input name="fcesforco" onChange={calc4} />
              </InputGroup>
              <InputGroup>
                <div>
                  <label htmlFor="">%VO2max</label>
                </div>
                <Input name="vo2max" readOnly subicon={FiPercent} />
              </InputGroup>
            </Section>
            <Section>
              <strong>ZONAS DE TREINO</strong>
              <InputGroup>
                <div>
                  <label htmlFor="">Muito leve</label>
                </div>
                <Input name="zonatreino.muitoleve.min" readOnly value={'<'} />
                <Input name="zonatreino.muitoleve.max" readOnly />
              </InputGroup>
              <InputGroup>
                <div>
                  <label htmlFor="">Leve</label>
                </div>
                <Input name="zonatreino.leve.min" readOnly />
                <Input name="zonatreino.leve.max" readOnly />
              </InputGroup>
              <InputGroup>
                <div>
                  <label htmlFor="">Leve a moderado</label>
                </div>
                <Input name="zonatreino.levemoderado.min" readOnly />
                <Input name="zonatreino.levemoderado.max" readOnly />
              </InputGroup>
              <InputGroup>
                <div>
                  <label htmlFor="">Moderado</label>
                </div>
                <Input name="zonatreino.moderado.min" readOnly />
                <Input name="zonatreino.moderado.max" readOnly />
              </InputGroup>
              <InputGroup>
                <div>
                  <label htmlFor="">Moderado a vigoroso</label>
                </div>
                <Input name="zonatreino.moderadovigoroso.min" readOnly />
                <Input name="zonatreino.moderadovigoroso.max" readOnly />
              </InputGroup>
              <InputGroup>
                <div>
                  <label htmlFor="">Vigoroso</label>
                </div>
                <Input name="zonatreino.vigoroso.min" readOnly />
                <Input name="zonatreino.vigoroso.max" readOnly />
              </InputGroup>
              <InputGroup>
                <div>
                  <label htmlFor="">Próximo ao máximo</label>
                </div>
                <Input name="zonatreino.proximomax.min" defaultValue={'>='} readOnly />
                <Input name="zonatreino.proximomax.max" readOnly />
              </InputGroup>
            </Section>
            <Section>
              <Indicadores>
                <p>Referenciais de intensidade por indicador</p>
                <table>
                  <thead>
                    <tr>
                      <th>Intesidade</th>
                      <th>% VO2 Máx</th>
                      <th>% FC Máx</th>
                      <Th>% FC Reserva</Th>
                      <th>PSE(Foster)</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>Muito Leve</td>
                      <td>{'< 37'}</td>
                      <td>{'< 57'}</td>
                      <Td>{'< 30'}</Td>
                      <td>1-2</td>
                    </tr>
                    <tr>
                      <td>Leve</td>
                      <td>37-45</td>
                      <td>57-63</td>
                      <Td>30-39</Td>
                      <td>3-4</td>
                    </tr>
                    <tr>
                      <td>Moderado</td>
                      <td>46-63</td>
                      <td>64-76</td>
                      <Td>40-59</Td>
                      <td>5-6</td>
                    </tr>
                    <tr>
                      <td>Virogoso</td>
                      <td>64-90</td>
                      <td>77-95</td>
                      <Td>60-89</Td>
                      <td>7-8</td>
                    </tr>
                    <tr>
                      <td>Próximo ao Máximo</td>
                      <td>{'>= 91'}</td>
                      <td>{'>= 96'}</td>
                      <TdLast>{'>= 90'}</TdLast>
                      <td>9-10</td>
                    </tr>
                  </tbody>
                </table>
                <p>Fonte: American College Sports Medicine</p>
              </Indicadores>
            </Section>
            <Section>
              <strong>Escala de percepção de esforço(Foster) </strong>
              <Esforco>
                <div>
                  <div>0 - Repouso</div>
                  <div>1 - Muito, muito facil</div>
                  <div>2 - Fácil</div>
                  <div>3 - Moderado</div>
                  <div>4 - Um pouco pesado</div>
                  <div>5 - Pesado</div>
                  <div>6 - Pesado 2</div>
                  <div>7 - Muito pesado 1</div>
                  <div>8 - Muito pesado 2</div>
                  <div>9 - Muito pesado 3</div>
                  <div>10 - Máximo</div>
                  <div>
                    <p>1. Aplicar o PSE após o exercicio</p>
                    <p>2. Registrar o tempo da sessão em minutos</p>
                    <p>3. Calcular a carga interna do treino(CI): Unidades arbitrárias(UA) = PSE x Tempo</p>
                  </div>
                </div>
                <div>
                  <div>
                    <InputGroup>
                      <div>
                        <label htmlFor="">Tempo(min) sessão</label>
                        <Input name="tempo" onChange={calc6} />
                      </div>
                    </InputGroup>
                    <InputGroup>
                      <div>
                        <label htmlFor="">Valor</label>
                        <Input name="esforco" onChange={calc6} />
                      </div>
                      <div>
                        <label htmlFor="">Carga interna do treino(UA)</label>
                        <Input name="cargainterna" readOnly />
                      </div>
                    </InputGroup>
                    <InputGroup>
                      <div>
                        <label htmlFor="">Intensidade</label>
                        <Input name="intensidade" readOnly />
                      </div>
                    </InputGroup>
                  </div>
                </div>
              </Esforco>
              <Intensidade></Intensidade>
            </Section>
            <Button loading={loading} disabled={loading} type="submit">
              Gravar
            </Button>
            <div style={{ visibility: 'hidden' }}>
              <Input name="aluno" type="number" />
            </div>
          </Form>
        </Content>
      </Container>
    </>
  );
};

export default CardioRespiratoria;
