import React from 'react';
import { Container, Content, Schedule } from './styles';
import { HabitoForm } from './Form';

const Habito: React.FC = () => {
  return (
    <Container>
      <Content>
        <Schedule>
          <h1>Hábito</h1>
          <HabitoForm />
        </Schedule>
      </Content>
    </Container>
  );
};

export default Habito;
