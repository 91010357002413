import { initializeApp } from 'firebase/app';

const firebaseConfig = {
  apiKey: 'AIzaSyAzPuip9_OW_gOx3Ayarops19FSsWlTT5U',
  authDomain: 'atitude-vida-saudavel.firebaseapp.com',
  projectId: 'atitude-vida-saudavel',
  storageBucket: 'atitude-vida-saudavel.appspot.com',
  messagingSenderId: '643424141275',
  appId: '1:643424141275:web:85f658abdac57537d0f1fe',
  measurementId: 'G-EGD25C3RVQ',
};

export const app = initializeApp(firebaseConfig);
