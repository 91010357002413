import styled from 'styled-components';

export const Container = styled.div`
  /* background-color: #202024; */
`;

export const Header = styled.header`
  padding: 32px 0;
  background: #28262e;
`;

export const HeaderContent = styled.div`
  max-width: 1120px;
  margin: 0 auto;
  display: flex;
  align-items: center;

  > img {
    height: 50px;
  }

  button {
    margin-left: auto;
    background: transparent;
    border: 0;

    svg {
      color: #999591;
      width: 20px;
      height: 20px;
    }
  }
`;

export const Profile = styled.div`
  display: flex;
  align-items: center;
  margin-left: 80px;

  > svg {
  }

  div {
    display: flex;
    flex-direction: column;
    margin-left: 16px;
    line-height: 24px;

    span {
      color: #f4ede8;
    }

    strong {
      color: #ff9000;
    }
  }
`;

export const Content = styled.main`
  max-width: 1120px;
  margin: 64px auto;
  display: flex;
`;
export const Schedule = styled.div`
  flex: 1;
  width: 100%;

  h1 {
    font-size: 36px;
  }

  p {
    margin-top: 8px;
    color: #4430b9;
    display: flex;
    align-items: center;
    font-weight: 500;

    span {
      display: flex;
      align-items: center;
    }
    span + span::before {
      content: '';
      width: 1px;
      height: 12px;
      background: #ff9000;
      margin: 0 8px;
    }
  }
`;
export const NextAppointment = styled.div`
  margin-top: 64px;
  strong {
    color: #999591;
    font-size: 20px;
    font-weight: 400;
  }

  div {
    background: #3e3b47;
    display: flex;
    align-items: center;
    padding: 16px 24px;
    border-radius: 10px;
    margin-top: 24px;
    position: relative;

    &::before {
      position: absolute;
      height: 80%;
      width: 1px;
      left: 0;
      top: 10%;
      content: '';
      background: #ff9000;
    }

    > svg {
      width: 80px;
      height: 80px;
      border-radius: 50%;
    }

    strong {
      margin-left: 24px;
      color: #fff;
    }

    span {
      margin-left: auto;
      display: flex;
      align-items: center;
      color: #999591;

      svg {
        color: #ff9000;
        margin-right: 8px;
      }
    }
  }
`;

export const Section = styled.section`
  margin-top: 48px;
  > strong {
    color: #999591;
    font-size: 20px;
    line-height: 26px;
    border-bottom: 1px solid #3e3b47;
    display: block;
    padding-bottom: 16px;
    margin-bottom: 16px;
  }
`;

export const InputGroup = styled.div`
  display: flex;
  justify-content: space-between;
  flex-grow: 1;

  & + div {
    margin-top: 8px;
  }

  > div {
    width: 50%;
    & + div {
      margin-left: 8px;
    }
  }
`;

export const ValidadeGroup = styled.div`
  display: grid;
  grid-auto-flow: column;
  grid-auto-columns: 1fr;
  gap: 8px;

  div {
    > button {
      margin-top: 0px;
      background: #2dce89;
      :hover {
        background: #219965;
      }
    }
  }
`;

export const BlockGroup = styled.div`
  display: grid;
  grid-auto-flow: column;
  grid-auto-columns: 1fr;
  gap: 8px;

  button {
    margin-top: 0px;
    background: #ef1111;
    :hover {
      background: #b60e0e;
    }
  }
`;

export const VinculoInput = styled.div`
  display: grid;
  grid-template-columns: 2fr 0.5fr;

  > div {
  }
  button {
    margin-left: 10px;
    margin-top: 0px;
    height: 43px;
  }
`;

export const RowContainer = styled.ul`
  margin-top: 20px;
  list-style: none;
  width: 100%;
`;

export const Row = styled.li`
  position: relative;
  display: flex;
  -webkit-box-align: center;
  align-items: center;
  background-color: #fff;
  border-radius: 5px;
  padding-right: 8px;
  margin-bottom: 10px;
  height: 35px;
  box-shadow: 0 1px 3px rgb(50 50 93 / 15%), 0 1px 0 rgb(0 0 0 / 2%);
  border: 0;
  transition: box-shadow 0.15s ease;

  > div {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-left: 10px;
    -webkit-box-align: center;
    align-items: center;
    place-self: stretch flex-start;
    width: 100%;
    a {
      text-decoration: none;
      /* color: black; */
    }
    button {
      height: 25px;
      width: 120px;
      margin-top: 0px;
      background: red;
    }

    &:nth-child(2) {
      justify-content: end;
      cursor: pointer;
    }
  }
`;

export const Pagination = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
`;

interface PageProps {
  selected?: boolean;
}

export const Page = styled.div<PageProps>`
  display: flex;

  align-items: center;
  background-color: ${(props) => (props.selected ? '#0357c6' : '#1171ef')}; // rgb(41, 41, 46);
  min-width: 40px;
  height: 40px;
  border-radius: 6px;
  padding-left: 10px;
  padding-right: 10px;
  cursor: pointer;
  color: #fff;

  &:hover {
    background-color: #0357c6;
  }

  > div {
    margin: auto;
  }

  & + div {
    margin-left: 10px;
  }
`;

export const AddContainer = styled.div`
  justify-content: end;
  margin-left: 60rem;
  button {
    display: flex;
    height: 30px;
    div {
      display: flex;
      margin: auto;
      font-size: 18px;
    }
  }
`;
