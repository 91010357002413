import styled from 'styled-components';
import iconUser from '../../assets/icons/user.svg';
import avatarF from '../../assets/icons/avatar-F.svg';
import avatarM from '../../assets/icons/avatar-M.svg';
import parq from '../../assets/icons/clipboard.svg';
import anamnese from '../../assets/icons/form.svg';
import radar from '../../assets/icons/radar.svg';
import atestado from '../../assets/icons/stethoscope.svg';
import imc from '../../assets/icons/bmi.svg';
import neuroq from '../../assets/icons/brain.svg';
import objetivo from '../../assets/icons/progress-report.svg';
import user from '../../assets/icons/user.svg';
import habito from '../../assets/icons/exercise.svg';

export const Container = styled.div`
  width: 100%;
  max-width: 1152px;
  margin: 0px auto;
  padding-top: 4rem;
  padding-bottom: 4rem;
`;

export const Main = styled.main`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;

  > div {
    max-width: 70rem;
    width: 100%;
  }
`;

export const Welcome = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 4rem;

  > div {
    display: flex;
    align-items: center;

    > h2 {
      color: #525f7f;
      font-size: 2rem;
    }

    > div {
      display: flex;
      flex-direction: column;
      p {
        max-width: 148.43rem;
        font-size: 1rem;
        color: #525f7f;
        line-height: 160%;
      }

      & + p {
        margin-top: 0.75rem;
      }
    }
    > * + * {
      margin-left: 2rem;
    }
  }
`;

export const Pending = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 2rem;
  background: linear-gradient(to left, rgb(45, 38, 74), rgb(32, 32, 36));
  border-radius: 5px;
  transition: box-shadow 0.1s ease-out 0s;
`;

export const BannerLeft = styled.div`
  display: flex;
  flex-direction: column;

  h2 {
    font-size: 1.5rem;
    color: #e1e1e6;
    /* font-family: var(--fonts-default); */
    line-height: 125%;
    margin: 0px;
  }

  span {
    color: #c4c4cc;
    font-size: 0.875rem;
  }

  > * + * {
    margin-top: 0.5rem;
  }
`;
export const BannerRigth = styled.div`
  text-transform: uppercase;
  font-weight: bold;
  font-size: 0.875rem;
  color: #ffffff;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  gap: 1rem;

  strong {
    font-size: 1rem;
    color: #c4c4cc;
    line-height: 160%;
    margin: 0px;
  }

  > span {
    box-sizing: border-box;
    display: inline-block;
    overflow: hidden;
    width: initial;
    height: initial;
    background: none;
    opacity: 1;
    border: 0px;
    margin: 0px;
    padding: 0px;
    position: relative;
    max-width: 100%;

    > span {
      box-sizing: border-box;
      display: block;
      width: initial;
      height: initial;
      background: none;
      opacity: 1;
      border: 0px;
      margin: 0px;
      padding: 0px;
      max-width: 100%;

      img {
        position: absolute;
        inset: 0px;
        box-sizing: border-box;
        padding: 0px;
        border: none;
        margin: auto;
        display: block;
        width: 0px;
        height: 0px;
        min-width: 100%;
        max-width: 100%;
        min-height: 100%;
        max-height: 100%;
      }
    }
  }
`;

export const Content = styled.div`
  margin-top: 0.75rem;
  display: grid;
  /* grid-template-columns: 1fr 1fr; */
  gap: 0.75rem;
`;

export const Indicadores = styled.div`
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 2rem;

  /* > div {
    &:nth-child(1) {
      cursor: not-allowed;
    }
  } */
`;

export const Indicador = styled.div`
  display: flex;
  position: relative;
  cursor: pointer;
  background: linear-gradient(to right, rgb(11, 104, 238), rgb(32, 32, 36));
  border-radius: 5px;
  transition: box-shadow 0.1s ease-out 0s;
  font-size: 30px;
  color: #ffff;
  text-align: center;
  width: 15rem;
  height: 7rem;
  padding: 1rem;
  margin: 10px;
  > div {
    &:nth-child(1) {
      margin: auto;
      font-size: 35px;
    }
    &:nth-child(2) {
      text-align: end;

      > span {
        visibility: hidden;
        width: 120px;
        background-color: black;
        color: #fff;
        text-align: center;
        padding: 5px 0;
        border-radius: 6px;
        position: absolute;
        z-index: 1;
        left: 0;
      }
    }
  }

  &:hover {
    div {
      span {
        visibility: visible;
      }
    }
  }
`;

export const RowContainer = styled.ul`
  margin-top: 20px;
  list-style: none;
  width: 100%;
`;

export const Row = styled.li<any>`
  position: relative;
  display: flex;
  -webkit-box-align: center;
  align-items: center;
  background-color: #fff;
  border-radius: 5px;
  padding-right: 8px;
  margin-bottom: 10px;
  cursor: pointer;
  box-shadow: 0 1px 3px rgb(50 50 93 / 15%), 0 1px 0 rgb(0 0 0 / 2%);
  border: 0;
  transition: box-shadow 0.15s ease;

  > div {
    display: flex;
    flex-direction: row;
    -webkit-box-align: center;
    align-items: center;
    place-self: stretch flex-start;
    width: 100%;
    &:nth-child(1) {
      > div {
        background-color: rgb(18, 18, 20);
        margin-right: 16px;
        width: 50px;
        height: 50px;
        border-radius: 30px; //6px;
        /* background-image: url(${iconUser}); */
        background-image: url(${(props) => (props.sexo === 1 ? avatarM : avatarF)});
        background-repeat: no-repeat;
        background-size: cover;
        background-color: white;
      }
    }
    &:nth-child(2) {
      justify-content: flex-end;
    }

    strong {
      text-decoration: none;
      color: black;
    }
  }
`;

export const ParqIcon = styled.div`
  height: 70px;
  width: 70px;
  background-color: white;
  mask: url(${parq}) no-repeat center / contain;
  -webkit-mask: url(${parq}) no-repeat center / contain;
`;

export const AnamneseIcon = styled.div`
  height: 70px;
  width: 70px;
  background-color: white;
  mask: url(${anamnese}) no-repeat center / contain;
  -webkit-mask: url(${anamnese}) no-repeat center / contain;
`;

export const RadarIcon = styled.div`
  height: 70px;
  width: 70px;
  background-color: white;
  mask: url(${radar}) no-repeat center / contain;
  -webkit-mask: url(${radar}) no-repeat center / contain;
`;

export const AtestadoIcon = styled.div`
  height: 70px;
  width: 70px;
  background-color: white;
  mask: url(${atestado}) no-repeat center / contain;
  -webkit-mask: url(${atestado}) no-repeat center / contain;
`;

export const ImcIcon = styled.div`
  height: 70px;
  width: 70px;
  background-color: white;
  mask: url(${imc}) no-repeat center / contain;
  -webkit-mask: url(${imc}) no-repeat center / contain;
`;

export const NeuroQIcon = styled.div`
  height: 70px;
  width: 70px;
  background-color: white;
  mask: url(${neuroq}) no-repeat center / contain;
  -webkit-mask: url(${neuroq}) no-repeat center / contain;
`;

export const ObjetivoIcon = styled.div`
  height: 70px;
  width: 70px;
  background-color: white;
  mask: url(${objetivo}) no-repeat center / contain;
  -webkit-mask: url(${objetivo}) no-repeat center / contain;
`;

export const AlunoIcon = styled.div`
  height: 70px;
  width: 70px;
  background-color: white;
  mask: url(${user}) no-repeat center / contain;
  -webkit-mask: url(${user}) no-repeat center / contain;
`;

export const HabitosIcon = styled.div`
  height: 70px;
  width: 70px;
  background-color: white;
  mask: url(${habito}) no-repeat center / contain;
  -webkit-mask: url(${habito}) no-repeat center / contain;
`;
