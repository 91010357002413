
import React, { Fragment, useEffect, useState } from 'react';
import { Disclosure, Menu, Transition } from '@headlessui/react';
import { Bars3Icon, XMarkIcon } from '@heroicons/react/24/outline';
import { useAuth } from '../../hooks/auth';
import avatar from '../../assets/icons/avatar.svg';
import logo from '../../assets/common/logo-small.png';
import { Link } from 'react-router-dom';

const firebaseUrl = (foto: string) => {
  if (foto !== 'semfoto') {
    return `https://firebasestorage.googleapis.com/v0/b/atitude-vida-saudavel.appspot.com/o/perfil%2F${foto}?alt=media`;
  } else {
    return avatar;
  }
};

const navigationAdmin = [
  { name: 'Dashboard', href: '/dashboard', current: true },
  { name: 'Clientes', href: '/clientes', current: false },
  { name: 'Exercicios', href: '/exercicios', current: false },
  { name: 'Treinos', href: '/treinos', current: false },
  { name: 'Habitos', href: '/habitos', current: false },
  { name: 'Empresas', href: '/empresas', current: false },
  { name: 'Professores', href: '/professores', current: false },
  { name: 'Grupos', href: '/grupos', current: false },
  { name: 'Atividades', href: '/atividades', current: false },
  { name: 'Documentos', href: '/documentos', current: false },
  { name: 'Tutoriais', href: '/tutoriais', current: false },
  { name: 'Exportar', href: '/exportar', current: false },
  { name: 'Relatorio', href: '/relatorio', current: false },
];

const navigationCoodernador = [
  { name: 'Dashboard', href: '/dashboard', current: true },
  { name: 'Clientes', href: '/clientes', current: false },
  { name: 'Exercicios', href: '/exercicios', current: false },
  { name: 'Treinos', href: '/treinos', current: false },
  { name: 'Habitos', href: '/habitos', current: false },
  { name: 'Documentos', href: '/documentos', current: false },
  { name: 'Empresas', href: '/empresas', current: false },
  { name: 'Professores', href: '/professores', current: false },
  { name: 'Grupos', href: '/grupos', current: false },
  { name: 'Atividades', href: '/atividades', current: false },
  { name: 'Recuperar aluno', href: '/cliente/recuperar', current: false },
  { name: 'Exportar', href: '/exportar', current: false },
  { name: 'Tutoriais', href: '/tutoriais', current: false },
  // { name: 'Relatorio', href: '/relatorio', current: false },
];

const navigationProfessor = [
  { name: 'Dashboard', href: '/dashboard', current: true },
  { name: 'Clientes', href: '/clientes', current: false },
  { name: 'Grupos', href: '/grupos', current: false },
  { name: 'Exercicios', href: '/exercicios', current: false },
  { name: 'Treinos', href: '/treinos', current: false },
  { name: 'Habitos', href: '/habitos', current: false },
  { name: 'Documentos', href: '/documentos', current: false },
  { name: 'Atividades', href: '/atividades', current: false },
  { name: 'Recuperar aluno', href: '/cliente/recuperar', current: false },
  { name: 'Exportar', href: '/exportar', current: false },
  { name: 'Tutoriais', href: '/tutoriais', current: false },
  // { name: 'Relatorio', href: '/relatorio', current: false },
];

function classNames(...classes: string[]) {
  return classes.filter(Boolean).join(' ');
}

const Header: React.FC = () => {
  // const [menuShow, setMenuShow] = useState(false);
  const { signOut, foto, role } = useAuth();
  const [navigation, setNavigation] = useState(navigationProfessor);

  useEffect(() => {
    if (role.toUpperCase() === 'ADMIN') setNavigation(navigationAdmin);
    if (role.toUpperCase() === 'COORDENADOR') setNavigation(navigationCoodernador);
    if (role.toUpperCase() === 'PROFESSOR') setNavigation(navigationProfessor);
  }, [role]);

  // const handleShow = () => {
  //   setMenuShow(!menuShow);
  // };

  // useEffect(() => {
  //   window.onclick = (e) => {
  //     const menu = document.getElementById('menu');
  //     if (e.target !== menu) {
  //       setMenuShow(false);
  //     }
  //   };
  // }, []);

  return (
    <Disclosure as="nav" className="bg-white fixed w-full z-30">
      {({ open }) => (
        <>
          <div className="mx-auto max-w-8xl px-2 sm:px-6 lg:px-8">
            <div className="relative flex h-16 items-center justify-between">
              <div className="absolute inset-y-0 left-0 flex items-center sm:hidden">
                {/* Mobile menu button*/}
                <Disclosure.Button className="inline-flex items-center justify-center rounded-md p-2 text-gray-400 hover:bg-gray-700 hover:text-white focus:outline-none focus:ring-2 focus:ring-inset focus:ring-white">
                  <span className="sr-only">Open main menu</span>
                  {open ? (
                    <XMarkIcon className="block h-6 w-6" aria-hidden="true" />
                  ) : (
                    <Bars3Icon className="block h-6 w-6" aria-hidden="true" />
                  )}
                </Disclosure.Button>
              </div>
              <div className="flex flex-1 items-center justify-center sm:items-stretch sm:justify-start">
                <div className="flex flex-shrink-0 items-center">
                  <img className="block h-8 w-auto lg:hidden" src={logo} alt="Your Company" />
                  <img className="hidden h-8 w-auto lg:block" src={logo} alt="Your Company" />
                </div>
                <div className="hidden sm:ml-6 sm:block xl:hidden">
                  <div className="flex space-x-4">
                    {navigation.map((item) => (
                      <a
                        key={item.name}
                        href={item.href}
                        className={classNames(
                          item.current ? 'bg-gray-400 text-white' : 'text-gray-900 hover:bg-gray-400 hover:text-white',
                          'px-3 py-2 rounded-md text-sm font-medium'
                        )}
                        aria-current={item.current ? 'page' : undefined}
                      >
                        {item.name}
                      </a>
                    ))}
                  </div>
                </div>
              </div>
              <div className="absolute inset-y-0 right-0 flex items-center pr-2 sm:static sm:inset-auto sm:ml-6 sm:pr-0">
                {/* <button
                  type="button"
                  className="hidden lg:block rounded-full bg-gray-400 p-1 text-white hover:text-gray-200 focus:outline-none focus:ring-2 focus:ring-white focus:ring-offset-2 focus:ring-offset-gray-400"
                >
                  <span className="sr-only">View notifications</span>
                  <BellIcon className="h-6 w-6" aria-hidden="true" />
                </button> */}

                {/* Profile dropdown */}
                <Menu as="div" className="relative ml-3">
                  <div>
                    <Menu.Button className="flex rounded-full bg-gray-800 text-sm focus:outline-none focus:ring-2 focus:ring-white focus:ring-offset-2 focus:ring-offset-gray-800">
                      <span className="sr-only">Open user menu</span>
                      <img className="h-14 w-14 rounded-full" src={firebaseUrl(foto)} alt="" />
                    </Menu.Button>
                  </div>
                  <Transition
                    as={Fragment}
                    enter="transition ease-out duration-100"
                    enterFrom="transform opacity-0 scale-95"
                    enterTo="transform opacity-100 scale-100"
                    leave="transition ease-in duration-75"
                    leaveFrom="transform opacity-100 scale-100"
                    leaveTo="transform opacity-0 scale-95"
                  >
                    <Menu.Items className="absolute right-0 z-10 mt-2 w-48 origin-top-right rounded-md bg-white py-1 shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
                      <Menu.Item>
                        {({ active }) => (
                          <Link
                            to={'perfil'}
                            className={classNames(active ? 'bg-gray-100' : '', 'block px-4 py-2 text-sm text-gray-700 w-full text-left')}
                          >
                            Perfil
                          </Link>
                        )}
                      </Menu.Item>
                      <Menu.Item>
                        {({ active }) => (
                          <button
                            onClick={signOut}
                            className={classNames(active ? 'bg-gray-100' : '', 'block px-4 py-2 text-sm text-gray-700 w-full text-left')}
                          >
                            Sair
                          </button>
                        )}
                      </Menu.Item>
                    </Menu.Items>
                  </Transition>
                </Menu>
              </div>
            </div>
          </div>

          <Disclosure.Panel className="sm:hidden">
            <div className="space-y-1 px-2 pt-2 pb-3">
              {navigation.map((item) => (
                <Disclosure.Button
                  key={item.name}
                  as="a"
                  href={item.href}
                  className={classNames(
                    item.current ? 'bg-blue-900 text-white' : 'text-blue-900 hover:bg-blue-700 hover:text-white',
                    'block px-3 py-2 rounded-md text-base font-medium'
                  )}
                  aria-current={item.current ? 'page' : undefined}
                >
                  {item.name}
                </Disclosure.Button>
              ))}
            </div>
          </Disclosure.Panel>
        </>
      )}
    </Disclosure>
  );
};

export default Header;
