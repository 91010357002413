import { shade } from 'polished';
import styled from 'styled-components';

export const Container = styled.div``;

export const Main = styled.main`
  max-width: 1120px;
  margin: 64px auto;
  display: flex;
`;

export const Content = styled.div`
  flex: 1;
  /* margin-right: 120px; */

  h1 {
    font-size: 36px;
  }

  p {
    margin-top: 8px;
    /* color: #ff9000; */
    display: flex;
    align-items: center;
    font-weight: 500;

    span {
      display: flex;
      align-items: center;
    }
    span + span::before {
      content: '';
      width: 1px;
      height: 12px;
      background: #ff9000;
      margin: 0 10px;
    }
  }

  form {
    margin-top: 15px;
    font-size: 15px;

    > div {
      > label {
        font-weight: 500;
      }

      & + div {
        margin-top: 15px;
      }
    }
  }

  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
`;

export const Title = styled.div`
  display: flex;
  justify-content: space-between;

  h1 {
    color: #525f7f;
  }

  a {
    display: flex;
    flex-direction: row;
    justify-content: center;
    max-width: 15%;
    max-height: 35px;
    display: flex;
    text-align: center;
    background: #1171ef;
    height: 56px;
    border-radius: 10px;
    border: 0;
    padding: 0 16px;
    color: #ffffff;
    text-decoration: none;
    width: 100%;
    font-weight: 500;
    margin-left: 10px;
    transition: background-color 0.2s;

    &:hover {
      background: ${shade(0.2, '#1171ef')};
    }

    > * {
      margin: auto 0;
    }
  }
`;

export const SubTitle = styled.h2`
  margin-bottom: 15px;
`;

export const OptionGroup = styled.div`
  > div {
    margin-top: 15px;
  }
`;
