import styled from 'styled-components';

export const Container = styled.div`
  max-width: 1120px;
  margin: 64px auto;
  display: flex;
  flex-direction: column;

  > div {
    display: flex;
    flex-wrap: wrap;

    > div {
      flex: 50%;
      padding: 10px;
    }
  }
`;

export const Titulo = styled.h1`
  font-size: 36px;
  color: #525f7f;
  font-weight: 500;
  margin-bottom: 20px;
`;
