export function classificacao(valor: any) {
  switch (true) {
    case valor < 18.5:
      return { label: 'Peso abaixo do ideal', cor: 'orange' };
    case valor < 24.9:
      return { label: 'Peso normal', cor: 'greenyellow' };
    case valor < 29.9:
      return { label: 'Sobrepeso', cor: 'orange' };
    case valor < 34.9:
      return { label: 'Obesidade grau I', cor: 'red' };
    case valor < 39.9:
      return { label: 'Obesidade grau II', cor: 'red' };
    case valor > 40:
      return { label: 'Obesidade grau III', cor: 'red' };
    default:
      return { label: '' };
  }
}

export function classificacaoDobra(valor: any, idade: any, sexo: any) {
  switch (true) {
    case valor < 8 && idade < 26 && sexo === 1:
    case valor < 12 && idade > 25 && idade < 36 && sexo === 1:
    case valor < 16 && idade > 35 && idade < 46 && sexo === 1:
    case valor < 18 && idade > 45 && idade < 56 && sexo === 1:
    case valor < 18 && idade > 55 && idade < 66 && sexo === 1:
    case valor < 17 && idade < 26 && sexo === 2:
    case valor < 17 && idade > 25 && idade < 36 && sexo === 2:
    case valor < 20 && idade > 35 && idade < 46 && sexo === 2:
    case valor < 23 && idade > 45 && idade < 56 && sexo === 2:
    case valor < 24 && idade > 55 && idade < 66 && sexo === 2:
      return { label: 'Excelente', cor: 'greenyellow' };
    case valor < 12 && idade < 26 && sexo === 1:
    case valor < 16 && idade > 25 && idade < 36 && sexo === 1:
    case valor < 19 && idade > 35 && idade < 46 && sexo === 1:
    case valor < 20 && idade > 45 && idade < 56 && sexo === 1:
    case valor < 22 && idade < 66 && sexo === 1:
    case valor < 20 && idade < 26 && sexo === 2:
    case valor < 21 && idade > 25 && idade < 36 && sexo === 2:
    case valor < 24 && idade > 35 && idade < 46 && sexo === 2:
    case valor < 26 && idade > 45 && idade < 56 && sexo === 2:
    case valor < 27 && idade > 55 && idade < 66 && sexo === 2:
      return { label: 'Bom', cor: 'greenyellow' };
    case valor < 14 && idade < 26 && sexo === 1:
    case valor < 18 && idade > 25 && idade < 36 && sexo === 1:
    case valor < 21 && idade > 35 && idade < 46 && sexo === 1:
    case valor < 23 && idade > 45 && idade < 56 && sexo === 1:
    case valor < 24 && idade > 55 && idade < 66 && sexo === 1:
    case valor < 23 && idade < 26 && sexo === 2:
    case valor < 24 && idade > 25 && idade < 36 && sexo === 2:
    case valor < 27 && idade > 35 && idade < 46 && sexo === 2:
    case valor < 29 && idade > 45 && idade < 56 && sexo === 2:
    case valor < 30 && idade > 55 && idade < 66 && sexo === 2:
      return { label: 'Acima da Média', cor: 'greenyellow' };
    case valor < 17 && idade < 26 && sexo === 1:
    case valor < 22 && idade > 25 && idade < 36 && sexo === 1:
    case valor < 24 && idade > 35 && idade < 46 && sexo === 1:
    case valor < 26 && idade > 45 && idade < 56 && sexo === 1:
    case valor < 26 && idade > 55 && idade < 66 && sexo === 1:
    case valor < 26 && idade < 26 && sexo === 2:
    case valor < 27 && idade > 25 && idade < 36 && sexo === 2:
    case valor < 30 && idade > 35 && idade < 46 && sexo === 2:
    case valor < 32 && idade > 45 && idade < 56 && sexo === 2:
    case valor < 33 && idade > 55 && idade < 66 && sexo === 2:
      return { label: 'Média', cor: 'greenyellow' };
    case valor < 20 && idade < 26 && sexo === 1:
    case valor < 24 && idade > 25 && idade < 36 && sexo === 1:
    case valor < 27 && idade > 35 && idade < 46 && sexo === 1:
    case valor < 28 && idade > 45 && idade < 56 && sexo === 1:
    case valor < 28 && idade > 55 && idade < 66 && sexo === 1:
    case valor < 29 && idade < 26 && sexo === 2:
    case valor < 31 && idade > 25 && idade < 36 && sexo === 2:
    case valor < 33 && idade > 35 && idade < 46 && sexo === 2:
    case valor < 35 && idade > 45 && idade < 56 && sexo === 2:
    case valor < 36 && idade > 55 && idade < 66 && sexo === 2:
      return { label: 'Abaixo da Média', cor: 'greenyellow' };
    case valor < 26 && idade < 26 && sexo === 1:
    case valor < 28 && idade > 25 && idade < 36 && sexo === 1:
    case valor < 30 && idade > 35 && idade < 46 && sexo === 1:
    case valor < 30 && idade > 45 && idade < 56 && sexo === 1:
    case valor < 32 && idade > 55 && idade < 66 && sexo === 1:
    case valor < 33 && idade < 26 && sexo === 2:
    case valor < 36 && idade > 25 && idade < 36 && sexo === 2:
    case valor < 38 && idade > 35 && idade < 46 && sexo === 2:
    case valor < 39 && idade > 45 && idade < 56 && sexo === 2:
    case valor < 39 && idade > 55 && idade < 66 && sexo === 2:
      return { label: 'Ruim', cor: 'greenyellow' };
    case valor >= 26 && idade < 26 && sexo === 1:
    case valor >= 28 && idade > 25 && idade < 36 && sexo === 1:
    case valor >= 30 && idade > 35 && idade < 46 && sexo === 1:
    case valor >= 30 && idade > 45 && idade < 56 && sexo === 1:
    case valor >= 32 && idade > 55 && idade < 66 && sexo === 1:
    case valor >= 33 && idade < 26 && sexo === 2:
    case valor >= 36 && idade > 25 && idade < 36 && sexo === 2:
    case valor >= 38 && idade > 35 && idade < 46 && sexo === 2:
    case valor >= 39 && idade > 45 && idade < 56 && sexo === 2:
    case valor >= 39 && idade > 55 && idade < 66 && sexo === 2:
      return { label: 'Muito Ruim', cor: 'Red' };
    default:
      return { label: '', cor: '#fff' };
  }
}

export const classificacaoRcq = (resp: any) => {
  const result = { texto: '', color: '' };
  if (resp.sexo === 1 && resp.cintura >= 94) {
    result.color = 'red';
    result.texto = 'Risco de doencas cardiovasculares e metabólicas';
  } else if (resp.sexo === 1 && resp.cintura < 94) {
    result.color = 'lawngreen';
    result.texto = 'Risco reduzido';
  } else if (resp.sexo === 2 && resp.cintura >= 80) {
    result.color = 'red';
    result.texto = 'Risco de doencas cardiovasculares e metabólicas';
  } else if (resp.sexo === 2 && resp.cintura < 80) {
    result.color = 'lawngreen';
    result.texto = 'Risco reduzido';
  } else {
    result.color = '';
  }
  return result;
};

export const classificacaoCintura = (sexo: any, cintura: any) => {
  const result = { texto: '', color: '' };
  if (sexo === 1 && cintura >= 94) {
    result.color = 'red';
    result.texto = 'Risco de doencas cardiovasculares e metabólicas';
  } else if (sexo === 1 && cintura < 94) {
    result.color = 'lawngreen';
    result.texto = 'Risco reduzido';
  } else if (sexo === 2 && cintura >= 80) {
    result.color = 'red';
    result.texto = 'Risco de doencas cardiovasculares e metabólicas';
  } else if (sexo === 2 && cintura < 80) {
    result.color = 'lawngreen';
    result.texto = 'Risco reduzido';
  } else {
    result.color = '';
  }
  return result;
};

export const colorCardWr1 = (resp: any) => {
  switch (resp) {
    case 'Nenhum':
    case 'Não':
    case 'Nunca':
    case 'FUMA mais de 20 cigarros p/dia':
    case 'nunca ou quase nunca':
    case 'Nunca ou raramente':
    case 'Ruim':
    case 'Nada':
    case 'Muito insatisfeito':
      return 'red';
    case '1 vez':
    case '1 dia por semana':
    case '1x a 3x por semana':
    case 'FUMO de 10 a 20 cigarros p/ dia':
    case 'Muito pouco':
    case 'Insatisfeito':
    case 'Algumas vezes':
      return 'orange';
    case '2 vezes':
    case '2 dias por semana':
    case '4x a 6x por semana':
    case 'FUMO menos de 10 cigarros p/ dia':
    case 'As vezes':
    case 'Regular':
    case 'Mais ou menos':
    case 'Medio':
    case 'Nem insatisfeito nem satisfeito':
    case 'Frequentemente':
      return 'yellow';
    case '3 vezes':
    case '3 dias por semana':
      return 'yellowgreen';
    case '4 vezes':
    case '4 dias por semana':
    case 'Diariamente 1x por dia':
    case 'Parei de fumar ha menos de 2 anos':
    case 'Bom':
    case 'Bastante':
    case 'Muito':
    case 'Satisfeito':
    case 'Muito frequentemente':
      return 'greenyellow';
    case 'Nunca fumei':
    case 'Sempre ou quase sempre':
    case 'Excelente':
      return 'lawngreen';
    default:
      return 'lawngreen';
  }
};

export const colorCardWr7 = (resp: any) => {
  switch (resp) {
    case 'Diariamente 1x por dia':
    case 'Diariamente 2x ou mais por dia':
      return 'red';
    case '4x a 6x por semana':
      return 'orange';
    case '1x a 3x por semana':
      return 'yellow';
    case 'Não':
    case 'Nunca':
      return 'lawngreen';
    default:
      return 'lawngreen';
  }
};

export const colorCardWr8 = (resp: any) => {
  switch (resp) {
    case 'FUMA mais de 20 cigarros p/dia':
      return 'red';
    case 'FUMO de 10 a 20 cigarros p/ dia':
      return 'red';
    case 'FUMO menos de 10 cigarros p/ dia':
    case 'FUMO menos de 10 cigarros por dia':
      return 'orange';
    case '3 vezes':
      return 'yellow';
    case 'Parei de fumar ha menos de 2 anos':
    case 'Parei de fumar há menos de 2 anos':
      return 'greenyellow';
    case 'Nunca fumei':
      return 'lawngreen';
    default:
      return 'white';
  }
};

export function RadarFisicoDepara(resp: any) {
  switch (resp) {
    case 'Nenhum':
    case 'Entre 10 minutos e 15 minutos':
    case 'Nao':
      return 0;
    case '1 Vez':
    case 'Entre 15 minutos e 20 minutos':
    case '1 dia por semana':
      return 1;
    case '2 Vezes':
    case 'Entre 20 minutos e 30 minutos':
    case '2 dias por semana':
      return 2;
    case '3 Vezes':
    case 'mais que 30 minutos':
    case '3 dias por semana':
      return 3;
    case '4 Vezes':
    case '4 dias por semana':
      return 4;
    case '5 a 7 vezes':
    case '5 a 7 dias por semana':
      return 5;
    default:
      return 0;
  }
}

export function RadarRelacionamentoDepara(resp: any) {
  switch (resp) {
    case 'Muito satisfeito':
      return 5;
    case 'Satisfeito':
    case 'Sempre':
      return 4;
    case 'Quase sempre':
    case 'Nem insatisfeito e nem satisfeito':
      return 3;
    case 'Às vezes':
    case 'Insatisfeito':
      return 2;
    case 'Nunca ou raramente':
    case 'Muito insatisfeito':
      return 1;
    default:
      return 0;
  }
}

export function RadarCalculo2(n1: any, n2: any, n3: any, n4: any) {
  const total = n1 + n2 + n3 + n4;
  console.log('Relacionamento', total);
  if (total > 12) {
    return 'Muito Bom';
  }
  if (total > 10 && total <= 12) {
    return 'Bom Relacionamento';
  }
  if (total > 6 && total <= 10) {
    return 'Regular';
  }
  if (total <= 6) {
    return 'Ruim';
  }
}

export function RadarEmocionalDepara(resp: any) {
  switch (resp) {
    case 'Muito satisfeito':
    case 'Extremamente':
    case 'Completamente':
    case 'Sempre':
      return 5;
    case 'Satisfeito':
    case 'Bastante':
    case 'Muito':
    case 'Muito frequentemente':
      return 4;
    case 'Mais ou menos':
    case 'Médio':
    case 'Frequentemente':
    case 'Nem insatisfeito, nem satifeito':
      return 3;
    case 'Muito pouco':
    case 'Insatisfeito':
    case 'Algumas vezes':
      return 2;
    case 'Nada':
    case 'Muito insatisfeito':
    case 'Nunca':
      return 1;
    default:
      return 0;
  }
}

export function RadarCalculo3(n1: any, n2: any, n3: any, n4: any, n5: any, n6: any) {
  const total = (n1 + n2 + n3 + n4 + n5 + n6) / 6;
  console.log('Emocional', total);
  if (total >= 5) {
    return 'Muito Boa';
  }
  if (total >= 4) {
    return 'Boa';
  }
  if (total >= 3) {
    return 'Regular';
  }
  if (total >= 1) {
    return 'Precisa Melhorar';
  }
}
