import styled from 'styled-components';

export const Container = styled.div`
  background: #fff;
  border-radius: 10px;
  border: 1px solid #f1f1f1;
  padding: 10px;
  width: 100%;
  color: #525f7f;
  display: flex;
  align-items: center;
  box-shadow: 0 1px 3px rgb(50 50 93 / 15%), 0 1px 0 rgb(0 0 0 / 2%);
  transition: box-shadow 0.15s ease;

  & + div {
    /* margin-top: 8px; */
  }

  textarea {
    flex: 1;
    background: transparent;
    border: 0;
    color: #525f7f;
    width: 100%;

    &::placeholder {
      color: #666360;
    }
  }

  svg {
    /* margin-right: 16px; */
    &:nth-child(1) {
      margin-right: 16px;
    }
    &:nth-child(2) {
      margin-right: -10px;
    }
  }
`;
