import React, { useCallback, useEffect, useRef, useState } from 'react';
import { FormHandles } from '@unform/core';
import { Form } from '@unform/web';
import { FiCalendar, FiChevronLeft } from 'react-icons/fi';
import { Link, useNavigate, useParams } from 'react-router-dom';
import * as Yup from 'yup';

import { useToast } from '../../hooks/toast';

import { Aprove, Container, Content, Main, TextAprove, Title } from './styles';
import Input from '../../components/Input';
import api from '../../services/api';
import Button from '../../components/Button';
import getValidationErrors from '../../utils/getValidationErrors';
import FileInput from '../../components/FileInput';
import { app } from '../../utils/firebase';
import { getStorage, ref, uploadBytes, deleteObject } from 'firebase/storage';
import InputDate from '../../components/InputDate';
import { convertToDate } from '../../utils/converter';

const firebaseUrl = (foto: string) => {
  if (foto) {
    return `https://firebasestorage.googleapis.com/v0/b/atitude-vida-saudavel.appspot.com/o/atestados%2F${foto}?alt=media`;
  }
};

type AtestadoType = {
  aluno: Number;
  dataPrescricao: Date;
  exame: String;
  id: Number;
  insercao: Date;
  observacao: String;
  situacao: Number;
};

const Atestado: React.FC = () => {
  const { alunoid, id } = useParams();
  const navigate = useNavigate();
  const { addToast } = useToast();
  const formRef = useRef<FormHandles>(null);
  const [loading, setLoading] = useState(false);
  const [anexo, setAnexo] = useState('');
  const [situacao, setSituacao] = useState(0);
  const [atestado, setAtestado] = useState<AtestadoType>();
  const storage = getStorage(app);

  useEffect(() => {
    if (Number(id) !== 0) {
      setLoading(true);
      api
        .get(`atestados/${id}`)
        .then((response) => {
          setAnexo(response.data.exame);
          setSituacao(response.data.situacao);
          setAtestado(response.data);
          setLoading(false);
        })
        .catch((e) => {
          addToast({
            type: 'error',
            title: 'error',
          });
        });
    }
  }, [id, addToast]);

  const handleSubmit = useCallback(
    async (data: any) => {
      try {
        formRef.current?.setErrors({});
        const schema = Yup.object().shape({
          aluno: Yup.number().required('Aluno obrigatorio'),
          exame: Yup.string(),
        });

        await schema.validate(data, {
          abortEarly: false,
        });

        setLoading(true);
        const file = data.atestado;
        const time = new Date().getTime();
        const anexoName = time + '_' + file.name;
        await setAnexo(anexoName);
        const storageRef = ref(storage, 'atestados/' + anexoName);
        await uploadBytes(storageRef, file).then((snapshot) => {
          console.log('Uploaded a blob or file!');
        });
        const request = {
          aluno: alunoid,
          exame: anexoName,
          dataPrescricao: convertToDate(formRef.current?.getFieldValue('dataPescricao')),
          situacao: 2,
          observacao: formRef.current?.getFieldValue('observacao'),
        };

        await api.post('atestados ', request);
        setLoading(false);

        formRef.current?.reset();
        addToast({
          type: 'success',
          title: 'Gravado com sucesso!',
        });
        navigate(`/cliente/${alunoid}/questionarios`);
      } catch (error: any) {
        const storageRef = ref(storage, 'atestados/' + anexo);
        setLoading(false);
        deleteObject(storageRef);
        const errors = getValidationErrors(error);
        formRef.current?.setErrors(errors);
        error.inner.forEach((er: any) => {
          addToast({
            type: 'error',
            title: er.message,
          });
        });
        addToast({
          type: 'error',
          title: 'Verifique o formulário',
        });
      }
    },
    [alunoid, anexo, storage, addToast, navigate]
  );

  const Aprovar = useCallback(async () => {
    const data = { ...atestado, situacao: 2 };
    try {
      setLoading(true);
      api
        .put(`atestados/${id}`, data)
        .then(() => {
          setLoading(false);
          addToast({
            type: 'success',
            title: 'Aprovado',
          });
        })
        .catch((e) => {
          setLoading(false);
          addToast({
            type: 'error',
            title: 'Erro ao Aprovar',
          });
        });
    } catch (error) {
      setLoading(false);
      addToast({
        type: 'error',
        title: 'Erro ao Aprovar',
      });
    }
  }, [addToast, atestado, id]);

  const Rejeitar = useCallback(async () => {
    const data = { ...atestado, situacao: 3 };
    try {
      setLoading(true);
      api
        .put(`atestados/${id}`, data)
        .then(() => {
          setLoading(false);
          addToast({
            type: 'success',
            title: 'Rejeitado',
          });
        })
        .catch((e) => {
          setLoading(false);
          addToast({
            type: 'error',
            title: 'Erro ao Rejeitar',
          });
        });
    } catch (error) {
      setLoading(false);
      addToast({
        type: 'error',
        title: 'Erro ao Rejeitar',
      });
    }
  }, [addToast, atestado, id]);

  return (
    <>
      <Container>
        {/* <Header /> */}
        <Main>
          <Content>
            <Title>
              <h1>Atestado Médico</h1>
              <Link to={`/cliente/${alunoid}/questionarios`}>
                <FiChevronLeft size={20} />
                <span>Voltar</span>
              </Link>
            </Title>
            {Number(id) !== 0 && (
              <div style={{ display: 'flex' }}>
                <div>
                  {anexo.includes('jpg') && <img src={firebaseUrl(anexo)} alt="Preview" width={600} height={600} />}
                  {anexo.includes('pdf') && (
                    <object data={firebaseUrl(anexo)} width={600} height={600}>
                      pdf
                    </object>
                  )}
                </div>
                {situacao === 1 && (
                  <Aprove>
                    <Button onClick={Aprovar} loading={loading}>
                      Aprovar
                    </Button>
                    <Button onClick={Rejeitar} loading={loading}>
                      Rejeitar
                    </Button>
                  </Aprove>
                )}
                {situacao === 2 && <TextAprove>Atestado Aprovado</TextAprove>}
                {situacao === 3 && <TextAprove>Atestado Rejeitado</TextAprove>}
              </div>
            )}
            {Number(id) === 0 && (
              <>
                <p>
                  É obrigatório que o exame médico de liberação para prática da atividade física tenha assinatura e carimbo com o CRM do
                  médico.
                </p>
                <Form
                  ref={formRef}
                  onSubmit={handleSubmit}
                  initialData={{ aluno: alunoid }}
                  placeholder={undefined}
                  onPointerEnterCapture={undefined}
                  onPointerLeaveCapture={undefined}
                >
                  <div>
                    <FileInput name="atestado" />
                  </div>
                  <div>
                    <InputDate name="dataPescricao" placeholder="dd/mm/aaaa" subicon={FiCalendar} />
                  </div>
                  <div>
                    <Input name="observacao" />
                  </div>
                  <Button loading={loading} disabled={loading} type="submit">
                    Gravar
                  </Button>
                  <div style={{ visibility: 'hidden' }}>
                    <Input name="aluno" type="number" />
                  </div>
                </Form>
              </>
            )}
          </Content>
        </Main>
      </Container>
    </>
  );
};

export default Atestado;
