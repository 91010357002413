import React from 'react';
import { Container, Page } from './styles';

interface Props {
  currentPage: number;
  pages: number;
  handleGotoPage(page: number): void;
}

const Pagination: React.FC<Props> = ({ currentPage, pages, handleGotoPage }) => {
  const MAX_ITEMS = 9;
  const MAX_LEFT = (MAX_ITEMS - 1) / 2;
  const first = Math.max(currentPage - MAX_LEFT, 1);
  const anterior = Math.max(currentPage - 1, 1);
  return (
    <Container>
      <Page onClick={() => handleGotoPage(anterior)}>Anterior</Page>
      {Array.from(
        { length: MAX_ITEMS },
        (_, i) =>
          i + first <= pages && (
            <Page key={i} selected={i + first === currentPage} onClick={() => handleGotoPage(i + first)}>
              <div>{i + first}</div>
            </Page>
          )
      )}
      <Page onClick={() => handleGotoPage(currentPage + 1)}>Próximo</Page>
    </Container>
  );
};

export default Pagination;
