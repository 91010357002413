import React from 'react';

type Props = {
  url: string;
  nome: string;
};

const Video: React.FC<Props> = ({ url, nome }) => {
  const isYouTube = url.includes('youtube');
  const isVimeo = url.includes('player.vimeo');

  let iframeSrc;
  if (isYouTube) {
    iframeSrc = url;
  } else if (isVimeo) {
    iframeSrc = url;
  } else {
    const parts = url.split('/');
    iframeSrc = `https://player.vimeo.com/video/${parts[3]}?h=${parts[4]}`;
  }

  return (
    <iframe
      src={iframeSrc}
      width="100%"
      height="100%"
      allow="autoplay; fullscreen; picture-in-picture"
      style={{ margin: 'auto' }}
      title={nome}
    ></iframe>
  );
};

export default Video;
