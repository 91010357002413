/* eslint no-eval: 0 */

import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { FiCalendar } from 'react-icons/fi';
import { Container, Content, Schedule, Section, InputGroup } from './styles';

import { Form } from '@unform/web';
import { FormHandles } from '@unform/core';
import * as Yup from 'yup';

import { useToast } from '../../hooks/toast';
import Input from '../../components/Input';
import InputDate from '../../components/InputDate';
import Button from '../../components/Button';
import Select from '../../components/Select';
import api from '../../services/api';
import Checkbox from '../../components/Checkbox/V2';
import Modal from '../../components/Modal';
import Order from './order';
import ModalExercicios from './order/modalExercicios';
import { convertToDate } from '../../utils/converter';
import _ from 'lodash';
import Option from '../../components/SingleOption';
import TextArea from '../../components/TextArea';

const Treino: React.FC = () => {
  const { id } = useParams();
  const { addToast } = useToast();
  const navigate = useNavigate();
  const formRef = useRef<FormHandles>(null);
  const [loading, setLoading] = useState(false);
  const [alunos, setAlunos] = useState<any>([]);
  const [exercicios, setExercicios] = useState<any>([]);
  const [exerciciosaux, setExerciciosAux] = useState<any>([]);
  const [treino, setTreino] = useState<any>({});
  const [edit, setEdit] = useState(false);
  const [modal, setModal] = useState(false);
  const [progrupo, setPorGrupo] = useState(false);
  const [grupos, setGrupos] = useState([]);
  const [confirmExcluir, setConfirmExcluir] = useState(false);

  useEffect(() => {
    api
      .get('alunos/all')
      .then((response) => {
        // eslint-disable-next-line
        response.data.map((r: any) => ((r.value = r.id), (r.name = r.nomeCompleto)));
        setAlunos(response.data);
      })
      .catch((error) => {
        addToast({
          type: 'error',
          title: error.message,
        });
      });
  }, [addToast]);

  useEffect(() => {
    api
      .get('grupos/all')
      .then((response) => {
        setGrupos(response.data);
      })
      .catch((error) => {
        addToast({
          type: 'error',
          title: error.message,
        });
      });
  }, [addToast]);

  useEffect(() => {
    if (Number(id) > 0) {
      api
        .get(`treinos/${id}`)
        .then((response) => {
          formRef.current?.setData(response.data);
          setTreino(response.data);
          setEdit(true);
        })
        .then(() => {
          api.get('exercicios/all').then((response) => {
            response.data.map((r: any) => (r.exercicio = r.id));
            setExerciciosAux(response.data);
          });
        });
    }
  }, [id]);

  useEffect(() => {
    if (_.isEmpty(treino) || exerciciosaux.length === 0) return;
    const exerciciosData = eval(treino.prescricao);
    exerciciosData.map((r: any) => (r.selected = true));
    exerciciosData.forEach((e: any) => {
      const item = exerciciosaux.find((a: any) => a.id === Number(e.id));
      if (!item) return;
      e.nome = item.nome;
      e.imagem = item.imagem;
      e.video = item.video;
    });
    setExercicios(exerciciosData);
    // formRef.current?.setFieldValue('exercicios', exerciciosData);
  }, [treino, exerciciosaux]);

  const handleSubmit = useCallback(
    async (data: any) => {
      try {
        formRef.current?.setErrors({});
        const schema = Yup.object().shape({});

        await schema.validate(data, {
          abortEarly: false,
        });

        setLoading(true);
        const request = {
          aluno: data.aluno,
          grupo: data.grupo,
          tipo: data.tipo,
          prescricao: JSON.stringify(data.exercicios),
          dataInicio: convertToDate(data.dataInicio),
          dataFinal: convertToDate(data.dataFinal),
          domingo: data.domingo,
          segunda: data.segunda,
          terca: data.terca,
          quarta: data.quarta,
          quinta: data.quinta,
          sexta: data.sexta,
          sabado: data.sabado,
          estrategia: data.estrategia,
        };

        // console.log(request);
        await api.post('treinos', request);

        setLoading(false);
        addToast({
          type: 'success',
          title: 'Registro salvo',
        });
        navigate(`/treinos`);
      } catch (err: any) {
        setLoading(false);
        console.log(err);
        // const errors = getValidationErrors(err);
        // formRef.current?.setErrors(errors);
        addToast({
          type: 'error',
          title: 'Erro ao gravar cadastro',
        });
      }
    },
    // eslint-disable-next-line
    [addToast, navigate, edit, id]
  );

  const excluirAluno = () => {
    try {
      setLoading(true);
      api
        .delete(`alunos/${id}`)
        .then(() => {
          navigate(`/alunos`);
          addToast({
            type: 'success',
            title: 'Excluido com sucesso',
          });
        })
        .catch(() => {
          addToast({
            type: 'error',
            title: 'Ocorreu um error ao excluir aluno',
          });
        });
      setLoading(false);
    } catch (error) {
      setConfirmExcluir(false);
      setLoading(false);
      addToast({
        type: 'error',
        title: 'Ocorreu um error ao excluir aluno',
      });
    }
  };

  const handleTipo = (e: any) => {
    const value = e.target.value;
    if (value === 'aluno') {
      setPorGrupo(false);
    } else {
      setPorGrupo(true);
    }
  };

  return (
    <>
      <Container>
        <Content>
          <Schedule>
            <h1>Treino</h1>
            <Form
              ref={formRef}
              onSubmit={handleSubmit}
              initialData={{ id: id }}
              placeholder={undefined}
              onPointerEnterCapture={undefined}
              onPointerLeaveCapture={undefined}
            >
              <Section>
                <InputGroup>
                  <InputDate name="dataInicio" placeholder="Inicio" subicon={FiCalendar} />
                  <InputDate name="dataFinal" placeholder="Final" subicon={FiCalendar} />
                </InputGroup>
              </Section>
              <Section style={{ marginTop: 20 }}>
                <InputGroup style={{ marginBottom: 20 }}>
                  <Option name="tipo" id="1" value={'aluno'} label="Por aluno" defaultChecked onChange={handleTipo} />
                  <Option name="tipo" id="2" value={'grupo'} label="Por grupo" onChange={handleTipo} />
                </InputGroup>
                {progrupo ? (
                  <InputGroup>
                    <Select name="grupo" id="grupo" placeholder="grupo" options={grupos} />
                  </InputGroup>
                ) : (
                  <InputGroup>
                    <Select name="aluno" id="aluno" placeholder="aluno" options={alunos} defaultValue={treino.aluno} />
                  </InputGroup>
                )}
              </Section>
              <Section style={{ display: 'flex', justifyContent: 'space-between', flexWrap: 'wrap', gap: '20px' }}>
                <Checkbox name="domingo" defaultChecked={false} label="Domingo" />
                <Checkbox name="segunda" defaultChecked={false} label="Segunda" />
                <Checkbox name="terca" defaultChecked={false} label="Terça" />
                <Checkbox name="quarta" defaultChecked={false} label="Quarta" />
                <Checkbox name="quinta" defaultChecked={false} label="Quinta" />
                <Checkbox name="sexta" defaultChecked={false} label="Sexta" />
                <Checkbox name="sabado" defaultChecked={false} label="Sabado" />
              </Section>
              <Section>
                <h3>Estrategia</h3>
                <TextArea name="estrategia" />
              </Section>
              <Section>
                <Button onClick={() => setModal(true)}>Adicionar Exercicios</Button>
                <Order list={exercicios} setList={setExercicios} />
              </Section>
              <div style={{ visibility: 'hidden' }}>
                <Input name="id" type="number" />
              </div>
              <Button loading={loading} type="submit">
                Gravar
              </Button>
            </Form>
            <ModalExercicios
              list={exercicios}
              setList={setExercicios}
              visible={modal}
              close={() => setModal(false)}
              confirm={() => console.log('')}
            />
            <Modal visible={confirmExcluir} close={() => setConfirmExcluir(false)}>
              <p style={{ justifyContent: 'center', marginBottom: 20 }}>EXCLUIR</p>
              <div>
                <p style={{ justifyContent: 'center' }}>Deseja realmente excluir o aluno?</p>
                <p style={{ justifyContent: 'center' }}>Essa operação não pode ser desfeita</p>
                <Button onClick={excluirAluno} loading={loading}>
                  Confirmar
                </Button>
              </div>
            </Modal>
          </Schedule>
        </Content>
      </Container>
    </>
  );
};

export default Treino;
