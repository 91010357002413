import { FormHandles } from '@unform/core';
import { Form } from '@unform/web';
import React, { useEffect, useRef, useState } from 'react';
import { FiSearch, FiPlusCircle, FiTrash } from 'react-icons/fi';
import { Link } from 'react-router-dom';

import Input from '../../../components/Input';
import Button from '../../../components/Button';
import Modal from '../../../components/Modal';

import Spinner from '../../../components/Spinner';
import { useToast } from '../../../hooks/toast';
import api from '../../../services/api';

import { Container, Content, RowContainer, Row, Schedule, Page, Pagination, Title, Search, ModalContent } from './styles';
import { useAuth } from '../../../hooks/auth';

const GrupoList: React.FC = () => {
  const formRef = useRef<FormHandles>(null);
  const { role } = useAuth();
  const { addToast } = useToast();
  const [loading, setLoading] = useState(false);
  const [list, setList] = useState<any>([]);
  const [pages, setPages] = useState(1);
  const [currentPage, setCurrentPage] = useState(1);
  const [text, setText] = useState<string>('');
  const [modal, setModal] = useState(false);
  const [aula, setAula] = useState({} as { id: number; nome: string });
  const [refresh, setRefresh] = useState(0);

  useEffect(() => {
    setLoading(true);
    api
      .get('grupos')
      .then((response) => {
        setList(response.data.list);
        setCurrentPage(response.data.currentPage);
        setPages(response.data.pages);
        setLoading(false);
      })
      .catch((error) => {
        setLoading(false);
        addToast({
          type: 'error',
          title: error.message,
        });
      });
  }, [addToast, refresh]);

  const handleGotoPage = (_page: number) => {
    if (_page > pages) return;
    setLoading(true);
    api
      .get(`grupos?&PageNumber=${_page}`)
      .then((resp) => {
        setList(resp.data.list);
        setCurrentPage(resp.data.currentPage);
        setPages(resp.data.pages);
        setLoading(false);
      })
      .catch((error) => {
        setLoading(false);
      });
  };

  const handleFilter = (e: React.ChangeEvent<HTMLInputElement>) => {
    setText(e.target.value || '');
  };

  const handleSearch = (e: any) => {
    if (e.key === 'Enter') {
      setLoading(true);
      const query = text ? `&Text=${text}` : '';
      api
        .get(`grupos?PageNumber=${1}${query}`)
        .then((resp) => {
          setList(resp.data.list);
          setCurrentPage(resp.data.currentPage);
          setPages(resp.data.pages);
          setLoading(false);
        })
        .catch((error) => {
          setLoading(false);
        });
    }
  };

  const showModal = (item: any) => {
    setAula(item);
    setModal(true);
  };

  const handleDelete = () => {
    try {
      setLoading(true);
      api.delete(`grupos/${aula.id}`).then(() => {
        setLoading(false);
        setModal(false);
        setRefresh(refresh + 1);
        addToast({
          type: 'success',
          title: 'Excluido com sucesso',
        });
      });
    } catch (error) {
      setLoading(false);
      addToast({
        type: 'error',
        title: 'Ocorreu um error ao excluir',
      });
    }
  };

  const handleSubmit = () => ({});

  return (
    <>
      <Container>
        {/* <Header /> */}
        <Content>
          <Schedule>
            <Title>
              <h1>Grupos</h1>
              {role !== 'Professor' && (
                <Link to={'/grupos/0'}>
                  <FiPlusCircle size={20} />
                  <span>Novo</span>
                </Link>
              )}
            </Title>
            <Form
              ref={formRef}
              onSubmit={handleSubmit}
              placeholder={undefined}
              onPointerEnterCapture={undefined}
              onPointerLeaveCapture={undefined}
            >
              <Search>
                <Input name="nome" placeholder="Buscar" icon={FiSearch} onChange={handleFilter} onKeyDown={handleSearch} />
              </Search>
            </Form>
            {!loading ? (
              <>
                <RowContainer>
                  {list.map((a: any, i: number) => (
                    <Row key={a.id}>
                      <div>
                        <div></div>
                        <strong>
                          <Link to={`/grupos/${a.id}`}>{a.nome}</Link>
                        </strong>
                      </div>
                      <div>
                        <strong>{a.tag}</strong>
                      </div>
                      {role !== 'Professor' && (
                        <div onClick={() => showModal(a)}>
                          <FiTrash size={25} color={'#ef1111'} />
                        </div>
                      )}
                    </Row>
                  ))}
                </RowContainer>
                <Pagination>
                  <Page onClick={() => handleGotoPage(currentPage - 1)}>Anterior</Page>
                  {Array.from({ length: pages }, (_, i) => (
                    <Page key={i} selected={i + 1 === currentPage} onClick={() => handleGotoPage(i + 1)}>
                      <div>{i + 1}</div>
                    </Page>
                  ))}
                  <Page onClick={() => handleGotoPage(currentPage + 1)}>Próximo</Page>
                </Pagination>
              </>
            ) : (
              <>
                <br />
                <Spinner />
              </>
            )}
          </Schedule>
        </Content>
        <Modal visible={modal} close={() => setModal(false)}>
          <ModalContent>
            <p style={{ marginBottom: 20 }}>EXCLUIR</p>
            <div>
              <p>{`Deseja realmente excluir a aula:`}</p>
              <strong>{aula.nome}</strong>
              <p>Essa operação não pode ser desfeita</p>
              <Button onClick={handleDelete} loading={loading}>
                Confirmar
              </Button>
            </div>
          </ModalContent>
        </Modal>
      </Container>
    </>
  );
};

export default GrupoList;
