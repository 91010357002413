import React, { useEffect, useState } from 'react';

import checklist from '../../assets/icons/checklist.png';

import { Card, Container, IconesContainer, ImageContainer, Main, Stats, Title, Unit, Wrapper } from './styles';
import { Link, useParams } from 'react-router-dom';
import api from '../../services/api';
import { useToast } from '../../hooks/toast';
import { FaEnvelope, FaStethoscope, FaWhatsapp } from 'react-icons/fa';
import Modal from '../../components/Modal';
import Resumo from '../../components/Resumo';

import objetivo from '../../assets/icons/objetivo.svg';
import clipboard from '../../assets/icons/clipboard.svg';
import form from '../../assets/icons/form.svg';
import radar from '../../assets/icons/health-condition.svg';
import brain from '../../assets/icons/brain.svg';
import stethoscope from '../../assets/icons/stethoscope.svg';
import neuromuscular from '../../assets/icons/chest.svg';
import measuring from '../../assets/icons/measuring-tape.svg';
import caliper from '../../assets/icons/caliper.svg';
import rcq from '../../assets/icons/rcq.svg';
import composicaocorporal from '../../assets/icons/bmi.svg';
import funcionalidadebasica from '../../assets/icons/funcionalidadebasica.png';
// import neuromuscular from '../../assets/icons/Neuromuscular.png';
import cardio from '../../assets/icons/cardio.png';
import passos from '../../assets/icons/passos.png';
import flexiteste from '../../assets/icons/flexiteste.png';
import gdlam from '../../assets/icons/gdlam.png';
import relatorio from '../../assets/icons/relatorio.png';
import medalha from '../../assets/icons/medal.svg';
// import gastocalorico from '../../assets/icons/gastocalorico.png'
import Medal from '../../components/Medal';

const Questionarios: React.FC = () => {
  const { aluno } = useParams();
  const { addToast } = useToast();
  const [nome, setNome] = useState('');
  const [celular, setCelular] = useState('');
  const [email, setEmail] = useState('');
  const [atestado, setAtestado] = useState(false);
  const [resumo, setResumo] = useState(false);
  const [viewMedal, setViewMedal] = useState(false);

  const Report = async () => {
    api
      .get('report/excel/' + aluno, { responseType: 'blob' })
      .then((res) => {
        const blob = res.data;
        const url = window.URL.createObjectURL(blob);
        const a = document.createElement('a');
        a.style.display = 'none';
        a.href = url;
        // the filename you want
        a.download = 'relatorio.xlsx';
        document.body.appendChild(a);
        a.click();
        window.URL.revokeObjectURL(url);
        alert('Download concluido!');
      })
      .catch((err) => {
        console.log(err);
        alert(`Erro ao Gerar relatorio`);
      });
  };

  useEffect(() => {
    api
      .get('alunos/' + aluno)
      .then((response) => {
        const { nome, sobrenome, celular, email } = response.data;
        setNome(`${nome} ${sobrenome}`);
        const whats = celular ? celular.replace(/[^0-9]/g, '') : '';
        setCelular(whats);
        setEmail(email);
      })
      .catch((e) => {
        addToast({
          type: 'error',
          title: 'error',
        });
      });
  }, [aluno, addToast]);

  useEffect(() => {
    api
      .get('atestados/pendente/' + aluno)
      .then((response) => {
        setAtestado(response.data !== '');
      })
      .catch((e) => {
        addToast({
          type: 'error',
          title: 'error',
        });
      });
  }, [aluno, addToast]);

  const showResumo = () => setResumo(true);
  const hideResumo = () => setResumo(false);

  const showMedal = () => setViewMedal(true);
  const hideMedal = () => setViewMedal(false);

  return (
    <Container>
      <Title>
        <div>
          <h1>{nome}</h1>
        </div>
        <IconesContainer>
          <a href={`https://wa.me/55${celular}`}>
            <FaWhatsapp size={30} />
          </a>
          <a href={`mailto:${email}`}>
            <FaEnvelope size={30} />
          </a>
          {/* <FaExclamationCircle size={30} onClick={showResumo} /> */}
          {atestado && <FaStethoscope size={30} />}
        </IconesContainer>
      </Title>
      <Main>
        <Wrapper>
          <Card>
            <ImageContainer>
              <img src={objetivo} alt="icon" />
            </ImageContainer>
            {/* <Level>#1</Level> */}
            <Unit>Objetivos</Unit>
            {/* <Description></Description> */}
            <Stats>
              <div>
                <div>
                  <Link to={`/cliente/${aluno}/objetivos/0`}>Novo</Link>
                </div>
                <div>
                  <Link to={`/cliente/${aluno}/avaliacoes`}>Historico</Link>
                </div>
              </div>
            </Stats>
          </Card>
        </Wrapper>
        <Wrapper>
          <Card>
            <ImageContainer>
              <img src={clipboard} alt="icon" />
            </ImageContainer>
            {/* <Level>#2</Level> */}
            <Unit>PAR-Q</Unit>
            {/* <Description></Description> */}
            <Stats>
              <div>
                <div>
                  <Link to={`/cliente/${aluno}/parq/0`}>Novo</Link>
                </div>
                <div>
                  <Link to={`/cliente/${aluno}/parq`}>Historico</Link>
                </div>
              </div>
            </Stats>
          </Card>
        </Wrapper>
        <Wrapper>
          <Card>
            <ImageContainer>
              <img src={stethoscope} alt="icon" />
            </ImageContainer>
            {/* <Level>#3</Level> */}
            <Unit>Atestado Médico</Unit>
            {/* <Description></Description> */}
            <Stats>
              <div>
                <div>
                  <Link to={`/cliente/${aluno}/atestado/0`}>Novo</Link>
                </div>
                <div>
                  <Link to={`/cliente/${aluno}/atestado`}>Historico</Link>
                </div>
              </div>
            </Stats>
          </Card>
        </Wrapper>
        <Wrapper>
          <Card>
            <ImageContainer>
              <img src={form} alt="icon" />
            </ImageContainer>
            {/* <Level>#4</Level> */}
            <Unit>Anamnese</Unit>
            {/* <Description></Description> */}
            <Stats>
              <div>
                <div>
                  <Link to={`/cliente/${aluno}/anamnese/0`}>Novo</Link>
                </div>
                <div>
                  <Link to={`/cliente/${aluno}/anamnese`}>Historico</Link>
                </div>
              </div>
            </Stats>
          </Card>
        </Wrapper>
        <Wrapper>
          <Card>
            <ImageContainer>
              <img src={radar} alt="icon" />
            </ImageContainer>
            {/* <Level>#5</Level> */}
            <Unit>Estilo de Vida</Unit>
            {/* <Description></Description> */}
            <Stats>
              <div>
                <div>
                  <Link to={`/cliente/${aluno}/radar/0`}>Novo</Link>
                </div>
                <div>
                  <Link to={`/cliente/${aluno}/radar`}>Historico</Link>
                </div>
              </div>
            </Stats>
          </Card>
        </Wrapper>
        <Wrapper>
          <Card>
            <ImageContainer>
              <img src={measuring} alt="icon" />
            </ImageContainer>
            {/* <Level>#6</Level> */}
            <Unit>Antropometria</Unit>
            {/* <Description></Description> */}
            <Stats>
              <div>
                <div>
                  <Link to={`/cliente/${aluno}/antropometria/0`}>Novo</Link>
                </div>
                <div>
                  <Link to={`/cliente/${aluno}/antropometria`}>Historico</Link>
                </div>
              </div>
            </Stats>
          </Card>
        </Wrapper>
        <Wrapper>
          <Card>
            <ImageContainer>
              <img src={caliper} alt="icon" />
            </ImageContainer>
            {/* <Level>#8</Level> */}
            <Unit>Dobras Cutâneas</Unit>
            {/* <Description></Description> */}
            <Stats>
              <div>
                <div>
                  <Link to={`/cliente/${aluno}/dobras/0`}>Novo</Link>
                </div>
                <div>
                  <Link to={`/cliente/${aluno}/dobras`}>Historico</Link>
                </div>
              </div>
            </Stats>
          </Card>
        </Wrapper>
        <Wrapper>
          <Card>
            <ImageContainer>
              <img src={composicaocorporal} alt="icon" />
            </ImageContainer>
            {/* <Level>#7</Level> */}
            <Unit>Composição Corporal</Unit>
            {/* <Description></Description> */}
            <Stats>
              <div>
                <div>
                  <Link to={`/cliente/${aluno}/composicaocorporatal/0`}>Novo</Link>
                </div>
                <div>
                  <Link to={`/cliente/${aluno}/composicaocorporatal`}>Historico</Link>
                </div>
              </div>
            </Stats>
          </Card>
        </Wrapper>
        <Wrapper>
          <Card>
            <ImageContainer>
              <img src={funcionalidadebasica} alt="icon" />
            </ImageContainer>
            {/* <Level>#8</Level> */}
            <Unit>Funcionalidades Básicas</Unit>
            {/* <Description></Description> */}
            <Stats>
              <div>
                <div>
                  <Link to={`/cliente/${aluno}/funcionalidadesbasicas/0`}>Novo</Link>
                </div>
                <div>
                  <Link to={`/cliente/${aluno}/funcionalidadesbasicas`}>Historico</Link>
                </div>
              </div>
            </Stats>
          </Card>
        </Wrapper>
        <Wrapper>
          <Card>
            <ImageContainer>
              <img src={neuromuscular} alt="icon" />
            </ImageContainer>
            {/* <Level>#8</Level> */}
            <Unit>Neuromuscular</Unit>
            {/* <Description></Description> */}
            <Stats>
              <div>
                <div>
                  <Link to={`/cliente/${aluno}/neuromuscular/0`}>Novo</Link>
                </div>
                <div>
                  <Link to={`/cliente/${aluno}/neuromuscular`}>Historico</Link>
                </div>
              </div>
            </Stats>
          </Card>
        </Wrapper>
        {/* <Wrapper>
            <Card>
              <ImageContainer>
                <img src={checklist} alt="icon" />
              </ImageContainer>
              
              <Unit>Flexiteste</Unit>
              // <Description></Description>
              <Stats>
                <div>
                  <div>
                    <Link to={`/cliente/${aluno}/flexiteste/0`}>Novo</Link>
                  </div>
                  <div>
                    <Link to={`/cliente/${aluno}/flexiteste`}>Historico</Link>
                  </div>
                </div>
              </Stats>
            </Card>
          </Wrapper> */}
        <Wrapper>
          <Card>
            <ImageContainer>
              <img src={cardio} alt="icon" />
            </ImageContainer>
            {/* <Level>#8</Level> */}
            <Unit>Cardio Respiratória</Unit>
            {/* <Description></Description> */}
            <Stats>
              <div>
                <div>
                  <Link to={`/cliente/${aluno}/cardio/0`}>Novo</Link>
                </div>
                <div>
                  <Link to={`/cliente/${aluno}/cardio`}>Historico</Link>
                </div>
              </div>
            </Stats>
          </Card>
        </Wrapper>
        <Wrapper>
          <Card>
            <ImageContainer>
              <img src={rcq} alt="icon" />
            </ImageContainer>
            {/* <Level>#8</Level> */}
            <Unit>VO2Max</Unit>
            {/* <Description></Description> */}
            <Stats>
              <div>
                <div>
                  <Link to={`/cliente/${aluno}/vo2max/0`}>Novo</Link>
                </div>
                <div>
                  <Link to={`/cliente/${aluno}/vo2max`}>Historico</Link>
                </div>
              </div>
            </Stats>
          </Card>
        </Wrapper>
        <Wrapper>
          <Card>
            <ImageContainer>
              <img src={passos} alt="icon" />
            </ImageContainer>
            {/* <Level>#8</Level> */}
            <Unit>Calculo Passos</Unit>
            {/* <Description></Description> */}
            <Stats>
              <div>
                <div>
                  <Link to={`/cliente/${aluno}/passos/0`}>Novo</Link>
                </div>
                <div>
                  <Link to={`/cliente/${aluno}/passos`}>Historico</Link>
                </div>
              </div>
            </Stats>
          </Card>
        </Wrapper>
        <Wrapper>
          <Card>
            <ImageContainer>
              <img src={checklist} alt="icon" />
            </ImageContainer>
            {/* <Level>#9</Level> */}
            <Unit>Respostas</Unit>
            {/* <Description></Description> */}
            <Stats>
              <div>
                <div style={{ height: 101.2 }}>
                  <Link to={`#`} onClick={Report}>
                    Exportar
                  </Link>
                </div>
              </div>
            </Stats>
          </Card>
        </Wrapper>
        <Wrapper>
          <Card>
            <ImageContainer>
              <img src={brain} alt="icon" />
            </ImageContainer>
            {/* <Level>#9</Level> */}
            <Unit>NeuroQ</Unit>
            {/* <Description></Description> */}
            <Stats>
              <div>
                <div>
                  <Link to={`/cliente/${aluno}/neuroq/0`}>Novo</Link>
                </div>
                <div>
                  <Link to={`/cliente/${aluno}/neuroq`}>Historico</Link>
                </div>
              </div>
            </Stats>
          </Card>
        </Wrapper>
        <Wrapper>
          <Card>
            <ImageContainer>
              <img src={flexiteste} alt="icon" style={{ width: 110 }} />
            </ImageContainer>
            {/* <Level>#9</Level> */}
            <Unit>Flexiteste</Unit>
            {/* <Description></Description> */}
            <Stats>
              <div>
                <div>
                  <Link to={`/cliente/${aluno}/flexiteste/0`}>Novo</Link>
                </div>
                <div>
                  <Link to={`/cliente/${aluno}/flexiteste`}>Historico</Link>
                </div>
              </div>
            </Stats>
          </Card>
        </Wrapper>
        <Wrapper>
          <Card>
            <ImageContainer>
              <img src={gdlam} alt="icon" style={{ width: 110 }} />
            </ImageContainer>
            {/* <Level>#9</Level> */}
            <Unit>GDLAM</Unit>
            {/* <Description></Description> */}
            <Stats>
              <div>
                <div>
                  <Link to={`/cliente/${aluno}/gdlam/0`}>Novo</Link>
                </div>
                <div>
                  <Link to={`/cliente/${aluno}/gdlam`}>Historico</Link>
                </div>
              </div>
            </Stats>
          </Card>
        </Wrapper>
        <Wrapper>
          <Card>
            <ImageContainer>
              <img src={relatorio} alt="icon" />
            </ImageContainer>
            {/* <Level>#9</Level> */}
            <Unit>Relatorio Basico</Unit>
            {/* <Description></Description> */}
            <Stats>
              <div>
                <div style={{ height: 90, display: 'flex', flexDirection: 'column', justifyContent: 'center' }}>
                  <Link to={`#`} onClick={showResumo}>
                    Abrir
                  </Link>
                </div>
              </div>
            </Stats>
          </Card>
        </Wrapper>
        <Wrapper>
          <Card>
            <ImageContainer>
              <img src={medalha} alt="icon" />
            </ImageContainer>
            {/* <Level>#9</Level> */}
            <Unit>Medalha das atividades</Unit>
            {/* <Description></Description> */}
            <Stats>
              <div>
                <div style={{ height: 90, display: 'flex', flexDirection: 'column', justifyContent: 'center' }}>
                  <Link to={`#`} onClick={showMedal}>
                    Abrir
                  </Link>
                </div>
              </div>
            </Stats>
          </Card>
        </Wrapper>
        <Wrapper>
          <Card>
            <ImageContainer>
              <img src={clipboard} alt="icon" />
            </ImageContainer>
            <Unit>Gasto Calorico</Unit>
            {/* // <Description></Description> */}
            <Stats>
              <div>
                <div>
                  <Link to={`/cliente/${aluno}/gastocalorico/0`}>Novo</Link>
                </div>
                <div>
                  <Link to={`/cliente/${aluno}/gastocalorico`}>Historico</Link>
                </div>
              </div>
            </Stats>
          </Card>
        </Wrapper>
      </Main>
      <Modal visible={resumo} close={hideResumo}>
        <Resumo aluno={Number(aluno)} />
      </Modal>
      <Modal visible={viewMedal} close={hideMedal}>
        <Medal aluno={Number(aluno)} />
      </Modal>
    </Container>
  );
};

export default Questionarios;
