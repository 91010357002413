import React, { ChangeEventHandler, InputHTMLAttributes, useEffect, useRef, useState } from 'react';
import { IconBaseProps } from 'react-icons';
import { useField } from '@unform/core';
import { format, isValid, parse } from 'date-fns';
import { Container } from './styles';
import FocusTrap from 'focus-trap-react';
import { DayPicker, SelectSingleEventHandler } from 'react-day-picker';
import { usePopper } from 'react-popper';
import ptBR from 'date-fns/locale/pt-BR';

import 'react-day-picker/dist/style.css';

interface InputProps extends InputHTMLAttributes<HTMLInputElement> {
  name: string;
  icon?: React.ComponentType<IconBaseProps>;
  subicon?: React.ComponentType<IconBaseProps>;
  handleclick?(): void;
  onauxfunction?(valor: any): void;
}

const InputDate: React.FC<InputProps> = ({
  name,
  icon: Icon,
  subicon: SubIcon,
  handleclick: handleClick,
  onauxfunction: onAuxFunction,
  ...rest
}) => {
  const inputRef = useRef(null);
  const { fieldName, defaultValue, error, registerField } = useField(name);
  const [selected, setSelected] = useState<Date>();
  const [inputValue, setInputValue] = useState<string>('');
  const [isPopperOpen, setIsPopperOpen] = useState(false);
  const popperRef = useRef<HTMLDivElement>(null);
  const [popperElement, setPopperElement] = useState<HTMLDivElement | null>(null);

  useEffect(() => {
    registerField({
      name: fieldName,
      ref: inputRef.current,
      path: 'value',
      setValue(_: any, value: Date) {
        setSelected(value);
      },
    });
  }, [fieldName, registerField]);

  const popper = usePopper(popperRef.current, popperElement, {
    placement: 'bottom-start',
  });

  const closePopper = () => {
    setIsPopperOpen(false);
  };

  const handleInputChange: ChangeEventHandler<HTMLInputElement> = (e) => {
    setInputValue(e.currentTarget.value);
    const date = parse(e.currentTarget.value, 'dd/MM/yyyy', new Date());
    if (isValid(date)) {
      // setSelected(date);
      if (onAuxFunction) {
        onAuxFunction(date);
      }
    } else {
      setSelected(undefined);
    }
  };

  const handleButtonClick = () => {
    setIsPopperOpen(true);
  };

  const handleDaySelect: SelectSingleEventHandler = (date: Date | undefined) => {
    setSelected(date);
    if (date) {
      setInputValue(format(date, 'dd/MM/yyyy'));
      closePopper();
      if (onAuxFunction) {
        onAuxFunction(date);
      }
    } else {
      setInputValue('');
    }
  };
  useEffect(() => {
    const d = selected?.toString() || new Date().toString();
    setInputValue(format(new Date(d), 'dd/MM/yyyy'));
  }, [selected]);

  return (
    <Container ref={popperRef}>
      {Icon && <Icon size={20} />}
      <input defaultValue={defaultValue} ref={inputRef} {...rest} onChange={handleInputChange} value={inputValue} maxLength={10} />
      {SubIcon && <SubIcon size={20} onClick={handleButtonClick} />}
      {error}
      {isPopperOpen && (
        <FocusTrap
          active
          focusTrapOptions={{
            initialFocus: false,
            allowOutsideClick: true,
            clickOutsideDeactivates: true,
            onDeactivate: closePopper,
            fallbackFocus: `#dialog`,
          }}
        >
          <div
            id="dialog"
            tabIndex={-1}
            style={popper.styles.popper}
            className="dialog-sheet"
            {...popper.attributes.popper}
            ref={setPopperElement}
            role="dialog"
          >
            <DayPicker
              locale={ptBR}
              initialFocus={isPopperOpen}
              // defaultMonth={selected}
              selected={selected}
              mode="single"
              captionLayout="dropdown"
              fromYear={1900}
              toYear={2025}
              onSelect={handleDaySelect}
            />
          </div>
        </FocusTrap>
      )}
    </Container>
  );
};

export default InputDate;
