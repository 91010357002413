import { FormHandles } from '@unform/core';
import { Form } from '@unform/web';
import { useEffect, useRef, useState } from 'react';
import Chart from 'react-apexcharts';

import Select from '../../components/Select';
import Spinner from '../../components/Spinner';
import { useToast } from '../../hooks/toast';
import api from '../../services/api';
import {
  BasicContainer,
  BoxCount,
  CadastroContainer,
  Container,
  Content,
  GrupoContainer,
  PerfilContainer,
  SaudeContainer,
  SaudeContainer2,
  Schedule,
} from './styles';
import { BarFour, BarOne, BarTwo, Donut } from '../../hooks/grafico';

var colors = ['#008FFB', '#00E396', '#FEB019', '#FF4560', '#775DD0', '#546E7A', '#26a69a', '#D10CE8', '#f95108', '#0c59e8'];

const mock = {
  options: {
    plotOptions: {
      pie: {
        donut: {
          labels: {
            show: true,
          },
        },
      },
    },
    labels: ['Atestado médico', 'Em atividade', 'Objetivos e Metas', 'PAR-Q'],
  },
  series: [9, 44, 3, 17],
};

const mock2 = {
  options: {
    chart: {
      id: 'basic-bar',
    },
    xaxis: {
      categories: ['Yoga', 'Ginástica Funcional', 'Corrida', 'Ginástica Localizada', 'Meditação', 'Ginástica Laboral'],
      labels: {
        style: {
          colors: colors,
          fontSize: '12px',
        },
      },
    },
    dataLabels: {
      enabled: true,
      formatter: function (val: any) {
        return val + '%';
      },
      // offsetY: -20,
      style: {
        fontSize: '12px',
        // colors: ['#304758'],
      },
    },
    colors: colors,
    plotOptions: {
      bar: {
        // columnWidth: '45%',
        distributed: true,
      },
    },
  },
  series: [
    {
      name: 'series-1',
      data: [31, 21, 19, 15, 8, 6],
    },
  ],
};

const mock3 = {
  options: {
    chart: {
      id: 'bar',
    },
    xaxis: {
      categories: ['Yoga', 'Ginástica Funcional', 'Corrida', 'Ginástica Localizada', 'Meditação', 'Ginástica Laboral'],
      labels: {
        style: {
          colors: colors,
          fontSize: '12px',
        },
      },
    },
    dataLabels: {
      enabled: true,
      formatter: function (val: any) {
        return val + '%';
      },
      // offsetY: -20,
      style: {
        fontSize: '12px',
        // colors: ['#304758'],
      },
    },
    colors: colors,
    plotOptions: {
      bar: {
        horizontal: true,
        distributed: true,
      },
    },
  },
  series: [
    {
      name: 'series-1',
      data: [31, 21, 19, 15, 8, 6],
    },
  ],
};

const mock4 = {
  series: [
    {
      name: 'Masculino',
      data: [7, 0, 1, 1, 0],
    },
    {
      name: 'Feminino',
      data: [-34, -4, -0, 49, 3],
    },
  ],
  options: {
    chart: {
      type: 'bar',
      height: 440,
      stacked: true,
    },
    colors: ['#008FFB', '#FF4560'],
    plotOptions: {
      bar: {
        borderRadius: 5,
        borderRadiusApplication: 'end', // 'around', 'end'
        borderRadiusWhenStacked: 'all', // 'all', 'last'
        horizontal: true,
        barHeight: '80%',
      },
    },
    dataLabels: {
      enabled: false,
    },
    stroke: {
      width: 1,
      colors: ['#fff'],
    },

    grid: {
      xaxis: {
        lines: {
          show: false,
        },
      },
    },
    yaxis: {
      min: -60,
      max: 60,
      title: {
        // text: 'Age',
      },
    },
    tooltip: {
      shared: false,
      x: {
        formatter: function (val: any) {
          return val;
        },
      },
      y: {
        formatter: function (val: any) {
          return Math.abs(val) + '%';
        },
      },
    },
    title: {
      text: 'Mauritius population pyramid 2011',
    },
    xaxis: {
      categories: ['Casado', 'Divorciado', 'Não declarado', 'Solteiro', 'Viúvo'],
      // title: {
      //   text: 'Percent',
      // },

      labels: {
        show: false,
        formatter: function (val: any) {
          return Math.abs(Math.round(val)) + '%';
        },
      },
    },
  },
};

const mock5 = {
  series: [
    {
      name: 'Marine Sprite',
      data: [44],
    },
    {
      name: 'Striking Calf',
      data: [53],
    },
  ],
  options: {
    chart: {
      type: 'bar',
      height: 350,
      stacked: true,
      stackType: '100%',
    },
    plotOptions: {
      bar: {
        horizontal: true,
        barHeight: '20%',
      },
    },
    stroke: {
      width: 1,
      colors: ['#fff'],
    },
    title: {
      text: '100% Stacked Bar',
    },
    xaxis: {
      categories: [2008],
      labels: {
        show: false,
      },
    },
    yaxis: {
      show: false,
    },
    tooltip: {
      y: {
        formatter: function (val: any) {
          return val + 'K';
        },
      },
    },
    fill: {
      opacity: 1,
    },
    legend: {
      position: 'top',
      horizontalAlign: 'left',
      offsetX: 40,
    },
  },
};

const Relatorio: React.FC = () => {
  const formRef = useRef<FormHandles>(null);
  const { addToast } = useToast();
  const [empresas, setEmpresas] = useState([]);
  const [grupos, setGrupos] = useState([]);
  // const [professor, setProfessor] = useState(0);
  const [empresa, setEmpresa] = useState(0);
  const [grupo, setGrupo] = useState(0);
  // const [show, setshow] = useState(false);
  const [loading, setLoading] = useState(false);
  // const [error, setError] = useState(false);
  const [dados, setDados] = useState<any>();

  const [data, setData] = useState<any>(mock);
  const [data2, setData2] = useState<any>(mock2);
  const [dataSexo, setDataSexo] = useState<any>(mock);
  const [faixaEtaria, setFaixaEtaria] = useState<any>(mock3);
  const [faixaEtaria2, setFaixaEtaria2] = useState<any>(mock4);
  const [estadoCivil, setEstadoCivil] = useState<any>(mock);
  const [estadoCivil2, setEstadoCivil2] = useState<any>(mock4);
  const [ini01, setIni01] = useState<any>(mock);
  const [ini02, setIni02] = useState<any>(mock);
  const [ini03, setIni03] = useState<any>(mock);
  const [ini04, setIni04] = useState<any>(mock);
  const [ini05, setIni05] = useState<any>(mock);
  const [ini06, setIni06] = useState<any>(mock);
  const [ini07, setIni07] = useState<any>(mock);
  const [ini08, setIni08] = useState<any>(mock);
  const [ini09, setIni09] = useState<any>(mock);
  const [ini10, setIni10] = useState<any>(mock);
  const [ini11, setIni11] = useState<any>(mock);
  const [ini12, setIni12] = useState<any>(mock5);
  const [ini13, setIni13] = useState<any>(mock);
  const [ini14, setIni14] = useState<any>(mock5);
  const [ini15, setIni15] = useState<any>(mock);
  const [ini16, setIni16] = useState<any>(mock5);
  const [ini17, setIni17] = useState<any>(mock5);
  const [ini18, setIni18] = useState<any>(mock5);
  const [ini19, setIni19] = useState<any>(mock5);
  const [ini20, setIni20] = useState<any>(mock5);
  const [ini21, setIni21] = useState<any>(mock5);
  const [ini22, setIni22] = useState<any>(mock5);
  const [ini23, setIni23] = useState<any>(mock5);
  const [ini24, setIni24] = useState<any>(mock5);
  const [wr01, setWr01] = useState<any>(mock);
  const [wr02, setWr02] = useState<any>(mock);
  const [wr03, setWr03] = useState<any>(mock);
  const [wr04, setWr04] = useState<any>(mock);
  const [wr05, setWr05] = useState<any>(mock);
  const [wr06, setWr06] = useState<any>(mock);
  const [wr07, setWr07] = useState<any>(mock);
  const [wr08, setWr08] = useState<any>(mock);
  const [wr09, setWr09] = useState<any>(mock);
  const [wr10, setWr10] = useState<any>(mock);
  const [wr11, setWr11] = useState<any>(mock);
  const [wr12, setWr12] = useState<any>(mock);
  const [wr13, setWr13] = useState<any>(mock);
  const [wr14, setWr14] = useState<any>(mock);
  const [wr15, setWr15] = useState<any>(mock);
  const [wr16, setWr16] = useState<any>(mock);
  const [wr17, setWr17] = useState<any>(mock);
  const [wr18, setWr18] = useState<any>(mock);
  const [wr19, setWr19] = useState<any>(mock);
  const [wr20, setWr20] = useState<any>(mock);
  const [data4, setData4] = useState<any>(mock4);

  useEffect(() => {
    if (!dados) return;

    const gf01 = {
      options: {
        plotOptions: {
          pie: {
            donut: {
              labels: {
                show: true,
              },
            },
          },
        },
        labels: ['Objetivos', 'PAR-Q', 'Atestado médico', 'Anamnese', 'Radar', 'NeuroQ', 'IMC'],
      },
      series: [
        dados.alunosObjetivos,
        dados.alunosParq,
        dados.alunosAtestado,
        dados.alunosAnamnese,
        dados.alunosRadar,
        dados.alunosNeuroQ,
        dados.alunosImc,
      ],
    };

    const grupos = dados.grupoQtd.map((x: any) => x.nome);
    const totais = dados.grupoQtd.map((x: any) => x.total);

    const gf02 = {
      options: {
        chart: {
          id: 'basic-bar',
        },
        xaxis: {
          categories: [...grupos],
          labels: {
            style: {
              colors: colors,
              fontSize: '12px',
            },
          },
        },
        dataLabels: {
          enabled: true,
          formatter: function (val: any) {
            return val + '%';
          },
          // offsetY: -20,
          style: {
            fontSize: '12px',
            // colors: ['#304758'],
          },
        },
        colors: colors,
        plotOptions: {
          bar: {
            // columnWidth: '45%',
            distributed: true,
          },
        },
      },
      series: [
        {
          name: 'series-1',
          data: [...totais],
        },
      ],
    };

    const sexoLabel = dados.sexoQtd.map((x: any) => x.nome);
    const sexoQtd = dados.sexoQtd.map((x: any) => x.total);

    const gf03 = {
      options: {
        plotOptions: {
          pie: {
            donut: {
              labels: {
                show: true,
              },
            },
          },
        },
        labels: [...sexoLabel],
      },
      series: [...sexoQtd],
    };

    const sumFaixaEtaria = dados.faixaEtariaQtd.reduce((accumulator: any, object: { total: any }) => {
      return accumulator + object.total;
    }, 0);

    const faixaEtariaLabel = dados.faixaEtariaQtd.map((x: any) => x.nome);
    const faixaEtariaQtd = dados.faixaEtariaQtd.map((x: any) => ((x.total / sumFaixaEtaria) * 100).toFixed(0));

    const gf04 = {
      options: {
        chart: {
          id: 'bar',
        },
        xaxis: {
          categories: [...faixaEtariaLabel],
          labels: {
            style: {
              colors: colors,
              fontSize: '12px',
            },
          },
        },
        dataLabels: {
          enabled: true,
          formatter: function (val: any) {
            return val + '%';
          },
          // offsetY: -20,
          style: {
            fontSize: '12px',
            // colors: ['#304758'],
          },
        },
        colors: colors,
        plotOptions: {
          bar: {
            horizontal: true,
            distributed: true,
          },
        },
      },
      series: [
        {
          name: 'series-1',
          data: [...faixaEtariaQtd],
        },
      ],
    };

    const estadoCivilLabel = dados.estadoCivilQtd.map((x: any) => x.nome);
    const estadoCivilQtd = dados.estadoCivilQtd.map((x: any) => x.total);

    const gf05 = {
      options: {
        plotOptions: {
          pie: {
            donut: {
              labels: {
                show: true,
              },
            },
          },
        },
        labels: [...estadoCivilLabel],
      },
      series: [...estadoCivilQtd],
    };

    const sumEstadoCivil = dados.estadoCivilQtd2.reduce((accumulator: any, object: { valueF: any; valueM: any }) => {
      return accumulator + object.valueF + object.valueM;
    }, 0);

    const estadoCivilF = dados.estadoCivilQtd2.map((x: any) => -((x.valueF / sumEstadoCivil) * 100).toFixed(0));
    const estadoCivilM = dados.estadoCivilQtd2.map((x: any) => ((x.valueM / sumEstadoCivil) * 100).toFixed(0));
    const estadoCivilCategorias = dados.estadoCivilQtd2.map((x: any) => x.label);

    const gf06 = {
      series: [
        {
          name: 'Masculino',
          data: [...estadoCivilM],
        },
        {
          name: 'Feminino',
          data: [...estadoCivilF],
        },
      ],
      options: {
        chart: {
          type: 'bar',
          height: 440,
          stacked: true,
        },
        colors: ['#008FFB', '#FF4560'],
        plotOptions: {
          bar: {
            borderRadius: 5,
            borderRadiusApplication: 'end', // 'around', 'end'
            borderRadiusWhenStacked: 'all', // 'all', 'last'
            horizontal: true,
            barHeight: '80%',
          },
        },
        dataLabels: {
          enabled: false,
        },
        stroke: {
          width: 1,
          colors: ['#fff'],
        },

        grid: {
          xaxis: {
            lines: {
              show: false,
            },
          },
        },
        yaxis: {
          min: -60,
          max: 60,
          title: {
            // text: 'Age',
          },
        },
        tooltip: {
          shared: false,
          x: {
            formatter: function (val: any) {
              return val;
            },
          },
          y: {
            formatter: function (val: any) {
              return Math.abs(val) + '%';
            },
          },
        },
        // title: {
        //   text: 'Mauritius population pyramid 2011',
        // },
        xaxis: {
          categories: [...estadoCivilCategorias],
          // title: {
          //   text: 'Percent',
          // },

          labels: {
            show: false,
            formatter: function (val: any) {
              return Math.abs(Math.round(val)) + '%';
            },
          },
        },
      },
    };
    const fxetaria2 = BarTwo(dados.faixaEtariaQtd2);
    const g_ini01 = Donut(dados.ini01Qtd);
    const g_ini02 = BarOne(dados.ini02Qtd);
    const g_ini03 = Donut(dados.ini03Qtd);
    const g_ini04 = BarOne(dados.ini04Qtd, true);
    const g_ini05 = BarOne(dados.ini05Qtd, true);
    const g_ini06 = Donut(dados.ini06Qtd);
    const g_ini07 = BarOne(dados.ini07Qtd);
    const g_ini08 = Donut(dados.ini08Qtd);
    const g_ini09 = BarOne(dados.ini09Qtd);
    const g_ini10 = Donut(dados.ini10Qtd);
    const g_ini11 = BarFour(dados.ini11Qtd, 'Quem recomendou ?');
    const g_ini12 = BarFour(dados.ini12Qtd, 'Plano de saúde ?');
    const g_ini13 = BarOne(dados.ini13Qtd, false, 'Plano');
    const g_ini14 = BarFour(dados.ini14Qtd, 'Estado de saúde atual');
    const g_ini15 = BarOne(dados.ini15Qtd, false, 'Doenças hereditárias');
    const g_ini16 = BarFour(dados.ini16Qtd, 'Diabetes');
    const g_ini17 = BarFour(dados.ini17Qtd, 'Hipertensão Arterial');
    const g_ini18 = BarFour(dados.ini18Qtd, 'Obesidade');
    const g_ini19 = BarFour(dados.ini19Qtd, 'Doença Respiratória');
    const g_ini20 = BarFour(dados.ini20Qtd, 'Doença Articular');
    const g_ini21 = BarFour(dados.ini21Qtd, 'Problema de Coluna');
    const g_ini22 = BarFour(dados.ini22Qtd, 'Tendinite e LER');
    const g_ini23 = BarFour(dados.ini23Qtd, 'Depressão');
    const g_ini24 = BarFour(dados.ini24Qtd, 'Transtorno de Ansiedade');
    const g_wr01 = BarOne(dados.wr01Qtd, false, 'Qtd de dias da semana que come legumes ou verduras');
    const g_wr02 = BarOne(dados.wr02Qtd, false, 'Qtd de dias da semana que come frutas');
    const g_wr03 = BarOne(dados.wr03Qtd, false, 'Qtd de dias da semana você toma refrigerantes ou sucos artificiais');
    const g_wr04 = BarFour(dados.wr04Qtd, 'Fumo de cigarros por dia');
    const g_wr05 = BarFour(dados.wr05Qtd, 'Ingestão de cinco ou mais doses de bebidas alcoólicas numa mesma ocasião');
    const g_wr06 = BarFour(dados.wr06Qtd, 'Frequência que considera dormir bem');
    const g_wr07 = BarOne(dados.wr07Qtd, false, 'Dias de CAMINHADA por pelo menos 10 minutos');
    const g_wr08 = BarOne(dados.wr08Qtd, false, 'A duração média por dia');
    const g_wr09 = BarOne(dados.wr09Qtd, false, 'Prática, regular de algum tipo de atividade física');
    const g_wr10 = BarOne(dados.wr10Qtd, false, 'A duração média por dia');
    const g_wr11 = BarFour(dados.wr11Qtd, 'Qualidade dos relacionamentos que mantém com outras pessoas');
    const g_wr12 = BarFour(dados.wr12Qtd, 'Dedicação voluntariamente, para algum projeto/ação social');
    const g_wr13 = BarFour(dados.wr13Qtd, 'Nível pessoal de integração/adaptação ao ambiente ao rodor');
    const g_wr14 = BarFour(dados.wr14Qtd, 'Utilidade na vida de alguém');
    const g_wr15 = BarFour(dados.wr15Qtd, 'Aproveitamento da vida');
    const g_wr16 = BarFour(dados.wr16Qtd, 'Sentido da própria vida');
    const g_wr17 = BarFour(dados.wr17Qtd, 'Capacidade de se concentrar');
    const g_wr18 = BarFour(dados.wr18Qtd, 'Capaciade de aceitar sua aparência física');
    const g_wr19 = BarFour(dados.wr19Qtd, 'Satisfação consigo');
    const g_wr20 = BarFour(dados.wr20Qtd, 'Frequência de sentimentos positivos');

    setData(gf01);
    setData2(gf02);
    setDataSexo(gf03);
    setFaixaEtaria(gf04);
    setFaixaEtaria2(fxetaria2);
    setEstadoCivil(gf05);
    setEstadoCivil2(gf06);
    setIni01(g_ini01);
    setIni02(g_ini02);
    setIni03(g_ini03);
    setIni04(g_ini04);
    setIni05(g_ini05);
    setIni06(g_ini06);
    setIni07(g_ini07);
    setIni08(g_ini08);
    setIni09(g_ini09);
    setIni10(g_ini10);
    setIni11(g_ini11);
    setIni12(g_ini12);
    setIni13(g_ini13);
    setIni14(g_ini14);
    setIni15(g_ini15);
    setIni16(g_ini16);
    setIni17(g_ini17);
    setIni18(g_ini18);
    setIni19(g_ini19);
    setIni20(g_ini20);
    setIni21(g_ini21);
    setIni22(g_ini22);
    setIni23(g_ini23);
    setIni24(g_ini24);
    setWr01(g_wr01);
    setWr02(g_wr02);
    setWr03(g_wr03);
    setWr04(g_wr04);
    setWr05(g_wr05);
    setWr06(g_wr06);
    setWr07(g_wr07);
    setWr08(g_wr08);
    setWr09(g_wr09);
    setWr10(g_wr10);
    setWr11(g_wr11);
    setWr12(g_wr12);
    setWr13(g_wr13);
    setWr14(g_wr14);
    setWr15(g_wr15);
    setWr16(g_wr16);
    setWr17(g_wr17);
    setWr18(g_wr18);
    setWr19(g_wr19);
    setWr20(g_wr20);
  }, [dados]);

  useEffect(() => {
    api
      .get('empresas/all')
      .then((response) => {
        setEmpresas(response.data);
      })
      .then(() => {
        api.get('grupos/all').then((response) => {
          setGrupos(response.data);
        });
      })
      .catch((error) => {
        addToast({
          type: 'error',
          title: error.message,
        });
      });
  }, [addToast]);

  useEffect(() => {
    setLoading(true);
    api
      .get('dashboard/relatorio')
      .then((response) => {
        setDados(response.data);
        setLoading(false);
      })
      .catch((error) => {
        addToast({
          type: 'error',
          title: error.message,
        });
        setLoading(false);
      });
  }, [addToast]);

  useEffect(() => {
    setLoading(true);
    // setError(false);
    setDados(null);
    api
      .get(`dashboard/relatorio?empresa=${empresa}&grupo=${grupo}`)
      .then((response) => {
        setDados(response.data);
        setLoading(false);
      })
      .catch((error) => {
        setLoading(false);
        // setError(true);
        addToast({
          type: 'error',
          title: error.message,
        });
      });
  }, [addToast, empresa, grupo]);

  const selectEmpresa = (e: any) => {
    setEmpresa(Number(e.target.value));
  };

  const selectGrupo = (e: any) => {
    setGrupo(Number(e.target.value));
  };

  return (
    <>
      <Container>
        <Content>
          <Schedule>
            <div style={{ marginBottom: 20 }}>
              <Form
                ref={formRef}
                onSubmit={() => ({})}
                placeholder={undefined}
                onPointerEnterCapture={undefined}
                onPointerLeaveCapture={undefined}
              >
                <Select name="empresa" placeholder="Todos" id="empresa" options={empresas} onChange={selectEmpresa} />
                <Select name="grupo" placeholder="Todos" id="grupo" options={grupos} onChange={selectGrupo} />
              </Form>
            </div>
            {dados && (
              <>
                <h1>Relatorio</h1>
                <CadastroContainer>
                  <div>
                    <div>
                      <strong>Cadastrados:</strong> Número de funcionários que já se cadastraram na plataforma.
                      <BoxCount>
                        <div>{dados.totalAlunos}</div>
                      </BoxCount>
                    </div>
                    <div>
                      <strong>Taxa de efetividade:</strong> Número de funcionários participantes das atividades em relação ao número
                      funcionários cadastrados.
                      <BoxCount>
                        <div>10</div>
                      </BoxCount>
                    </div>
                  </div>
                  <div>
                    <Chart options={data.options} series={data.series} type="donut" width="500" />
                  </div>
                </CadastroContainer>
                <GrupoContainer>
                  <Chart options={data2.options} series={data2.series} type="bar" width="1000" />
                </GrupoContainer>
                <PerfilContainer>
                  <Chart options={dataSexo.options} series={dataSexo.series} type="donut" width="400" />
                  <Chart options={faixaEtaria.options} series={faixaEtaria.series} type="bar" width="400" />
                  <Chart options={estadoCivil.options} series={estadoCivil.series} type="donut" width="400" />
                </PerfilContainer>
                <PerfilContainer>
                  <Chart options={estadoCivil2.options} series={estadoCivil2.series} type="bar" width="500" />
                  <Chart options={faixaEtaria2.options} series={faixaEtaria2.series} type="bar" width="500" />
                </PerfilContainer>
                <SaudeContainer>
                  <div>
                    <Chart options={ini01.options} series={ini01.series} type="donut" width="500" />
                    <Chart options={ini02.options} series={ini02.series} type="bar" width="500" />
                  </div>
                  <div>
                    <Chart options={ini03.options} series={ini03.series} type="donut" width="500" style={{ justifyContent: 'center' }} />
                    <div>
                      <Chart options={ini04.options} series={ini04.series} type="bar" width="400" />
                      <Chart options={ini05.options} series={ini05.series} type="bar" width="400" />
                    </div>
                  </div>
                </SaudeContainer>
                <SaudeContainer>
                  <div>
                    <Chart options={ini06.options} series={ini06.series} type="donut" width="500" />
                    <Chart options={ini07.options} series={ini07.series} type="bar" width="500" />
                  </div>
                  <div>
                    <Chart options={ini08.options} series={ini08.series} type="donut" width="500" />
                    <Chart options={ini09.options} series={ini09.series} type="bar" width="500" />
                  </div>
                </SaudeContainer>
                <SaudeContainer>
                  <div>
                    <Chart options={ini10.options} series={ini10.series} type="donut" width="500" />
                    <Chart options={ini11.options} series={ini11.series} type="bar" width="500" />
                  </div>
                  <div>
                    <Chart options={ini12.options} series={ini12.series} type="bar" width="500" />
                    <Chart options={ini13.options} series={ini13.series} type="bar" width="500" />
                  </div>
                </SaudeContainer>
                <SaudeContainer2>
                  <div>
                    <Chart options={ini14.options} series={ini14.series} type="bar" width="300" />
                    <Chart options={ini15.options} series={ini15.series} type="bar" width="300" height="400" />
                  </div>
                  <div>
                    <Chart options={ini16.options} series={ini16.series} type="bar" width="300" />
                    <Chart options={ini17.options} series={ini17.series} type="bar" width="300" />
                    <Chart options={ini18.options} series={ini18.series} type="bar" width="300" />
                    <Chart options={ini19.options} series={ini19.series} type="bar" width="300" />
                    <Chart options={ini20.options} series={ini20.series} type="bar" width="300" />
                    <Chart options={ini21.options} series={ini21.series} type="bar" width="300" />
                    <Chart options={ini22.options} series={ini22.series} type="bar" width="300" />
                    <Chart options={ini23.options} series={ini23.series} type="bar" width="300" />
                    <Chart options={ini24.options} series={ini24.series} type="bar" width="300" />
                  </div>
                </SaudeContainer2>
                <BasicContainer>
                  <div>
                    <Chart options={data2.options} series={data2.series} type="bar" width="500" />
                    <BoxCount>
                      <div>10</div>
                      Sobrepeso + Obeso
                    </BoxCount>
                  </div>
                  <div>
                    <Chart options={data4.options} series={data4.series} type="bar" width="500" />
                    <Chart options={data2.options} series={data2.series} type="bar" width="500" />
                  </div>
                </BasicContainer>
                <BasicContainer>
                  <div>
                    <Chart options={wr01.options} series={wr01.series} type="bar" width="400" />
                    <Chart options={wr02.options} series={wr02.series} type="bar" width="400" />
                    <Chart options={wr03.options} series={wr03.series} type="bar" width="400" />
                  </div>
                  <div>
                    <Chart options={wr04.options} series={wr04.series} type="bar" width="400" />
                    <Chart options={wr05.options} series={wr05.series} type="bar" width="400" />
                    <Chart options={wr06.options} series={wr06.series} type="bar" width="400" />
                  </div>
                </BasicContainer>
                <BasicContainer>
                  <div>
                    <Chart options={wr07.options} series={wr07.series} type="bar" width="400" />
                    <Chart options={wr08.options} series={wr08.series} type="bar" width="400" />
                  </div>
                  <div>
                    <Chart options={wr09.options} series={wr09.series} type="bar" width="400" />
                    <Chart options={wr10.options} series={wr10.series} type="bar" width="400" />
                  </div>
                </BasicContainer>
                <BasicContainer>
                  <div>
                    <Chart options={wr11.options} series={wr11.series} type="bar" width="400" />
                    <Chart options={wr12.options} series={wr12.series} type="bar" width="400" />
                    <Chart options={wr13.options} series={wr13.series} type="bar" width="400" />
                    <Chart options={wr14.options} series={wr14.series} type="bar" width="400" />
                  </div>
                  <div>
                    <Chart options={wr15.options} series={wr15.series} type="bar" width="400" />
                    <Chart options={wr16.options} series={wr16.series} type="bar" width="400" />
                    <Chart options={wr17.options} series={wr17.series} type="bar" width="400" />
                    <Chart options={wr18.options} series={wr18.series} type="bar" width="400" />
                  </div>
                </BasicContainer>
              </>
            )}
            {loading && <Spinner />}
          </Schedule>
        </Content>
      </Container>
    </>
  );
};

export default Relatorio;
