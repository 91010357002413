import { shade } from 'polished';
import styled from 'styled-components';
// import { shade } from 'polished';

export const Container = styled.div``;

export const Header = styled.header`
  padding: 32px 0;
  background: #28262e;
`;

export const HeaderContent = styled.div`
  max-width: 1120px;
  margin: 0 auto;
  display: flex;
  align-items: center;

  > img {
    height: 50px;
  }

  button {
    margin-left: auto;
    background: transparent;
    border: 0;

    svg {
      color: #999591;
      width: 20px;
      height: 20px;
    }
  }
`;

export const Title = styled.div`
  display: flex;
  justify-content: space-between;

  h1 {
    color: #525f7f;
  }

  a {
    display: flex;
    flex-direction: row;
    justify-content: center;
    max-width: 15%;
    max-height: 35px;
    display: flex;
    text-align: center;
    background: #1171ef;
    height: 56px;
    border-radius: 10px;
    border: 0;
    padding: 0 16px;
    color: #ffffff;
    text-decoration: none;
    width: 100%;
    font-weight: 500;
    margin-left: 10px;
    transition: background-color 0.2s;

    &:hover {
      background: ${shade(0.2, '#1171ef')};
    }

    > * {
      margin: auto 0;
    }
  }
`;

export const Profile = styled.div`
  display: flex;
  align-items: center;
  margin-left: 80px;

  > svg {
  }

  div {
    display: flex;
    flex-direction: column;
    margin-left: 16px;
    line-height: 24px;

    span {
      color: #f4ede8;
    }

    strong {
      color: #ff9000;
    }
  }
`;

export const Content = styled.main`
  max-width: 1120px;
  margin: 64px auto;
  display: flex;
`;
export const Schedule = styled.div`
  flex: 1;
  /* margin-right: 120px; */

  h1 {
    font-size: 36px;
  }

  p {
    margin-top: 8px;
    color: #ff9000;
    display: flex;
    align-items: center;
    font-weight: 500;

    span {
      display: flex;
      align-items: center;
    }
    span + span::before {
      content: '';
      width: 1px;
      height: 12px;
      background: #ff9000;
      margin: 0 8px;
    }
  }
`;

export const Section = styled.section`
  margin-top: 48px;
  > strong {
    color: #999591;
    font-size: 20px;
    line-height: 26px;
    border-bottom: 1px solid #3e3b47;
    display: block;
    padding-bottom: 16px;
    margin-bottom: 16px;
  }
`;

export const Protocolos = styled.div`
  display: flex;
  flex-wrap: wrap;

  > div {
    display: flex;
    flex: 0 0 33.333333%;
    flex-direction: row;
    margin-bottom: 10px;

    > div {
      &:nth-child(1) {
        width: 100px;
        display: flex;
        align-items: center;
        justify-content: center;
      }
    }
  }
`;

export const Perimetria = styled.div`
  display: flex;
  flex-wrap: wrap;
  flex: auto;
  flex-direction: row;
  /* justify-content: space-between; */

  > div {
    display: flex;
    flex-direction: column;
    margin-bottom: 5px;
    align-items: center;

    & + div {
      margin-left: 20px;
    }

    &:nth-child(1) {
      > div {
        &:nth-child(2) {
          display: flex;
          align-items: center;
          justify-content: center;
          width: 100%;
          height: 100%;
          height: 55px;
          margin-bottom: 8px;
        }
        &:nth-child(3) {
          display: flex;
          align-items: center;
          justify-content: center;
          width: 100%;
          height: 100%;
          height: 55px;
        }
      }
    }

    &:nth-child(2) {
      > div {
        &:nth-child(2) {
          display: flex;
          align-items: center;
          justify-content: center;
          width: 100%;
          height: 100%;
        }
      }
    }
    &:nth-child(3) {
      > div {
        &:nth-child(2) {
          display: flex;
          align-items: center;
          justify-content: center;
          width: 100%;
          height: 100%;
        }
      }
    }
  }

  input {
    text-align: center;
  }
`;

export const Calculos = styled.div`
  display: flex;
  flex-direction: column;

  > div {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;

    & + div {
      margin-top: 10px;
    }

    > div {
      min-width: 180px;
      display: flex;
      align-items: center;

      & + div {
        margin-left: 10px;
      }
    }
  }
`;

export const MassaCorporal = styled.div`
  display: flex;

  > div {
    display: flex;
    flex-direction: column;
    justify-content: space-around;

    &:nth-child(1) {
      width: 180px;
    }

    & + div {
      margin-left: 10px;
    }

    > div {
      & + div {
        margin-top: 10px;
      }
    }

    &:nth-child(3) {
      div {
        height: 100%;

        input {
          text-align: center;
        }
      }
    }
  }
`;

export const Col01 = styled.div`
  width: 10%;
`;
export const Col02 = styled.div`
  width: 70%;
`;
export const Col03 = styled.div`
  display: flex;
  justify-content: space-around;
  width: 20%;
  text-align: center;

  div {
    text-align: center;
    margin-top: 0px !important;

    & + div {
      margin-left: 8px;
    }

    input {
      text-align: center;
    }
  }
`;
export const Col04 = styled.div`
  width: 10%;
  text-align: center;

  input {
    text-align: center;
  }
`;

export const Riscos = styled.div`
  display: flex;
  flex-direction: column;

  > div {
    display: flex;
    flex: auto;
    justify-content: space-between;
    flex-direction: row;
    border-bottom: 3px solid #232129;

    > div {
      margin: 5px;
    }
  }

  input {
    text-align: center;
  }
`;

export const Sindrome = styled.div`
  display: flex;
  flex-direction: column;

  > div {
    display: flex;
    flex: auto;
    justify-content: space-between;
    flex-direction: row;
    border-bottom: 3px solid #232129;

    > div {
      width: 200px;
      margin: 5px;
      &:nth-child(1) {
        margin: auto 0 auto 0;
      }
    }
  }

  input {
    text-align: center;
  }
`;

export const TwoFields = styled.div`
  display: flex;
  justify-content: space-around;
  > div {
    width: 50%;

    & + div {
      margin-left: 8px;
      margin-top: 0;
    }
  }
`;

export const ImagemContent = styled.aside`
  width: 200px;
`;

export const Protocolo = styled.div`
  display: flex;

  div {
    margin-right: 20px;
  }
`;
