import axios from 'axios';

let _url = '';
if (process.env.NODE_ENV !== 'production') {
  _url = 'https://localhost:7111/api';
} else {
  _url = 'https://atitudevsprofessorapi.azurewebsites.net/api';
}

const api = axios.create({
  baseURL: _url,
});

export default api;
