export function classificacaoFlexao(sexo: number, idade: number, valor: number) {
  switch (true) {
    case valor <= 17 && idade <= 19 && sexo === 1:
    case valor <= 16 && idade <= 29 && sexo === 1:
    case valor <= 11 && idade <= 39 && sexo === 1:
    case valor <= 9 && idade <= 49 && sexo === 1:
    case valor <= 8 && idade <= 59 && sexo === 1:
    case valor <= 4 && idade > 60 && sexo === 1:
    case valor <= 11 && idade <= 19 && sexo === 2:
    case valor <= 9 && idade <= 29 && sexo === 2:
    case valor <= 7 && idade <= 39 && sexo === 2:
    case valor <= 4 && idade <= 49 && sexo === 2:
    case valor <= 1 && idade <= 59 && sexo === 2:
    case valor <= 1 && idade > 60 && sexo === 2:
      return 'Fraco';
    case valor <= 22 && idade <= 19 && sexo === 1:
    case valor <= 21 && idade <= 29 && sexo === 1:
    case valor <= 18 && idade <= 39 && sexo === 1:
    case valor <= 12 && idade <= 49 && sexo === 1:
    case valor <= 9 && idade <= 59 && sexo === 1:
    case valor <= 7 && idade > 60 && sexo === 1:
    case valor <= 17 && idade <= 19 && sexo === 2:
    case valor <= 14 && idade <= 29 && sexo === 2:
    case valor <= 12 && idade <= 39 && sexo === 2:
    case valor <= 10 && idade <= 49 && sexo === 2:
    case valor <= 6 && idade <= 59 && sexo === 2:
    case valor <= 4 && idade > 60 && sexo === 2:
      return 'Regular';
    case valor <= 28 && idade <= 19 && sexo === 1:
    case valor <= 28 && idade <= 29 && sexo === 1:
    case valor <= 21 && idade <= 39 && sexo === 1:
    case valor <= 16 && idade <= 49 && sexo === 1:
    case valor <= 12 && idade <= 59 && sexo === 1:
    case valor <= 10 && idade > 60 && sexo === 1:
    case valor <= 24 && idade <= 19 && sexo === 2:
    case valor <= 20 && idade <= 29 && sexo === 2:
    case valor <= 19 && idade <= 39 && sexo === 2:
    case valor <= 14 && idade <= 49 && sexo === 2:
    case valor <= 10 && idade <= 59 && sexo === 2:
    case valor <= 11 && idade > 60 && sexo === 2:
      return 'Médio';
    case valor <= 38 && idade <= 19 && sexo === 1:
    case valor <= 35 && idade <= 29 && sexo === 1:
    case valor <= 29 && idade <= 39 && sexo === 1:
    case valor <= 21 && idade <= 49 && sexo === 1:
    case valor <= 20 && idade <= 59 && sexo === 1:
    case valor <= 17 && idade > 60 && sexo === 1:
    case valor <= 32 && idade <= 19 && sexo === 2:
    case valor <= 29 && idade <= 29 && sexo === 2:
    case valor <= 26 && idade <= 39 && sexo === 2:
    case valor <= 23 && idade <= 49 && sexo === 2:
    case valor <= 20 && idade <= 59 && sexo === 2:
    case valor <= 16 && idade > 60 && sexo === 2:
      return 'Bom';
    case valor >= 39 && idade <= 19 && sexo === 1:
    case valor >= 36 && idade <= 29 && sexo === 1:
    case valor >= 30 && idade <= 39 && sexo === 1:
    case valor >= 22 && idade <= 49 && sexo === 1:
    case valor >= 21 && idade <= 59 && sexo === 1:
    case valor >= 18 && idade > 60 && sexo === 1:
    case valor >= 33 && idade <= 19 && sexo === 2:
    case valor >= 30 && idade <= 29 && sexo === 2:
    case valor >= 27 && idade <= 39 && sexo === 2:
    case valor >= 24 && idade <= 49 && sexo === 2:
    case valor >= 21 && idade <= 59 && sexo === 2:
    case valor >= 17 && idade > 60 && sexo === 2:
      return 'Excelente';
    default:
      return '#N/D';
  }
}

export function classificacaoAbdominal(sexo: number, idade: number, valor: number) {
  switch (true) {
    case valor <= 32 && idade <= 19 && sexo === 1:
    case valor <= 28 && idade <= 29 && sexo === 1:
    case valor <= 21 && idade <= 39 && sexo === 1:
    case valor <= 16 && idade <= 49 && sexo === 1:
    case valor <= 12 && idade <= 59 && sexo === 1:
    case valor <= 6 && idade > 60 && sexo === 1:
    case valor <= 26 && idade <= 19 && sexo === 2:
    case valor <= 20 && idade <= 29 && sexo === 2:
    case valor <= 14 && idade <= 39 && sexo === 2:
    case valor <= 6 && idade <= 49 && sexo === 2:
    case valor <= 2 && idade <= 59 && sexo === 2:
    case valor <= 1 && idade > 60 && sexo === 2:
      return 'Fraco';
    case valor <= 37 && idade <= 19 && sexo === 1:
    case valor <= 32 && idade <= 29 && sexo === 1:
    case valor <= 26 && idade <= 39 && sexo === 1:
    case valor <= 21 && idade <= 49 && sexo === 1:
    case valor <= 17 && idade <= 59 && sexo === 1:
    case valor <= 11 && idade > 60 && sexo === 1:
    case valor <= 31 && idade <= 19 && sexo === 2:
    case valor <= 24 && idade <= 29 && sexo === 2:
    case valor <= 19 && idade <= 39 && sexo === 2:
    case valor <= 14 && idade <= 49 && sexo === 2:
    case valor <= 4 && idade <= 59 && sexo === 2:
    case valor <= 3 && idade > 60 && sexo === 2:
      return 'Regular';
    case valor <= 41 && idade <= 19 && sexo === 1:
    case valor <= 36 && idade <= 29 && sexo === 1:
    case valor <= 30 && idade <= 39 && sexo === 1:
    case valor <= 25 && idade <= 49 && sexo === 1:
    case valor <= 21 && idade <= 59 && sexo === 1:
    case valor <= 16 && idade > 60 && sexo === 1:
    case valor <= 35 && idade <= 19 && sexo === 2:
    case valor <= 30 && idade <= 29 && sexo === 2:
    case valor <= 23 && idade <= 39 && sexo === 2:
    case valor <= 19 && idade <= 49 && sexo === 2:
    case valor <= 11 && idade <= 59 && sexo === 2:
    case valor <= 11 && idade > 60 && sexo === 2:
      return 'Médio';
    case valor <= 47 && idade <= 19 && sexo === 1:
    case valor <= 42 && idade <= 29 && sexo === 1:
    case valor <= 35 && idade <= 39 && sexo === 1:
    case valor <= 30 && idade <= 49 && sexo === 1:
    case valor <= 25 && idade <= 59 && sexo === 1:
    case valor <= 22 && idade > 60 && sexo === 1:
    case valor <= 41 && idade <= 19 && sexo === 2:
    case valor <= 35 && idade <= 29 && sexo === 2:
    case valor <= 28 && idade <= 39 && sexo === 2:
    case valor <= 24 && idade <= 49 && sexo === 2:
    case valor <= 18 && idade <= 59 && sexo === 2:
    case valor <= 15 && idade > 60 && sexo === 2:
      return 'Bom';
    case valor >= 48 && idade <= 19 && sexo === 1:
    case valor >= 43 && idade <= 29 && sexo === 1:
    case valor >= 36 && idade <= 39 && sexo === 1:
    case valor >= 31 && idade <= 49 && sexo === 1:
    case valor >= 26 && idade <= 59 && sexo === 1:
    case valor >= 23 && idade > 60 && sexo === 1:
    case valor >= 42 && idade <= 19 && sexo === 2:
    case valor >= 36 && idade <= 29 && sexo === 2:
    case valor >= 29 && idade <= 39 && sexo === 2:
    case valor >= 25 && idade <= 49 && sexo === 2:
    case valor >= 19 && idade <= 59 && sexo === 2:
    case valor >= 16 && idade > 60 && sexo === 2:
      return 'Excelente';

    default:
      return '#N/D';
  }
}
