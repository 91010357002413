import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  > div {
    flex-direction: row;
    margin: auto;
    /* width: 200px; */

    > img {
      width: 300px;
      height: auto;
    }
    > object {
      width: 300px;
      height: 400px;
    }
  }

  & + div {
    margin-top: 5px;
  }

  label {
    color: #ffff;
    display: inline-block;
    background: #1171ef; //-webkit-linear-gradient(top, #f9f9f9, #e3e3e3);
    /* border: 1px solid #999; */
    border-radius: 5px;
    padding: 5px 8px;
    outline: none;
    white-space: nowrap;
    user-select: none;
    cursor: pointer;
    /* text-shadow: 1px 1px #fff; */
    font-weight: 500;
    font-size: 10pt;
    width: 200px;
    text-align: center;
  }

  input[type='file'] {
    color: transparent;
    ::-webkit-file-upload-button {
      visibility: hidden;
    }
    ::before {
      content: 'Carregar arquivo';
      color: #ffff;
      display: inline-block;
      background: #1171ef; //-webkit-linear-gradient(top, #f9f9f9, #e3e3e3);
      /* border: 1px solid #999; */
      border-radius: 5px;
      padding: 5px 8px;
      outline: none;
      white-space: nowrap;
      user-select: none;
      cursor: pointer;
      /* text-shadow: 1px 1px #fff; */
      font-weight: 500;
      font-size: 10pt;
      width: 100%;
      text-align: center;
    }
  }
`;
