import React, { useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useAuth } from '../../hooks/auth';
// import Base64 from '../../utils/encrypt';

// import { Container } from './styles';

const SingleSignOn: React.FC = () => {
  const { token } = useParams() as { token: string };
  let navigate = useNavigate();
  const { sso } = useAuth();

  useEffect(() => {
    const data = {
      tokenValid: token,
    };
    sso(data).then(() => navigate('/alunos'));
  }, [token, navigate, sso]);

  return (
    <>
      <div>
        <p>AGUARDE!</p>
        <p>Caso você não seja redirecinado, atualize a página</p>
      </div>
    </>
  );
};

export default SingleSignOn;
