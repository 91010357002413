export function classificacaoVO2Max(sexo: number, idade: number, valor: number) {
  switch (true) {
    case valor <= 0 && idade <= 30 && sexo === 1:
    case valor <= 0 && idade <= 40 && sexo === 1:
    case valor <= 0 && idade <= 50 && sexo === 1:
    case valor <= 0 && idade <= 60 && sexo === 1:
    case valor <= 0 && idade > 60 && sexo === 1:
    case valor <= 0 && idade <= 30 && sexo === 2:
    case valor <= 0 && idade <= 40 && sexo === 2:
    case valor <= 0 && idade <= 50 && sexo === 2:
    case valor <= 0 && idade <= 60 && sexo === 2:
    case valor <= 0 && idade > 60 && sexo === 2:
      return 'Condição de risco';
    case valor <= 38 && idade <= 30 && sexo === 1:
    case valor <= 36 && idade <= 40 && sexo === 1:
    case valor <= 34 && idade <= 50 && sexo === 1:
    case valor <= 31 && idade <= 60 && sexo === 1:
    case valor <= 27 && idade > 60 && sexo === 1:
    case valor <= 32 && idade <= 30 && sexo === 2:
    case valor <= 30 && idade <= 40 && sexo === 2:
    case valor <= 28 && idade <= 50 && sexo === 2:
    case valor <= 25 && idade <= 60 && sexo === 2:
    case valor <= 24 && idade > 60 && sexo === 2:
      return 'Baixa aptidão';
    case valor <= 42 && idade <= 30 && sexo === 1:
    case valor <= 40 && idade <= 40 && sexo === 1:
    case valor <= 38 && idade <= 50 && sexo === 1:
    case valor <= 35 && idade <= 60 && sexo === 1:
    case valor <= 31 && idade > 60 && sexo === 1:
    case valor <= 35 && idade <= 30 && sexo === 2:
    case valor <= 31 && idade <= 40 && sexo === 2:
    case valor <= 31 && idade <= 50 && sexo === 2:
    case valor <= 28 && idade <= 60 && sexo === 2:
    case valor <= 26 && idade > 60 && sexo === 2:
      return 'Faixa recomendável';
    case valor <= 49 && idade <= 30 && sexo === 1:
    case valor <= 48 && idade <= 40 && sexo === 1:
    case valor <= 45 && idade <= 50 && sexo === 1:
    case valor <= 42 && idade <= 60 && sexo === 1:
    case valor <= 39 && idade > 60 && sexo === 1:
    case valor <= 42 && idade <= 30 && sexo === 2:
    case valor <= 40 && idade <= 40 && sexo === 2:
    case valor <= 37 && idade <= 50 && sexo === 2:
    case valor <= 33 && idade <= 60 && sexo === 2:
    case valor <= 32 && idade > 60 && sexo === 2:
      return 'Condição atlética';
    case valor > 49 && idade <= 30 && sexo === 1:
    case valor > 48 && idade <= 40 && sexo === 1:
    case valor > 45 && idade <= 50 && sexo === 1:
    case valor > 42 && idade <= 60 && sexo === 1:
    case valor > 39 && idade > 60 && sexo === 1:
    case valor > 42 && idade <= 30 && sexo === 2:
    case valor > 40 && idade <= 40 && sexo === 2:
    case valor > 37 && idade <= 50 && sexo === 2:
    case valor > 33 && idade <= 60 && sexo === 2:
    case valor > 32 && idade > 60 && sexo === 2:
      return 'Condição atlética';
    default:
      return '#N/D';
  }
}

export const calculoMET = (valor: number) => {
  switch (valor) {
    case 2:
      return 1.8;
    case 3:
      return 2.2;
    case 4:
      return 2.7;
    case 5:
      return 3.2;
    case 6:
      return 4.2;
    case 7:
      return 5.4;
    case 8:
      return 7.1;
    case 9:
      return 8.6;
    case 10:
      return 10.1;
    case 11:
      return 10.9;
    case 12:
      return 12;
    case 13:
      return 12.9;
    case 14:
      return 13.8;
    case 15:
      return 14.6;
    default:
      return 0;
  }
};
