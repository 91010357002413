import React, { useCallback, useRef, useState } from 'react';
import { FiMail, FiLock, FiEye, FiEyeOff } from 'react-icons/fi';
import { Form } from '@unform/web';
import { FormHandles } from '@unform/core';
import * as Yup from 'yup';

import Input from '../../components/Input';
import Button from '../../components/Button';

import * as S from './styles';
import { Link, useNavigate } from 'react-router-dom';
import { useAuth } from '../../hooks/auth';
// import getValidationErrors from '../../utils/getValidationErrors';
import { useToast } from '../../hooks/toast';
import api from '../../services/api';

interface SignInFormData {
  email: string;
  password: string;
}

const EsqueciSenha: React.FC = () => {
  const formRef = useRef<FormHandles>(null);
  const { addToast } = useToast();
  const [loading, setLoading] = useState(false);
  const [showpass, setShowPass] = useState(false);
  const showPassword = () => setShowPass(!showpass);
  let navigate = useNavigate();

  const handleSubmit = useCallback(
    async (data: SignInFormData) => {
      setShowPass(false);
      try {
        formRef.current?.setErrors({});

        const schema = Yup.object().shape({
          email: Yup.string(),
        });

        await schema.validate(data, {
          abortEarly: false,
        });

        setLoading(true);
        const email = data.email;
        await api.post(`account/forgot-password`, { email }).then(() => {
          addToast({
            type: 'success',
            title: 'Verifique seu e-mail',
          });
          setLoading(false);
        });
      } catch (err: any) {
        setLoading(false);
        // const errors = getValidationErrors(err);
        // formRef.current?.setErrors(errors);
        console.log(err);
        let errMsg: string = '';
        if (err.response.data) {
          errMsg = err.response.data?.message;
        } else {
          errMsg = err.message;
        }
        addToast({
          type: 'error',
          title: errMsg,
        });
      }
    },
    [navigate, addToast]
  );

  return (
    <S.Container>
      <div>
        <div>
          <div>
            <div>
              <h1>
                Faça seu login <br />
                na plataforma
              </h1>
            </div>
            <Form
              ref={formRef}
              onSubmit={handleSubmit}
              placeholder={undefined}
              onPointerEnterCapture={undefined}
              onPointerLeaveCapture={undefined}
            >
              <section>
                <Input name="email" icon={FiMail} placeholder="E-mail" disabled={loading} />
                <Button loading={loading} type="submit">
                  Recuperar
                </Button>
              </section>
            </Form>
          </div>
        </div>
      </div>
    </S.Container>
  );
};

export default EsqueciSenha;
