import React from 'react';

import { Container } from './styles';

type Props = {
  visible: boolean;
  close(): void;
  children: React.ReactNode;
};

const Modal: React.FC<Props> = ({ visible, close, children }) => {
  return (
    <>
      {visible && (
        <Container>
          <div>
            <span onClick={close}>&times;</span>
            <div>{children}</div>
          </div>
        </Container>
      )}
    </>
  );
};

export default Modal;
