import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { FiCalendar } from 'react-icons/fi';
import { Container, Content, Schedule, Section, InputGroup, Calendar } from './styles';

import { Form } from '@unform/web';
import { FormHandles } from '@unform/core';
import * as Yup from 'yup';

import { useToast } from '../../hooks/toast';
// import getValidationErrors from '../../utils/getValidationErrors';
import Input from '../../components/Input';
import InputDate from '../../components/InputDate';
import Button from '../../components/Button';
import Select from '../../components/Select';
import InputMask from '../../components/InputMask';

import { calcularIdade } from '../../utils/calcular';
import api from '../../services/api';
import { convertToDate } from '../../utils/converter';
import { estados } from '../CadastroAluno/apoio';

const Perfil: React.FC = () => {
  const { addToast } = useToast();
  const navigate = useNavigate();
  const formRef = useRef<FormHandles>(null);
  const [loading, setLoading] = useState(false);
  const [aluno, setAluno] = useState<any>(null);
  const [edit, setEdit] = useState(false);
  const [modal, setModal] = useState(false);
  const [count, setCount] = useState(0);
  const [id, setId] = useState(0);

  useEffect(() => {
    api
      .get('account/me')
      .then((response) => {
        // console.log(response.data);
        setId(response.data.id);
        formRef.current?.setFieldValue('nome', response.data.nome);
        formRef.current?.setFieldValue('sobrenome', response.data.sobrenome);
        formRef.current?.setFieldValue('email', response.data.email);
      })
      .then(() => {
        api
          .get('professores/me')
          .then((response) => {
            // console.log(response.data);
            setId(response.data.id);
            if (response.data === '') return;
            formRef.current?.setData(response.data);
            setEdit(true);
            handleCalcIdade(response.data.nascimento);
          })
          .catch(() => {
            setEdit(false);
          });
      });
  }, []);

  const handleSubmit = useCallback(
    async (data: any) => {
      try {
        formRef.current?.setErrors({});
        const schema = Yup.object().shape({
          nome: Yup.string().required('Nome obrigatorio'),
          sobrenome: Yup.string().required('Sobrenome obrigatorio'),
          nascimento: Yup.string().required('Nascimento obrigatorio'),
          sexo: Yup.string().required('Sexo obrigatorio'),
          estadoCivil: Yup.string().required('Estado civil obrigatorio'),
          email: Yup.string().required('Email obrigatorio'),
          celular: Yup.string().required('Celular obrigatorio'),
          cpf: Yup.string().required('CPF obrigatorio'),
        });

        await schema.validate(data, {
          abortEarly: false,
        });

        setLoading(true);

        data.nascimento = convertToDate(data.nascimento);
        data.estado = data.estado === '' ? null : data.estado;
        data.estadoCivil = data.estadoCivil === '' ? null : data.estadoCivil;

        // const a = { ...data, nascimento: convertToDate(data.nascimento) };
        if (edit) {
          await api.put('professores/' + id, data);

          addToast({
            type: 'success',
            title: 'Cadastro atualizado',
          });
          setLoading(false);
        } else {
          await api
            .post('professores', data)
            .then(() => {
              localStorage.setItem('@atitudevidasaudavel:completed', 'true');
              addToast({
                type: 'success',
                title: 'Cadastro realizado',
              });
              setLoading(false);
              formRef.current?.reset();
            })
            .then(() => {
              navigate(`/dashboard`);
            });
        }
      } catch (err: any) {
        setLoading(false);
        console.log(err);
        // const errors = getValidationErrors(err);
        // formRef.current?.setErrors(errors);
        addToast({
          type: 'error',
          title: 'Erro ao gravar cadastro',
        });
      }
    },
    // eslint-disable-next-line
    [addToast, navigate, edit, id]
  );

  const handleCalcIdade = (valor: Date) => {
    const idade = calcularIdade(valor, new Date());
    formRef.current?.setFieldValue('idade', idade);
  };

  const getCEP = () => {
    const _cep: string = formRef.current?.getFieldValue('cep') || aluno.cep;
    const cep = _cep.replaceAll('_', '');
    console.log(_cep.replaceAll('_', ''));

    if (cep.length < 9) return;
    api
      .get(`https://viacep.com.br/ws/${cep}/json/`)
      .then((response) => {
        if (response.data.erro) {
          addToast({
            type: 'error',
            title: 'Ocorreu um error ao buscar o CEP',
          });
          return;
        }

        const { uf, logradouro, localidade, bairro } = response.data;
        const estado = estados.find((e) => e.name === uf);

        formRef.current?.setFieldValue('endereco', logradouro);
        formRef.current?.setFieldValue('estado', estado?.value);
        formRef.current?.setFieldValue('cidade', localidade);
        formRef.current?.setFieldValue('bairro', bairro);
      })
      .catch((error) => {
        addToast({
          type: 'error',
          title: 'Ocorreu um error ao buscar o CEP',
        });
      });
  };

  return (
    <>
      {/* <Header /> */}
      <Container>
        <Content>
          <Schedule>
            <h1>Meu Cadastro</h1>
            <Form
              ref={formRef}
              onSubmit={handleSubmit}
              initialData={{ id: id }}
              placeholder={undefined}
              onPointerEnterCapture={undefined}
              onPointerLeaveCapture={undefined}
            >
              <Section>
                <strong>Dados</strong>
                <InputGroup>
                  <Input name="nome" placeholder="Nome" />
                  <Input name="sobrenome" placeholder="Sobrenome" />
                </InputGroup>
                <InputGroup>
                  <InputDate name="nascimento" placeholder="Nascimento" subicon={FiCalendar} onauxfunction={handleCalcIdade} />
                  <Input name="idade" placeholder="Idade" readOnly />
                </InputGroup>
                <InputGroup>
                  <Select
                    name="sexo"
                    id="sexo"
                    placeholder="Sexo"
                    options={[
                      { name: 'Feminino', value: '2' },
                      { name: 'Masculino', value: '1' },
                    ]}
                  />
                  <Select
                    name="estadoCivil"
                    id="estadoCivil"
                    placeholder="Estado Civil"
                    options={[
                      { name: 'Solteiro', value: 1 },
                      { name: 'Casado', value: 2 },
                      { name: 'Viuvo', value: 3 },
                      { name: 'Divorciado', value: 4 },
                      { name: 'Não Declarado', value: 5 },
                    ]}
                  />
                </InputGroup>
                <InputGroup>
                  <InputMask name="cpf" placeholder="CPF" mask={'999.999.999-99'} />
                </InputGroup>
                <InputGroup>
                  <Input name="email" placeholder="E-mail" readOnly />
                  <InputMask name="celular" placeholder="Celular" mask={'(99) 99999-9999'} />
                </InputGroup>
                <InputGroup>
                  <Input name="cref" placeholder="Registro do Profissional" />
                  <Select name="crefEstado" id="crefEstado" placeholder="Estado" options={estados} />
                </InputGroup>
              </Section>
              <Section>
                <strong>Endereço</strong>
                <InputGroup>
                  <InputMask name="cep" placeholder="CEP" mask={'99999-999'} alwaysShowMask={true} onChange={getCEP} />
                  <Input name="endereco" placeholder="Endereço" />
                </InputGroup>
                <InputGroup>
                  <Select name="estado" id="estado" placeholder="Estado" options={estados} />
                  <Input name="cidade" placeholder="Cidade" />
                  <Input name="bairro" placeholder="Bairro" />
                </InputGroup>
                <InputGroup>
                  <Input name="codigo" readOnly />
                </InputGroup>
              </Section>
              <div style={{ visibility: 'hidden' }}>
                <Input name="id" type="number" />
              </div>
              <Button loading={loading} type="submit">
                Gravar
              </Button>
            </Form>
          </Schedule>
          <Calendar>FOTO EM BREVE</Calendar>
        </Content>
      </Container>
    </>
  );
};

export default Perfil;
