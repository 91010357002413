import React, { ChangeEvent, useRef, useEffect, useCallback, useState } from 'react';

import { useField } from '@unform/core';
import { Container } from './styles';

interface Props {
  name: string;
  filepath?: string;
}

type InputProps = JSX.IntrinsicElements['input'] & Props;

export default function FileInput({ name, filepath, ...rest }: InputProps) {
  const inputRef = useRef<HTMLInputElement>(null);

  const { fieldName, registerField, defaultValue } = useField(name);
  const [preview, setPreview] = useState<any>(defaultValue);
  const [type, setType] = useState<string>('');

  const handlePreview = useCallback((e: ChangeEvent<HTMLInputElement>) => {
    const file: any = e.target.files?.[0];

    if (!file) {
      setPreview(null);
      setType('');
      return;
    }

    const previewURL = URL.createObjectURL(file);

    setPreview(previewURL);
    setType(file.type);
  }, []);

  useEffect(() => {
    registerField({
      name: fieldName,
      ref: inputRef.current,
      path: 'files[0]',
      clearValue(ref: HTMLInputElement) {
        ref.value = '';
        setPreview(null);
      },
      setValue(_: HTMLInputElement, value: string) {
        setPreview(value);
      },
    });
  }, [fieldName, registerField]);

  return (
    <>
      <Container>
        <div>
          {filepath && !preview && <img src={filepath} alt="Preview" />}
          {preview && type.includes('image') && <img src={preview} alt="Preview" />}
          {preview && type === 'application/pdf' && <object data={preview}>pdf</object>}
          {preview && type === 'audio/wav' && (
            <audio controls>
              <source src={preview} type="audio/wav" />
              Your browser does not support the audio element.
            </audio>
          )}
        </div>
        <div>
          {rest.placeholder && <label htmlFor={fieldName}>{rest.placeholder}</label>}
          <input
            id={fieldName}
            type="file"
            ref={inputRef}
            onChange={handlePreview}
            {...rest}
            style={{ display: rest.placeholder ? 'none' : 'block' }}
          />
        </div>
      </Container>
    </>
  );
}
