export function calcularIdade(aniversario: Date, data: Date) {
  const d = new Date(data);
  const ano_atual = d.getFullYear();
  const mes_atual = d.getMonth() + 1;
  const dia_atual = d.getDate();

  const d2 = new Date(aniversario);
  const ano_aniversario = d2.getFullYear();
  const mes_aniversario = d2.getMonth() + 1;
  const dia_aniversario = d2.getDate();

  let quantos_anos = ano_atual - ano_aniversario;

  if (mes_atual < mes_aniversario || (mes_atual === mes_aniversario && dia_atual < dia_aniversario)) {
    quantos_anos--;
  }

  return quantos_anos < 0 ? 0 : quantos_anos;
}

export function classificacaoGordura(sexo: number, idade: number, valor: number) {
  switch (true) {
    case valor <= 4 && idade <= 25 && sexo === 1:
    case valor <= 11 && idade <= 35 && sexo === 1:
    case valor <= 14 && idade <= 45 && sexo === 1:
    case valor <= 16 && idade <= 55 && sexo === 1:
    case valor <= 18 && idade <= 65 && sexo === 1:
    case valor <= 18 && idade > 65 && sexo === 1:
    case valor <= 16 && idade <= 25 && sexo === 2:
    case valor <= 16 && idade <= 35 && sexo === 2:
    case valor <= 19 && idade <= 45 && sexo === 2:
    case valor <= 21 && idade <= 55 && sexo === 2:
    case valor <= 22 && idade <= 65 && sexo === 2:
    case valor <= 22 && idade > 65 && sexo === 2:
      return 'Excelente';
    case valor <= 10 && idade <= 25 && sexo === 1:
    case valor <= 15 && idade <= 35 && sexo === 1:
    case valor <= 18 && idade <= 45 && sexo === 1:
    case valor <= 20 && idade <= 55 && sexo === 1:
    case valor <= 21 && idade <= 65 && sexo === 1:
    case valor <= 21 && idade > 65 && sexo === 1:
    case valor <= 19 && idade <= 25 && sexo === 2:
    case valor <= 20 && idade <= 35 && sexo === 2:
    case valor <= 23 && idade <= 45 && sexo === 2:
    case valor <= 25 && idade <= 55 && sexo === 2:
    case valor <= 26 && idade <= 65 && sexo === 2:
    case valor <= 26 && idade > 65 && sexo === 2:
      return 'Bom';
    case valor <= 13 && idade <= 25 && sexo === 1:
    case valor <= 18 && idade <= 35 && sexo === 1:
    case valor <= 20 && idade <= 45 && sexo === 1:
    case valor <= 23 && idade <= 55 && sexo === 1:
    case valor <= 23 && idade <= 65 && sexo === 1:
    case valor <= 23 && idade > 65 && sexo === 1:
    case valor <= 22 && idade <= 25 && sexo === 2:
    case valor <= 23 && idade <= 35 && sexo === 2:
    case valor <= 26 && idade <= 45 && sexo === 2:
    case valor <= 28 && idade <= 55 && sexo === 2:
    case valor <= 29 && idade <= 65 && sexo === 2:
    case valor <= 29 && idade > 65 && sexo === 2:
      return 'Acima da Média';
    case valor <= 16 && idade <= 25 && sexo === 1:
    case valor <= 21 && idade <= 35 && sexo === 1:
    case valor <= 23 && idade <= 45 && sexo === 1:
    case valor <= 25 && idade <= 55 && sexo === 1:
    case valor <= 25 && idade <= 65 && sexo === 1:
    case valor <= 25 && idade > 65 && sexo === 1:
    case valor <= 25 && idade <= 25 && sexo === 2:
    case valor <= 25 && idade <= 35 && sexo === 2:
    case valor <= 29 && idade <= 45 && sexo === 2:
    case valor <= 31 && idade <= 55 && sexo === 2:
    case valor <= 32 && idade <= 65 && sexo === 2:
    case valor <= 32 && idade > 65 && sexo === 2:
      return 'Média';
    case valor <= 20 && idade <= 25 && sexo === 1:
    case valor <= 24 && idade <= 35 && sexo === 1:
    case valor <= 26 && idade <= 45 && sexo === 1:
    case valor <= 27 && idade <= 55 && sexo === 1:
    case valor <= 27 && idade <= 65 && sexo === 1:
    case valor <= 27 && idade > 65 && sexo === 1:
    case valor <= 28 && idade <= 25 && sexo === 2:
    case valor <= 29 && idade <= 35 && sexo === 2:
    case valor <= 32 && idade <= 45 && sexo === 2:
    case valor <= 34 && idade <= 55 && sexo === 2:
    case valor <= 35 && idade <= 65 && sexo === 2:
    case valor <= 35 && idade > 65 && sexo === 2:
      return 'Abaixo da Média';
    case valor <= 25 && idade <= 25 && sexo === 1:
    case valor <= 27 && idade <= 35 && sexo === 1:
    case valor <= 29 && idade <= 45 && sexo === 1:
    case valor <= 31 && idade <= 55 && sexo === 1:
    case valor <= 31 && idade <= 65 && sexo === 1:
    case valor <= 31 && idade > 65 && sexo === 1:
    case valor <= 31 && idade <= 25 && sexo === 2:
    case valor <= 33 && idade <= 35 && sexo === 2:
    case valor <= 36 && idade <= 45 && sexo === 2:
    case valor <= 38 && idade <= 55 && sexo === 2:
    case valor <= 38 && idade <= 65 && sexo === 2:
    case valor <= 38 && idade > 65 && sexo === 2:
      return 'Ruim';
    case valor <= 36 && idade <= 25 && sexo === 1:
    case valor <= 36 && idade <= 35 && sexo === 1:
    case valor <= 39 && idade <= 45 && sexo === 1:
    case valor <= 38 && idade <= 55 && sexo === 1:
    case valor <= 38 && idade <= 65 && sexo === 1:
    case valor <= 38 && idade > 65 && sexo === 1:
    case valor <= 43 && idade <= 25 && sexo === 2:
    case valor <= 49 && idade <= 35 && sexo === 2:
    case valor <= 48 && idade <= 45 && sexo === 2:
    case valor <= 50 && idade <= 55 && sexo === 2:
    case valor <= 49 && idade <= 65 && sexo === 2:
    case valor <= 49 && idade > 65 && sexo === 2:
      return 'Muito Ruim';
    default:
      return '#N/D';
  }
}
