import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { Container, Content, Schedule, Section, InputGroup } from './styles';
import { Form } from '@unform/web';
import { FormHandles } from '@unform/core';
import { getStorage } from 'firebase/storage';
import { BlobServiceClient, AnonymousCredential } from '@azure/storage-blob';
import * as Yup from 'yup';
import { useToast } from '../../hooks/toast';
import Input from '../../components/Input';
import Button from '../../components/Button';
import Select from '../../components/Select';
import api from '../../services/api';
import FileInput from '../../components/FileInput';
// import { app } from '../../utils/firebase';

const REACT_APP_AZURE_STORAGE_URL =
  'https://atitudevs.blob.core.windows.net/?sv=2022-11-02&ss=bfqt&srt=sco&sp=rwdlacupiytfx&se=2025-10-09T02:48:42Z&st=2023-10-08T18:48:42Z&spr=https&sig=frxsqUUF%2B1KhBrtAVDgl8BvrRZU0FdKZyl9Elul6WoU%3D';
const firebaseurl = (url: string) => `https://atitudevs.blob.core.windows.net/exercicios/${url}`;

const Exercicio: React.FC = () => {
  const { id } = useParams();
  const { addToast } = useToast();
  const navigate = useNavigate();
  // const storage = getStorage(app);
  const formRef = useRef<FormHandles>(null);
  const [loading, setLoading] = useState(false);
  const [exercicio, setExercicio] = useState<any>(null);
  const [edit, setEdit] = useState(false);
  const [segmentos, setSegmentos] = useState([]);
  const [selectedImage, setSelectedImage] = useState('');
  // const [confirmExcluir, setConfirmExcluir] = useState(false);

  useEffect(() => {
    api
      .get(`segmentos`)
      .then((response) => {
        // eslint-disable-next-line
        response.data.map((r: any) => ((r.value = r.id), (r.name = r.nome)));
        setSegmentos(response.data);
      })
      .then(() => {
        if (Number(id) > 0) {
          api.get(`exercicios/${id}`).then((response) => {
            formRef.current?.setData(response.data);
            setExercicio(response.data);
            setEdit(true);
          });
        }
      });
  }, [id]);

  useEffect(() => {
    const fetchImage = async () => {
      const blobServiceClient = new BlobServiceClient(REACT_APP_AZURE_STORAGE_URL, new AnonymousCredential());
      const containerClient = blobServiceClient.getContainerClient('exercicios');
      if (!exercicio?.imagem) return;
      const blobClient = containerClient.getBlobClient(exercicio?.imagem);

      try {
        const response = await blobClient.download(0);
        const blob: Blob = (await response.blobBody) || new Blob();
        // Create a URL for the blob and set it as the selected image
        const imageUrl: string = URL.createObjectURL(blob) || '';
        setSelectedImage(imageUrl);
      } catch (error) {
        console.error('Error downloading image:', error);
      }
    };

    fetchImage();
  }, [exercicio]);

  const handleSubmit = useCallback(
    async (data: any) => {
      try {
        formRef.current?.setErrors({});
        const schema = Yup.object().shape({});

        await schema.validate(data, {
          abortEarly: false,
        });

        setLoading(true);

        let anexoName = '';
        if (data.imagemaux) {
          const file = data.imagemaux;
          const time = new Date().getTime();
          // eslint-disable-next-line
          const folder = 'exercicios';
          anexoName = time + '_' + file.name;
          data.imagem = anexoName;
          // // setAnexo(anexoName);
          // const storageRef = ref(storage, `${folder}/${anexoName}`);
          // await uploadBytes(storageRef, file).then((snapshot) => {
          //   console.log('Uploaded a blob or file!');
          // });

          const blobServiceClient = new BlobServiceClient(REACT_APP_AZURE_STORAGE_URL, new AnonymousCredential());
          const containerClient = blobServiceClient.getContainerClient('exercicios');

          const blobName = anexoName;
          const blockBlobClient = containerClient.getBlockBlobClient(blobName);

          try {
            await blockBlobClient.uploadData(file);
            console.log('Upload successful!');
          } catch (error) {
            console.error('Error uploading file:', error);
            alert('Upload failed');
          }
        }

        if (edit) {
          await api.put('exercicios/' + id, data);
          addToast({
            type: 'success',
            title: 'Cadastro atualizado',
          });
          setLoading(false);
        } else {
          await api.post('exercicios', data);
          addToast({
            type: 'success',
            title: 'Cadastro realizado',
          });

          setLoading(false);
          formRef.current?.reset();
          navigate(`/exercicios`);
        }
      } catch (err: any) {
        setLoading(false);
        console.log(err);
        // const errors = getValidationErrors(err);
        // formRef.current?.setErrors(errors);
        addToast({
          type: 'error',
          title: 'Erro ao gravar cadastro',
        });
      }
    },
    [addToast, navigate, edit, id]
  );

  return (
    <>
      <Container>
        <Content>
          <Schedule>
            <h1>Exercício</h1>
            <Form
              ref={formRef}
              onSubmit={handleSubmit}
              initialData={{ id: id }}
              placeholder={undefined}
              onPointerEnterCapture={undefined}
              onPointerLeaveCapture={undefined}
            >
              <Section>
                <strong>Dados</strong>
                <InputGroup>
                  <Input name="nome" placeholder="Nome" />
                </InputGroup>
              </Section>
              <Section>
                <InputGroup>
                  <Select name="segmentoId" id="segmento" placeholder="Segmento" options={segmentos} />
                  <Input name="video" placeholder="youtube ou vimeo url" />
                  {(id === undefined || exercicio?.isCriador) && <FileInput name="imagemaux" placeholder="Carregar imagem" />}
                </InputGroup>
              </Section>
              <Section>
                {exercicio && (
                  <InputGroup>
                    {exercicio.imagem && (
                      <div>
                        <img src={selectedImage} alt="" />
                      </div>
                    )}
                    {exercicio.video && (
                      <div>
                        {exercicio.video.includes('youtu') ? (
                          <iframe
                            src={exercicio.video}
                            width="626"
                            height="440"
                            allow="autoplay; fullscreen; picture-in-picture"
                            style={{ margin: 'auto' }}
                            title={exercicio.nome}
                          ></iframe>
                        ) : exercicio.video.includes('player.vimeo') ? (
                          <iframe
                            src={exercicio.video}
                            width="626"
                            height="440"
                            allow="autoplay; fullscreen; picture-in-picture"
                            style={{ margin: 'auto' }}
                            title={exercicio.nome}
                          ></iframe>
                        ) : (
                          <iframe
                            src={'https://player.vimeo.com/video/' + exercicio.video.split(`/`)[3] + '?h=' + exercicio.video.split(`/`)[4]}
                            width="626"
                            height="440"
                            allow="autoplay; fullscreen; picture-in-picture"
                            style={{ margin: 'auto' }}
                            title={exercicio.nome}
                          ></iframe>
                        )}
                      </div>
                    )}
                  </InputGroup>
                )}
              </Section>
              <div style={{ visibility: 'hidden' }}>
                <Input name="id" type="number" />
                <Input name="imagem" type="text" />
              </div>
              {(exercicio?.isCriador || !(Number(id) > 0)) && (
                <Button loading={loading} type="submit" disabled={loading}>
                  Gravar
                </Button>
              )}
            </Form>
          </Schedule>
        </Content>
      </Container>
    </>
  );
};

export default Exercicio;
