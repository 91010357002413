import { createGlobalStyle } from 'styled-components';

export default createGlobalStyle`
    *{
        margin: 0;
        padding: 0;
        outline: 0;
        box-sizing: border-box ;
    }

    :root {
        --rdp-cell-size: 40px;
        --rdp-accent-color: #3e3b47;
        --rdp-background-color: #3e3b47;
        /* Switch to dark colors for dark themes */
        --rdp-accent-color-dark: #3003e1;
        --rdp-background-color-dark: #180270;
        /* Outline border for focused elements */
        --rdp-outline: 2px solid var(--rdp-accent-color);
        /* Outline border for focused and selected elements */
        --rdp-outline-selected: 2px solid rgba(0, 0, 0, 0.75);
    }

    ::-webkit-scrollbar {
        width: 6px;
        height: 6px;
        margin-right: 10px;
    }

    ::-webkit-scrollbar-corner {
        border: none;
        background: none;
    }

    ::-webkit-scrollbar-thumb {
        background-color: rgb(61, 60, 66);
        border-radius: 3px;
        cursor: move;
    }

    ::-webkit-scrollbar-track {
        background-color: transparent;
        border: none;
    }

    body {
        background-color: #f1f1f1;
        color: rgb(168, 168, 179);        
        -webkit-font-smoothing: antialiased;
    }

    body, input, select, button {
        font-family: 'Roboto', serif;
        font-size: 16px ;
    }

    h1, h2, h3, h4, h5, h6, strong {
        font-weight: 500 ;
    }

    button {
        cursor: pointer;
    }
`;

const size = {
  mobileS: '320px',
  mobileM: '375px',
  mobileL: '425px',
  tablet: '768px',
  laptop: '1024px',
  laptopL: '1440px',
  desktop: '2560px',
};

export const device = {
  mobileS: `(min-width: ${size.mobileS})`,
  mobileM: `(min-width: ${size.mobileM})`,
  mobileL: `(min-width: ${size.mobileL})`,
  tablet: `(min-width: ${size.tablet})`,
  laptop: `(min-width: ${size.laptop})`,
  laptopL: `(min-width: ${size.laptopL})`,
  desktop: `(min-width: ${size.desktop})`,
  desktopL: `(min-width: ${size.desktop})`,
  mobileS_Max: `(max-width: ${size.mobileS})`,
  mobileM_Max: `(max-width: ${size.mobileM})`,
  mobileL_Max: `(max-width: ${size.mobileL})`,
  tablet_Max: `(max-width: ${size.tablet})`,
  laptop_Max: `(max-width: ${size.laptop})`,
  laptopL_Max: `(max-width: ${size.laptopL})`,
  desktop_Max: `(max-width: ${size.desktop})`,
  desktopL_Max: `(max-width: ${size.desktop})`,
};
