import './styles.css';
import React, { useEffect, useState } from 'react';
import { useMotionValue, Reorder } from 'framer-motion';
import { useRaisedShadow } from './use-raised-shadow';
import Input from '../../../components/Input';
import { InputGroup } from '../styles';
import { FaImage, FaVideo } from 'react-icons/fa';
import Modal from '../../../components/Modal';

interface Iprops {
  list: any[];
  setList: any;
}

const Order: React.FC<Iprops> = ({ list, setList }) => {
  const [items, setItems] = useState(list);

  useEffect(() => {
    setItems(list);
  }, [list]);

  useEffect(() => {
    setList(items);
  }, [items, setList]);

  const remove = (id: number) => {
    setList(list.filter((x) => x.id !== id));
  };

  return (
    <Reorder.Group axis="y" onReorder={setItems} values={items}>
      {items.map((item: any, i: number) => (
        <Item key={item.id} item={item} index={i} remove={remove} />
      ))}
    </Reorder.Group>
  );
};

export default Order;

interface Props {
  item: any;
  index: number;
  remove(id: number): void;
}

const Item = ({ item, index, remove }: Props) => {
  const y = useMotionValue(0);
  const boxShadow = useRaisedShadow(y);
  const [modalImagem, setModalImagem] = useState(false);
  const [modalVideo, setModalVideo] = useState(false);

  return (
    <Reorder.Item value={item} id={item.id} style={{ boxShadow, y }}>
      <div style={{ visibility: 'hidden' }}>
        <Input type="hidden" name={`exercicios[${index}].id`} defaultValue={item.id} />
      </div>
      <div style={{ border: 'solid 1px ', padding: 10, position: 'relative' }}>
        <span style={{ position: 'absolute', top: 0, right: 10, cursor: 'pointer' }} onClick={() => remove(item.id)}>
          x
        </span>
        <span style={{ fontSize: 25, fontWeight: 500 }}>{item.nome}</span>
        <InputGroup>
          <label htmlFor="">
            Prescrição
            <Input name={`exercicios[${index}].prescricao`} defaultValue={item.prescricao} />
          </label>
        </InputGroup>
        <InputGroup>
          <label htmlFor="">
            Observação
            <Input name={`exercicios[${index}].observacao`} defaultValue={item.observacao} />
          </label>
        </InputGroup>
        <div style={{ display: 'flex', marginTop: 10 }}>
          {item.imagem && <FaImage color="#1171ef" style={{ cursor: 'pointer' }} size={40} onClick={() => setModalImagem(true)} />}
          {item.video && (
            <FaVideo color="#1171ef" style={{ cursor: 'pointer', marginLeft: 20 }} size={40} onClick={() => setModalVideo(true)} />
          )}
        </div>
      </div>
      <Modal visible={modalImagem} close={() => setModalImagem(false)}>
        <div>
          <img src={firebaseUrl(item.imagem)} alt={item.nome} />
        </div>
      </Modal>
      {item.video && (
        <Modal visible={modalVideo} close={() => setModalVideo(false)}>
          <div>
            {item.video.includes('youtube') ? (
              <iframe
                src={item.video}
                width="626"
                height="440"
                allow="autoplay; fullscreen; picture-in-picture"
                style={{ margin: 'auto' }}
                title={item.nome}
              ></iframe>
            ) : item.video.includes('player.vimeo') ? (
              <iframe
                src={item.video}
                width="626"
                height="440"
                allow="autoplay; fullscreen; picture-in-picture"
                style={{ margin: 'auto' }}
                title={item.nome}
              ></iframe>
            ) : (
              <iframe
                src={'https://player.vimeo.com/video/' + item.video.split(`/`)[3] + '?h=' + item.video.split(`/`)[4]}
                width="626"
                height="440"
                allow="autoplay; fullscreen; picture-in-picture"
                style={{ margin: 'auto' }}
                title={item.nome}
              ></iframe>
            )}
          </div>
        </Modal>
      )}
    </Reorder.Item>
  );
};

const firebaseUrl = (url: string) => `https://atitudevs.blob.core.windows.net/exercicios/${url}`;
