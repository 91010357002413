import React, { useCallback, useEffect, useRef, useState } from 'react';
import { FiArrowDownLeft, FiMail, FiUser, FiLock, FiEye, FiEyeOff } from 'react-icons/fi';
import { FormHandles } from '@unform/core';
import { Form } from '@unform/web';
import * as Yup from 'yup';

import getValidationErrors from '../../utils/getValidationErrors';

import logo from '../../assets/common/logo1.png';

import Button from '../../components/Button';
import Input from '../../components/Input';

import { Container, Content, Background, InputContainer, TermoContainer, VideoContent } from './styles';
import { Link, useNavigate } from 'react-router-dom';
import api from '../../services/api';
import { useToast } from '../../hooks/toast';
import { IconType } from 'react-icons';
import Checkbox from '../../components/Checkbox/V2';

const SignUp: React.FC = () => {
  const formRef = useRef<FormHandles>(null);
  const navigate = useNavigate();
  const { addToast } = useToast();
  const [loading, setLoading] = useState(false);
  const [showpass, setShowPass] = useState(false);
  const [attr, setAttr] = useState<string>('password');

  useEffect(() => {
    if (showpass) {
      setAttr('text');
    } else {
      setAttr('password');
    }
  }, [showpass]);

  const showPassword = () => setShowPass(!showpass);

  const handleSubmit = useCallback(async (data: object) => {
    try {
      formRef.current?.setErrors({});
      setLoading(true);
      const schema = Yup.object().shape({
        nome: Yup.string().required('Nome obrigatorio'),
        sobrenome: Yup.string().required('Sobrenome obrigatorio'),
        email: Yup.string().required('E-mail obrigatorio').email('Digite um e-mail valido'),
        password: Yup.string().min(6, 'No minimo 6 digitos'),
        confirmpassword: Yup.string()
          .min(6, 'No minimo 6 digitos')
          .oneOf([Yup.ref('password')], 'Senha devem ser iguais'),
        termo: Yup.boolean().isTrue('Termo obrigatorio'),
      });

      await schema.validate(data, {
        abortEarly: true,
      });

      api
        .post('account', data)
        .then(() => {
          addToast({
            type: 'success',
            title: 'Conta criada!',
          });
          setLoading(false);
          navigate('/');
        })
        .catch((e) => {
          setLoading(false);
          console.log(e);

          addToast({
            type: 'error',
            title: e.message,
          });
        });
    } catch (err: any) {
      // const errors = getValidationErrors(err);
      // formRef.current?.setErrors(errors);
      setLoading(false);
      addToast({
        type: 'error',
        title: err.response.data.message,
      });
    }
  }, []);

  return (
    <Container>
      {/* <Background /> */}
      <VideoContent>
        <iframe src="https://player.vimeo.com/video/892310547" />
      </VideoContent>
      <Content>
        {/* <img src={logo} alt="AtitudeVS" /> */}
        <Form
          ref={formRef}
          onSubmit={handleSubmit}
          placeholder={undefined}
          onPointerEnterCapture={undefined}
          onPointerLeaveCapture={undefined}
        >
          <h1>Faça seu cadastro</h1>
          <InputContainer>
            <Input name="nome" icon={FiUser} placeholder="Nome" />
            <Input name="sobrenome" icon={FiUser} placeholder="Sobrenome" />
            <Input name="email" icon={FiMail} placeholder="E-mail" />
            <Input
              name="password"
              icon={FiLock}
              subicon={showpass ? FiEye : FiEyeOff}
              type={attr}
              placeholder="Senha"
              handleclick={showPassword}
            />
            <Input
              name="confirmpassword"
              icon={FiLock}
              subicon={showpass ? FiEye : FiEyeOff}
              type={attr}
              placeholder="Confirme a Senha"
              handleclick={showPassword}
            />
            <Button type="submit" loading={loading}>
              Cadastrar
            </Button>
          </InputContainer>
          <TermoContainer>
            <Checkbox name="termo" defaultChecked={false} />
            <span>
              Concordo com os{' '}
              <a href="https://atitudevs.blob.core.windows.net/documentos/Termo de uso AVS_professor_31.10.20.pdf">Termos e Condições</a> de
              uso dessa plataforma
            </span>
          </TermoContainer>
        </Form>
        <Link to="/">
          <FiArrowDownLeft />
          Voltar para o login
        </Link>
      </Content>
    </Container>
  );
};

export default SignUp;
