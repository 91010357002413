import { shade } from 'polished';
import styled from 'styled-components';

export const Container = styled.div`
  max-width: 1120px;
  margin: 64px auto;
  display: flex;

  input {
    text-align: 'center';
  }
`;

export const Content = styled.main`
  flex: 1;
`;

export const Title = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 20px;

  h1 {
    color: #525f7f;
  }

  a {
    display: flex;
    flex-direction: row;
    justify-content: center;
    max-width: 15%;
    max-height: 35px;
    display: flex;
    text-align: center;
    background: #1171ef;
    height: 56px;
    border-radius: 10px;
    border: 0;
    padding: 0 16px;
    color: #ffffff;
    text-decoration: none;
    width: 100%;
    font-weight: 500;
    margin-left: 10px;
    transition: background-color 0.2s;

    &:hover {
      background: ${shade(0.2, '#1171ef')};
    }

    > * {
      margin: auto 0;
    }
  }
`;

export const Section = styled.section`
  margin-top: 48px;
  > strong {
    color: #999591;
    font-size: 20px;
    line-height: 26px;
    border-bottom: 1px solid #3e3b47;
    display: block;
    padding-bottom: 16px;
    margin-bottom: 16px;
  }
`;

export const InputGroup = styled.div`
  display: grid;
  grid-auto-flow: column;
  grid-auto-columns: 1fr;
  gap: 8px;

  & + div {
    margin-top: 8px;
  }
`;

export const Row = styled.div`
  display: flex;
  /* justify-content: space-between; */

  > div {
    height: 72px;

    &:nth-child(1) {
      width: 230px;
    }
    &:nth-child(2) {
      width: 100px;
    }
    &:nth-child(3) {
      width: 100px;
    }
    &:nth-child(4) {
      width: 100px;
    }
    &:nth-child(5) {
      width: 100px;
    }
    &:nth-child(6) {
      width: 100px;
      > div {
        height: 100%;
      }
    }

    > img {
      height: 100%;
      width: 100%;
    }
  }
`;
