import React, { InputHTMLAttributes, useEffect, useRef } from 'react';
import { useField } from '@unform/core';

import { Container } from './styles';

interface InputProps extends InputHTMLAttributes<HTMLInputElement> {
  name: string;
}

const Checkbox: React.FC<InputProps> = ({ name, ...rest }) => {
  const inputRef = useRef(null);
  const { fieldName, defaultValue, error, registerField } = useField(name);
  useEffect(() => {
    registerField({
      name: fieldName,
      ref: inputRef,
      path: 'value',
    });
  }, [fieldName, registerField]);

  return (
    <Container>
      <input type="checkbox" defaultValue={defaultValue} ref={inputRef} name={name} {...rest} />
    </Container>
  );
};

export default Checkbox;
