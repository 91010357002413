import React from 'react';
import image404 from '../../assets/common/404.png';

import { Container } from './styles';

const NotFound: React.FC = () => {
  return (
    <Container>
      <img src={image404} alt="Not Found" />
      <p>Página não encontrada!</p>
    </Container>
  );
};

export default NotFound;
