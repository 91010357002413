import React, { useEffect, useRef, useState } from 'react';
import Modal from '../../../components/Modal';
import Button from '../../../components/Button';
import api from '../../../services/api';
import { useToast } from '../../../hooks/toast';
import Input from '../../../components/Input';
import Select from '../../../components/Select';
import { Form } from '@unform/web';
import { FormHandles } from '@unform/core';

// import { Container } from './styles';
interface Props {
  visible: boolean;
  list: any[];
  setList: any;
  close(): void;
  confirm(): void;
}
const ModalExercicios: React.FC<Props> = ({ visible, list, setList, close, confirm }) => {
  const { addToast } = useToast();
  const formRef = useRef<FormHandles>(null);
  const [exercicios, setExercicios] = useState<any>([]);
  const [text, setText] = useState<string>('');
  const [segmento, setSegmento] = useState<number>(0);
  const [segmentos, setSegmentos] = useState([]);

  useEffect(() => {
    api
      .get('exercicios/all')
      .then((response) => {
        // eslint-disable-next-line
        response.data.map((r: any) => (r.selected = false));
        setExercicios(response.data);
      })
      .catch((error) => {
        addToast({
          type: 'error',
          title: error.message,
        });
      });

    api.get(`segmentos`).then((response) => {
      // eslint-disable-next-line
      response.data.map((r: any) => ((r.value = r.id), (r.name = r.nome)));
      setSegmentos(response.data);
    });
  }, [addToast, list]);

  const handleFilter = (e: React.ChangeEvent<HTMLInputElement>) => {
    setText(e.target.value || '');
  };

  const handleSegmento = (e: React.ChangeEvent<HTMLSelectElement>) => {
    setSegmento(Number(e.target.value));
  };

  const select = (id: any) => {
    const index = exercicios.findIndex((e: any) => e.id === id);
    exercicios[index].selected = !exercicios[index].selected;
    setExercicios([...exercicios]);
  };

  const save = () => {
    const newlist = exercicios.filter((item: any) => !list.some((l) => l.id === item.id.toString()) && item.selected === true);
    setList([...list, ...newlist]);
    close();
  };

  return (
    <Modal visible={visible} close={close}>
      <p style={{ justifyContent: 'center', marginBottom: 20 }}>Adicionar exercicios</p>
      <Form ref={formRef} onSubmit={() => ({})} placeholder={undefined} onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined}>
        <div style={{ marginBottom: 10 }}>
          <Select name="segmento" id="segmento" placeholder="Segmento" options={segmentos} onChange={handleSegmento} />
        </div>
        <div>
          <Input name="search" onChange={handleFilter} placeholder="Filtrar" />
        </div>
      </Form>
      {(text.length > 3 || segmento > 0) && (
        <>
          {exercicios
            .filter((a: any) => a.segmentoId === segmento || segmento === 0)
            .filter((a: any) => a.nome.toLowerCase().includes(text.toLowerCase()))
            .map((e: any) => (
              <div key={e.id} style={{ display: 'flex', justifyContent: 'flex-start', gap: 10 }}>
                <div onClick={() => select(e.id)}>{e.nome}</div>
                {/* <div>{e.selected ? <Button onClick={() => select(e.id)}>-</Button> : <Button onClick={() => select(e.id)}>+</Button>}</div> */}
                <div>
                  <input type="checkbox" value={e.selected} checked={e.selected} onChange={() => select(e.id)} />
                </div>
              </div>
            ))}
        </>
      )}
      <div>
        <Button onClick={save}>Confirmar</Button>
      </div>
    </Modal>
  );
};

export default ModalExercicios;
