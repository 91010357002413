import React, { InputHTMLAttributes, useEffect, useRef } from 'react';
import { IconBaseProps } from 'react-icons';
import { FiAlertCircle } from 'react-icons/fi';
import { useField } from '@unform/core';

import { Container } from './styles';

interface InputProps extends InputHTMLAttributes<HTMLInputElement> {
  name: string;
  icon?: React.ComponentType<IconBaseProps>;
  subicon?: React.ComponentType<IconBaseProps>;
  handleclick?(): void;
}

const Input: React.FC<InputProps> = ({ name, icon: Icon, subicon: SubIcon, handleclick: handleClick, ...rest }) => {
  const inputRef = useRef(null);
  const { fieldName, defaultValue, error, registerField, clearError } = useField(name);

  useEffect(() => {
    registerField({
      name: fieldName,
      ref: inputRef.current,
      path: 'value',
    });
  }, [fieldName, registerField]);

  return (
    <Container>
      {Icon && <Icon size={20} />}
      <input defaultValue={defaultValue} ref={inputRef} onFocus={clearError} {...rest} />
      {SubIcon && <SubIcon size={20} onClick={handleClick} />}
      {error && <FiAlertCircle size={20} color="red" />}
      {/* <span>{error}</span> */}
    </Container>
  );
};

export default Input;
