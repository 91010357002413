import { shade } from 'polished';
import styled from 'styled-components';

export const Container = styled.div``;

export const Header = styled.header`
  padding: 32px 0;
  background: #28262e;
`;

export const HeaderContent = styled.div`
  max-width: 1120px;
  margin: 0 auto;
  display: flex;
  align-items: center;

  > img {
    height: 50px;
  }

  button {
    margin-left: auto;
    background: transparent;
    border: 0;

    svg {
      color: #999591;
      width: 20px;
      height: 20px;
    }
  }
`;

export const Title = styled.div`
  display: flex;
  justify-content: space-between;

  h1 {
    color: #525f7f;
  }

  a {
    display: flex;
    flex-direction: row;
    justify-content: center;
    max-width: 15%;
    max-height: 35px;
    display: flex;
    text-align: center;
    background: #1171ef;
    height: 56px;
    border-radius: 10px;
    border: 0;
    padding: 0 16px;
    color: #ffffff;
    text-decoration: none;
    width: 100%;
    font-weight: 500;
    margin-left: 10px;
    transition: background-color 0.2s;

    &:hover {
      background: ${shade(0.2, '#1171ef')};
    }

    > * {
      margin: auto 0;
    }
  }
`;

export const Profile = styled.div`
  display: flex;
  align-items: center;
  margin-left: 80px;

  > svg {
  }

  div {
    display: flex;
    flex-direction: column;
    margin-left: 16px;
    line-height: 24px;

    span {
      color: #f4ede8;
    }

    strong {
      color: #ff9000;
    }
  }
`;

export const Content = styled.main`
  max-width: 1120px;
  margin: 64px auto;
  display: flex;
`;
export const Schedule = styled.div`
  flex: 1;
  /* margin-right: 120px; */

  h1 {
    font-size: 36px;
  }

  > p {
    margin-top: 8px;
    color: #ff9000;
    display: flex;
    align-items: center;
    font-weight: 500;

    span {
      display: flex;
      align-items: center;
    }
    span + span::before {
      content: '';
      width: 1px;
      height: 12px;
      background: #ff9000;
      margin: 0 8px;
    }
  }
`;

export const Section = styled.section`
  margin-top: 48px;
  > strong {
    color: #999591;
    font-size: 20px;
    line-height: 26px;
    border-bottom: 1px solid #3e3b47;
    display: block;
    padding-bottom: 16px;
    margin-bottom: 16px;
  }
`;

export const Agachamento = styled.div`
  display: grid;
  grid:
    'HEAD1 HEAD2 HEAD3 HEAD4 HEAD5 HEAD6 HEAD7' 0.2fr
    'TXT3 PT3 IMAGE1 IMAGE2 OP1 RES CLASS' 1fr
    'TXT2 PT2 IMAGE3 IMAGE4 OP2 RES CLASS' 1fr
    'TXT1 PT1 IMAGE5 IMAGE6 OP3 RES CLASS' 0.5fr
    'TXT0 PT0 PT0    PT0    OP4 RES CLASS' 0.5fr
    / 1.5fr 0.5fr 0.5fr 0.5fr 0.5fr 0.8fr 0.8fr;
  gap: 0px;

  div {
    border: 1px solid #3e3b47;
  }

  #OP1,
  #OP2,
  #OP3,
  #OP4 {
    label {
      justify-content: center;
      height: 100%;
    }
  }
`;

export const Image1 = styled.div`
  grid-area: IMAGE1;
  display: flex;
  justify-content: space-around;
  flex-direction: column;
  text-align: center;
  img {
    width: 100%;
  }
`;

export const Image2 = styled.div`
  grid-area: IMAGE2;
  display: flex;
  justify-content: space-around;
  flex-direction: column;
  text-align: center;
  img {
    width: 100%;
  }
`;

export const Image3 = styled.div`
  grid-area: IMAGE3;
  display: flex;
  justify-content: space-around;
  flex-direction: column;
  text-align: center;
  img {
    width: 100%;
  }
`;

export const Image4 = styled.div`
  grid-area: IMAGE4;
  display: flex;
  justify-content: space-around;
  flex-direction: column;
  text-align: center;
  img {
    width: 100%;
  }
`;

export const Image5 = styled.div`
  grid-area: IMAGE5;
  display: flex;
  justify-content: space-around;
  flex-direction: column;
  text-align: center;
  img {
    width: 100%;
  }
`;

export const Image6 = styled.div`
  grid-area: IMAGE6;
  display: flex;
  justify-content: space-around;
  flex-direction: column;
  text-align: center;
  img {
    width: 100%;
  }
`;

/*
img {
    width: 100px;
  }

  input {
    margin-right: 0;
  }

  > div {
    width: 100%;
    &:nth-child(1) {
      width: 80%;
      > div {
        display: flex;
        justify-content: space-between;

        > div {
          border: 1px solid #3e3b47;

          &:nth-child(1) {
            width: 40%;
            padding: 5px;
          }

          &:nth-child(2) {
            width: 10%;
            display: flex;
            justify-content: center;
            height: auto;
            flex-direction: column;
            text-align: center;
          }
          &:nth-child(3) {
            width: 20%;
            display: flex;
            justify-content: center;
            height: auto;
          }
          &:nth-child(4) {
            width: 20%;
            display: flex;
            justify-content: center;
            height: auto;
          }
          &:nth-child(5) {
            width: 10%;
            display: flex;
            justify-content: center;
            height: auto;
            flex-direction: column;
            text-align: center;

            > div {
              margin: auto;
            }
          }
        }
      }
    }
    &:nth-child(2) {
      width: 20%;
      > div {
        display: flex;

        &:nth-child(1) {
          > div {
            padding: 5px;
          }
        }

        &:nth-child(2) {
          height: 95%;
        }
        > div {
          border: 1px solid #3e3b47;
          &:nth-child(1) {
            width: 50%;
            display: flex;
            justify-content: center;
            height: auto;
            p {
              background-color: #232129;
              display: flex;
              justify-content: center;
              height: auto;
              width: 100%;
              flex-direction: column;
              text-align: center;
            }
          }
          &:nth-child(2) {
            width: 50%;
            display: flex;
            justify-content: center;
            height: auto;
            p {
              background-color: #232129;
              display: flex;
              justify-content: center;
              height: auto;
              width: 100%;
              flex-direction: column;
              text-align: center;
            }
          }
        }
      }
    }
  }
*/

export const MobilidadeOmbros = styled.div`
  display: grid;
  grid:
    'HEAD1 HEAD2 HEAD3 HEAD4 HEAD5 HEAD6 HEAD7' 0.2fr
    'TXT4 PT4 IMAGE1 OP1 OP5 RES CLASS' 1fr
    'TXT3 PT3 IMAGE2 OP2 OP6 RES CLASS' 1fr
    'TXT2 PT2 IMAGE3 OP3 OP7 RES CLASS' 1fr
    'TXT1 PT1 IMAGE4 OP4 OP8 RES CLASS' 0.5fr
    / 1.5fr 0.5fr 0.5fr 0.5fr 0.5fr 0.8fr 0.8fr;
  gap: 0px;

  div {
    border: 1px solid #3e3b47;
  }

  #OP0,
  #OP1,
  #OP2,
  #OP3,
  #OP4,
  #OP5,
  #OP6,
  #OP7,
  #OP8 {
    label {
      justify-content: center;
      height: 100%;
    }
  }
`;

export const MobilidadeTornozelo = styled.div`
  display: flex;

  > div {
    width: 100%;

    &:nth-child(1) {
      width: 80%;
      > div {
        display: flex;
        justify-content: space-between;

        > div {
          border: 1px solid #3e3b47;

          &:nth-child(1) {
            width: 40%;
            padding: 5px;
          }

          &:nth-child(2) {
            width: 10%;
            display: flex;
            justify-content: center;
            height: auto;
            flex-direction: column;
            text-align: center;
          }
          &:nth-child(3) {
            width: 20%;
            display: flex;
            justify-content: center;
            height: auto;
          }
          &:nth-child(4) {
            width: 15%;
            display: flex;
            justify-content: center;
            height: auto;
            flex-direction: column;
            text-align: center;
          }
          &:nth-child(5) {
            width: 15%;
            display: flex;
            justify-content: center;
            height: auto;
            flex-direction: column;
            text-align: center;
          }
        }
      }
    }
    &:nth-child(2) {
      width: 20%;
      > div {
        display: flex;

        &:nth-child(1) {
          > div {
            padding: 5px;
          }
        }

        &:nth-child(2) {
          height: 95%;
        }
        > div {
          border: 1px solid #3e3b47;
          &:nth-child(1) {
            width: 50%;
            display: flex;
            justify-content: center;
            height: auto;
            p {
              background-color: #232129;
              display: flex;
              justify-content: center;
              height: auto;
              width: 100%;
              flex-direction: column;
              text-align: center;
            }
          }
          &:nth-child(2) {
            width: 50%;
            display: flex;
            justify-content: center;
            height: auto;
            p {
              background-color: #232129;
              display: flex;
              justify-content: center;
              height: auto;
              width: 100%;
              flex-direction: column;
              text-align: center;
            }
          }
        }
      }
    }
  }
`;

export const GridTornozelo = styled.div`
  display: grid;
  grid:
    'HEAD1 HEAD2 HEAD3 HEAD4 HEAD5 HEAD6 HEAD7' 0.2fr
    'TXT3 PT3 IMAGE OP1 OP5 RES CLASS' 1fr
    'TXT2 PT2 IMAGE OP2 OP6 RES CLASS' 1fr
    'TXT1 PT1 IMAGE OP3 OP7 RES CLASS' 0.5fr
    'TXT0 PT0 IMAGE OP4 OP8 RES CLASS' 0.5fr
    / 2fr 0.5fr 1fr 0.5fr 0.5fr 0.8fr 0.8fr;
  gap: 0px;

  div {
    border: 1px solid #3e3b47;
  }

  #OP0,
  #OP1,
  #OP2,
  #OP3,
  #OP4,
  #OP5,
  #OP6,
  #OP7,
  #OP8 {
    label {
      justify-content: center;
      height: 100%;
    }
  }
`;

export const AnaliseHeader = styled.div`
  grid-area: HEAD1;
`;
export const Analise04 = styled.div`
  grid-area: TXT4;
  padding: 5px;
`;
export const Analise03 = styled.div`
  grid-area: TXT3;
  padding: 5px;
`;
export const Analise02 = styled.div`
  grid-area: TXT2;
  padding: 5px;
`;
export const Analise01 = styled.div`
  grid-area: TXT1;
  padding: 5px;
`;
export const Analise00 = styled.div`
  grid-area: TXT0;
  padding: 5px;
`;

export const PontuacaoHeader = styled.div`
  grid-area: HEAD2;
`;
export const Pontuacao04 = styled.div`
  grid-area: PT4;
  text-align: center;
  line-height: 55px;
`;
export const Pontuacao03 = styled.div`
  grid-area: PT3;
  text-align: center;
  line-height: 55px;
`;
export const Pontuacao02 = styled.div`
  grid-area: PT2;
  text-align: center;
  line-height: 55px;
`;
export const Pontuacao01 = styled.div`
  grid-area: PT1;
  text-align: center;
  line-height: 55px;
`;
export const Pontuacao00 = styled.div`
  grid-area: PT0;
  text-align: center;
  display: flex;
  justify-content: center;
  flex-direction: column;
`;

export const ImageHeader = styled.div`
  grid-area: HEAD3;
  text-align: center;
`;
export const Image = styled.div`
  grid-area: IMAGE;
  display: flex;
  justify-content: space-around;
  flex-direction: column;
  text-align: center;
  img {
    width: 100%;
  }
`;
export const CheckHeaderD = styled.div`
  grid-area: HEAD4;
  text-align: center;
`;
export const CheckD01 = styled.div`
  grid-area: D1;

  div {
    display: flex;
    justify-content: center;
    height: 100%;
  }

  input {
    margin: auto;
  }
`;
export const CheckD02 = styled.div`
  grid-area: D2;

  div {
    display: flex;
    justify-content: center;
    height: 100%;
  }

  input {
    margin: auto;
  }
`;
export const CheckD03 = styled.div`
  grid-area: D3;

  div {
    display: flex;
    justify-content: center;
    height: 100%;
  }

  input {
    margin: auto;
  }
`;
export const CheckD00 = styled.div`
  grid-area: D0;

  div {
    display: flex;
    justify-content: center;
    height: 100%;
  }

  input {
    margin: auto;
  }
`;
export const CheckHeaderE = styled.div`
  grid-area: HEAD5;
  text-align: center;
`;
export const CheckE01 = styled.div`
  grid-area: E1;

  div {
    display: flex;
    justify-content: center;
    height: 100%;
  }

  input {
    margin: auto;
  }
`;
export const CheckE02 = styled.div`
  grid-area: E2;

  div {
    display: flex;
    justify-content: center;
    height: 100%;
  }

  input {
    margin: auto;
  }
`;
export const CheckE03 = styled.div`
  grid-area: E3;

  div {
    display: flex;
    justify-content: center;
    height: 100%;
  }

  input {
    margin: auto;
  }
`;

export const ResultadoHeader = styled.div`
  grid-area: HEAD6;
`;
export const Resultado = styled.div`
  grid-area: RES;

  display: flex;
  justify-content: space-around;
  flex-direction: column;
  text-align: center;
  background-color: #f1f1f1;
`;
export const ClassificacaoHeader = styled.div`
  grid-area: HEAD7;
`;
export const Classificacao = styled.div`
  grid-area: CLASS;

  display: flex;
  justify-content: space-around;
  flex-direction: column;
  text-align: center;
  background-color: #f1f1f1;
`;

export const GridCore = styled.div`
  display: grid;
  grid:
    'HEAD1 HEAD1 HEAD3 HEAD4 HEAD5 HEAD6' 0.2fr
    'TXT TXT IMAGE C0 TEMP CLASS' 1fr
    'TXT TXT IMAGE C1 TEMP CLASS' 1fr
    'TXT TXT IMAGE C2 TEMP CLASS' 0.5fr
    'TXT TXT IMAGE C3 TEMP CLASS' 0.5fr
    'TXT TXT IMAGE C4 TEMP CLASS' 0.5fr
    / 0.5fr 0.5fr 1fr 0.5fr 0.8fr 0.8fr;
  gap: 0px;

  div {
    border: 1px solid #3e3b47;
  }
`;
export const Header1 = styled.div`
  grid-area: HEAD1;
`;
export const Header2 = styled.div`
  grid-area: HEAD2;
`;
export const Header3 = styled.div`
  grid-area: HEAD3;
`;
export const Header4 = styled.div`
  grid-area: HEAD4;
`;
export const Header5 = styled.div`
  grid-area: HEAD5;
`;
export const Header6 = styled.div`
  grid-area: HEAD6;
`;
export const Header7 = styled.div`
  grid-area: HEAD7;
`;
export const TXT = styled.div`
  grid-area: TXT;
  padding: 10px;
`;
export const Tempo0 = styled.div`
  grid-area: T0;
  padding: 5px;
`;
export const Tempo1 = styled.div`
  grid-area: T1;
  padding: 5px;
`;
export const Tempo2 = styled.div`
  grid-area: T2;
  padding: 5px;
`;
export const Tempo3 = styled.div`
  grid-area: T3;
  padding: 5px;
`;
export const Tempo4 = styled.div`
  grid-area: T4;
  padding: 5px;
`;
export const Classif0 = styled.div`
  grid-area: C0;
  padding: 5px;
`;
export const Classif1 = styled.div`
  grid-area: C1;
  padding: 5px;
`;
export const Classif2 = styled.div`
  grid-area: C2;
  padding: 5px;
`;
export const Classif3 = styled.div`
  grid-area: C3;
  padding: 5px;
`;
export const Classif4 = styled.div`
  grid-area: C4;
  padding: 5px;
`;

export const TEMPO = styled.div`
  grid-area: TEMP;

  div {
    display: flex;
    justify-content: center;
    height: 100%;
  }

  input {
    margin: auto;
    text-align: center;
  }
`;
