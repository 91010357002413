import { FormHandles } from '@unform/core';
import { Form } from '@unform/web';
import React, { ChangeEventHandler, useEffect, useRef, useState } from 'react';
import { FaDoorOpen } from 'react-icons/fa';
import { FiSearch, FiTrash } from 'react-icons/fi';
import Button from '../../../components/Button';
import Checkbox from '../../../components/Checkbox/V2';

import Input from '../../../components/Input';
import Modal from '../../../components/Modal';
import Spinner from '../../../components/Spinner';
import { useToast } from '../../../hooks/toast';
import api from '../../../services/api';

import * as S from './styles';
import { Link } from 'react-router-dom';

const ProfessoresList: React.FC = () => {
  const formRef = useRef<FormHandles>(null);
  const { addToast } = useToast();
  const [loading, setLoading] = useState(false);
  const [professores, setProfessores] = useState<any>([]);
  const [pages, setPages] = useState(1);
  const [currentPage, setCurrentPage] = useState(1);
  const [text, setText] = useState<string>('');
  const [bloqueados, setBloqueados] = useState(false);
  const [modal, setModal] = useState(false);
  const [modalAcesso, setModalAcesso] = useState(false);
  const [professor, setProfessor] = useState(
    {} as {
      vagas: string | number | readonly string[] | undefined;
      id: number;
      nome: string;
      role: string;
    }
  );
  const [bloqueado, setBloqueado] = useState(false);
  const [refresh, setRefresh] = useState(0);

  useEffect(() => {
    setLoading(true);
    const query = text ? `&Text=${text}` : '';
    api
      .get(`professores?PageNumber=${currentPage}${query}&Bloqueados=${bloqueados}`)
      .then((response) => {
        setProfessores(response.data.list);
        setCurrentPage(response.data.currentPage);
        setPages(response.data.pages);
        setLoading(false);
      })
      .catch((error) => {
        setLoading(false);
        addToast({
          type: 'error',
          title: error.message,
        });
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [addToast, bloqueados, refresh]);

  const handleGotoPage = (_page: number) => {
    if (_page > pages) return;
    setLoading(true);
    const query = text ? `&Text=${text}` : '';
    api
      .get(`professores?PageNumber=${_page}${query}&Bloqueados=${bloqueados}`)
      .then((resp) => {
        setProfessores(resp.data.list);
        setCurrentPage(resp.data.currentPage);
        setPages(resp.data.pages);
        setLoading(false);
      })
      .catch((error) => {
        setLoading(false);
      });
  };

  const handleFilter = (e: React.ChangeEvent<HTMLInputElement>) => {
    setText(e.target.value || '');
  };

  const handleSearch = (e: any) => {
    if (e.key === 'Enter') {
      setLoading(true);
      const query = text ? `&Text=${text}` : '';
      setCurrentPage(1);
      api
        .get(`professores?PageNumber=${1}${query}&Bloqueados=${bloqueados}`)
        .then((resp) => {
          setProfessores(resp.data.list);
          setCurrentPage(resp.data.currentPage);
          setPages(resp.data.pages);
          setLoading(false);
        })
        .catch((error) => {
          setLoading(false);
        });
    }
  };

  const FilterBloqueados: ChangeEventHandler<HTMLInputElement> = (e) => {
    setBloqueados(e.target.checked);
  };

  const showModal = (item: any) => {
    setProfessor(item);
    setModal(true);
  };

  const showAcesso = (item: any) => {
    setProfessor(item);
    setBloqueado(item.bloqueado);
    setModalAcesso(true);
  };

  const handleDelete = () => {
    try {
      setLoading(true);
      api.delete(`professores/${professor.id}`).then(() => {
        setLoading(false);
        setModal(false);
        setRefresh(refresh + 1);
        addToast({
          type: 'success',
          title: 'Excluido com sucesso',
        });
      });
    } catch (error) {
      setLoading(false);
      addToast({
        type: 'error',
        title: 'Ocorreu um error ao excluir',
      });
    }
  };

  const changeRole = (role: string) => {
    const data = { ...professor, role: role };
    setProfessor(data);
  };

  const changeVagas = (vagas: number) => {
    const data = { ...professor, vagas: vagas };
    setProfessor(data);
  };

  const changeAcesso = () => {
    try {
      setLoading(true);
      const request = {
        id: professor.id,
        bloqueado: bloqueado,
        role: professor.role,
        vagas: professor.vagas,
      };

      const title = bloqueado ? 'bloqueado' : 'desbloqueado';
      api.put(`professores/${professor.id}/access`, request).then(() => {
        addToast({
          type: 'success',
          title: `Atualizado - status: ${title}`,
        });
        setModalAcesso(false);
        setLoading(false);
        setRefresh(refresh + 1);
      });
      setLoading(false);
    } catch (error) {
      setLoading(false);
      addToast({
        type: 'error',
        title: 'Ocorreu um error',
      });
    }
  };

  const handleSubmit = () => ({});

  return (
    <>
      <S.Container>
        {/* <Header /> */}
        <S.Content>
          <S.Schedule>
            <S.Title>
              <h1>Professores</h1>
              <p>{/* <span>Hoje</span> */}</p>
              {/* <Link to={'/aluno/cadastrar'}>
                <FiUserPlus size={20} />
                <span>Novo</span>
              </Link> */}
            </S.Title>
            <Form
              ref={formRef}
              onSubmit={handleSubmit}
              placeholder={undefined}
              onPointerEnterCapture={undefined}
              onPointerLeaveCapture={undefined}
            >
              <S.Search>
                <Input name="nome" placeholder="Buscar" icon={FiSearch} onChange={handleFilter} onKeyDown={handleSearch} />
              </S.Search>
              <S.Filter>
                <Checkbox name={'bloqueados'} label="Bloqueados" onChange={FilterBloqueados} defaultChecked={false} />
              </S.Filter>
            </Form>
            {!loading ? (
              <>
                <S.RowContainer>
                  {professores
                    .filter((a: any) => a.nomeCompleto.toLowerCase().includes(text.toLowerCase()))
                    .map((a: any) => (
                      <S.Row key={a.id} sexo={a.sexo}>
                        <div>
                          <div></div>
                          <strong>
                            <Link to={`/professores/${a.id}/alunos`}>{a.nomeCompleto}</Link>
                          </strong>
                          {a.role === 'Professor' && <S.Professor>{a.role}</S.Professor>}
                          {a.role === 'Coordenador' && <S.Coordenador>{a.role}</S.Coordenador>}
                        </div>
                        <div>
                          <>
                            {/* <FiEdit size={25} color={a.bloqueado ? '#ef1111' : '#2dce89'} /> */}
                            <FaDoorOpen onClick={() => showAcesso(a)} size={25} color={a.bloqueado ? '#ef1111' : '#2dce89'} />
                            <FiTrash onClick={() => showModal(a)} size={25} color={'#ef1111'} style={{ marginLeft: 15 }} />
                          </>
                        </div>
                      </S.Row>
                    ))}
                </S.RowContainer>
                <S.Pagination>
                  <S.Page onClick={() => handleGotoPage(currentPage - 1)}>Anterior</S.Page>
                  {Array.from({ length: pages < 20 ? pages : pages }, (_, i) => (
                    <S.Page key={i} selected={i + 1 === currentPage} onClick={() => handleGotoPage(i + 1)}>
                      <div>{i + 1}</div>
                    </S.Page>
                  ))}
                  <S.Page onClick={() => handleGotoPage(currentPage + 1)}>Próximo</S.Page>
                </S.Pagination>
              </>
            ) : (
              <>
                <br />
                <Spinner />
              </>
            )}
          </S.Schedule>
        </S.Content>
        <Modal visible={modal} close={() => setModal(false)}>
          <S.ModalContent>
            <p style={{ marginBottom: 20 }}>EXCLUIR</p>
            <div>
              <p>
                {`Deseja realmente excluir o professor`} <strong>{professor.nome}</strong>
              </p>
              <p>Verifique se o professor não tem nenhum aluno associado</p>
              <p>Essa operação não pode ser desfeita</p>
              <Button onClick={handleDelete} loading={loading}>
                Confirmar
              </Button>
            </div>
          </S.ModalContent>
        </Modal>
        <Modal visible={modalAcesso} close={() => setModalAcesso(false)}>
          <S.ModalContent>
            <p style={{ marginBottom: 20 }}>ACESSO</p>
            <div>
              <p>
                {`Professor`} <strong>{professor.nome}</strong>
              </p>
              <S.ToogleContainer>
                <div style={{ display: 'flex', flexDirection: 'column' }}>
                  <div style={{ marginBottom: 30 }}>
                    Permissao
                    <select onChange={(e: any) => changeRole(e.target.value)} defaultValue={professor.role}>
                      <option value="Professor">Professor</option>
                      <option value="Coordenador">Coordenador</option>
                      <option value="Supervisor Técnico">Supervisor Técnico</option>
                      <option value="Supervisor Geral">Supervisor Geral</option>
                      <option value="Gestor Técnico">Gestor Técnico</option>
                      <option value="Gestor Geral">Gestor Geral</option>
                      <option value="Key Account">Key Account</option>
                    </select>
                  </div>
                  <div>
                    Vagas
                    <div>
                      <input
                        type="number"
                        name="vagas"
                        id="vagas"
                        value={professor.vagas}
                        onChange={(e: any) => changeVagas(e.target.value)}
                      />
                    </div>
                  </div>
                </div>
                <div>
                  <div>Situação: {bloqueado ? 'bloqueado' : 'liberado'}</div>
                  <div>
                    <label>
                      <input type="checkbox" onChange={(e) => setBloqueado(e.target.checked)} checked={bloqueado} />
                      <span></span>
                    </label>
                  </div>
                </div>
              </S.ToogleContainer>
              <Button onClick={() => changeAcesso()} loading={loading}>
                Confirmar
              </Button>
            </div>
          </S.ModalContent>
        </Modal>
      </S.Container>
    </>
  );
};

export default ProfessoresList;
