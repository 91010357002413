var colors = ['#008FFB', '#00E396', '#FEB019', '#FF4560', '#775DD0', '#546E7A', '#26a69a', '#D10CE8', '#f95108', '#0c59e8'];
// const settingsDefault = {
//   options: {
//     plotOptions: {
//       pie: {
//         donut: {
//           labels: {
//             show: true,
//           },
//         },
//       },
//     },
//     labels: [],
//   },
//   series: [],
// };

export const Donut = (data: any) => {
  const Label = data.map((x: any) => x.nome);
  const Qtd = data.map((x: any) => x.total);
  const settings = {
    options: {
      plotOptions: {
        pie: {
          donut: {
            labels: {
              show: true,
            },
          },
        },
      },
      labels: [...Label],
    },
    series: [...Qtd],
  };

  return settings;
};

export const BarOne = (data: any, horizontal = false, title = '') => {
  const Label = data.map((x: any) => x.nome);
  const Qtd = data.map((x: any) => x.total);

  const settings = {
    options: {
      chart: {
        id: 'basic-bar',
      },
      title: {
        text: title,
      },
      xaxis: {
        categories: [...Label],
        labels: {
          show: false,
          style: {
            colors: colors,
            fontSize: '12px',
          },
        },
      },
      dataLabels: {
        enabled: true,
        formatter: function (val: any) {
          return val + '';
        },
        // offsetY: -20,
        style: {
          fontSize: '12px',
          // colors: ['#304758'],
        },
      },
      colors: colors,
      plotOptions: {
        bar: {
          // columnWidth: '45%',
          distributed: true,
          horizontal: horizontal,
        },
      },
    },
    series: [
      {
        name: 'series-1',
        data: [...Qtd],
      },
    ],
  };

  return settings;
};

export const BarTwo = (data: any) => {
  const sum = data.reduce((accumulator: any, object: { valueF: any; valueM: any }) => {
    return accumulator + object.valueF + object.valueM;
  }, 0);

  const F = data.map((x: any) => -((x.valueF / sum) * 100).toFixed(0));
  const M = data.map((x: any) => ((x.valueM / sum) * 100).toFixed(0));
  const Categorias = data.map((x: any) => x.label);

  const settings = {
    series: [
      {
        name: 'Masculino',
        data: [...M],
      },
      {
        name: 'Feminino',
        data: [...F],
      },
    ],
    options: {
      chart: {
        type: 'bar',
        height: 440,
        stacked: true,
      },
      colors: ['#008FFB', '#FF4560'],
      plotOptions: {
        bar: {
          borderRadius: 5,
          borderRadiusApplication: 'end', // 'around', 'end'
          borderRadiusWhenStacked: 'all', // 'all', 'last'
          horizontal: true,
          barHeight: '80%',
        },
      },
      dataLabels: {
        enabled: false,
      },
      stroke: {
        width: 1,
        colors: ['#fff'],
      },

      grid: {
        xaxis: {
          lines: {
            show: false,
          },
        },
      },
      yaxis: {
        min: -60,
        max: 60,
        title: {
          // text: 'Age',
        },
      },
      tooltip: {
        shared: false,
        x: {
          formatter: function (val: any) {
            return val;
          },
        },
        y: {
          formatter: function (val: any) {
            return Math.abs(val) + '%';
          },
        },
      },
      // title: {
      //   text: 'Mauritius population pyramid 2011',
      // },
      xaxis: {
        categories: [...Categorias],
        // title: {
        //   text: 'Percent',
        // },

        labels: {
          show: false,
          formatter: function (val: any) {
            return Math.abs(Math.round(val)) + '%';
          },
        },
      },
    },
  };

  return settings;
};

export const BarThree = (data: any, Title = '') => {
  const sum = data.reduce((accumulator: any, object: { total: any }) => {
    return accumulator + object.total;
  }, 0);

  const _data = data.map((x: any) => {
    return { name: x.nome, data: [((x.total / sum) * 100).toFixed(1)] };
  });

  // const Categorias = data.map((x: any) => x.nome);

  const settings = {
    series: [..._data],
    options: {
      chart: {
        type: 'bar',
        height: 350,
        stacked: true,
        stackType: '100%',
      },
      plotOptions: {
        bar: {
          horizontal: true,
          barHeight: '30%',
        },
      },
      stroke: {
        width: 1,
        colors: ['#fff'],
      },
      title: {
        text: Title,
      },
      xaxis: {
        categories: [Title],
        labels: {
          show: false,
        },
      },
      yaxis: {
        show: false,
      },
      tooltip: {
        y: {
          formatter: function (val: any) {
            return val + '%';
          },
        },
      },
      fill: {
        opacity: 1,
      },
      legend: {
        position: 'top',
        horizontalAlign: 'left',
        offsetX: 40,
      },
    },
  };
  return settings;
};

export const BarFour = (data: any, title = '') => {
  const _data = data.map((x: any) => {
    return { name: x.nome, data: [x.total] };
  });

  const settings = {
    series: [..._data],
    options: {
      chart: {
        type: 'bar',
        height: 350,
        width: '100%',
        stacked: true,
        // stackType: '100%',
      },
      plotOptions: {
        bar: {
          horizontal: true,
          barHeight: '30%',
          // columnWidth: '100%',
        },
      },
      stroke: {
        width: 1,
        colors: ['#fff'],
      },
      title: {
        text: title,
      },
      xaxis: {
        categories: [title],
        labels: {
          show: false,
        },
      },
      yaxis: {
        show: false,
      },
      tooltip: {
        y: {
          formatter: function (val: any) {
            return val;
          },
        },
      },
      fill: {
        opacity: 1,
      },
      legend: {
        position: 'top',
        horizontalAlign: 'left',
        offsetX: 40,
      },
    },
  };
  return settings;
};
