import { RadioOption } from '../../model/Inputs';

export const options: RadioOption[] = [
  { id: '1', value: 1, label: 'Sim' },
  { id: '2', value: 0, label: 'Não' },
];

export const options1: RadioOption[] = [
  { id: '1', value: '1 a 2x', label: '1 a 2x' },
  { id: '2', value: '3 a 4x', label: '3 a 4x' },
  { id: '3', value: '5 a 10x', label: '5 a 10x' },
  { id: '4', value: '+ que 10', label: '+ que 10' },
];

export const options2: RadioOption[] = [
  { id: '1', value: 'Ansiedade', label: 'Ansiedade' },
  { id: '2', value: 'Depressão', label: 'Depressão' },
  { id: '3', value: 'TOC', label: 'TOC' },
  { id: '4', value: 'Fobias', label: 'Fobias' },
  { id: '5', value: 'Síndrome do Pânico', label: 'Síndrome do Pânico' },
  { id: '6', value: 'Vácios', label: 'Vácios' },
  { id: '7', value: 'Outros Transtornos', label: 'Outros Transtornos' },
];

export const options3: RadioOption[] = [
  { id: '1', value: 'Menos de 6 meses', label: 'Menos de 6 meses' },
  { id: '2', value: 'De 6 meses a 1 ano', label: 'De 6 meses a 1 ano' },
  { id: '3', value: 'Mais de 1 ano', label: 'Mais de 1 ano' },
];

export const options4: RadioOption[] = [
  { id: '1', value: 'Médico', label: 'Médico' },
  { id: '2', value: 'Automedicação', label: 'Automedicação' },
  { id: '3', value: 'Ambos', label: 'Ambos' },
  { id: '4', value: 'Não Aplicável', label: 'Não Aplicável' },
];

export const options5: RadioOption[] = [
  { id: '1', value: 'Amil', label: 'Amil' },
  { id: '2', value: 'Assim Saúde', label: 'Assim Saúde' },
  { id: '3', value: 'Bradesco Saúde', label: 'Bradesco Saúde' },
  { id: '4', value: 'Golden Cross', label: 'Golden Cross' },
  { id: '5', value: 'Sulamérica', label: 'Sulamérica' },
  { id: '6', value: 'Unimed', label: 'Unimed' },
  { id: '7', value: 'Outro', label: 'Outro' },
  { id: '8', value: 'Não Aplicável', label: 'Não Aplicável' },
];

export const options6: RadioOption[] = [
  { id: '1', value: 'Muito Bom', label: 'Muito Bom' },
  { id: '2', value: 'Bom', label: 'Bom' },
  { id: '3', value: 'Regular', label: 'Regular' },
  { id: '4', value: 'Ruim', label: 'Ruim' },
  { id: '5', value: 'Muito Ruim', label: 'Muito Ruim' },
];

export const options7: RadioOption[] = [
  { id: '1', value: 'Não', label: 'Não' },
  { id: '2', value: 'Hipertensão Arterial', label: 'Hipertensão Arterial' },
  { id: '3', value: 'Diabétes', label: 'Diabétes' },
  { id: '4', value: 'Infarto', label: 'Infarto' },
  { id: '5', value: 'Acidente Vascular Cerebral (Derrame)', label: 'Acidente Vascular Cerebral (Derrame)' },
  { id: '6', value: 'Câncer', label: 'Câncer' },
  { id: '7', value: 'Depressão', label: 'Depressão' },
  { id: '8', value: 'Mais de uma', label: 'Mais de uma' },
];
