import React, { useEffect, useState } from 'react';
import { FiPlusCircle, FiTrash } from 'react-icons/fi';
import { Link, useParams } from 'react-router-dom';
import Button from '../../../components/Button';

import Modal from '../../../components/Modal';

import Spinner from '../../../components/Spinner';
import { useToast } from '../../../hooks/toast';
import api from '../../../services/api';
import { ModalContent, Title } from './styles';

import { Container, Content, RowContainer, Row, Schedule, Page, Pagination } from './styles';

const EmpresaList: React.FC = () => {
  const { page } = useParams();
  const { addToast } = useToast();
  const [loading, setLoading] = useState(false);
  const [list, setList] = useState<any>([]);
  const [pages, setPages] = useState(1);
  const [currentPage, setCurrentPage] = useState(1);
  const [modal, setModal] = useState(false);
  const [empresa, setEmpresa] = useState({} as { id: number; nome: string });
  const [refresh, setRefresh] = useState(0);

  //const [text, setText] = useState<string>('');

  useEffect(() => {
    setLoading(true);
    let _page = Number(page);
    if (isNaN(_page)) _page = 1;
    api
      .get(`empresas?PageNumber=${_page}`)
      .then((response) => {
        setList(response.data.list);
        setCurrentPage(response.data.currentPage);
        setPages(response.data.pages);
        setLoading(false);
      })
      .catch((error) => {
        setLoading(false);
        addToast({
          type: 'error',
          title: error.message,
        });
      });
  }, [addToast, page, refresh]);

  const handleGotoPage = (_page: number) => {
    if (_page > pages) return;
    setLoading(true);
    api
      .get(`empresas?PageNumber=${_page}`)
      .then((resp) => {
        setList(resp.data.list);
        setCurrentPage(resp.data.currentPage);
        setPages(resp.data.pages);
        setLoading(false);
      })
      .catch((error) => {
        setLoading(false);
      });
  };

  const showModal = (item: any) => {
    setEmpresa(item);
    setModal(true);
  };

  const handleDelete = () => {
    try {
      setLoading(true);
      api
        .delete(`empresas/${empresa.id}`)
        .then(() => {
          setLoading(false);
          setModal(false);
          setRefresh(refresh + 1);
          addToast({
            type: 'success',
            title: 'Excluido com sucesso',
          });
        })
        .catch(() => {
          setLoading(false);
          addToast({
            type: 'error',
            title: 'Ocorreu um error ao excluir',
          });
        });
    } catch (error) {
      setLoading(false);
      addToast({
        type: 'error',
        title: 'Ocorreu um error ao excluir',
      });
    }
  };

  return (
    <>
      <Container>
        {/* <Header /> */}
        <Content>
          <Schedule>
            <Title>
              <h1>Empresas</h1>
              <Link to={'/empresa/novo'}>
                <FiPlusCircle size={20} />
                <span>Novo</span>
              </Link>
            </Title>
            {!loading ? (
              <>
                <RowContainer>
                  {list.map((a: any, i: number) => (
                    <Row key={a.id}>
                      <div>
                        <div></div>
                        <strong>
                          <Link to={`/empresa/${a.id}`}>{a.nome}</Link>
                        </strong>
                      </div>
                      <div onClick={() => showModal(a)}>
                        <FiTrash size={25} color={'#ef1111'} />
                      </div>
                    </Row>
                  ))}
                </RowContainer>
                <Pagination>
                  <Page onClick={() => handleGotoPage(currentPage - 1)}>Anterior</Page>
                  {Array.from({ length: pages }, (_, i) => (
                    <Page key={i} selected={i + 1 === currentPage} onClick={() => handleGotoPage(i + 1)}>
                      <div>{i + 1}</div>
                    </Page>
                  ))}
                  <Page onClick={() => handleGotoPage(currentPage + 1)}>Próximo</Page>
                </Pagination>
              </>
            ) : (
              <>
                <br />
                <Spinner />
              </>
            )}
          </Schedule>
        </Content>
        <Modal visible={modal} close={() => setModal(false)}>
          <ModalContent>
            <p style={{ marginBottom: 20 }}>EXCLUIR</p>
            <div>
              <p>
                {`Deseja realmente excluir a empresa`} <strong>{empresa.nome}</strong>
              </p>
              <p>Essa operação não pode ser desfeita</p>
              <Button onClick={handleDelete} loading={loading}>
                Confirmar
              </Button>
            </div>
          </ModalContent>
        </Modal>
      </Container>
    </>
  );
};

export default EmpresaList;
