import { shade } from 'polished';
import styled from 'styled-components';

export const Container = styled.div`
  max-width: 1120px;
  margin: 64px auto;
  display: flex;
`;

export const Content = styled.main`
  flex: 1;
`;

export const Title = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 20px;

  h1 {
    color: #525f7f;
  }

  a {
    display: flex;
    flex-direction: row;
    justify-content: center;
    max-width: 15%;
    max-height: 35px;
    display: flex;
    text-align: center;
    background: #1171ef;
    height: 56px;
    border-radius: 10px;
    border: 0;
    padding: 0 16px;
    color: #ffffff;
    text-decoration: none;
    width: 100%;
    font-weight: 500;
    margin-left: 10px;
    transition: background-color 0.2s;

    &:hover {
      background: ${shade(0.2, '#1171ef')};
    }

    > * {
      margin: auto 0;
    }
  }
`;

export const Section = styled.section`
  margin-top: 48px;
  > strong {
    color: #999591;
    font-size: 20px;
    line-height: 26px;
    border-bottom: 1px solid #3e3b47;
    display: block;
    padding-bottom: 16px;
    margin-bottom: 16px;
  }
`;

export const DivGroup = styled.div`
  display: grid;
  grid-auto-flow: column;
  grid-auto-columns: 1fr;
  gap: 50px;

  & + div {
    margin-top: 8px;
  }
`;

export const Desempenho = styled.div`
  display: grid;
  grid-auto-flow: column;
  grid-auto-columns: 1fr;
  gap: 8px;

  & + div {
    margin-top: 8px;
  }

  > div {
    width: 400px;
    > div {
      &:nth-child(2) {
        height: 78px;
      }
    }
  }
`;

export const DesempenhoInput = styled.div`
  display: grid;
  grid-auto-flow: column;
  grid-auto-columns: 2fr 1fr 1fr;
  gap: 4px;

  & + div {
    margin-top: 8px;
  }
  > div {
    /* padding: 5px; */
    display: flex;
    flex-direction: column;
    justify-content: center;

    &:nth-child(2) {
      input {
        text-align: center;
      }
    }
  }
`;

export const DesempenhoValor = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 8px;

  > div {
    margin-top: 0;
    display: block;
  }
`;

export const Referencia = styled.div`
  margin-bottom: 15px;

  > table {
    width: 100%;
    border: 1px solid;
    border-collapse: collapse;

    tr {
      text-align: center;
      border: 1px solid;
      border-collapse: collapse;
      height: 30px;

      > td,
      th {
        border: 1px solid;
        border-collapse: collapse;
      }
    }
  }
`;
