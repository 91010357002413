import styled from 'styled-components';

export const Container = styled.div`
  /* background-color: #202024; */
`;

export const Content = styled.main`
  max-width: 1320px;
  margin: 64px auto;
  display: flex;
`;

export const Schedule = styled.div`
  flex: 1;
  width: 100%;
  h1 {
    font-size: 36px;
  }

  p {
    margin-top: 8px;
    color: #ff9000;
    display: flex;
    align-items: center;
    font-weight: 500;

    span {
      display: flex;
      align-items: center;
    }
    span + span::before {
      content: '';
      width: 1px;
      height: 12px;
      background: #ff9000;
      margin: 0 8px;
    }
  }
  i {
    i {
      background-color: rgb(0 90 255);
    }
  }
`;

export const CadastroContainer = styled.div`
  display: flex;
  justify-content: flex-end;
`;

export const GrupoContainer = styled.div`
  display: flex;
  justify-content: center;
`;

export const PerfilContainer = styled.div`
  display: flex;
  justify-content: space-evenly;
`;

export const BasicContainer = styled.div`
  display: flex;
  justify-content: space-evenly;
`;

export const SaudeContainer = styled.div`
  display: flex;
  justify-content: space-evenly;
  > div {
    flex-direction: column;
    > div {
      display: flex;
      flex-direction: row;
    }
  }
`;

export const SaudeContainer2 = styled.div`
  display: flex;
  justify-content: space-evenly;
  > div {
    flex-direction: column;
    &:nth-child(2) {
      flex-grow: 1;
    }
    &:nth-child(2) {
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      flex-grow: 3;
    }
    > div {
      display: flex;
      flex-direction: row;
    }
  }
`;

export const EstiloVidaContainer = styled.div`
  display: flex;
  /* justify-content: flex-end; */
`;

export const BoxCount = styled.div`
  width: 150px;
  height: 100px;
  /* border: 1px solid; */
  margin: 20px auto;
  background: #ffce8d;
  flex-direction: column;

  > div {
    font-size: 35px;
    font-weight: 500;
    color: black;
    height: 100%;
    text-align: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
`;
