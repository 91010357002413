import { shade } from 'polished';
import styled from 'styled-components';
// import { shade } from 'polished';
import video from '../../../assets/icons/video-player-movie-svgrepo-com.svg';

export const Container = styled.div``;

export const Header = styled.header`
  padding: 32px 0;
  background: #28262e;
`;

export const HeaderContent = styled.div`
  max-width: 1120px;
  margin: 0 auto;
  display: flex;
  align-items: center;

  > img {
    height: 50px;
  }

  button {
    margin-left: auto;
    background: transparent;
    border: 0;

    svg {
      color: #999591;
      width: 20px;
      height: 20px;
    }
  }
`;

export const Title = styled.div`
  display: flex;
  justify-content: space-between;

  h1 {
    color: #525f7f;
  }

  a {
    display: flex;
    flex-direction: row;
    justify-content: center;
    max-width: 15%;
    max-height: 35px;
    display: flex;
    text-align: center;
    background: #1171ef;
    height: 56px;
    border-radius: 10px;
    border: 0;
    padding: 0 16px;
    color: #ffffff;
    text-decoration: none;
    width: 100%;
    font-weight: 500;
    margin-left: 10px;
    transition: background-color 0.2s;

    &:hover {
      background: ${shade(0.2, '#1171ef')};
    }

    > * {
      margin: auto 0;
    }
  }
`;

export const Profile = styled.div`
  display: flex;
  align-items: center;
  margin-left: 80px;

  > svg {
  }

  div {
    display: flex;
    flex-direction: column;
    margin-left: 16px;
    line-height: 24px;

    span {
      color: #f4ede8;
    }

    strong {
      color: #ff9000;
    }
  }
`;

export const Content = styled.main`
  max-width: 1120px;
  margin: 64px auto;
  display: flex;
`;
export const Schedule = styled.div`
  flex: 1;
  /* margin-right: 120px; */

  h1 {
    font-size: 36px;
  }

  p {
    margin-top: 8px;
    color: #ff9000;
    display: flex;
    align-items: center;
    font-weight: 500;

    span {
      display: flex;
      align-items: center;
    }
    span + span::before {
      content: '';
      width: 1px;
      height: 12px;
      background: #ff9000;
      margin: 0 8px;
    }
  }

  Form {
    margin-top: 28px;
  }
`;

export const Search = styled.div`
  display: flex;
  margin-bottom: 8px;

  > div {
    max-width: 100%;
  }

  > a {
    max-width: 20%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    text-align: center;
    background: #1171ef;
    height: 56px;
    border-radius: 10px;
    border: 0;
    padding: 0 16px;
    color: #ffffff;
    text-decoration: none;
    width: 100%;
    font-weight: 500;
    margin-left: 10px;
    transition: background-color 0.2s;

    &:hover {
      background: ${shade(0.2, '#1171ef')};
    }
  }
`;

export const RowContainer = styled.ul`
  margin-top: 20px;
  list-style: none;
  width: 100%;
`;

interface AlunoProps {
  sexo?: number;
}

export const Row = styled.li<AlunoProps>`
  position: relative;
  display: flex;
  -webkit-box-align: center;
  align-items: center;
  background-color: #fff;
  border-radius: 5px;
  padding-right: 8px;
  margin-bottom: 10px;
  cursor: pointer;
  box-shadow: 0 1px 3px rgb(50 50 93 / 15%), 0 1px 0 rgb(0 0 0 / 2%);
  border: 0;
  transition: box-shadow 0.15s ease;

  > div {
    display: flex;
    flex-grow: 1;
    flex-direction: row;
    -webkit-box-align: center;
    align-items: center;
    place-self: stretch flex-start;
    /* width: 100%; */

    > div {
      background-color: rgb(18, 18, 20);
      margin-right: 16px;
      min-width: 50px;
      height: 50px;
      border-radius: 30px; //6px;
      background-image: url(${video});
      background-repeat: no-repeat;
      background-size: cover;
      background-color: white;
    }

    a {
      text-decoration: none;
      color: black;
    }

    &:nth-child(2) {
      cursor: pointer;
      justify-content: end;
    }
  }
`;

export const Pagination = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
`;

interface PageProps {
  selected?: boolean;
}

export const Page = styled.div<PageProps>`
  display: flex;

  align-items: center;
  background-color: ${(props) => (props.selected ? '#0357c6' : '#1171ef')}; // rgb(41, 41, 46);
  min-width: 40px;
  height: 40px;
  border-radius: 6px;
  padding-left: 10px;
  padding-right: 10px;
  cursor: pointer;
  color: #fff;

  &:hover {
    background-color: #0357c6;
  }

  > div {
    margin: auto;
  }

  & + div {
    margin-left: 10px;
  }
`;

export const ModalContent = styled.div`
  strong {
    font-size: 20px;
    font-weight: 600;
    color: black;
  }
`;
