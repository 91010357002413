import styled from 'styled-components';
import { device } from '../../styles/global';

export const Content = styled.main`
  max-width: 1120px;
  margin: 64px 10px;
  display: flex;

  @media ${device.tablet} {
    margin: 64px auto;
  }

  > div {
    text-align: 'center';
    width: 100%;
    > p {
      font-size: 32px;
      margin-bottom: 20px;
    }
  }
`;
