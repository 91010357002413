import { FormHandles } from '@unform/core';
import { Form } from '@unform/web';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { Link, useNavigate, useParams } from 'react-router-dom';
import * as Yup from 'yup';

import Input from '../../components/Input';
import Select from '../../components/Select';
import { useToast } from '../../hooks/toast';
import api from '../../services/api';

import { Container, Content, Title, Section, InputGroup, Desempenho, DesempenhoInput, DesempenhoValor } from './styles';
import Button from '../../components/Button';
import { FiChevronLeft } from 'react-icons/fi';
import { classificacaoAbdominal, classificacaoFlexao } from './classificacao';
import { Aluno } from '../../model/Aluno';

const Options = [
  {
    value: 'MB',
    name: 'Encostou a palma da mão no chão',
  },
  {
    value: 'B',
    name: 'Encostou a ponta dos dedos no chão',
  },
  {
    value: 'R',
    name: 'Não encostou no chão',
  },
];

const Neuromuscular: React.FC = () => {
  const { alunoid, id } = useParams();
  const navigate = useNavigate();
  const { addToast } = useToast();
  const formRef = useRef<FormHandles>(null);
  const [loading, setLoading] = useState(false);
  const [aluno, setAluno] = useState<Aluno>({} as Aluno);

  useEffect(() => {
    api
      .get('alunos/' + alunoid)
      .then((response) => {
        setAluno(response.data);
      })
      .catch((e) => {
        addToast({
          type: 'error',
          title: 'error',
        });
      });
  }, [alunoid, addToast]);

  useEffect(() => {
    if (Number(id) !== 0) {
      api.get(`neuromuscular/${id}`).then((response) => {
        formRef.current?.setData(response.data);
        flexao();
        abdominal();
        flexibilidade();
        aganchamento();
      });
    }
    // eslint-disable-next-line
  }, [id, aluno]);

  const flexao = () => {
    const valor = Number(formRef.current?.getFieldValue('flexaobraco'));
    const classificacao = classificacaoFlexao(aluno.sexo, aluno.idade, valor);

    formRef.current?.setFieldValue('flexaobracoclassif', classificacao);
  };

  const abdominal = () => {
    const valor = Number(formRef.current?.getFieldValue('abdominal'));
    const classificacao = classificacaoAbdominal(aluno.sexo, aluno.idade, valor);

    formRef.current?.setFieldValue('abdominalclassif', classificacao);
  };

  const flexibilidade = () => {
    const valor = formRef.current?.getFieldValue('flexibilidade');

    if (valor === 'MB') {
      formRef.current?.setFieldValue('flexibilidadeclassif', 'Muito Bom');
    } else if (valor === 'B') {
      formRef.current?.setFieldValue('flexibilidadeclassif', 'Bom');
    } else if (valor === 'R') {
      formRef.current?.setFieldValue('flexibilidadeclassif', 'Ruim');
    } else {
      formRef.current?.setFieldValue('flexibilidadeclassif', '');
    }
  };

  const aganchamento = () => {
    const valor: any = formRef.current?.getData();
    const quantidade = valor.agachamento.quantidade;

    const arrOfNum: number[] = [];

    quantidade.forEach((str: any) => {
      if (str !== '' && !isNaN(Number(str))) arrOfNum.push(Number(str));
    });

    const min = Math.min(...arrOfNum);
    const max = Math.max(...arrOfNum);
    const fadiga = (min - max) / min;

    formRef.current?.setFieldValue('agachamento.min', min);
    formRef.current?.setFieldValue('agachamento.max', max);
    formRef.current?.setFieldValue('agachamento.fadiga', fadiga.toFixed(2));
  };

  const Polichinelo = () => {
    const valor: any = formRef.current?.getData();
    const quantidade = valor.polichinelo.quantidade;

    const arrOfNum: number[] = [];

    quantidade.forEach((str: any) => {
      if (str !== '' && !isNaN(Number(str))) arrOfNum.push(Number(str));
    });

    const min = Math.min(...arrOfNum);
    const max = Math.max(...arrOfNum);
    const fadiga = (min - max) / min;

    formRef.current?.setFieldValue('polichinelo.min', min);
    formRef.current?.setFieldValue('polichinelo.max', max);
    formRef.current?.setFieldValue('polichinelo.fadiga', fadiga.toFixed(2));
  };

  const handleSubmit = useCallback(
    async (data: any) => {
      try {
        formRef.current?.setErrors({});
        const schema = Yup.object().shape({
          agachamento: Yup.object({
            fadiga: Yup.string(),
            max: Yup.number()
              .transform((value) => (!value ? null : value))
              .nullable(),
            min: Yup.number()
              .transform((curr, orig) => (orig === '' ? null : curr))
              .nullable(),
            // quantidade: Yup.mixed().when('isArray', {
            //   is: Array.isArray,
            //   then: Yup.array().of(Yup.string()),
            //   otherwise: Yup.string(),
            // }),
          }),
        });

        await schema.validate(data, {
          abortEarly: false,
        });

        setLoading(true);
        data.flexaobraco = data.flexaobraco === '' ? null : data.flexaobraco;
        data.abdominal = data.abdominal === '' ? null : data.abdominal;
        data.agachamento.quantidade = data.agachamento.quantidade.filter((q: string) => q !== '');
        data.polichinelo.quantidade = data.polichinelo.quantidade.filter((q: string) => q !== '');
        data.agachamento.max = data.agachamento.max === '' ? null : data.agachamento.max;
        data.agachamento.min = data.agachamento.min === '' ? null : data.agachamento.min;
        data.agachamento.fadiga = data.agachamento.fadiga === '' ? null : data.agachamento.fadiga;
        data.polichinelo.max = data.polichinelo.max === '' ? null : data.polichinelo.max;
        data.polichinelo.min = data.polichinelo.min === '' ? null : data.polichinelo.min;
        data.polichinelo.fadiga = data.polichinelo.fadiga === '' ? null : data.polichinelo.fadiga;

        if (Number(id) === 0) {
          await api.post('neuromuscular', data);
        } else {
          await api.put('neuromuscular/' + id, data);
        }
        setLoading(false);

        addToast({
          type: 'success',
          title: 'Gravado com sucesso!',
        });

        navigate(`/cliente/${alunoid}/questionarios`);
      } catch (error) {
        console.log(error);
        setLoading(false);
        addToast({
          type: 'error',
          title: 'Verifique o formulário',
        });
      }
    },
    [alunoid, id, addToast, navigate]
  );

  return (
    <>
      <Container>
        <Content>
          <Title>
            <h1>Neuromuscular</h1>
            <Link to={`/cliente/${alunoid}/questionarios`}>
              <FiChevronLeft size={20} />
              <span>Voltar</span>
            </Link>
          </Title>

          <Form
            ref={formRef}
            onSubmit={handleSubmit}
            initialData={{ aluno: alunoid }}
            placeholder={undefined}
            onPointerEnterCapture={undefined}
            onPointerLeaveCapture={undefined}
          >
            <strong>TESTES DE FORÇA/RESISTÊNCIA</strong>
            <Section>
              <strong>Teste de flexão de braço</strong>
              <span>
                Realizar a flexão de cotovelo durante 1 minuto – Homens com pontas dos pés apoiadas e mulheres com joelhos apoiados no solo.
              </span>
              <InputGroup>
                <Input name="flexaobraco" onChange={flexao} />
                <Input name="flexaobracoclassif" readOnly />
              </InputGroup>
            </Section>
            <Section>
              <strong>Teste de abdominal</strong>
              <span>
                <p>Deitado ao solo, pés apoiados, dedos tocando ao lado da cabeça</p>
                <p>flexionar o tronco até encostar os cotovelo no joelho e retornar a posição original durante 1'.</p>
              </span>
              <InputGroup>
                <Input name="abdominal" onChange={abdominal} />
                <Input name="abdominalclassif" readOnly />
              </InputGroup>
            </Section>
            <Section>
              <strong>Testes de flexibilidade membros inferiores</strong>
              <span>Posição em pé. Pés juntos, flexionar o tronco a frente com braços estendido tentando tocar o solo.</span>
              <br />
              <span>Medir com fita métrica a distância da ponta dos dedos ao solo.</span>
              <InputGroup>
                <Select name="flexibilidade" options={Options} onChange={flexibilidade} />
                <Input name="flexibilidadeclassif" readOnly />
              </InputGroup>
            </Section>
            <Section>
              <strong>Testes de desempenho</strong>
              <Desempenho>
                <div>
                  <div>Teste de agachamento</div>
                  <div>
                    <span>
                      De pé, pés afastados na largura do quadril – flexionar o joelho até 90o. Com as mão extendidas a frente e retornar a
                      posição original durante 1 minuto.
                    </span>
                  </div>
                  <div>
                    <DesempenhoInput>
                      <div>Quantidade aos 10":</div>
                      <Input name="agachamento.quantidade[0]" onChange={aganchamento} />
                    </DesempenhoInput>
                    <DesempenhoInput>
                      <div>Quantidade nos 11 ao 20":</div>
                      <Input name="agachamento.quantidade[1]" onChange={aganchamento} />
                    </DesempenhoInput>
                    <DesempenhoInput>
                      <div>Quantidade nos 21 ao 30":</div>
                      <Input name="agachamento.quantidade[2]" onChange={aganchamento} />
                    </DesempenhoInput>
                    <DesempenhoInput>
                      <div>Quantidade nos 31 ao 40":</div>
                      <Input name="agachamento.quantidade[3]" onChange={aganchamento} />
                    </DesempenhoInput>
                    <DesempenhoInput>
                      <div>Quantidade nos 41 ao 50":</div>
                      <Input name="agachamento.quantidade[4]" onChange={aganchamento} />
                    </DesempenhoInput>
                    <DesempenhoInput>
                      <div>Quantidade nos 51 ao 60":</div>
                      <Input name="agachamento.quantidade[5]" onChange={aganchamento} />
                    </DesempenhoInput>
                    <div>Cálculo</div>
                    <DesempenhoValor>
                      <DesempenhoInput>
                        <div>{'< Valor :'}</div>
                        <Input name="agachamento.min" readOnly />
                      </DesempenhoInput>
                      <DesempenhoInput>
                        <div>{'> Valor :'}</div>
                        <Input name="agachamento.max" readOnly />
                      </DesempenhoInput>
                    </DesempenhoValor>
                    <div>
                      <DesempenhoInput>
                        <div>Índice de fadiga:</div>
                        <Input name="agachamento.fadiga" readOnly />
                      </DesempenhoInput>
                    </div>
                  </div>
                </div>
                <div>
                  <div>Teste de polichinelo</div>
                  <div>
                    <span>Realizar o exercício polichilo durante 1 minuto.</span>
                  </div>
                  <div>
                    <DesempenhoInput>
                      <div>Quantidade aos 10":</div>
                      <Input name="polichinelo.quantidade[0]" onChange={Polichinelo} />
                    </DesempenhoInput>
                    <DesempenhoInput>
                      <div>Quantidade nos 11 ao 20":</div>
                      <Input name="polichinelo.quantidade[1]" onChange={Polichinelo} />
                    </DesempenhoInput>
                    <DesempenhoInput>
                      <div>Quantidade nos 21 ao 30":</div>
                      <Input name="polichinelo.quantidade[2]" onChange={Polichinelo} />
                    </DesempenhoInput>
                    <DesempenhoInput>
                      <div>Quantidade nos 31 ao 40":</div>
                      <Input name="polichinelo.quantidade[3]" onChange={Polichinelo} />
                    </DesempenhoInput>
                    <DesempenhoInput>
                      <div>Quantidade nos 41 ao 50":</div>
                      <Input name="polichinelo.quantidade[4]" onChange={Polichinelo} />
                    </DesempenhoInput>
                    <DesempenhoInput>
                      <div>Quantidade nos 51 ao 60":</div>
                      <Input name="polichinelo.quantidade[5]" onChange={Polichinelo} />
                    </DesempenhoInput>
                    <div>Cálculo</div>
                    <DesempenhoValor>
                      <DesempenhoInput>
                        <div>{'< Valor :'}</div>
                        <Input name="polichinelo.min" readOnly />
                      </DesempenhoInput>
                      <DesempenhoInput>
                        <div>{'> Valor :'}</div>
                        <Input name="polichinelo.max" readOnly />
                      </DesempenhoInput>
                    </DesempenhoValor>
                    <div>
                      <DesempenhoInput>
                        <div>Índice de fadiga:</div>
                        <Input name="polichinelo.fadiga" readOnly />
                      </DesempenhoInput>
                    </div>
                  </div>
                </div>
              </Desempenho>
            </Section>
            <Button loading={loading} disabled={loading} type="submit">
              Gravar
            </Button>
            <div style={{ visibility: 'hidden' }}>
              <Input name="aluno" type="number" />
            </div>
          </Form>
        </Content>
      </Container>
    </>
  );
};

export default Neuromuscular;
