import { shade } from 'polished';
import styled from 'styled-components';
import { device } from '../../styles/global';

export const Container = styled.div`
  max-width: 1120px;
  margin: 64px auto;
  display: flex;
`;

export const Content = styled.main`
  flex: 1;
  width: 100%;

  @media ${device.mobileL_Max} {
    padding: 5px;
  }
`;

export const Title = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 20px;

  h1 {
    color: #525f7f;
  }

  a {
    display: flex;
    flex-direction: row;
    justify-content: center;
    max-width: 15%;
    max-height: 35px;
    display: flex;
    text-align: center;
    background: #1171ef;
    height: 56px;
    border-radius: 10px;
    border: 0;
    padding: 0 16px;
    color: #ffffff;
    text-decoration: none;
    width: 100%;
    font-weight: 500;
    margin-left: 10px;
    transition: background-color 0.2s;

    &:hover {
      background: ${shade(0.2, '#1171ef')};
    }

    > * {
      margin: auto 0;
    }
  }
`;

export const Section = styled.section`
  margin-top: 48px;
  /* width: 350px; */

  > strong {
    color: #999591;
    font-size: 20px;
    line-height: 26px;
    border-bottom: 1px solid #3e3b47;
    display: block;
    padding-bottom: 16px;
    margin-bottom: 16px;
  }
`;

export const Section2 = styled.section`
  margin-top: 48px;
  width: 600px;

  > strong {
    color: #999591;
    font-size: 20px;
    line-height: 26px;
    border-bottom: 1px solid #3e3b47;
    display: block;
    padding-bottom: 16px;
    margin-bottom: 16px;
  }
`;

export const InputGroup = styled.div`
  display: flex;

  > div {
    &:nth-child(1) {
      display: flex;
      flex-direction: column;
      justify-content: center;
      margin-right: 15px;
      min-width: 85px;
    }
  }

  & + div {
    margin-top: 8px;
  }
`;

export const Flex = styled.div`
  display: flex;
  > div {
    > div {
      &:nth-child(1) {
        text-align: center;
      }
    }
  }
`;

export const Indicadores = styled.div`
  display: block;

  > table {
    width: 70%;
    border-collapse: collapse;
    background: #fff;
    color: #525f7f;

    td,
    th {
      border: 1px solid #dddddd;
      text-align: left;
      padding: 8px;
    }
  }
`;

export const Esforco = styled.div`
  display: flex;

  > div {
    &:nth-child(1) {
      background: #fff;
      width: 600px;
      div {
        border: 1px solid #dddddd;
        padding: 5px;
      }
    }

    & + div {
      margin-left: 12px;
    }
  }
`;

export const Intensidade = styled.div`
  margin-top: 10px;
  > div {
    flex-direction: column;
  }
`;

export const Th = styled.th`
  border-top: 2px solid red !important;
  border-left: 2px solid red !important;
  border-right: 2px solid red !important;
`;

export const Td = styled.td`
  border-left: 2px solid red !important;
  border-right: 2px solid red !important;
`;

export const TdLast = styled.td`
  border-left: 2px solid red !important;
  border-right: 2px solid red !important;
  border-bottom: 2px solid red !important;
`;
