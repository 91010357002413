import React, { ChangeEventHandler, useEffect, useRef, useState } from 'react';
import { FiPlusCircle, FiSearch, FiTrash } from 'react-icons/fi';
import { Link, useParams } from 'react-router-dom';
import Button from '../../../components/Button';

import Modal from '../../../components/Modal';

import Spinner from '../../../components/Spinner';
import { useToast } from '../../../hooks/toast';
import api from '../../../services/api';
import { Filter, ModalContent, Search, Title } from './styles';

import { Container, Content, RowContainer, Row, Schedule } from './styles';
import { Form } from '@unform/web';
import Input from '../../../components/Input';
import { FormHandles } from '@unform/core';
import Checkbox from '../../../components/Checkbox/V2';
import Pagination from '../../../components/Pagination';
import { AnonymousCredential, BlobServiceClient } from '@azure/storage-blob';

const REACT_APP_AZURE_STORAGE_URL =
  'https://atitudevs.blob.core.windows.net/?sv=2022-11-02&ss=bfqt&srt=sco&sp=rwdlacupiytfx&se=2025-10-09T02:48:42Z&st=2023-10-08T18:48:42Z&spr=https&sig=frxsqUUF%2B1KhBrtAVDgl8BvrRZU0FdKZyl9Elul6WoU%3D';

const ExercicioList: React.FC = () => {
  const { page } = useParams();
  const { addToast } = useToast();
  const formRef = useRef<FormHandles>(null);
  const [loading, setLoading] = useState(false);
  const [list, setList] = useState<any>([]);
  const [pages, setPages] = useState(1);
  const [currentPage, setCurrentPage] = useState(1);
  const [modal, setModal] = useState(false);
  const [exercicio, setExercicio] = useState({} as { id: number; nome: string; imagem: string });
  const [text, setText] = useState<string>('');
  const [my, setMy] = useState(false);
  const [refresh, setRefresh] = useState(0);

  //const [text, setText] = useState<string>('');

  useEffect(() => {
    setLoading(true);
    let _page = Number(page);
    if (isNaN(_page)) _page = 1;
    api
      .get(`exercicios?PageNumber=${_page}&My=${my}`)
      .then((response) => {
        setList(response.data.list);
        setCurrentPage(response.data.currentPage);
        setPages(response.data.pages);
        setLoading(false);
      })
      .catch((error) => {
        setLoading(false);
        addToast({
          type: 'error',
          title: error.message,
        });
      });
  }, [addToast, page, my, refresh]);

  const handleGotoPage = (_page: number) => {
    if (_page > pages) return;
    setLoading(true);
    const query = text ? `&Text=${text}` : '';
    api
      .get(`exercicios?PageNumber=${_page}${query}&My=${my}`)
      .then((resp) => {
        setList(resp.data.list);
        setCurrentPage(resp.data.currentPage);
        setPages(resp.data.pages);
        setLoading(false);
      })
      .catch((error) => {
        setLoading(false);
      });
  };

  const showModal = (item: any) => {
    setExercicio(item);
    setModal(true);
  };

  const handleDelete = () => {
    try {
      setLoading(true);
      api.delete(`exercicios/${exercicio.id}`).then(async () => {
        const blobServiceClient = new BlobServiceClient(REACT_APP_AZURE_STORAGE_URL, new AnonymousCredential());
        const containerClient = blobServiceClient.getContainerClient('exercicios');
        const blobClient = containerClient.getBlobClient(exercicio.imagem);

        try {
          await blobClient.deleteIfExists();
          console.log('Image deleted successfully!');
        } catch (error) {
          console.error('Error deleting image:', error);
          alert('Error deleting image');
        }
        setLoading(false);
        setModal(false);
        setRefresh(refresh + 1);
        addToast({
          type: 'success',
          title: 'Excluido com sucesso',
        });
      });
    } catch (error) {
      setLoading(false);
      addToast({
        type: 'error',
        title: 'Ocorreu um error ao excluir',
      });
    }
  };

  const handleFilter = (e: React.ChangeEvent<HTMLInputElement>) => {
    setText(e.target.value || '');
  };

  const myAlunos: ChangeEventHandler<HTMLInputElement> = (e) => {
    setMy(e.target.checked);
  };

  const handleSearch = (e: any) => {
    if (e.key === 'Enter') {
      setLoading(true);
      const query = text ? `&Text=${text}` : '';
      api
        .get(`exercicios?PageNumber=${1}${query}&My=${my}`)
        .then((resp) => {
          setList(resp.data.list);
          setCurrentPage(resp.data.currentPage);
          setPages(resp.data.pages);
          setLoading(false);
        })
        .catch((error) => {
          setLoading(false);
        });
    }
  };

  const handleSubmit = () => ({});

  return (
    <>
      <Container>
        {/* <Header /> */}
        <Content>
          <Schedule>
            <Title>
              <h1>Exercícios</h1>
              <Link to={'/exercicios/novo'}>
                <FiPlusCircle size={20} />
                <span>Novo</span>
              </Link>
            </Title>
            <Form
              ref={formRef}
              onSubmit={handleSubmit}
              placeholder={undefined}
              onPointerEnterCapture={undefined}
              onPointerLeaveCapture={undefined}
            >
              <Search>
                <Input name="nome" placeholder="Buscar" icon={FiSearch} onChange={handleFilter} onKeyDown={handleSearch} />
              </Search>
              <Filter>
                <Checkbox name={'my'} label="Meus exercicios" onChange={myAlunos} defaultChecked={false} />
              </Filter>
            </Form>
            {!loading ? (
              <>
                <RowContainer>
                  {list.map((a: any, i: number) => (
                    <Row key={a.id}>
                      <div>
                        <div></div>
                        <strong>
                          <Link to={`/exercicios/${a.id}`}>
                            {a.nome} {a?.segmento && '-'} {a?.segmento?.nome}
                          </Link>
                        </strong>
                      </div>
                      {a.isCriador && (
                        <div onClick={() => showModal(a)}>
                          <FiTrash size={25} color={'#ef1111'} />
                        </div>
                      )}
                    </Row>
                  ))}
                </RowContainer>
                <Pagination currentPage={currentPage} pages={pages} handleGotoPage={handleGotoPage} />
              </>
            ) : (
              <>
                <br />
                <Spinner />
              </>
            )}
          </Schedule>
        </Content>
        <Modal visible={modal} close={() => setModal(false)}>
          <ModalContent>
            <p style={{ marginBottom: 20 }}>EXCLUIR</p>
            <div>
              <p>
                {`Deseja realmente excluir a exercicio`} <strong>{exercicio.nome}</strong>
              </p>
              <p>Essa operação não pode ser desfeita</p>
              <Button onClick={handleDelete} loading={loading}>
                Confirmar
              </Button>
            </div>
          </ModalContent>
        </Modal>
      </Container>
    </>
  );
};

export default ExercicioList;
