import React, { InputHTMLAttributes, useEffect, useRef } from 'react';
import { useField } from '@unform/core';

import { Container } from './styles';

interface InputProps extends InputHTMLAttributes<HTMLInputElement> {
  name: string;
  id: string;
  value: string | number;
  label?: string;
}

const Option: React.FC<InputProps> = ({ name, id, value, label, ...rest }) => {
  const inputRefs = useRef<HTMLInputElement>(null);
  const { fieldName, defaultValue = '', registerField } = useField(name);
  useEffect(() => {
    registerField({
      name: fieldName,
      ref: inputRefs.current,
      // path: 'value',
      getValue: (refs: HTMLInputElement) => {
        return refs?.value || '';
      },
      setValue: (refs: HTMLInputElement, id: string) => {
        const inputRef = refs;
        if (inputRef) inputRef.checked = true;
      },
      clearValue: (refs: HTMLInputElement) => {
        const inputRef = refs;
        if (inputRef) inputRef.checked = false;
      },
    });
  }, [fieldName, registerField]);

  return (
    <>
      <Container key={id}>
        <label htmlFor={id}>
          <input ref={inputRefs} id={id} type="radio" name={name} defaultChecked={defaultValue} value={value} {...rest} />
          {label}
        </label>
      </Container>
    </>
  );
};

export default Option;
