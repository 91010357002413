import { FormHandles } from '@unform/core';
import { Form } from '@unform/web';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { Link, useNavigate, useParams } from 'react-router-dom';
import * as Yup from 'yup';

import Input from '../../components/Input';
import { useToast } from '../../hooks/toast';
import api from '../../services/api';

import { Container, Content, Grid, Head, Text, Desc, Valor, Title, Classif, ResultContainer } from './styles';
import Button from '../../components/Button';
import { FiChevronLeft } from 'react-icons/fi';
import { classiC10, classiIG, classiLCLC, classiLPDV, classiLPS, classiVTC } from './formulas';

const Gdlam: React.FC = () => {
  const { alunoid, id } = useParams();
  const navigate = useNavigate();
  const { addToast } = useToast();
  const formRef = useRef<FormHandles>(null);
  const [loading, setLoading] = useState(false);
  const [aluno, setAluno] = useState<any>({} as any);

  useEffect(() => {
    api
      .get('alunos/' + alunoid)
      .then((response) => {
        setAluno(response.data);
      })
      .catch((e) => {
        addToast({
          type: 'error',
          title: 'error',
        });
      });
    if (Number(id) !== 0) {
      api
        .get(`gdlams/${id}`)
        .then((response) => {
          formRef.current?.setData(response.data);
        })
        .catch((e) => {
          addToast({
            type: 'error',
            title: 'error',
          });
        });
    }
  }, [alunoid, id, addToast]);

  const handleSubmit = useCallback(
    async (data: any) => {
      try {
        formRef.current?.setErrors({});
        const schema = Yup.object().shape({
          // aluno: Yup.number().required('Obrigatorio'),
          // c10: Yup.number().min(0.1).required('Obrigatorio'),
          // lps: Yup.number().min(0.1).required('Obrigatorio'),
          // lpdv: Yup.number().min(0.1).required('Obrigatorio'),
          // lclc: Yup.number().min(0.1).required('Obrigatorio'),
        });
        await schema.validate(data, {
          abortEarly: false,
        });

        if (Number(id) === 0) {
          await api.post('gdlams', data);
        } else {
          await api.put('gdlams/' + id, data);
        }
        setLoading(false);

        addToast({
          type: 'success',
          title: 'Gravado com sucesso!',
        });
        navigate(`/cliente/${alunoid}/questionarios`);
      } catch (error) {
        console.log(error);
        setLoading(false);
        addToast({
          type: 'error',
          title: 'Verifique o formulário',
        });
      }
    },
    [alunoid, id, addToast, navigate]
  );

  const classif1 = () => {
    let valor = formRef.current?.getFieldValue('c10');
    valor = Number(valor.toString().replace(',', '.'));

    if (aluno == null) return;

    let classificacao = classiC10(valor, aluno.idade);
    console.log(classificacao, valor, aluno.idade);

    formRef.current?.setFieldValue('c10Classificacao', classificacao);
    classifIG();
  };

  const classif2 = () => {
    let valor = formRef.current?.getFieldValue('lps');
    valor = Number(valor.toString().replace(',', '.'));
    if (aluno == null) return;
    let classificacao = classiLPS(valor, aluno.idade);
    formRef.current?.setFieldValue('lpsClassificacao', classificacao);
    classifIG();
  };

  const classif3 = () => {
    let valor = formRef.current?.getFieldValue('lpdv');
    valor = Number(valor.toString().replace(',', '.'));
    if (aluno == null) return;

    let classificacao = classiLPDV(valor, aluno.idade);
    formRef.current?.setFieldValue('lpdvClassificacao', classificacao);
    classifIG();
  };

  const classif4 = () => {
    let valor = formRef.current?.getFieldValue('lclc');
    valor = Number(valor.toString().replace(',', '.'));
    if (aluno == null) return;

    let classificacao = classiLCLC(valor, aluno.idade);
    formRef.current?.setFieldValue('lclcClassificacao', classificacao);
    classifIG();
  };

  const classif5 = () => {
    let valor = formRef.current?.getFieldValue('vtc');
    valor = Number(valor.toString().replace(',', '.'));
    if (aluno == null) return;

    let classificacao = classiVTC(valor, aluno.idade);
    formRef.current?.setFieldValue('vtcClassificacao', classificacao);
    classifIG();
  };

  const classifIG = () => {
    const c10 = Number(formRef.current?.getFieldValue('c10'));
    const lps = Number(formRef.current?.getFieldValue('lps'));
    const lpdv = Number(formRef.current?.getFieldValue('lpdv'));
    const lclc = Number(formRef.current?.getFieldValue('lclc'));
    const vtc = Number(formRef.current?.getFieldValue('vtc'));

    const valor = ((c10 + lps + lpdv + vtc) * 2 + lclc) / 4;

    if (aluno == null) return;

    let classificacao = classiIG(valor, aluno.idade);

    formRef.current?.setFieldValue('ig', valor.toFixed(2));
    formRef.current?.setFieldValue('igClassificacao', classificacao);
  };

  return (
    <>
      {/* <Header /> */}
      <Container>
        <Content>
          <Title>
            <h1>GDLAM</h1>
            <Link to={`/cliente/${alunoid}/questionarios`}>
              <FiChevronLeft size={20} />
              <span>Voltar</span>
            </Link>
          </Title>
          <Form
            ref={formRef}
            onSubmit={handleSubmit}
            initialData={{ aluno: alunoid, id: id }}
            placeholder={undefined}
            onPointerEnterCapture={undefined}
            onPointerLeaveCapture={undefined}
          >
            <Grid>
              <Head id="1">Teste</Head>
              <Head id="2">Realizar os testes o mais rápido possível</Head>
              <Head id="3">Valor (segundos)</Head>
              <Head id="4">Classificação</Head>
              <Text id="1">C10 = caminhar 10m</Text>
              <Text id="2">LPS = Levantar da posição sentada</Text>
              <Text id="3">LPDV = Levantr da posição decúbito ventral</Text>
              <Text id="4">LCLC = Levantar da cadeira e lcomover pela casa</Text>
              <Text id="5">VTC = Vestir camisa sem auxílio</Text>
              <Desc id="1">Caminhar a distância de 10 metros</Desc>
              <Desc id="2">
                Partindo da posição sentada em uma cadeira, sem apoio dos braços, e altura de 50cm do solo, levantar e sentar 5 vezes
                consecutivas
              </Desc>
              <Desc id="3">Levantar da posição de decúbito ventral com os braços ao longo do corpo, ao comando de "já".</Desc>
              <Desc id="4">
                Sentado na cadeira e pés fora do chão, ao comando de "já", se levanta, move-se para a direita e circula o cone posicionada
                atrás da cadeira a uma distância de 4m e na diagonal. Retorna a posição inicial e em seguida faz o percurso a esquerda.
                Realizar o percurso 2x. Os cones da direita e da esquerda devem estar na distância de 3m
              </Desc>
              <Desc id="5">
                Na posição de pé, com os braços estendidos ao longo do corpo, em uma das mãos de seu lado dominante uma camiseta padrão “G”.
                No comando de "já", o avaliando deve vestir a camiseta e, rapidamente, retirá-la, voltando à posição inicial. A contagem do
                tempo deverá ocorrer no início do movimento e finalizar quando a camisa retornar a mão.
              </Desc>
              <Valor id="1">
                <Input name="c10" type="number" step=".01" onChange={classif1} />
              </Valor>
              <Valor id="2">
                <Input name="lps" type="number" step=".01" onChange={classif2} />
              </Valor>
              <Valor id="3">
                <Input name="lpdv" type="number" step=".01" onChange={classif3} />
              </Valor>
              <Valor id="4">
                <Input name="lclc" type="number" step=".01" onChange={classif4} />
              </Valor>
              <Valor id="5">
                <Input name="vtc" type="number" step=".01" onChange={classif5} />
              </Valor>
              <Classif id="1">
                <Input name="c10Classificacao" readOnly />
              </Classif>
              <Classif id="2">
                <Input name="lpsClassificacao" readOnly />
              </Classif>
              <Classif id="3">
                <Input name="lpdvClassificacao" readOnly />
              </Classif>
              <Classif id="4">
                <Input name="lclcClassificacao" readOnly />
              </Classif>
              <Classif id="5">
                <Input name="vtcClassificacao" readOnly />
              </Classif>
            </Grid>
            <ResultContainer>
              <div>IG = Índice GDLAM</div>
              <div>
                <div>
                  <div>Escore</div>
                  <div>
                    <Input name="ig" type="number" readOnly />
                  </div>
                </div>
                <div>
                  <div>Classificacao</div>
                  <div>
                    <Input name="igClassificacao" readOnly />
                  </div>
                </div>
              </div>
            </ResultContainer>
            <Button loading={loading} disabled={loading} type="submit">
              Gravar
            </Button>
            <div style={{ visibility: 'hidden' }}>
              <Input name="aluno" type="number" />
              <Input name="id" type="number" />
            </div>
          </Form>
        </Content>
      </Container>
    </>
  );
};

export default Gdlam;
