import { FormHandles } from '@unform/core';
import { Form } from '@unform/web';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { FiChevronLeft } from 'react-icons/fi';
import { Link, useNavigate, useParams } from 'react-router-dom';
import * as Yup from 'yup';

import Input from '../../components/Input';
import Button from '../../components/Button';
import { useToast } from '../../hooks/toast';
import api from '../../services/api';
import { Container, Content, Flex, Section, Title } from './styles';
import { Aluno } from '../../model/Aluno';
import { calculoMET, classificacaoVO2Max } from './classificacao';

const VO2Max: React.FC = () => {
  const { alunoid, id } = useParams();
  const navigate = useNavigate();
  const { addToast } = useToast();
  const formRef = useRef<FormHandles>(null);
  const [loading, setLoading] = useState(false);
  const [aluno, setAluno] = useState<Aluno>({} as Aluno);
  const [peso, setPeso] = useState(0);

  useEffect(() => {
    api
      .get('alunos/' + alunoid)
      .then((response) => {
        setAluno(response.data);
      })
      .then(() => {
        api.get('antropometrias/aluno/' + alunoid).then((response) => {
          setPeso(response.data.peso);
        });
      })
      .catch((e) => {
        addToast({
          type: 'error',
          title: 'error',
        });
      });
    if (Number(id) !== 0) {
      api
        .get('vo2max/' + id)
        .then((response) => {
          formRef.current?.setData(response.data);
        })
        .catch((e) => {
          addToast({
            type: 'error',
            title: 'error',
          });
        });
    }
  }, [alunoid, id, addToast]);

  const calc1 = () => {
    const valor = Number(formRef.current?.getFieldValue('fcexercicio.valor'));
    const a = aluno.sexo === 1 ? 0.42 : 0.1847;
    const result = 111.33 - a * valor;
    const classificacao = classificacaoVO2Max(aluno.sexo, aluno.idade, result);

    formRef.current?.setFieldValue('fcexercicio.vo2max', result.toFixed(2));
    formRef.current?.setFieldValue('fcexercicio.classificacao', classificacao);
  };

  const calc2 = () => {
    const valor = Number(formRef.current?.getFieldValue('distancia.valor'));
    const result = (valor - 504.09) / 44.78;
    const classificacao = classificacaoVO2Max(aluno.sexo, aluno.idade, result);

    formRef.current?.setFieldValue('distancia.vo2max', result.toFixed(2));
    formRef.current?.setFieldValue('distancia.classificacao', classificacao);
  };

  const calc3 = () => {
    const valor = Number(formRef.current?.getFieldValue('cicloergometro.valor'));
    const absoluto = (6300 - 19.26 * valor) / 1000;
    const relativo = (absoluto / peso) * 1000;
    const met = relativo / 3.5;
    const classificacao = classificacaoVO2Max(aluno.sexo, aluno.idade, absoluto);
    console.log(valor, absoluto, peso, relativo, classificacao);

    formRef.current?.setFieldValue('cicloergometro.vo2max.absoluto', absoluto.toFixed(2));
    formRef.current?.setFieldValue('cicloergometro.vo2max.relativo', relativo.toFixed(2));
    formRef.current?.setFieldValue('cicloergometro.met', met.toFixed(2));
    formRef.current?.setFieldValue('cicloergometro.classificacao', classificacao);
  };

  const calc4 = () => {
    const tempo = Number(formRef.current?.getFieldValue('caminhada.tempo'));
    const fc = Number(formRef.current?.getFieldValue('caminhada.fcexercicio'));
    const sexo = aluno.sexo === 1 ? 1 : 0;
    const result = 132.853 - (0.1692 * peso - 0.3877 * aluno.idade) + (6.315 * sexo - 3.2649 * tempo - 0.1565 * fc);
    const classificacao = classificacaoVO2Max(aluno.sexo, aluno.idade, result);

    formRef.current?.setFieldValue('caminhada.vo2max', result.toFixed(2));
    formRef.current?.setFieldValue('caminhada.classificacao', classificacao);
  };

  const calc5 = () => {
    const valor = Number(formRef.current?.getFieldValue('esteira.velocidade'));
    const consumo = calculoMET(valor);
    const result = consumo * 3.5;
    const classificacao = classificacaoVO2Max(aluno.sexo, aluno.idade, result);

    formRef.current?.setFieldValue('esteira.met', consumo);
    formRef.current?.setFieldValue('esteira.vo2max', result.toFixed(2));
    formRef.current?.setFieldValue('esteira.classificacao', classificacao);
  };

  const handleSubmit = useCallback(
    async (data: any) => {
      try {
        formRef.current?.setErrors({});
        const schema = Yup.object().shape({});
        await schema.validate(data, {
          abortEarly: false,
        });
        setLoading(true);
        console.log(data);

        if (Number(id) === 0) {
          await api.post('vo2max', data);
        } else {
          await api.put('vo2max/' + id, data);
        }
        setLoading(false);

        addToast({
          type: 'success',
          title: 'Gravado com sucesso!',
        });
        navigate(`/cliente/${alunoid}/questionarios`);
      } catch (error) {
        setLoading(false);
        addToast({
          type: 'error',
          title: 'Verifique o formulário',
        });
      }
    },
    [alunoid, id, addToast, navigate]
  );
  return (
    <>
      {/* <Header /> */}
      <Container>
        <Content>
          <Title>
            <h1>VO2MAX</h1>
            <Link to={`/cliente/${alunoid}/questionarios`}>
              <FiChevronLeft size={20} />
              <span>Voltar</span>
            </Link>
          </Title>
          <Form
            ref={formRef}
            onSubmit={handleSubmit}
            initialData={{ aluno: alunoid }}
            placeholder={undefined}
            onPointerEnterCapture={undefined}
            onPointerLeaveCapture={undefined}
          >
            <Section>
              <strong>Teste de banco - Subir e descer banco de 41cm altura – adulto jovem</strong>
              <span>Homens = 24 ciclos/minuto; Mulher = 22 ciclos/minuto</span>
              <Flex>
                <div>
                  FCExercicio
                  <Input name="fcexercicio.valor" onChange={calc1} />
                </div>
                <div>
                  VO2max
                  <Input name="fcexercicio.vo2max" readOnly />
                </div>
                <div>
                  Classificação
                  <Input name="fcexercicio.classificacao" readOnly />
                </div>
              </Flex>
            </Section>
            <Section>
              <strong>Teste de Cooper – correr 12 minutos – a partir dos 13 anos</strong>
              <Flex>
                <div>
                  Distância percorrida (Metros)
                  <Input name="distancia.valor" onChange={calc2} />
                </div>
                <div>
                  VO2max
                  <Input name="distancia.vo2max" readOnly />
                </div>
                <div>
                  Classificação
                  <Input name="distancia.classificacao" readOnly />
                </div>
              </Flex>
            </Section>
            <Section>
              <strong>Teste de cicloergômetro (FOX)</strong>
              <span>Pedalar 3min a 50watts(1kg) e 60rpm(22 km/h) + Pedalar 5min a 150watts(3kg) e 60rpm(22 km/h)</span>
              <Flex>
                <div>
                  FC 5o. Min
                  <Input name="cicloergometro.valor" onChange={calc3} />
                </div>
                <div>
                  VO2max absoluto
                  <Input name="cicloergometro.vo2max.absoluto" readOnly />
                </div>
                <div>
                  VO2max relativo
                  <Input name="cicloergometro.vo2max.relativo" readOnly />
                </div>
                <div>
                  MET
                  <Input name="cicloergometro.met" readOnly />
                </div>
                <div>
                  Classificação
                  <Input name="cicloergometro.classificacao" readOnly />
                </div>
              </Flex>
            </Section>
            <Section>
              <strong>Teste de caminhada de milha(1,6km) – 20 a 69 anos - pouco ativos</strong>
              <Flex>
                <div>
                  Tempo(min)
                  <Input name="caminhada.tempo" onChange={calc4} />
                </div>
                <div>
                  FC exercício
                  <Input name="caminhada.fcexercicio" onChange={calc4} />
                </div>
                <div>
                  VO2max
                  <Input name="caminhada.vo2max" readOnly />
                </div>
                <div>
                  Classificação
                  <Input name="caminhada.classificacao" readOnly />
                </div>
              </Flex>
            </Section>
            <Section>
              <strong>Teste de corrida esteira ergométrica – adultos ativos</strong>
              <span>
                Caminhar de 3 a 5 min leve + aumentar 1k/h a cada minuto até a velocidade que não consegue sustentar mais. Registrar a
                última velocidade sustentada.
              </span>
              <Flex>
                <div>
                  Velocidade(km/h)
                  <Input name="esteira.velocidade" onChange={calc5} />
                </div>
                <div>
                  Consumo em MET
                  <Input name="esteira.met" readOnly />
                </div>
                <div>
                  VO2max
                  <Input name="esteira.vo2max" readOnly />
                </div>
                <div>
                  Classificação
                  <Input name="esteira.classificacao" readOnly />
                </div>
              </Flex>
            </Section>
            <Button loading={loading} disabled={loading} type="submit">
              Gravar
            </Button>
            <div style={{ visibility: 'hidden' }}>
              <Input name="aluno" type="number" />
            </div>
          </Form>
        </Content>
      </Container>
    </>
  );
};

export default VO2Max;
