import React, { useCallback, useEffect, useRef, useState } from 'react';
import { FormHandles } from '@unform/core';
import { Form } from '@unform/web';
import { FiChevronLeft } from 'react-icons/fi';
import { Link, useNavigate, useParams } from 'react-router-dom';
import * as Yup from 'yup';

import { useToast } from '../../hooks/toast';
import { Container, Content, Main, OptionGroup, SubTitle, Title } from './styles';
import Input from '../../components/Input';
import api from '../../services/api';
import Button from '../../components/Button';
import Option from '../../components/Option';
import getValidationErrors from '../../utils/getValidationErrors';
import {
  options,
  options1,
  options10,
  options11,
  options12,
  options2,
  options3,
  options4,
  options5,
  options6,
  options7,
  options8,
  options9,
} from './respostas';

const Radar: React.FC = () => {
  const { alunoid, id } = useParams();
  const navigate = useNavigate();
  const { addToast } = useToast();
  const formRef = useRef<FormHandles>(null);
  const [loading, setLoading] = useState(false);
  const [resp, setResp] = useState<any>({});

  useEffect(() => {
    if (Number(id) !== 0) {
      setLoading(true);
      api
        .get(`radar/${id}`)
        .then((response) => {
          formRef.current?.setData(response.data);
          setLoading(false);
        })
        .catch((e) => {
          addToast({
            type: 'error',
            title: 'error',
          });
        });
    }
  }, [id, addToast]);

  const handleSubmit = useCallback(
    async (data: any) => {
      try {
        formRef.current?.setErrors({});
        const schema = Yup.object().shape({
          aluno: Yup.number().required('Aluno obrigatorio'),
          wr01: Yup.string().required('Pergunta 1 obrigatório'),
          wr02: Yup.string(),
          wr03: Yup.string().required('Pergunta 3 obrigatório'),
          wr04: Yup.string(),
          wr05: Yup.string().required('Pergunta 5 obrigatório'),
          wr06: Yup.string().required('Pergunta 6 obrigatório'),
          wr07: Yup.string().required('Pergunta 7 obrigatório'),
          wr08: Yup.string().required('Pergunta 8 obrigatório'),
          wr09: Yup.string().required('Pergunta 9 obrigatório'),
          wr10: Yup.string().required('Pergunta 10 obrigatório'),
          wr11: Yup.string().required('Pergunta 11 obrigatório'),
          wr12: Yup.string().required('Pergunta 12 obrigatório'),
          wr13: Yup.string().required('Pergunta 13 obrigatório'),
          wr14: Yup.string().required('Pergunta 14 obrigatório'),
          wr15: Yup.string().required('Pergunta 15 obrigatório'),
          wr16: Yup.string().required('Pergunta 16 obrigatório'),
          wr17: Yup.string().required('Pergunta 17 obrigatório'),
          wr18: Yup.string().required('Pergunta 18 obrigatório'),
          wr19: Yup.string().required('Pergunta 19 obrigatório'),
          wr20: Yup.string().required('Pergunta 20 obrigatório'),
        });

        await schema.validate(data, {
          abortEarly: false,
        });

        setLoading(true);
        if (Number(id) === 0) {
          await api.post('radar', data);
        } else {
          await api.put('radar/' + id, data);
        }
        setLoading(false);

        formRef.current?.reset();
        addToast({
          type: 'success',
          title: 'Gravado com sucesso!',
        });
        navigate(`/cliente/${alunoid}/questionarios`);
      } catch (error: any) {
        setLoading(false);
        const errors = getValidationErrors(error);
        formRef.current?.setErrors(errors);
        error.inner.forEach((er: any) => {
          addToast({
            type: 'error',
            title: er.message,
          });
        });
        addToast({
          type: 'error',
          title: 'Verifique o formulário',
        });
      }
    },
    [alunoid, id, addToast, navigate]
  );

  return (
    <>
      <Container>
        {/* <Header /> */}
        <Main>
          <Content>
            <Title>
              <h1>Estilo de Vida</h1>
              <Link to={`/cliente/${alunoid}/questionarios`}>
                <FiChevronLeft size={20} />
                <span>Voltar</span>
              </Link>
            </Title>
            <p>
              O Radar do Bem Estar é um instrumento que avalia indicadores subjetivos relacionados a saúde física, relacionamento pessoal e
              no bem estar emocional.
            </p>

            <Form
              ref={formRef}
              onSubmit={handleSubmit}
              initialData={{ aluno: alunoid }}
              placeholder={undefined}
              onPointerEnterCapture={undefined}
              onPointerLeaveCapture={undefined}
            >
              <OptionGroup>
                <SubTitle>Na DIMENSÃO FíSICA, queremos saber:</SubTitle>
                <label>
                  1) Em quantos dias da última semana você CAMINHOU por pelo menos 10 minutos contínuos em casa ou no trabalho, como forma
                  de transporte para ir de um lugar para outro, ou por lazer?
                </label>
                <Option name="wr01" options={options1} onChange={(e) => setResp({ ...resp, wr01: e.target.value })} />
              </OptionGroup>
              {resp.wr01 !== 'Nenhum' && (
                <OptionGroup>
                  <label>2) Nos dias em que você caminhou, qual a duração média por dia?</label>
                  <Option name="wr02" options={options2} />
                </OptionGroup>
              )}
              <OptionGroup>
                <label>
                  3) Você pratica, regularmente, algum tipo de atividade física(ginástica, musculação, ciclismo, esporte, dança ou artes
                  marciais)?
                </label>
                <Option name="wr03" options={options3} onChange={(e) => setResp({ ...resp, wr03: e.target.value })} />
              </OptionGroup>
              {resp.wr03 !== 'Não' && (
                <OptionGroup>
                  <label>
                    4) Se você pratica alguma destas atividades física de forma regular, a duração média é igual ou superior a 30 minutos
                    por dia?
                  </label>
                  <Option name="wr04" options={options2} />
                </OptionGroup>
              )}
              <OptionGroup>
                <label>
                  5) Em quantos dias da semana você come legumes ou verduras (alface, tomate, couve, cenoura, chuchu, berinjela, abobrinha /
                  não vale batata, mandioca ou inhame)?
                </label>
                <Option name="wr05" options={options4} />
              </OptionGroup>
              <OptionGroup>
                <label>6) Em quantos dias da semana você come frutas (ou toma suco de fruta natural)?</label>
                <Option name="wr06" options={options4} />
              </OptionGroup>
              <OptionGroup>
                <label>7) Em quantos dias da semana você toma refrigerantes ou sucos artificiais(refrescos)?</label>
                <Option name="wr07" options={options4} />
              </OptionGroup>
              <OptionGroup>
                <label>8) Quantos cigarros você fuma por dia?</label>
                <Option name="wr08" options={options5} />
              </OptionGroup>
              <OptionGroup>
                <label>
                  9) Nos últimos 30 dias, você tomou cinco ou mais doses de bebidas alcoólicas numa mesma ocasião.(uma lata de cerveja, uma
                  taça de vinho ou uma dose de cachaça, whisky ou qualquer outra bebida alcoólica destilada)
                </label>
                <Option name="wr09" options={options} />
              </OptionGroup>
              <OptionGroup>
                <label>10) Com que frequência você considera que dorme bem?</label>
                <Option name="wr10" options={options6} />
              </OptionGroup>
              <OptionGroup>
                <SubTitle>Na DIMENSÃO SOCIAL, informe:</SubTitle>
                <label>
                  11) De uma maneira geral, como você classifica a qualidade dos seus relacionamentos que mantém com outras pessoas?(amigos,
                  colegas de trabalho, chefes)
                </label>
                <Option name="wr11" options={options7} />
              </OptionGroup>
              <OptionGroup>
                <label>12) Você procura cultivar amigos e está satisfeito com seus relacionamentos.</label>
                <Option name="wr12" options={options8} />
              </OptionGroup>
              <OptionGroup>
                <label>
                  13) Seu lazer inclui encontros com amigos, atividades em grupo, participação em associações ou entidades sociais?
                </label>
                <Option name="wr13" options={options8} />
              </OptionGroup>
              <OptionGroup>
                <label>14) Você procura ser ativo em sua comunidade, sentindo-se útil no seu ambiente social?</label>
                <Option name="wr14" options={options8} />
              </OptionGroup>
              <OptionGroup>
                <SubTitle>Na DIMENSÃO EMOCIONAL, informe:</SubTitle>
                <label>15) Quanto você aproveita a vida?</label>
                <Option name="wr15" options={options9} />
              </OptionGroup>
              <OptionGroup>
                <label>16) Em que medida você acha que a sua vida tem sentido?</label>
                <Option name="wr16" options={options9} />
              </OptionGroup>
              <OptionGroup>
                <label>17) Quanto você consegue se concentrar?</label>
                <Option name="wr17" options={options9} />
              </OptionGroup>
              <OptionGroup>
                <label>18) Quanto você é capaz de aceitar sua aparência física?</label>
                <Option name="wr18" options={options10} />
              </OptionGroup>
              <OptionGroup>
                <label>19) Quão satisfeito(a) você está consigo mesmo?</label>
                <Option name="wr19" options={options11} />
              </OptionGroup>
              <OptionGroup>
                <label>20) Com que frequência você tem sentimentos positivos tais como bom humor, calma e tranquilidade?</label>
                <Option name="wr20" options={options12} />
              </OptionGroup>
              <Button loading={loading} disabled={loading} type="submit">
                Gravar
              </Button>
              <div style={{ visibility: 'hidden' }}>
                <Input name="aluno" type="number" />
              </div>
            </Form>
          </Content>
        </Main>
      </Container>
    </>
  );
};

export default Radar;
