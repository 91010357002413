import { FormHandles } from '@unform/core';
import { Form } from '@unform/web';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useParams, useNavigate, Link } from 'react-router-dom';
import { useToast } from '../../hooks/toast';
import * as Yup from 'yup';
import { FiChevronLeft } from 'react-icons/fi';
import api from '../../services/api';
import getValidationErrors from '../../utils/getValidationErrors';

import Input from '../../components/Input';
import Button from '../../components/Button';

import { Container, Content, DesempenhoInput, Section, Title } from './styles';
import { Aluno } from '../../model/Aluno';
import { AntropometriaSimples } from '../../model/Antropometria';
import Select from '../../components/Select';

const dimensaoWR = [
  { id: '1', value: 'Inativo', name: 'Inativo' },
  { id: '2', value: 'Parcialmente ativo', name: 'Parcialmente ativo' },
  { id: '3', value: 'Ativo', name: 'Ativo' },
  { id: '4', value: 'Muito ativo', name: 'Muito ativo' },
];

const intensidade = [
  { id: '1', value: 'Muito leve', name: 'Muito leve' },
  { id: '2', value: 'Leve', name: 'Leve' },
  { id: '3', value: 'Moderado', name: 'Moderado' },
  { id: '4', value: 'Vigoroso', name: 'Vigoroso' },
  { id: '4', value: 'Próximo ao máximo', name: 'Próximo ao máximo' },
];

const Passos: React.FC = () => {
  const { alunoid, id } = useParams();
  const navigate = useNavigate();
  const { addToast } = useToast();
  const formRef = useRef<FormHandles>(null);
  const [loading, setLoading] = useState(false);
  const [aluno, setAluno] = useState<Aluno>({} as Aluno);
  const [antropometria, setAntropometria] = useState<AntropometriaSimples>({} as AntropometriaSimples);

  useEffect(() => {
    api
      .get('alunos/' + alunoid)
      .then((response) => {
        setAluno(response.data);
      })
      .then(() => {
        api.get('antropometrias/aluno/' + alunoid).then((response) => {
          setAntropometria(response.data);
          calc1();
        });
      })
      .catch((e) => {
        addToast({
          type: 'error',
          title: 'error',
        });
      });
    if (Number(id) !== 0) {
      api
        .get('intesidade/' + id)
        .then((response) => {
          formRef.current?.setData(response.data);
        })
        .catch((e) => {
          addToast({
            type: 'error',
            title: 'error',
          });
        });
    }
    // eslint-disable-next-line
  }, [alunoid, id, addToast]);

  const calc1 = () => {
    let ged_diario = 0;
    if (aluno.sexo === 1) {
      ged_diario = 66 + 13.7 * antropometria.peso + 5 * antropometria.altura - 6.8 * aluno.idade;
    } else {
      ged_diario = 655 + 9.6 * antropometria.peso + 1.8 * antropometria.altura - 4.7 * aluno.idade;
    }

    let ged_peso = 0;
    if (aluno.idade > 59 && aluno.sexo === 1) {
      ged_peso = 13.5 * antropometria.peso + 487;
    } else if (aluno.idade > 29 && aluno.sexo === 1) {
      ged_peso = 11.6 * antropometria.peso + 879;
    } else if (aluno.idade > 17 && aluno.sexo === 1) {
      ged_peso = 15.3 * antropometria.peso + 679;
    } else if (aluno.idade > 59 && aluno.sexo === 2) {
      ged_peso = 10.5 * antropometria.peso + 596;
    } else if (aluno.idade > 29 && aluno.sexo === 2) {
      ged_peso = 8.7 * antropometria.peso + 829;
    } else if (aluno.idade > 17 && aluno.sexo === 2) {
      ged_peso = 14.7 * antropometria.peso + 496;
    }

    formRef.current?.setFieldValue('gastoenergeticodiario', ged_diario.toFixed(2));
    formRef.current?.setFieldValue('gastoenergeticopeso', ged_peso.toFixed(2));
  };

  const calc2 = () => {
    let naf = 0;
    const wr = formRef.current?.getFieldValue('dimensaofisica');
    if (wr === 'Inativo') {
      naf = 1.25;
    } else if (wr === 'Parcialmente ativo') {
      naf = 1.5;
    } else if (wr === 'Ativo') {
      naf = 1.55;
    } else if (wr === 'Muito ativo') {
      naf = 1.7;
    }
    const ged_diario = formRef.current?.getFieldValue('gastoenergeticodiario');
    const gastoenergeticoperfil = ged_diario * naf;
    formRef.current?.setFieldValue('nivelatividadefisica', naf);
    formRef.current?.setFieldValue('gastoenergeticoperfil', gastoenergeticoperfil.toFixed(2));
  };

  const calc3 = () => {};

  const handleSubmit = useCallback(
    async (data: any) => {
      try {
        formRef.current?.setErrors({});
        const schema = Yup.object().shape({});

        await schema.validate(data, {
          abortEarly: false,
        });

        setLoading(true);
        if (Number(id) === 0) {
          await api.post('passos', data);
        } else {
          await api.put('passos/' + id, data);
        }
        setLoading(false);

        formRef.current?.reset();
        addToast({
          type: 'success',
          title: 'Gravado com sucesso!',
        });
        navigate(`/cliente/${alunoid}/questionarios`);
      } catch (error: any) {
        setLoading(false);
        const errors = getValidationErrors(error);
        formRef.current?.setErrors(errors);
        error.inner.forEach((er: any) => {
          addToast({
            type: 'error',
            title: er.message,
          });
        });
        setLoading(false);
        addToast({
          type: 'error',
          title: 'Verifique o formulário',
        });
      }
    },
    [alunoid, id, addToast, navigate]
  );
  return (
    <>
      {/* <Header /> */}
      <Container>
        <Content>
          <Title>
            <h1>Gasto Energético Diário</h1>
            <Link to={`/cliente/${alunoid}/questionarios`}>
              <FiChevronLeft size={20} />
              <span>Voltar</span>
            </Link>
          </Title>

          <Form
            ref={formRef}
            onSubmit={handleSubmit}
            initialData={{ aluno: alunoid }}
            placeholder={undefined}
            onPointerEnterCapture={undefined}
            onPointerLeaveCapture={undefined}
          >
            <Section>
              <strong>Gasto Energético diário repouso</strong>
              <DesempenhoInput>
                <div>Gasto energético basal diário - Método Harris-Benedict</div>
                <Input name="gastoenergeticodiario" onChange={calc1} />
              </DesempenhoInput>
              <DesempenhoInput>
                <div>GED por peso corporal- OMS,1985)</div>
                <Input name="gastoenergeticopeso" readOnly />
              </DesempenhoInput>
            </Section>
            <Section>
              <strong>Radar do Bem Estar</strong>
              <DesempenhoInput>
                <div>Dimensão física(WR)</div>
                <Select name="dimensaofisica" options={dimensaoWR} onChange={calc2} />
              </DesempenhoInput>
              <DesempenhoInput>
                <div>Nível de Atividade Física(NAF)</div>
                <Input name="nivelatividadefisica" />
              </DesempenhoInput>
              <DesempenhoInput>
                <div>GED por perfil atividade física</div>
                <Input name="gastoenergeticoperfil" />
              </DesempenhoInput>
            </Section>
            <Section>
              <strong>Gasto Energético diário por peso corporal e intensidade da Atividade Física</strong>
              <DesempenhoInput>
                <div>Nível de intensidade</div>
                <Select name="nivelintensidade" options={intensidade} onChange={calc3} />
                <Input name="gastoenergeticointensidade" />
              </DesempenhoInput>
              <DesempenhoInput>
                <div>Consumo diário de Água(litros)</div>
                <div style={{ justifyContent: 'space-around', textAlign: 'end' }}>
                  <div>Sem exercicio</div>
                  <div>durante o exercício</div>
                </div>
                <div>
                  <Input name="gcpordia" />
                  <Input name="gcpordia" />
                </div>
              </DesempenhoInput>
            </Section>
            <Button loading={loading} disabled={loading} type="submit">
              Gravar
            </Button>
            <div style={{ visibility: 'hidden' }}>
              <Input name="aluno" type="number" />
            </div>
          </Form>
        </Content>
      </Container>
    </>
  );
};

export default Passos;
