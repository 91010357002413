import React, { useCallback, useEffect, useRef, useState } from 'react';
import { FormHandles } from '@unform/core';
import { Form } from '@unform/web';
import { FiChevronLeft, FiChevronRight } from 'react-icons/fi';
import { Link, useNavigate, useParams } from 'react-router-dom';
import * as Yup from 'yup';

import { useToast } from '../../hooks/toast';

import { ButtonNav, Container, Content, Main, Title } from './styles';
import Input from '../../components/Input';
import api from '../../services/api';
import Button from '../../components/Button';
import Option from '../../components/Option';
import { RadioOption } from '../../model/Inputs';
import getValidationErrors from '../../utils/getValidationErrors';
import Checkbox from '../../components/Checkbox/V2';
import Modal from '../../components/Modal';

const options: RadioOption[] = [
  { id: '1', value: 'Sim', label: 'Sim' },
  { id: '2', value: 'Não', label: 'Não' },
];

const Parq: React.FC = () => {
  const { alunoid, id } = useParams();
  const navigate = useNavigate();
  const { addToast } = useToast();
  const formRef = useRef<FormHandles>(null);
  const [loading, setLoading] = useState(false);
  const [alerta, setAlerta] = useState(false);

  useEffect(() => {
    if (Number(id) !== 0) {
      setLoading(true);
      api
        .get(`parqs/${id}`)
        .then((response) => {
          formRef.current?.setData(response.data);
          setLoading(false);
        })
        .catch((e) => {
          addToast({
            type: 'error',
            title: 'error',
          });
        });
    }
  }, [id, addToast]);

  const handleSubmit = useCallback(
    async (data: any) => {
      try {
        formRef.current?.setErrors({});
        const schema = Yup.object().shape({
          aluno: Yup.number().required('Aluno obrigatorio'),
          pq01: Yup.string().required('Pergunta 1 obrigatório'),
          pq02: Yup.string().required('Pergunta 2 obrigatório'),
          pq03: Yup.string().required('Pergunta 3 obrigatório'),
          pq04: Yup.string().required('Pergunta 4 obrigatório'),
          pq05: Yup.string().required('Pergunta 5 obrigatório'),
          pq06: Yup.string().required('Pergunta 6 obrigatório'),
          pq07: Yup.string().required('Pergunta 7 obrigatório'),
          pq08: Yup.string().required('Pergunta 8 obrigatório'),
          pq09: Yup.string().required('Pergunta 9 obrigatório'),
          pq10: Yup.string().required('Pergunta 10 obrigatório'),
          check1: Yup.boolean().required(),
          check2: Yup.boolean().required(),
        });

        await schema.validate(data, {
          abortEarly: false,
        });

        setLoading(true);
        const parqOk = checkParq(data);
        if (Number(id) === 0) {
          await api.post('parqs', data);
        } else {
          await api.put('parqs/' + id, data);
        }
        setLoading(false);

        formRef.current?.reset();
        addToast({
          type: 'success',
          title: 'Gravado com sucesso!',
        });

        if (parqOk) {
          navigate(`/cliente/${alunoid}/questionarios`);
        }
      } catch (error: any) {
        setLoading(false);
        const errors = getValidationErrors(error);
        formRef.current?.setErrors(errors);
        error.inner.forEach((er: any) => {
          addToast({
            type: 'error',
            title: er.message,
          });
        });
        addToast({
          type: 'error',
          title: 'Verifique o formulário',
        });
      }
    },
    [alunoid, id, addToast, navigate]
  );

  const checkParq = (data: any) => {
    if (
      data.pq01 === 'Sim' ||
      data.pq02 === 'Sim' ||
      data.pq03 === 'Sim' ||
      data.pq04 === 'Sim' ||
      data.pq05 === 'Sim' ||
      data.pq06 === 'Sim' ||
      data.pq07 === 'Sim' ||
      data.pq08 === 'Sim' ||
      data.pq09 === 'Sim' ||
      data.pq10 === 'Sim'
    ) {
      setAlerta(true);
      return false;
    } else {
      return true;
    }
  };

  const handleClose = () => {
    setAlerta(false);
  };

  return (
    <>
      <Container>
        <Main>
          <Content>
            <Title>
              <h1>PAR-Q</h1>
              <Link to={`/cliente/${alunoid}/questionarios`}>
                <FiChevronLeft size={20} />
                <span>Voltar</span>
              </Link>
            </Title>
            <p>
              Este questionário tem por objetivo identificar a necessidade de avaliação por um médico antes do início ou do aumento de nível
              da atividade física. Por favor, assinale "sim" ou "não" às seguintes perguntas:
            </p>
            <p>Instrumento de acordo com a lei 6765/2014</p>
            <p>
              Conforme Lei no. 6765/2014, a apresentação de atestado médico ou qualquer exame de aptidão física é obrigatório caso alguma
              resposta seja positiva em relação a alguma pergunta do questionário de prontidão para a atividade física (Par-q).
            </p>
            <Form
              ref={formRef}
              onSubmit={handleSubmit}
              initialData={{ aluno: alunoid }}
              placeholder={undefined}
              onPointerEnterCapture={undefined}
              onPointerLeaveCapture={undefined}
            >
              <div>
                <label>
                  1) Algum médico já disse que você possui algum problema de coração ou pressão arterial, e que somente deveria realizar
                  atividade física supervisionado por profissionais de saúde?
                </label>
                <Option name="pq01" options={options} />
              </div>
              <div>
                <label>2) Você sente dores no peito quando pratica atividade física?</label>
                <Option name="pq02" options={options} />
              </div>
              <div>
                <label>3) No último mês, você sentiu dores no peito ao praticar atividade física?</label>
                <Option name="pq03" options={options} />
              </div>
              <div>
                <label>4) Você apresenta algum desequilíbrio devido à tontura e/ou perda momentânea da consciência?</label>
                <Option name="pq04" options={options} />
              </div>
              <div>
                <label>5) Você possui algum problema ósseo ou articular, que pode ser afetado ou agravado pela atividade física?</label>
                <Option name="pq05" options={options} />
              </div>
              <div>
                <label>
                  6) Você toma atualmente algum tipo de medicação de uso contínuo? (Para sua pressão arterial ou doença cardíaca)
                </label>
                <Option name="pq06" options={options} />
              </div>
              <div>
                <label>7) Você realiza algum tipo de tratamento médico para pressão arterial ou problemas cardíacos?</label>
                <Option name="pq07" options={options} />
              </div>
              <div>
                <label>
                  8) Você realiza algum tratamento médico contínuo, que possa ser afetado ou prejudicado com a atividade física?
                </label>
                <Option name="pq08" options={options} />
              </div>
              <div>
                <label>9) Você já se submeteu a algum tipo de cirurgia, que comprometa de alguma forma a atividade física?</label>
                <Option name="pq09" options={options} />
              </div>
              <div>
                <label>10) Sabe de alguma outra razão pela qual a atividade física possa eventualmente comprometer sua saúde?</label>
                <Option name="pq10" options={options} />
              </div>
              <div>
                <Checkbox
                  name="check1"
                  label="Confirmo a veracidade dos dados aqui apresentados e me responsabilizo pelos mesmos, sabendo que são de fundamental importância para minha avaliação física."
                  defaultChecked={false}
                />
                <Checkbox
                  name="check2"
                  label="Declaro que estou ciente de que é recomendável conversar com um médico, antes de iniciar ou aumentar o nível de atividade física pretendido, assumindo plena responsabilidade pela realização de qualquer atividade física sem o atendimento desta recomendação."
                  defaultChecked={false}
                />
              </div>
              <Button loading={loading} disabled={loading} type="submit">
                Gravar
              </Button>
              <div style={{ visibility: 'hidden' }}>
                <Input name="aluno" type="number" />
              </div>
            </Form>
            <Modal visible={alerta} close={handleClose}>
              <p style={{ justifyContent: 'center', marginBottom: 20 }}>Necessário Atestado Médico</p>
              <ButtonNav>
                <Link to={`/cliente/${alunoid}/questionarios`}>
                  <FiChevronLeft size={20} />
                  <span>Sair</span>
                </Link>
                <Link to={`/cliente/${alunoid}/atestado/0`}>
                  <span>Incluir atestado</span>
                  <FiChevronRight size={20} />
                </Link>
              </ButtonNav>
            </Modal>
          </Content>
        </Main>
      </Container>
    </>
  );
};

export default Parq;
