import React, { useRef, useState, useEffect } from 'react';

import { Container, Titulo } from './styles';
import Button from '../../components/Button';
import api from '../../services/api';
import Checkbox from '../../components/Checkbox';
import { Form } from '@unform/web';
import { FormHandles } from '@unform/core';
import { useAuth } from '../../hooks/auth';

const Exportar: React.FC = () => {
  const { role } = useAuth();
  const [loading1, setloading1] = useState(false);
  const [loading2, setloading2] = useState(false);
  const [loading3, setloading3] = useState(false);
  const [loading4, setloading4] = useState(false);
  const [loading5, setloading5] = useState(false);
  const [loading6, setloading6] = useState(false);
  const [agree1, setAgree1] = useState(false);
  const [agree2, setAgree2] = useState(false);
  const [visible, setVisible] = useState(false);

  const formRef = useRef<FormHandles>(null);

  const handleSubmit = () => ({});

  useEffect(() => {
    const view = ['Admin', 'Coordenador'].includes(role);
    setVisible(view);
  }, []);

  const getRelGrupo = () => {
    setloading1(true);
    api
      .get('exportar/grupo', { responseType: 'blob' })
      .then((res) => {
        const blob = res.data;
        const url = window.URL.createObjectURL(blob);
        const a = document.createElement('a');
        a.style.display = 'none';
        a.href = url;
        // the filename you want
        a.download = 'Grupo.xlsx';
        document.body.appendChild(a);
        a.click();
        window.URL.revokeObjectURL(url);
        alert('Download concluido!');
        setloading1(false);
      })
      .catch((err) => {
        console.log(err);
        alert(`Erro ao Gerar relatorio`);
        setloading1(false);
      });
  };

  const getRelNF = () => {
    setloading2(true);
    api
      .get('exportar/nf', { responseType: 'blob' })
      .then((res) => {
        const blob = res.data;
        const url = window.URL.createObjectURL(blob);
        const a = document.createElement('a');
        a.style.display = 'none';
        a.href = url;
        // the filename you want
        a.download = 'NF.xlsx';
        document.body.appendChild(a);
        a.click();
        window.URL.revokeObjectURL(url);
        alert('Download concluido!');
        setloading2(false);
      })
      .catch((err) => {
        console.log(err);
        alert(`Erro ao Gerar relatorio`);
        setloading2(false);
      });
  };

  const getRelColetivo = () => {
    setloading3(true);
    api
      .get('exportar/coletivo', { responseType: 'blob' })
      .then((res) => {
        const blob = res.data;
        const url = window.URL.createObjectURL(blob);
        const a = document.createElement('a');
        a.style.display = 'none';
        a.href = url;
        // the filename you want
        a.download = 'Coletivo.xlsx';
        document.body.appendChild(a);
        a.click();
        window.URL.revokeObjectURL(url);
        alert('Download concluido!');
        setloading3(false);
      })
      .catch((err) => {
        console.log(err);
        alert(`Erro ao Gerar relatorio`);
        setloading3(false);
      });
  };

  const getRelIndividual = () => {
    setloading4(true);
    api
      .get('exportar/individual', { responseType: 'blob' })
      .then((res) => {
        const blob = res.data;
        const url = window.URL.createObjectURL(blob);
        const a = document.createElement('a');
        a.style.display = 'none';
        a.href = url;
        // the filename you want
        a.download = 'Individual.xlsx';
        document.body.appendChild(a);
        a.click();
        window.URL.revokeObjectURL(url);
        alert('Download concluido!');
        setloading4(false);
      })
      .catch((err) => {
        console.log(err);
        alert(`Erro ao Gerar relatorio`);
        setloading4(false);
      });
  };

  const getRelContas = () => {
    setloading5(true);
    api
      .get('exportar/contas-incompletas', { responseType: 'blob' })
      .then((res) => {
        const blob = res.data;
        const url = window.URL.createObjectURL(blob);
        const a = document.createElement('a');
        a.style.display = 'none';
        a.href = url;
        // the filename you want
        a.download = 'Contas.xlsx';
        document.body.appendChild(a);
        a.click();
        window.URL.revokeObjectURL(url);
        alert('Download concluido!');
        setloading5(false);
      })
      .catch((err) => {
        console.log(err);
        alert(`Erro ao Gerar relatorio`);
        setloading5(false);
      });
  };

  const getRelGdlam = () => {
    setloading6(true);
    api
      .get('exportar/gdlam', { responseType: 'blob' })
      .then((res) => {
        const blob = res.data;
        const url = window.URL.createObjectURL(blob);
        const a = document.createElement('a');
        a.style.display = 'none';
        a.href = url;
        // the filename you want
        a.download = 'Gdlam.xlsx';
        document.body.appendChild(a);
        a.click();
        window.URL.revokeObjectURL(url);
        alert('Download concluido!');
        setloading6(false);
      })
      .catch((err) => {
        console.log(err);
        alert(`Erro ao Gerar relatorio`);
        setloading6(false);
      });
  };

  return (
    <Container>
      <Titulo>Exportar</Titulo>
      <div>
        {visible && (
          <div>
            Grupo
            <Button onClick={getRelGrupo} disabled={!agree1 && !agree2} loading={loading1}>
              Download
            </Button>
          </div>
        )}
        {role === 'Admin' && (
          <div>
            Emissão de NF
            <Button onClick={getRelNF} disabled={!agree1 && !agree2} loading={loading2}>
              Download
            </Button>
          </div>
        )}
        {visible && (
          <div>
            Relatório Coletivo
            <Button onClick={getRelColetivo} disabled={!agree1 && !agree2} loading={loading3}>
              Download
            </Button>
          </div>
        )}
        <div>
          Relatório Individual
          <Button onClick={getRelIndividual} disabled={!agree1 && !agree2} loading={loading4}>
            Download
          </Button>
        </div>
        <div>
          Relatório Gdlam
          <Button onClick={getRelGdlam} disabled={!agree1 && !agree2} loading={loading6}>
            Download
          </Button>
        </div>
        {role === 'Admin' && (
          <div>
            Relatório de Contas Incompletas
            <Button onClick={getRelContas} disabled={!agree1 && !agree2} loading={loading5}>
              Download
            </Button>
          </div>
        )}
      </div>
      <div>
        <div>
          <Form
            ref={formRef}
            onSubmit={handleSubmit}
            placeholder={undefined}
            onPointerEnterCapture={undefined}
            onPointerLeaveCapture={undefined}
          >
            <div>
              <Checkbox name="agree1" onChange={(e) => setAgree1(e.target.checked)} />
              Estou ciente que as informações de saúde do aluno estão protegidas pela Lei Federal 13.709/2018.
            </div>
            {agree1}
            <div>
              <Checkbox name="agree2" onChange={(e) => setAgree2(e.target.checked)} />
              Concordo em utilizá-la, apenas, para uso profissional e em benefício do aluno. O compartilhamento sem prévio consentimento do
              aluno, sobretudo fora do contexto da saúde, será de responsabilidade única e exclusiva do profissional responsável.
            </div>
            {agree2}
          </Form>
        </div>
      </div>
    </Container>
  );
};

export default Exportar;
