import React from 'react';
import { Content } from './styles';

const AcessoNegado: React.FC = () => {
  return (
    <Content>
      {/* <img src={image404} alt="Not Found" /> */}
      <div>
        <p>Acesso negado, usuário bloqueado!</p>
        <p>Procure o adminstrador para liberar o acesso!</p>
      </div>
    </Content>
  );
};

export default AcessoNegado;
