import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  > input {
    flex-shrink: 0;
    position: relative;
    width: 20px;
    height: 20px;
    margin-right: 10px;
    appearance: none;
    padding: 0px;
    background: #fff;
    border: 1px solid #d1d1d1;
    border-radius: 4px;
    cursor: pointer;
    transition: all 0.2s ease 0s;
    overflow: hidden;
    /* opacity: 0;
    width: 0;
    height: 0; */
  }

  input:checked {
    background: #1171ef;
    border-color: #1171ef;
  }

  label {
    color: #525f7f;
    font-weight: 500;
  }

  span {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #ccc;
    -webkit-transition: 0.4s;
    transition: 0.4s;
    border-radius: 34px;

    ::before {
      position: absolute;
      content: '';
      height: 26px;
      width: 26px;
      left: 4px;
      bottom: 4px;
      background-color: white;
      -webkit-transition: 0.4s;
      transition: 0.4s;
      border-radius: 50%;
    }
  }

  & + div {
    /* margin-left: 10px; */
  }
`;
