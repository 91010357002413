import { shade } from 'polished';
import styled from 'styled-components';
import { device } from '../../styles/global';

export const Container = styled.div`
  max-width: 1120px;
  margin: 64px auto;
  display: flex;
`;

export const Content = styled.main`
  flex: 1;
  width: 100%;

  @media ${device.mobileL_Max} {
    padding: 5px;
  }
`;

export const Title = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 20px;

  h1 {
    color: #525f7f;
  }

  a {
    display: flex;
    flex-direction: row;
    justify-content: center;
    max-width: 15%;
    max-height: 35px;
    display: flex;
    text-align: center;
    background: #1171ef;
    height: 56px;
    border-radius: 10px;
    border: 0;
    padding: 0 16px;
    color: #ffffff;
    text-decoration: none;
    width: 100%;
    font-weight: 500;
    margin-left: 10px;
    transition: background-color 0.2s;

    &:hover {
      background: ${shade(0.2, '#1171ef')};
    }

    > * {
      margin: auto 0;
    }
  }
`;

export const Section = styled.section`
  margin-top: 48px;
  /* width: 350px; */

  > strong {
    color: #999591;
    font-size: 20px;
    line-height: 26px;
    border-bottom: 1px solid #3e3b47;
    display: block;
    padding-bottom: 16px;
    margin-bottom: 16px;
  }

  > div {
    margin-top: 10px;
  }
`;

export const Section2 = styled.section`
  margin-top: 48px;
  width: 600px;

  > strong {
    color: #999591;
    font-size: 20px;
    line-height: 26px;
    border-bottom: 1px solid #3e3b47;
    display: block;
    padding-bottom: 16px;
    margin-bottom: 16px;
  }
`;

export const Flex = styled.div`
  display: flex;
  flex-wrap: wrap;
`;
