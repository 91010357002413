import styled from 'styled-components';
import { shade } from 'polished';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  -webkit-box-align: center;
  align-items: center;
  min-height: 100vh;

  > div {
    flex: 1 1 0%;
    display: flex;
    flex-direction: column;
    -webkit-box-align: center;
    align-items: center;
    width: 100%;
    max-width: 1000px;
    padding: 32px;

    > div {
      flex: 1 1 0%;
      display: flex;
      -webkit-box-pack: center;
      justify-content: center;
      -webkit-box-align: center;
      align-items: center;
      width: 100%;
      padding: 28px 0px 50px;

      > div {
        width: 100%;
        display: flex;
        -webkit-box-pack: justify;
        justify-content: space-between;

        > div {
          display: flex;
          flex-direction: column;
          align-self: center;
          width: 100%;
          max-width: 480px;

          h1 {
            color: #525f7f;
            margin-bottom: 24px;
            font-size: 54px;
            line-height: 64px;

            @media only screen and (max-width: 900px) {
              font-size: 50px;
            }
          }
        }

        form {
          width: 100%;
          max-width: 480px;
          background: #202024;
          border-radius: 5px;
          padding: 64px;
          display: flex;
          flex-direction: column;

          @media only screen and (max-width: 900px) {
            padding: 20px;
          }

          section {
            display: grid;
            grid-auto-flow: row;
            gap: 10px;
          }

          > div {
            display: flex;
            justify-content: space-between;

            a {
              color: #f4ede8;
              display: block;
              margin-top: 24px;
              text-decoration: none;
              transition: color 0.2s;

              &:hover {
                color: ${shade(0.2, '#F4EDE8')};
              }
            }
          }
        }

        @media only screen and (max-width: 900px) {
          flex-direction: column;
        }
      }
    }
  }
`;

export const Img = styled.img`
  margin-right: 135px;

  @media only screen and (max-width: 900px) {
    margin: auto;
    height: 130px;
  }
`;
