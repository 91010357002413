import React, { useCallback, useEffect, useRef, useState } from 'react';
import { Container, Content, Schedule, Section, InputGroup } from './styles';

import { Form } from '@unform/web';
import { FormHandles } from '@unform/core';
import * as Yup from 'yup';

import { useToast } from '../../../hooks/toast';
import Button from '../../../components/Button';
import InputMask from '../../../components/InputMask';
import api from '../../../services/api';
import Modal from '../../../components/Modal';

const RecuperarAluno: React.FC = () => {
  const { addToast } = useToast();
  const formRef = useRef<FormHandles>(null);
  const [loading, setLoading] = useState(false);
  const [aluno, setAluno] = useState<any>(null);
  const [cpf, setCPF] = useState('');
  const [modal, setModal] = useState(false);

  useEffect(() => {
    const value = cpf.replace(/\D/g, '');
    if (value.length < 11) return;
    setLoading(true);
    api.get(`alunos/recuperar/${cpf}`).then((response) => {
      console.log(response.data);
      setAluno(response.data);
      setLoading(false);
    });
  }, [cpf]);

  const getCPF = () => {
    const cpfValue = formRef.current?.getFieldValue('cpf');
    setCPF(cpfValue);
  };

  const handleSubmit = useCallback(
    async (data: any) => {
      try {
        formRef.current?.setErrors({});
        const schema = Yup.object().shape({
          cpf: Yup.string().required('CPF obrigatorio'),
        });

        await schema.validate(data, {
          abortEarly: false,
        });

        setLoading(true);

        console.log(data);

        await api.post(`alunos/recuperar/${data.cpf}`).then(() => {
          addToast({
            type: 'success',
            title: 'Recuperado com sucesso!',
          });
        });

        setLoading(false);
        formRef.current?.reset();
      } catch (err: any) {
        setLoading(false);
        console.log(err);
        // const errors = getValidationErrors(err);
        // formRef.current?.setErrors(errors);
        addToast({
          type: 'error',
          title: 'Erro ao recuperar aluno',
        });
      }
    },
    // eslint-disable-next-line
    [addToast]
  );

  return (
    <>
      {/* <Header /> */}
      <Container>
        <Content>
          <Schedule>
            <h1>Recuperar aluno</h1>
            <Form
              ref={formRef}
              onSubmit={handleSubmit}
              placeholder={undefined}
              onPointerEnterCapture={undefined}
              onPointerLeaveCapture={undefined}
            >
              <Section>
                {/* <strong></strong> */}
                <InputGroup>
                  <InputMask name="cpf" placeholder="CPF" mask={'999.999.999-99'} onChange={getCPF} />
                </InputGroup>
              </Section>
              {aluno && (
                <div style={{ marginTop: 20 }}>
                  <div style={{ display: 'flex' }}>
                    <div style={{ marginRight: 10, fontWeight: 500 }}>Nome:</div>
                    <div>
                      {aluno.nome} {aluno.sobrenome}
                    </div>
                  </div>
                  <div style={{ display: 'flex' }}>
                    <div style={{ marginRight: 10, fontWeight: 500 }}>E-mail:</div>
                    <div>{aluno.email}</div>
                  </div>
                  {aluno.ativo && aluno.professor > 0 && (
                    <div style={{ display: 'flex' }}>
                      <div style={{ marginRight: 10, fontWeight: 500, color: 'red' }}>
                        Cliente já vinculado a outro professor deseja continuar?
                      </div>
                    </div>
                  )}
                </div>
              )}
              <Button loading={loading} disabled={!aluno} type="submit">
                Recuperar
              </Button>
              <Modal visible={modal} close={() => setModal(false)}>
                <p style={{ justifyContent: 'center', marginBottom: 20 }}>EXCLUIR</p>
                <div>
                  <p style={{ justifyContent: 'center' }}>Deseja realmente excluir o aluno?</p>
                  <p style={{ justifyContent: 'center' }}>Essa operação não pode ser desfeita</p>
                  <Button onClick={() => setModal(false)}>Confirmar</Button>
                </div>
              </Modal>
            </Form>
          </Schedule>
        </Content>
      </Container>
    </>
  );
};

export default RecuperarAluno;
