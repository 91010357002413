import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { getStorage, ref, uploadBytes } from 'firebase/storage';
import { Form } from '@unform/web';
import { FormHandles } from '@unform/core';
import * as Yup from 'yup';

import { Container, Content, Schedule, Section, InputGroup } from './styles';
import { useToast } from '../../hooks/toast';
import Input from '../../components/Input';
import Button from '../../components/Button';
import Select from '../../components/Select';

import api from '../../services/api';
import FileInput from '../../components/FileInput';
import { app } from '../../utils/firebase';
import { DocumentoRequest } from '../../model/Requests';
import Option from '../../components/Option';

const categorias = [
  {
    id: '1',
    value: '1',
    label: 'Documento',
    name: 'Documento',
  },
  {
    id: '2',
    value: '2',
    label: 'Video',
    name: 'Video',
  },
  {
    id: '3',
    value: '3',
    label: 'Audio',
    name: 'Audio',
  },
];

const firebaseUrl = (documento: any) => {
  if (documento.tipo === 1) {
    return `https://firebasestorage.googleapis.com/v0/b/atitude-vida-saudavel.appspot.com/o/documentos%2F${documento.url}?alt=media`;
  }
  if (documento.tipo === 3) {
    return `https://firebasestorage.googleapis.com/v0/b/atitude-vida-saudavel.appspot.com/o/audios%2F${documento.url}?alt=media`;
  }
};

const Documento: React.FC = () => {
  const { id } = useParams();
  const { addToast } = useToast();
  const navigate = useNavigate();
  const formRef = useRef<FormHandles>(null);
  const [grupos, setGrupos] = useState([]);
  const [alunos, setAlunos] = useState([]);
  const [loading, setLoading] = useState(false);
  const [tipo, setTipo] = useState(0);
  const [documento, setDocumento] = useState<any>({});
  const storage = getStorage(app);
  const [edit, setEdit] = useState(false);
  const [categoria, setCategoria] = useState(0);
  // const [confirmExcluir, setConfirmExcluir] = useState(false);

  useEffect(() => {
    api
      .get('grupos/all')
      .then((response) => {
        setGrupos(response.data);
      })
      .catch((error) => {
        addToast({
          type: 'error',
          title: error.message,
        });
      });
  }, [addToast]);

  useEffect(() => {
    api
      .get('alunos/all')
      .then((response) => {
        response.data.map((r: any) => ((r.value = r.id), (r.name = r.nomeCompleto)));
        setAlunos(response.data);
      })
      .catch((error) => {
        addToast({
          type: 'error',
          title: error.message,
        });
      });
  }, [addToast]);

  useEffect(() => {
    if (Number(id) > 0) {
      api.get(`documentos/${id}`).then((response) => {
        response.data.aluno ? (response.data.categoria = 2) : (response.data.categoria = 1);
        formRef.current?.setData(response.data);
        setDocumento(response.data);
        setTipo(response.data.tipo);
        formRef.current?.setFieldValue('url', response.data.url);
        setEdit(true);
      });
    }
  }, [id]);

  useEffect(() => {
    formRef.current?.setFieldValue('url', documento.url);
    formRef.current?.setFieldValue('aluno', documento.aluno);
    formRef.current?.setFieldValue('grupo', documento.grupo);
    setCategoria(documento.categoria);
  }, [documento, alunos, grupos]);

  const handleSubmit = useCallback(
    async (data: any) => {
      try {
        formRef.current?.setErrors({});
        const schema = Yup.object().shape({
          // nome: Yup.string().required('nome obrigatorio'),
          // categoria: Yup.string().required('Categoria obrigatorio'),
          // url: Yup.string().required('URL obrigatorio'),
        });

        await schema.validate(data, {
          abortEarly: false,
        });

        setLoading(true);
        let anexoName = '';
        if (data.tipo !== '2') {
          const file = data.documento;
          const time = new Date().getTime();
          // eslint-disable-next-line
          const folder = data.tipo == '1' ? 'documentos' : 'audios';
          anexoName = time + '_' + file.name;
          // setAnexo(anexoName);
          const storageRef = ref(storage, `${folder}/${anexoName}`);
          await uploadBytes(storageRef, file).then((snapshot) => {
            console.log('Uploaded a blob or file!');
          });
        }

        const request: DocumentoRequest = {
          nome: data.nome,
          url: data.tipo === '2' ? data.url : anexoName,
          tipo: data.tipo,
          categoria: data.categoria,
          grupo: data.grupo,
          aluno: data.aluno,
        };
        let urlvideo;
        if (request.tipo === '2') {
          if (request.url.includes('vimeo')) {
            const url = request.url.split('/')[3];
            urlvideo = `https://player.vimeo.com/video/${url}?title=0&byline=0&portrait=0`;
          } else {
            if (request.url.split('/')[2] === 'youtu.be') {
              urlvideo = `https://www.youtube.com/embed/${request.url.split('/')[3]}`;
            } else {
              urlvideo = request.url.replace('watch?v=', 'embed/');
            }
            request.url = urlvideo;
          }
        } else {
          urlvideo = request.url;
          request.url = urlvideo;
        }

        console.log(request);

        if (edit) {
          await api.put('documentos/' + id, request);
          addToast({
            type: 'success',
            title: 'Cadastro atualizado',
          });
          setLoading(false);
        } else {
          await api.post('documentos', request);
          addToast({
            type: 'success',
            title: 'Cadastro realizado',
          });

          setLoading(false);
          formRef.current?.reset();
          navigate(`/documentos`);
        }
      } catch (err: any) {
        setLoading(false);
        console.log(err);
        // const errors = getValidationErrors(err);
        // formRef.current?.setErrors(errors);
        let errMsg = err.response.data?.message;
        addToast({
          type: 'error',
          title: errMsg || 'Erro ao gravar cadastro',
        });
      }
    },
    [addToast, navigate, edit, id, storage]
  );

  return (
    <>
      {/* <Header /> */}
      <Container>
        <Content>
          <Schedule>
            <h1>Documento</h1>
            <Form
              ref={formRef}
              onSubmit={handleSubmit}
              initialData={{ id: id }}
              placeholder={undefined}
              onPointerEnterCapture={undefined}
              onPointerLeaveCapture={undefined}
            >
              <Section>
                <strong>Dados</strong>
                <InputGroup>
                  <Input name="nome" placeholder="Nome" />
                  <Select name="tipo" id="tipo" placeholder="Tipo" options={categorias} onChange={(e) => setTipo(Number(e.target.value))} />
                </InputGroup>
                <InputGroup style={{ display: 'flex', justifyContent: 'space-between' }}>
                  <Option
                    name="categoria"
                    onChange={(e) => setCategoria(Number(e.target.value))}
                    options={[
                      { id: '1', value: 1, label: 'Grupo' },
                      { id: '2', value: 2, label: 'Individual' },
                    ]}
                  />
                </InputGroup>
                <InputGroup>
                  {categoria === 1 && <Select name="grupo" placeholder="Grupo" id="grupo" options={grupos} />}
                  {categoria === 2 && <Select name="aluno" placeholder="Aluno" id="aluno" options={alunos} />}
                </InputGroup>
                <InputGroup>
                  {tipo === 2 ? <Input name="url" placeholder="youtube ou vimeo url" /> : <FileInput name="documento" />}
                </InputGroup>
                {edit && (
                  <div style={{ marginTop: 30 }}>
                    {documento.tipo === 1 && (documento.url.includes('jpg') || documento.url.includes('png')) && (
                      <img src={firebaseUrl(documento)} alt="Preview" width={1000} height={1000} style={{ margin: 'auto' }} />
                    )}

                    {documento.tipo === 1 && documento.url.includes('pdf') && (
                      <object data={firebaseUrl(documento)} width={600} height={600} style={{ margin: 'auto' }}>
                        pdf
                      </object>
                    )}
                    {documento.tipo === 2 && (
                      <div>
                        {documento.url.includes('youtu') ? (
                          <iframe
                            src={documento.url}
                            width="626"
                            height="440"
                            allow="autoplay; fullscreen; picture-in-picture"
                            style={{ margin: 'auto' }}
                            title={documento.nome}
                          ></iframe>
                        ) : documento.url.includes('player.vimeo') ? (
                          <iframe
                            src={documento.url}
                            width="626"
                            height="440"
                            allow="autoplay; fullscreen; picture-in-picture"
                            style={{ margin: 'auto' }}
                            title={documento.nome}
                          ></iframe>
                        ) : (
                          <iframe
                            src={'https://player.vimeo.com/video/' + documento.url.split(`/`)[3] + '?h=' + documento.url.split(`/`)[4]}
                            width="626"
                            height="440"
                            allow="autoplay; fullscreen; picture-in-picture"
                            style={{ margin: 'auto' }}
                            title={documento.nome}
                          ></iframe>
                        )}
                      </div>
                    )}
                    {documento.tipo === 3 && (
                      <audio controls style={{ margin: 'auto' }}>
                        <source src={firebaseUrl(documento)} type="audio/wav" />
                        Your browser does not support the audio element.
                      </audio>
                    )}
                  </div>
                )}
              </Section>
              <div style={{ visibility: 'hidden' }}>
                <Input name="id" type="number" />
              </div>
              <Button loading={loading} type="submit">
                Gravar
              </Button>
            </Form>
          </Schedule>
        </Content>
      </Container>
    </>
  );
};

export default Documento;
