import React, { useCallback, useEffect, useRef, useState } from 'react';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { Form } from '@unform/web';
import { FormHandles } from '@unform/core';
import * as Yup from 'yup';

import { Container, Content, Schedule, Section, InputGroup, RowContainer, Row, Pagination, Page, AddContainer } from './styles';
import { useToast } from '../../hooks/toast';
import Input from '../../components/Input';
import Button from '../../components/Button';
import Select from '../../components/Select';

import api from '../../services/api';
import { estados } from '../CadastroAluno/apoio';
import { FiPlusCircle, FiTrash } from 'react-icons/fi';
import Modal from '../../components/Modal';
import { useAuth } from '../../hooks/auth';

const Grupo: React.FC = () => {
  const { id } = useParams();
  const { role } = useAuth();
  const { addToast } = useToast();
  const navigate = useNavigate();
  const formRef = useRef<FormHandles>(null);
  const subformRef = useRef<FormHandles>(null);
  const [loading, setLoading] = useState(false);
  const [empresas, setEmpresas] = useState([]);
  const [professores, setProfessores] = useState<any>([]);
  const [tab, setTab] = useState(1);
  const [list, setList] = useState([]);
  const [sublist, setSubList] = useState([]);
  const [pages, setPages] = useState(1);
  const [currentPage, setCurrentPage] = useState(1);
  const [edit, setEdit] = useState(false);
  const [modal, setModal] = useState(false);
  const [refresh, setRefresh] = useState(0);

  useEffect(() => {
    setLoading(true);
    api
      .get(`empresas/all`)
      .then((response) => {
        // eslint-disable-next-line
        response.data.map((r: any) => ((r.value = r.id), (r.name = r.nome)));
        setEmpresas(response.data);
        setLoading(false);
      })
      .then(() => {
        if (Number(id) > 0) {
          api.get(`grupos/${id}`).then((response) => {
            formRef.current?.setData(response.data);
            // formRef.current?.setFieldValue('empresa', response.data.empresa.toString());
            setEdit(true);
          });
        }
      })
      .then(() => {
        api.get(`alunos/grupo/${id}`).then((resp) => {
          setList(resp.data.list);
          setCurrentPage(resp.data.currentPage);
          setPages(resp.data.pages);
          setLoading(false);
        });
      })
      .catch((error) => {
        setLoading(false);
        addToast({
          type: 'error',
          title: error.message,
        });
      });
  }, [addToast, id]);

  useEffect(() => {
    api
      .get('professores/all')
      .then((response) => {
        // eslint-disable-next-line
        response.data.map((r: any) => ((r.value = r.id), (r.name = `${r.nome} ${r.sobrenome}`.trim())));
        setProfessores(response.data);
      })
      .catch((error) => {
        addToast({
          type: 'error',
          title: error.message,
        });
      });
  }, [addToast]);

  useEffect(() => {
    setLoading(true);
    const url = tab === 2 ? `professores` : `alunos`;
    api
      .get(`${url}/grupo/${id}`)
      .then((resp) => {
        setList(resp.data.list);
        setCurrentPage(resp.data.currentPage);
        setPages(resp.data.pages);
        setLoading(false);
      })
      .catch((error) => {
        setLoading(false);
        addToast({
          type: 'error',
          title: error.message,
        });
      });
  }, [tab, addToast, id, refresh]);

  const handleSubmit = useCallback(
    async (data: any) => {
      try {
        formRef.current?.setErrors({});
        const schema = Yup.object().shape({
          // nome: Yup.string().required('nome obrigatorio'),
          // categoria: Yup.string().required('Categoria obrigatorio'),
          // url: Yup.string().required('URL obrigatorio'),
        });

        await schema.validate(data, {
          abortEarly: false,
        });

        setLoading(true);
        console.log(data);

        if (edit) {
          await api.put('grupos/' + id, data);
          addToast({
            type: 'success',
            title: 'Cadastro atualizado',
          });
          setLoading(false);
        } else {
          await api.post('grupos', data);
          addToast({
            type: 'success',
            title: 'Cadastro realizado',
          });

          setLoading(false);
          formRef.current?.reset();
          navigate(`/grupos`);
        }
      } catch (err: any) {
        setLoading(false);
        console.log(err);
        // const errors = getValidationErrors(err);
        // formRef.current?.setErrors(errors);
        addToast({
          type: 'error',
          title: 'Erro ao gravar cadastro',
        });
      }
    },
    [addToast, navigate, edit, id]
  );

  const handleGotoPage = (_page: number) => {
    if (_page > pages) return;
    setLoading(true);
    const url = tab === 2 ? `professores` : `alunos`;
    api
      .get(`${url}/grupo/${id}?&PageNumber=${_page}`)
      // .get(`grupos?&PageNumber=${_page}`)
      .then((resp) => {
        setList(resp.data.list);
        setCurrentPage(resp.data.currentPage);
        setPages(resp.data.pages);
        setLoading(false);
      })
      .catch((error) => {
        setLoading(false);
      });
  };

  const HandleAdd = () => {
    setModal(true);
    const url = tab === 1 ? 'alunos' : 'professores';
    api
      .get(`${url}/all`)
      .then((resp) => {
        // eslint-disable-next-line
        resp.data.map((r: any) => ((r.value = r.id), (r.name = `${r.nome} ${r.sobrenome}`.trim())));
        setSubList(resp.data);
        setLoading(false);
      })
      .catch((error) => {
        setLoading(false);
        console.log(error);
      });
  };
  const HandleRemove = (a: any) => {
    const tipo = tab === 1 ? 'aluno' : 'professor';

    api
      .delete(`grupos/vinculo/${a.id}/${tipo}`)
      .then(() => {
        setRefresh(refresh + 1);
        addToast({
          type: 'success',
          title: 'Excluido com sucesso',
        });
      })
      .catch((error) => {
        addToast({
          type: 'error',
          title: 'Ocorreu um error ao excluir vincular',
        });
      });
  };

  const handleCloseModal = () => {
    setModal(false);
  };

  const confirmAdd = () => {
    const pessoa = subformRef.current?.getFieldValue('pessoa');
    const data = {
      grupo: id,
      aluno: pessoa,
      professor: pessoa,
    };
    const url = tab === 1 ? 'vincular' : 'vincularprofessor';
    api
      .post(`grupos/${url}`, data)
      .then(() => {
        addToast({
          type: 'success',
          title: 'Vinculado com sucesso',
        });
      })
      .catch(() => {
        addToast({
          type: 'error',
          title: 'Ocorreu um error ao vincular o aluno',
        });
      });
  };

  return (
    <>
      {/* <Header /> */}
      <Container>
        <Content>
          <Schedule>
            <h1>Grupo</h1>
            <Form
              ref={formRef}
              onSubmit={handleSubmit}
              initialData={{ id: id }}
              placeholder={undefined}
              onPointerEnterCapture={undefined}
              onPointerLeaveCapture={undefined}
            >
              <Section>
                <strong>Dados</strong>
                <InputGroup>
                  <Select name="empresa" placeholder="Empresa" id="empresa" options={empresas} />
                  <Select name="estado" id="estado" placeholder="Estado" options={estados} />
                </InputGroup>
                <Input name="nome" placeholder="Nome" />
                <InputGroup></InputGroup>
                <Select name="professor" placeholder="Professor" id="empresa" options={professores} />
              </Section>
              <div style={{ visibility: 'hidden' }}>
                <Input name="id" type="number" />
              </div>
              {role !== 'Professor' && (
                <Button loading={loading} type="submit">
                  Gravar
                </Button>
              )}
            </Form>
            {Number(id) > 0 && (
              <div>
                <ul className="mb-5 flex list-none flex-col flex-wrap border-b-0 pl-0 md:flex-row">
                  <li role="presentation" className="flex-auto text-center">
                    <Link
                      to={'#'}
                      className={`${
                        tab === 1 ? 'bg-blue-700' : 'bg-blue-500'
                      } my-2 block border-x-0 border-t-0 border-b-2 border-transparent px-7 pt-4 pb-3.5 text-xs font-medium uppercase leading-tight text-white hover:isolate hover:border-transparent hover:bg-blue-300 focus:isolate focus:border-transparent data-[te-nav-active]:border-primary data-[te-nav-active]:text-primary dark:text-neutral-400 dark:hover:bg-transparent dark:data-[te-nav-active]:border-primary-400 dark:data-[te-nav-active]:text-primary-400`}
                      onClick={() => setTab(1)}
                    >
                      Clientes
                    </Link>
                  </li>
                  {role !== 'Professor' && (
                    <li role="presentation" className="flex-auto text-center">
                      <Link
                        to={'#'}
                        className={`${
                          tab === 2 ? 'bg-blue-700' : 'bg-blue-500'
                        } my-2 block border-x-0 border-t-0 border-b-2 border-transparent px-7 pt-4 pb-3.5 text-xs font-medium uppercase leading-tight text-white hover:isolate hover:border-transparent hover:bg-blue-300 focus:isolate focus:border-transparent data-[te-nav-active]:border-primary data-[te-nav-active]:text-primary dark:text-neutral-400 dark:hover:bg-transparent dark:data-[te-nav-active]:border-primary-400 dark:data-[te-nav-active]:text-primary-400`}
                        onClick={() => setTab(2)}
                      >
                        Professores
                      </Link>
                    </li>
                  )}
                </ul>
                <div className="mb-6">
                  <div className={`${tab !== 0 ? 'block' : 'hidden'} transition-opacity duration-150 ease-linear`}>
                    <AddContainer>
                      <Button onClick={HandleAdd}>
                        <div>
                          <FiPlusCircle size={20} />
                          <span>Novo</span>
                        </div>
                      </Button>
                    </AddContainer>
                    <RowContainer>
                      {list.map((a: any, i: number) => (
                        <Row key={a.id}>
                          <div>
                            <strong>
                              {a.nome} {a.sobrenome}
                            </strong>
                          </div>
                          <div onClick={() => HandleRemove(a)}>
                            <FiTrash size={25} color={'#ef1111'} />
                          </div>
                        </Row>
                      ))}
                    </RowContainer>
                    <Pagination>
                      <Page onClick={() => handleGotoPage(currentPage - 1)}>Anterior</Page>
                      {Array.from({ length: pages }, (_, i) => (
                        <Page key={i} selected={i + 1 === currentPage} onClick={() => handleGotoPage(i + 1)}>
                          <div>{i + 1}</div>
                        </Page>
                      ))}
                      <Page onClick={() => handleGotoPage(currentPage + 1)}>Próximo</Page>
                    </Pagination>
                  </div>
                  <div className={`${tab === 3 ? 'block' : 'hidden'} transition-opacity duration-150 ease-linear`}>Tab 2 content</div>
                </div>
              </div>
            )}
          </Schedule>
          <Modal visible={modal} close={handleCloseModal}>
            <p style={{ justifyContent: 'center', marginBottom: 20 }}>Alunos</p>
            <div style={{ display: 'flex', justifyContent: 'space-around', flexWrap: 'wrap' }}>
              <Form
                ref={subformRef}
                onSubmit={() => ({})}
                placeholder={undefined}
                onPointerEnterCapture={undefined}
                onPointerLeaveCapture={undefined}
              >
                <Select name="pessoa" placeholder="Todos" options={sublist} />
              </Form>
              <Button onClick={() => confirmAdd()}>Confirmar</Button>
            </div>
          </Modal>
        </Content>
      </Container>
    </>
  );
};

export default Grupo;
