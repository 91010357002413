/* eslint-disable jsx-a11y/anchor-is-valid */

import { FormHandles } from '@unform/core';
import { Form } from '@unform/web';
import React, { useEffect, useRef, useState } from 'react';
import { FiSearch } from 'react-icons/fi';
import Input from '../../../components/Input';
import Modal from '../../../components/Modal';

import Spinner from '../../../components/Spinner';
import { useToast } from '../../../hooks/toast';
import api from '../../../services/api';

import { Container, Content, ItemContainer, Item, Schedule, Page, Pagination, Title, Search, ModalContent, ItemTitle } from './styles';

const VideoListUser: React.FC = () => {
  const formRef = useRef<FormHandles>(null);
  const { addToast } = useToast();
  const [loading, setLoading] = useState(false);
  const [list, setList] = useState<any>([]);
  const [pages, setPages] = useState(1);
  const [currentPage, setCurrentPage] = useState(1);
  const [text, setText] = useState<string>('');
  const [modal, setModal] = useState(false);
  const [video, setVideo] = useState<any>(null);

  useEffect(() => {
    setLoading(true);
    api
      .get('videos?PageSize=12&Categoria=2')
      .then((response) => {
        setList(response.data.list);
        setCurrentPage(response.data.currentPage);
        setPages(response.data.pages);
        setLoading(false);
      })
      .catch((error) => {
        setLoading(false);
        addToast({
          type: 'error',
          title: error.message,
        });
      });
  }, [addToast]);

  const handleGotoPage = (_page: number) => {
    if (_page > pages) return;
    setLoading(true);
    api
      .get(`videos?PageSize=12&Categoria=2&PageNumber=${_page}`)
      .then((resp) => {
        setList(resp.data.list);
        setCurrentPage(resp.data.currentPage);
        setPages(resp.data.pages);
        setLoading(false);
      })
      .catch((error) => {
        setLoading(false);
      });
  };

  const handleFilter = (e: React.ChangeEvent<HTMLInputElement>) => {
    setText(e.target.value || '');
  };

  const handleSearch = (e: any) => {
    if (e.key === 'Enter') {
      setLoading(true);
      const query = text ? `&Text=${text}` : '';
      api
        .get(`videos?PageSize=12&&Categoria=2PageNumber=${1}${query}`)
        .then((resp) => {
          setList(resp.data.list);
          setCurrentPage(resp.data.currentPage);
          setPages(resp.data.pages);
          setLoading(false);
        })
        .catch((error) => {
          setLoading(false);
        });
    }
  };

  const showModal = (item: any) => {
    setVideo(item);
    setModal(true);
  };

  const handleSubmit = (e: any) => handleSearch(e);

  return (
    <>
      <Container>
        {/* <Header /> */}
        <Content>
          <Schedule>
            <Title>
              <h1>Tutoriais</h1>
            </Title>
            <Form
              ref={formRef}
              onSubmit={handleSubmit}
              placeholder={undefined}
              onPointerEnterCapture={undefined}
              onPointerLeaveCapture={undefined}
            >
              <Search>
                <Input name="nome" placeholder="Buscar" icon={FiSearch} onChange={handleFilter} onKeyDown={handleSearch} />
              </Search>
            </Form>
            {!loading ? (
              <>
                <ItemContainer>
                  {list.map((a: any, i: number) => (
                    <Item key={a.id}>
                      <div>
                        <div>
                          {/* <div>
                            <button>
                              <svg
                                stroke="currentColor"
                                fill="currentColor"
                                stroke-width="0"
                                viewBox="0 0 512 512"
                                height="20"
                                width="20"
                                xmlns="http://www.w3.org/2000/svg"
                              >
                                <circle cx="256" cy="256" r="32" fill="none" stroke-miterlimit="10" stroke-width="32"></circle>
                                <circle cx="256" cy="416" r="32" fill="none" stroke-miterlimit="10" stroke-width="32"></circle>
                                <circle cx="256" cy="96" r="32" fill="none" stroke-miterlimit="10" stroke-width="32"></circle>
                              </svg>
                            </button>
                          </div> */}
                          <button onClick={() => showModal(a)}>
                            <span>
                              <span>
                                <img src={`https://vumbnail.com/${a.url.split('/')[3].split('?')[0]}.jpg`} alt={a.titulo} />
                              </span>
                            </span>
                          </button>
                        </div>
                        <ItemTitle>
                          <a href="#">
                            <h2>{a.titulo}</h2>
                          </a>
                        </ItemTitle>
                      </div>
                    </Item>
                  ))}
                </ItemContainer>
                <Pagination>
                  <Page onClick={() => handleGotoPage(currentPage - 1)}>Anterior</Page>
                  {Array.from({ length: pages }, (_, i) => (
                    <Page key={i} selected={i + 1 === currentPage} onClick={() => handleGotoPage(i + 1)}>
                      <div>{i + 1}</div>
                    </Page>
                  ))}
                  <Page onClick={() => handleGotoPage(currentPage + 1)}>Próximo</Page>
                </Pagination>
              </>
            ) : (
              <>
                <br />
                <Spinner />
              </>
            )}
          </Schedule>
        </Content>
        <Modal visible={modal} close={() => setModal(false)}>
          <ModalContent>
            {video && (
              <iframe
                src={'https://player.vimeo.com/video/' + video.url.split(`/`)[3]}
                width="626"
                height="440"
                allow="autoplay; fullscreen; picture-in-picture"
                style={{ margin: 'auto' }}
                title={formRef.current?.getFieldValue('titulo')}
              />
            )}
          </ModalContent>
        </Modal>
      </Container>
    </>
  );
};

export default VideoListUser;
