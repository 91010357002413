import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: auto;

  img {
    width: 500px;
  }

  p {
    font-size: 50px;
  }
`;
