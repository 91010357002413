/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { createContext, useContext, useCallback, useState } from 'react';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

// import { uuid } from 'uuidv4';

// import ToastContainer from '../components/ToastContainer';

type Props = {
  children: JSX.Element;
};

export interface ToastMessage {
  id: string;
  type?: 'success' | 'error' | 'info';
  title: string;
  // description?: string;
}

interface ToastContextData {
  addToast(message: Omit<ToastMessage, 'id'>): void;
  removeToast(id: string): void;
}

const ToastContext = createContext<ToastContextData>({} as ToastContextData);

const ToastProvider: React.FC<Props> = ({ children }) => {
  //@typescript-eslint/no-unused-vars
  const [messagens, setMessages] = useState<ToastMessage[]>([]);

  const addToast = useCallback(({ type, title }: Omit<ToastMessage, 'id'>) => {
    // const id = uuid();
    switch (type) {
      case 'success':
        toast.success(title);
        break;
      case 'error':
        toast.error(title);
        break;
      case 'info':
        toast.info(title);
        break;
      default:
        break;
    }
  }, []);

  const removeToast = useCallback((id: string) => {
    setMessages((state) => state.filter((message) => message.id !== id));
  }, []);

  return (
    <ToastContext.Provider value={{ addToast, removeToast }}>
      <ToastContainer />
      {children}
    </ToastContext.Provider>
  );
};

function useToast(): ToastContextData {
  const context = useContext(ToastContext);

  return context;
}

export { ToastProvider, useToast };
