import React from 'react';
import LayoutMenus from './LayoutMenus';
import NavLinks from './NavLinks';
const classLinkActive =
  'text-blue-600 bg-violet-100 border-r-4 border-blue-500 dark:bg-slate-700/[.2] dark:text-slate-200 dark:border-slate-200';

const Siderbar: React.FC = () => {
  //   const menuOpen = useAppSelector((state) => state.menu.menuHeaderOpened);
  const menuOpen = false;
  //   const dispatch = useAppDispatch();

  const closeMenuHandler = () => {
    // dispatch(menusActions.closeMenuHeader());
  };
  return (
    <LayoutMenus menuOpen={menuOpen} closeMenuHandler={closeMenuHandler} className="left-0">
      <header className="h-full flex flex-col">
        {/* eslint-disable-next-line */}
        <h1 className="font-bold uppercase text-center h-16 text-lg tracking-wide hidden xl:block" />
        <NavLinks classActive={classLinkActive} />
      </header>
    </LayoutMenus>
  );
};

export default Siderbar;
