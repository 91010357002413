import { format } from 'date-fns';
import React, { useEffect, useState } from 'react';
import { FiChevronLeft } from 'react-icons/fi';
import { Link, useParams } from 'react-router-dom';

import Spinner from '../../../components/Spinner';
import { useToast } from '../../../hooks/toast';
import api from '../../../services/api';
import { Title } from '../styles';

import { Container, Content, RowContainer, Row, Schedule, Page, Pagination } from './styles';

const AtestadoList: React.FC = () => {
  const { alunoid } = useParams();
  const { addToast } = useToast();
  const [loading, setLoading] = useState(false);
  const [list, setList] = useState<any>([]);
  const [pages, setPages] = useState(1);
  const [currentPage, setCurrentPage] = useState(1);
  //const [text, setText] = useState<string>('');

  useEffect(() => {
    setLoading(true);
    api
      .get('atestados/aluno?aluno=' + alunoid)
      .then((response) => {
        setList(response.data.list);
        setCurrentPage(response.data.currentPage);
        setPages(response.data.pages);
        setLoading(false);
      })
      .catch((error) => {
        setLoading(false);
        addToast({
          type: 'error',
          title: error.message,
        });
      });
  }, [alunoid, addToast]);

  const handleGotoPage = (_page: number) => {
    if (_page > pages) return;
    setLoading(true);
    api
      .get(`atestados/aluno?aluno=${alunoid}&PageNumber=${_page}`)
      .then((resp) => {
        setList(resp.data.list);
        setCurrentPage(resp.data.currentPage);
        setPages(resp.data.pages);
        setLoading(false);
      })
      .catch((error) => {
        setLoading(false);
      });
  };

  return (
    <>
      <Container>
        {/* <Header /> */}
        <Content>
          <Schedule>
            <Title>
              <h1>Atestado Médico</h1>
              <Link to={`/cliente/${alunoid}/questionarios`}>
                <FiChevronLeft size={20} />
                <span>Voltar</span>
              </Link>
            </Title>
            {!loading ? (
              <>
                <RowContainer>
                  {list.map((a: any, i: number) => (
                    <Row key={a.id}>
                      <div>
                        <div></div>
                        <strong>
                          <Link to={`/cliente/${alunoid}/atestado/${a.id}`}>{`Avaliação ${format(new Date(a.criado), 'dd/MM/yyyy')}`}</Link>
                        </strong>
                      </div>
                    </Row>
                  ))}
                </RowContainer>
                <Pagination>
                  <Page onClick={() => handleGotoPage(currentPage - 1)}>Anterior</Page>
                  {Array.from({ length: pages }, (_, i) => (
                    <Page key={i} selected={i + 1 === currentPage} onClick={() => handleGotoPage(i + 1)}>
                      <div>{i + 1}</div>
                    </Page>
                  ))}
                  <Page onClick={() => handleGotoPage(currentPage + 1)}>Próximo</Page>
                </Pagination>
              </>
            ) : (
              <>
                <br />
                <Spinner />
              </>
            )}
          </Schedule>
        </Content>
      </Container>
    </>
  );
};

export default AtestadoList;
