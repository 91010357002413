import React, { useEffect, useState } from 'react';
import api from '../../../services/api';
import _ from 'lodash';
import Video from '../video';
import { FaVideo } from 'react-icons/fa';
import Modal from '../../../components/Modal';

type Props = {
  id: number;
};

const View: React.FC<Props> = ({ id }) => {
  const [exercicios, setExercicios] = useState<any>([]);
  const [exerciciosaux, setExerciciosAux] = useState<any>([]);
  const [treino, setTreino] = useState<any>({});
  const [viewVideo, setViewVideo] = useState(false);
  const [video, setVideo] = useState<any>({});

  useEffect(() => {
    if (Number(id) > 0) {
      api
        .get(`treinos/${id}`)
        .then((response) => {
          setTreino(response.data);
        })
        .then(() => {
          api.get('exercicios/all').then((response) => {
            response.data.map((r: any) => (r.exercicio = r.id));
            setExerciciosAux(response.data);
          });
        });
    }
  }, [id]);

  useEffect(() => {
    if (_.isEmpty(treino) || exerciciosaux.length === 0) return;
    /* eslint no-eval: 0 */
    const exerciciosData = eval(treino.prescricao);
    exerciciosData.map((r: any) => (r.selected = true));
    exerciciosData.forEach((e: any) => {
      const item = exerciciosaux.find((a: any) => a.id === Number(e.id));
      if (!item) return;
      e.nome = item.nome;
      e.imagem = item.imagem;
      e.video = item.video;
    });
    setExercicios(exerciciosData);
  }, [treino, exerciciosaux]);

  const select = (item: any) => {
    setVideo(item);
    setViewVideo(true);
  };

  return (
    <div className="flex grid gap-2 grid-cols-1">
      {exercicios.map((c: any) => (
        <div
          key={c.id}
          className={`relative flex rounded-[20px] !p-4 3xl:p-![18px] bg-white transition hover:shadow-lg hover:shadow-slate-300 dark:bg-slate-800 dark:hover:shadow-transparent flex-col`}
        >
          <div className={`h-full w-full`}>
            <div className={`relative flex flex-row h-full w-full justify-around items-center text-center`}>
              <div className="h-auto w-72 mb-2">
                {c.imagem ? (
                  <img className="object-contain" src={firebaseUrl(c.imagem)} alt="exercicio" />
                ) : (
                  <Video url={c.video} nome={c.nome} />
                )}
              </div>
              <div className="flex flex-col items-start gap-y-2 w-2/3">
                <div className="font-bold flex">
                  {c.nome}
                  {c.video && <FaVideo color="#1171ef" style={{ cursor: 'pointer', marginLeft: 20 }} size={20} onClick={() => select(c)} />}
                </div>
                <div className="">{c.prescricao}</div>
                <div className="">{c.observacao}</div>
              </div>
            </div>
          </div>
        </div>
      ))}
      <Modal visible={viewVideo} close={() => setViewVideo(false)}>
        <Video url={video.video} nome={video.nome} />
      </Modal>
    </div>
  );
};

export default View;

const firebaseUrl = (nome: string) => `https://atitudevs.blob.core.windows.net/exercicios/${nome}`;
