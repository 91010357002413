export const classiC10 = (valor: number, idade: number) => {
  switch (true) {
    case valor < 5.52 && idade >= 60 && idade <= 64:
    case valor < 5.67 && idade >= 65 && idade <= 69:
    case valor < 5.83 && idade >= 70 && idade <= 74:
    case valor < 5.98 && idade >= 75 && idade <= 79:
    case valor < 6.14 && idade >= 80:
      return 'Muito Bom';
    case valor >= 5.52 && valor <= 7.04 && idade >= 60 && idade <= 64:
    case valor >= 5.67 && valor <= 7.21 && idade >= 65 && idade <= 69:
    case valor >= 5.83 && valor <= 7.38 && idade >= 70 && idade <= 74:
    case valor >= 5.98 && valor <= 7.56 && idade >= 75 && idade <= 79:
    case valor >= 6.14 && valor <= 7.73 && idade >= 80:
      return 'Bom';
    case valor >= 7.05 && valor <= 8.92 && idade >= 60 && idade <= 64:
    case valor >= 7.22 && valor <= 9.04 && idade >= 65 && idade <= 69:
    case valor >= 7.39 && valor <= 9.16 && idade >= 70 && idade <= 74:
    case valor >= 7.57 && valor <= 9.28 && idade >= 75 && idade <= 79:
    case valor >= 7.74 && valor <= 9.4 && idade >= 80:
      return 'Regular';
    case valor > 8.92 && idade >= 60 && idade <= 64:
    case valor > 9.04 && idade >= 65 && idade <= 69:
    case valor > 9.16 && idade >= 70 && idade <= 74:
    case valor > 9.28 && idade >= 75 && idade <= 79:
    case valor > 9.4 && idade >= 80:
      return 'Insuficiente';
    default:
      return '';
  }
};

export const classiLPS = (valor: number, idade: number) => {
  switch (true) {
    case valor < 6.84 && idade >= 60 && idade <= 64:
    case valor < 6.91 && idade >= 65 && idade <= 69:
    case valor < 6.97 && idade >= 70 && idade <= 74:
    case valor < 7.04 && idade >= 75 && idade <= 79:
    case valor < 7.11 && idade >= 80:
      return 'Muito Bom';
    case valor >= 6.84 && valor <= 10.12 && idade >= 60 && idade <= 64:
    case valor >= 6.92 && valor <= 10.19 && idade >= 65 && idade <= 69:
    case valor >= 6.97 && valor <= 10.26 && idade >= 70 && idade <= 74:
    case valor >= 7.04 && valor <= 10.33 && idade >= 75 && idade <= 79:
    case valor >= 7.11 && valor <= 10.4 && idade >= 80:
      return 'Bom';
    case valor >= 10.13 && valor <= 13.62 && idade >= 60 && idade <= 64:
    case valor >= 10.2 && valor <= 13.72 && idade >= 65 && idade <= 69:
    case valor >= 10.27 && valor <= 13.81 && idade >= 70 && idade <= 74:
    case valor >= 10.34 && valor <= 13.91 && idade >= 75 && idade <= 79:
    case valor >= 10.41 && valor <= 14.01 && idade >= 80:
      return 'Regular';
    case valor > 13.62 && idade >= 60 && idade <= 64:
    case valor > 13.72 && idade >= 65 && idade <= 69:
    case valor > 13.81 && idade >= 70 && idade <= 74:
    case valor > 13.91 && idade >= 75 && idade <= 79:
    case valor > 14.01 && idade >= 80:
      return 'Insuficiente';
    default:
      return '';
  }
};

export const classiLPDV = (valor: number, idade: number) => {
  switch (true) {
    case valor < 2.3 && idade >= 60 && idade <= 64:
    case valor < 2.47 && idade >= 65 && idade <= 69:
    case valor < 2.63 && idade >= 70 && idade <= 74:
    case valor < 2.8 && idade >= 75 && idade <= 79:
    case valor < 2.96 && idade >= 80:
      return 'Muito Bom';
    case valor >= 2.3 && valor <= 3.52 && idade >= 60 && idade <= 64:
    case valor >= 2.47 && valor <= 3.81 && idade >= 65 && idade <= 69:
    case valor >= 2.63 && valor <= 4.11 && idade >= 70 && idade <= 74:
    case valor >= 2.8 && valor <= 4.4 && idade >= 75 && idade <= 79:
    case valor >= 2.96 && valor <= 4.7 && idade >= 80:
      return 'Bom';
    case valor >= 3.53 && valor <= 5.41 && idade >= 60 && idade <= 64:
    case valor >= 3.82 && valor <= 5.8 && idade >= 65 && idade <= 69:
    case valor >= 4.12 && valor <= 6.2 && idade >= 70 && idade <= 74:
    case valor >= 4.41 && valor <= 6.6 && idade >= 75 && idade <= 79:
    case valor >= 4.71 && valor <= 6.99 && idade >= 80:
      return 'Regular';
    case valor > 5.41 && idade >= 60 && idade <= 64:
    case valor > 5.8 && idade >= 65 && idade <= 69:
    case valor > 6.2 && idade >= 70 && idade <= 74:
    case valor > 6.6 && idade >= 75 && idade <= 79:
    case valor > 6.99 && idade >= 80:
      return 'Insuficiente';
    default:
      return '';
  }
};

export const classiVTC = (valor: number, idade: number) => {
  switch (true) {
    case valor < 8.22 && idade >= 60 && idade <= 64:
    case valor < 8.75 && idade >= 65 && idade <= 69:
    case valor < 9.29 && idade >= 70 && idade <= 74:
    case valor < 9.83 && idade >= 75 && idade <= 79:
    case valor < 10.36 && idade >= 80:
      return 'Muito Bom';
    case valor >= 8.22 && valor <= 11.45 && idade >= 60 && idade <= 64:
    case valor >= 8.75 && valor <= 12.0 && idade >= 65 && idade <= 69:
    case valor >= 9.29 && valor <= 12.54 && idade >= 70 && idade <= 74:
    case valor >= 9.83 && valor <= 13.08 && idade >= 75 && idade <= 79:
    case valor >= 10.36 && valor <= 13.63 && idade >= 80:
      return 'Bom';
    case valor >= 11.46 && valor <= 15.51 && idade >= 60 && idade <= 64:
    case valor >= 12.01 && valor <= 16.04 && idade >= 65 && idade <= 69:
    case valor >= 12.55 && valor <= 16.56 && idade >= 70 && idade <= 74:
    case valor >= 13.09 && valor <= 17.08 && idade >= 75 && idade <= 79:
    case valor >= 13.64 && valor <= 17.6 && idade >= 80:
      return 'Regular';
    case valor > 15.51 && idade >= 60 && idade <= 64:
    case valor > 16.04 && idade >= 65 && idade <= 69:
    case valor > 16.56 && idade >= 70 && idade <= 74:
    case valor > 17.08 && idade >= 75 && idade <= 79:
    case valor > 17.6 && idade >= 80:
      return 'Insuficiente';
    default:
      return '';
  }
};

export const classiLCLC = (valor: number, idade: number) => {
  switch (true) {
    case valor < 35.17 && idade >= 60 && idade <= 64:
    case valor < 35.96 && idade >= 65 && idade <= 69:
    case valor < 36.76 && idade >= 70 && idade <= 74:
    case valor < 37.55 && idade >= 75 && idade <= 79:
    case valor < 38.35 && idade >= 80:
      return 'Muito Bom';
    case valor >= 35.17 && valor <= 42.37 && idade >= 60 && idade <= 64:
    case valor >= 35.96 && valor <= 43.28 && idade >= 65 && idade <= 69:
    case valor >= 36.76 && valor <= 44.19 && idade >= 70 && idade <= 74:
    case valor >= 37.55 && valor <= 45.11 && idade >= 75 && idade <= 79:
    case valor >= 38.35 && valor <= 46.02 && idade >= 80:
      return 'Bom';
    case valor >= 42.38 && valor <= 49.68 && idade >= 60 && idade <= 64:
    case valor >= 43.29 && valor <= 50.81 && idade >= 65 && idade <= 69:
    case valor >= 44.2 && valor <= 51.94 && idade >= 70 && idade <= 74:
    case valor >= 45.12 && valor <= 53.06 && idade >= 75 && idade <= 79:
    case valor >= 46.03 && valor <= 54.19 && idade >= 80:
      return 'Regular';
    case valor > 49.68 && idade >= 60 && idade <= 64:
    case valor > 50.81 && idade >= 65 && idade <= 69:
    case valor > 51.94 && idade >= 70 && idade <= 74:
    case valor > 53.06 && idade >= 75 && idade <= 79:
    case valor > 54.19 && idade >= 80:
      return 'Insuficiente';
    default:
      return '';
  }
};

export const classiIG = (valor: number, idade: number) => {
  switch (true) {
    case valor < 22.28 && idade >= 60 && idade <= 64:
    case valor < 22.82 && idade >= 65 && idade <= 69:
    case valor < 23.37 && idade >= 70 && idade <= 74:
    case valor < 23.91 && idade >= 75 && idade <= 79:
    case valor < 24.36 && idade >= 80:
      return 'Muito Bom';
    case valor >= 22.28 && valor <= 27.43 && idade >= 60 && idade <= 64:
    case valor >= 22.82 && valor <= 28.1 && idade >= 65 && idade <= 69:
    case valor >= 23.37 && valor <= 28.77 && idade >= 70 && idade <= 74:
    case valor >= 23.91 && valor <= 29.45 && idade >= 75 && idade <= 79:
    case valor >= 24.36 && valor <= 30.02 && idade >= 80:
      return 'Bom';
    case valor >= 27.44 && valor <= 33.01 && idade >= 60 && idade <= 64:
    case valor >= 28.11 && valor <= 33.71 && idade >= 65 && idade <= 69:
    case valor >= 28.78 && valor <= 34.41 && idade >= 70 && idade <= 74:
    case valor >= 29.46 && valor <= 35.11 && idade >= 75 && idade <= 79:
    case valor >= 30.03 && valor <= 35.81 && idade >= 80:
      return 'Regular';
    case valor > 33.01 && idade >= 60 && idade <= 64:
    case valor > 33.71 && idade >= 65 && idade <= 69:
    case valor > 34.41 && idade >= 70 && idade <= 74:
    case valor > 35.11 && idade >= 75 && idade <= 79:
    case valor > 35.81 && idade >= 80:
      return 'Insuficiente';
    default:
      return '';
  }
};
