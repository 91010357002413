import { FormHandles } from '@unform/core';
import { Form } from '@unform/web';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { Link, useNavigate, useParams } from 'react-router-dom';
import * as Yup from 'yup';

import { useToast } from '../../hooks/toast';
import Button from '../../components/Button';
import Option from '../../components/Option';

import agachamento1 from '../../assets/exercicios/agachamento/Imagem1.jpg';
import agachamento2 from '../../assets/exercicios/agachamento/Imagem2.jpg';
import agachamento3 from '../../assets/exercicios/agachamento/Imagem3.jpg';
import agachamento4 from '../../assets/exercicios/agachamento/Imagem4.jpg';
import agachamento5 from '../../assets/exercicios/agachamento/Imagem5.jpg';
import agachamento6 from '../../assets/exercicios/agachamento/Imagem6.jpg';
import ombro1 from '../../assets/exercicios/ombros/Imagem1.jpg';
import ombro2 from '../../assets/exercicios/ombros/Imagem2.jpg';
import ombro3 from '../../assets/exercicios/ombros/Imagem3.jpg';
import ombro4 from '../../assets/exercicios/ombros/Imagem4.jpg';
import tornozelo1 from '../../assets/exercicios/tornozelo/Imagem1.jpg';
import core1 from '../../assets/exercicios/core/core2.jpeg';

import {
  Agachamento,
  Analise00,
  Analise01,
  Analise02,
  Analise03,
  Analise04,
  AnaliseHeader,
  CheckHeaderD,
  CheckHeaderE,
  Classif0,
  Classif1,
  Classif2,
  Classif3,
  Classif4,
  Classificacao,
  ClassificacaoHeader,
  Container,
  Content,
  GridCore,
  GridTornozelo,
  Header1,
  Header2,
  Header3,
  Header4,
  Header5,
  Header6,
  Header7,
  Image,
  Image1,
  Image2,
  Image3,
  Image4,
  Image5,
  Image6,
  ImageHeader,
  MobilidadeOmbros,
  Pontuacao00,
  Pontuacao01,
  Pontuacao02,
  Pontuacao03,
  Pontuacao04,
  PontuacaoHeader,
  Resultado,
  ResultadoHeader,
  Schedule,
  Section,
  TEMPO,
  Title,
  TXT,
} from './styles';

import Input from '../../components/Input';
import { RadioOption } from '../../model/Inputs';
import api from '../../services/api';
import { FiChevronLeft } from 'react-icons/fi';

const radioOptions: RadioOption[] = [
  { id: '1', value: 3 },
  { id: '2', value: 2 },
  { id: '3', value: 1 },
  { id: '4', value: 0 },
];

const ombroDOptions: RadioOption[] = [
  { id: '1', value: 3 },
  { id: '2', value: 2 },
  { id: '3', value: 1 },
  { id: '4', value: 0 },
];
const ombroEOptions: RadioOption[] = [
  { id: '5', value: 3 },
  { id: '6', value: 2 },
  { id: '7', value: 1 },
  { id: '8', value: 0 },
];

const tornozeloDOptions: RadioOption[] = [
  { id: '1', value: 3 },
  { id: '2', value: 2 },
  { id: '3', value: 1 },
  { id: '4', value: 0 },
];
const tornozeloEOptions: RadioOption[] = [
  { id: '5', value: 3 },
  { id: '6', value: 2 },
  { id: '7', value: 1 },
  { id: '8', value: 0 },
];

const FuncionalidadeBasicas: React.FC = () => {
  const { alunoid, id } = useParams();
  const navigate = useNavigate();
  const formRef = useRef<FormHandles>(null);
  const { addToast } = useToast();
  const [loading, setLoading] = useState(false);
  const [ag, setAG] = useState<any>({});
  const [ombroResult, setOmbroResult] = useState<any>({});
  const [tornozelo, setTornozelo] = useState<any>({});
  const [core, setCore] = useState('');
  // const [dados, setDados] = useState(null);

  useEffect(() => {
    formRef.current?.setFieldValue('aluno', Number(alunoid));
  }, [alunoid]);

  useEffect(() => {
    if (Number(id) > 0) {
      api.get('funcionalidadesbasicas/' + id).then((response) => {
        const { data } = response;
        formRef.current?.setData(data);
        // setDados(data);
        handleAgachamento();
        handleOmbro();
        handleTornozelo();
        handleCore();
      });
    } else {
      handleAgachamento();
      handleOmbro();
      handleTornozelo();
      handleCore();
    }

    return () => {};
  }, [id]);

  const handleAgachamento = () => {
    const fms = formRef.current?.getFieldValue('fms');
    switch (fms) {
      case '3':
        setAG({ resultado: 3, classificacao: 'Ótima funcionalidade básica' });
        break;
      case '2':
        setAG({ resultado: 2, classificacao: 'Funcionalidade básica comprometida' });
        break;
      case '1':
        setAG({ resultado: 1, classificacao: 'Funcionalidade básica muito comprometida' });
        break;
      case '0':
        setAG({ resultado: 0, classificacao: 'Realizar consulta médica' });
        break;
      default:
        break;
    }
  };

  const handleOmbro = () => {
    const ombroD = formRef.current?.getFieldValue('ombroDireito');
    const ombroE = formRef.current?.getFieldValue('ombroEsquerdo');
    // const ombros = formRef.current?.getFieldValue('ombros');
    const valor = Number(ombroD) + Number(ombroE);
    let classif = '';

    if (ombroD === '' || ombroE === '') {
      return;
    }

    if (valor >= 5) {
      classif = 'Ótima funcionalidade básica';
    } else if (valor < 5 && valor >= 3) {
      classif = 'Funcionalidade básica comprometida';
    } else if (valor > 0 && valor < 3) {
      classif = 'Funcionalidade básica muito comprometida';
    } else if (valor === 0) {
      classif = 'Realizar consulta médica';
    } else {
      classif = 'N/D';
    }

    setOmbroResult({ resultado: valor, classificacao: classif });
  };

  const handleTornozelo = () => {
    const tornozeloD = formRef.current?.getFieldValue('tornozeloDireito');
    const tornozeloE = formRef.current?.getFieldValue('tornozeloEsquerdo');
    const valor = Number(tornozeloD) + Number(tornozeloE);
    let classif = '';
    if (tornozeloD === '' || tornozeloE === '') {
      return;
    }

    if (valor >= 5) {
      classif = 'Ótima funcionalidade básica';
    } else if (valor < 5 && valor >= 3) {
      classif = 'Funcionalidade básica comprometida';
    } else if (valor > 0 && valor < 3) {
      classif = 'Funcionalidade básica muito comprometida';
    } else if (valor === 0) {
      classif = 'Realizar consulta médica';
    } else {
      classif = 'N/D';
    }

    setTornozelo({ resultado: valor, classificacao: classif });
  };

  const handleCore = () => {
    const valor = formRef.current?.getFieldValue('core');
    let classif = '';
    if (valor === '') {
      setCore(classif);
      return;
    }

    if (valor < 21) {
      classif = 'Fraco';
    } else if (valor > 20 && valor < 41) {
      classif = 'Regular';
    } else if (valor > 40 && valor < 61) {
      classif = 'Bom';
    } else if (valor > 60 && valor < 81) {
      classif = 'Muito bom';
    } else {
      classif = 'Excelente';
    }

    setCore(classif);
  };

  const handleSubmit = useCallback(
    async (data: any) => {
      try {
        formRef.current?.setErrors({});
        const schema = Yup.object().shape({});
        await schema.validate(data, {
          abortEarly: false,
        });

        setLoading(true);
        if (Number(id) === 0) {
          await api.post('funcionalidadesbasicas', data);
        } else {
          await api.put('funcionalidadesbasicas', data);
        }
        setLoading(false);
        formRef.current?.reset();
        addToast({
          type: 'success',
          title: 'Registro salvo com sucesso!',
        });
        navigate(`/cliente/${alunoid}/questionarios`);
      } catch (error) {
        console.log(error);
        setLoading(false);
        addToast({
          type: 'error',
          title: 'Verifique o formulário',
        });
      }
    },
    [addToast, alunoid, id, navigate]
  );

  return (
    <Container>
      {/* <Header /> */}
      <Content>
        <Schedule>
          <Title>
            <h1>FUNCIONALIDADES BÁSICAS</h1>
            <Link to={`/cliente/${alunoid}/questionarios`}>
              <FiChevronLeft size={20} />
              <span>Voltar</span>
            </Link>
          </Title>
          <Form
            ref={formRef}
            onSubmit={handleSubmit}
            initialData={{ aluno: alunoid }}
            placeholder={undefined}
            onPointerEnterCapture={undefined}
            onPointerLeaveCapture={undefined}
          >
            <Section>
              <strong>Testes</strong>
              <strong style={{ textAlign: 'center' }}>Agachamento</strong>
              <Agachamento>
                <Header1>Analise</Header1>
                <Header2>Pontuação</Header2>
                <Header3>Vista Frontal</Header3>
                <Header4>Vista Lateral</Header4>
                <Header5>Marque</Header5>
                <Header6>Resultado</Header6>
                <Header7>Classificação</Header7>
                <Analise03>
                  <p>Tronco superior paralelo à tibia ou em direção vertical.</p>
                  <p>Fêmur abaixo da horizontal.</p>
                  <p>Joelhos alinhados sobre os pés.</p>
                  <p>Bastão alinhado sobre os pés.</p>
                </Analise03>
                <Analise02>
                  <p>Tronco superior paralelo à tibia ou em direção vertical.</p>
                  <p>Fêmur abaixo da horizontal.</p>
                  <p>Joelhos alinhados sobre os pés.</p>
                  <p>Bastão alinhado sobre os pés.</p>
                </Analise02>
                <Analise01>
                  <p>Tibia e tronco não podem ficar paralelos.</p>
                  <p>Fêmur não está abaixo da horizontal.</p>
                  <p>Joelhos não estão alinhados sobre os pés.</p>
                  <p>Flexão lombar (bastão não alinhado sobre os pés).</p>
                </Analise01>
                <Analise00>
                  <p>Avaliado relata dor</p>
                </Analise00>
                <Pontuacao03>3</Pontuacao03>
                <Pontuacao02>2</Pontuacao02>
                <Pontuacao01>1</Pontuacao01>
                <Pontuacao00>0</Pontuacao00>
                <Image1>
                  <img src={agachamento1} alt="agachamento" />
                </Image1>
                <Image2>
                  <img src={agachamento2} alt="agachamento" />
                </Image2>
                <Image3>
                  <img src={agachamento5} alt="agachamento" />
                </Image3>
                <Image4>
                  <img src={agachamento6} alt="agachamento" />
                </Image4>
                <Image5>
                  <img src={agachamento3} alt="agachamento" />
                </Image5>
                <Image6>
                  <img src={agachamento4} alt="agachamento" />
                </Image6>
                <Option name="fms" options={radioOptions} onChange={handleAgachamento} />
                <Resultado>
                  <p>{ag.resultado}</p>
                </Resultado>
                <Classificacao>
                  <p>{ag.classificacao}</p>
                </Classificacao>
              </Agachamento>
            </Section>
            <Section>
              <strong style={{ textAlign: 'center' }}>Mobilidade dos ombros</strong>
              <MobilidadeOmbros>
                <Header1>Analise</Header1>
                <Header2>Pontuação</Header2>
                <Header3>Verificação</Header3>
                <Header4>Direito</Header4>
                <Header5>Esquerdo</Header5>
                <Header6>Resultado</Header6>
                <Header7>Classificação</Header7>
                <Analise04>
                  <p>Os punhos dentro de uma distância próxima ao tamanho da mão.</p>
                </Analise04>
                <Analise03>
                  <p>distância dos punhos está dentro da distância levemente abaixo à uma 1 mão e meia(1 ½)</p>
                </Analise03>
                <Analise02>
                  <p>A distância dos punhos é maior do que uma 1 mão e meia(1 ½)</p>
                </Analise02>
                <Analise01>
                  <p>Avaliado relata dor</p>
                </Analise01>
                <Pontuacao04>3</Pontuacao04>
                <Pontuacao03>2</Pontuacao03>
                <Pontuacao02>1</Pontuacao02>
                <Pontuacao01>0</Pontuacao01>
                <Image1>
                  <img src={ombro1} alt="ombro" />
                </Image1>
                <Image2>
                  <img src={ombro2} alt="ombro" />
                </Image2>
                <Image3>
                  <img src={ombro3} alt="ombro" />
                </Image3>
                <Image4>
                  <img src={ombro4} alt="ombro" />
                </Image4>
                <Option name="ombroDireito" options={ombroDOptions} onChange={handleOmbro} />
                <Option name="ombroEsquerdo" options={ombroEOptions} onChange={handleOmbro} />
                <Resultado>
                  <p>{ombroResult.resultado}</p>
                </Resultado>
                <Classificacao>
                  <p>{ombroResult.classificacao}</p>
                </Classificacao>
              </MobilidadeOmbros>
            </Section>
            <Section>
              <strong style={{ textAlign: 'center' }}>Mobilidade do tornozelo</strong>
              <GridTornozelo>
                <AnaliseHeader>Analise</AnaliseHeader>
                <PontuacaoHeader>Pontuação</PontuacaoHeader>
                <ImageHeader>Imagem</ImageHeader>
                <CheckHeaderD>Direito</CheckHeaderD>
                <CheckHeaderE>Esquerdo</CheckHeaderE>
                <ResultadoHeader>Resultado</ResultadoHeader>
                <ClassificacaoHeader>Classificação</ClassificacaoHeader>
                <Analise03>Encostar o joelho na parede sem mudança de direção para dentro, na distância de 12 cm.</Analise03>
                <Analise02>
                  Encostar o joelho na parede sem mudança de direção para dentro, sem encostar na parede. (menor de 12 cm).
                </Analise02>
                <Analise01>Encostar o joelho na parede com mudança de direção e retirada do calcanhar do chão.</Analise01>
                <Analise00>Avaliado relata dor durante o moviemnto, comendar avaliação médica.</Analise00>
                <Pontuacao03>3</Pontuacao03>
                <Pontuacao02>2</Pontuacao02>
                <Pontuacao01>1</Pontuacao01>
                <Pontuacao00>0</Pontuacao00>
                <Image>
                  <img src={tornozelo1} alt="tornozelo" />
                </Image>
                <Resultado>
                  <p>{tornozelo.resultado}</p>
                </Resultado>
                <Classificacao>
                  <p>{tornozelo.classificacao}</p>
                </Classificacao>
                <Option name="tornozeloDireito" options={tornozeloDOptions} onChange={handleTornozelo} />
                <Option name="tornozeloEsquerdo" options={tornozeloEOptions} onChange={handleTornozelo} />
                {/* <Option name="tornozeloDireito" options={ombroOptions} onChange={handleTornozelo} /> */}
              </GridTornozelo>
            </Section>
            <Section>
              <strong style={{ textAlign: 'center' }}>Teste de core - Exercicio de prancha</strong>
              <GridCore>
                <Header1>Análise</Header1>
                {/* <Header2>Tempo</Header2> */}
                <Header3>Imagem</Header3>
                <Header4>Classificação</Header4>
                <Header5>Tempo</Header5>
                <Header6>Classificação</Header6>
                <TXT>Tempo de permanencia na posição de forma estável. (em segundos)</TXT>
                {/* <Tempo0>Até 20"</Tempo0>
                <Tempo1>Entre 21" e 40"</Tempo1>
                <Tempo2>Entre 41" e 60"</Tempo2>
                <Tempo3>Entre 61" e 80"</Tempo3>
                <Tempo4>Acima de 80"</Tempo4> */}
                <Image>
                  <img src={core1} alt="prancha" />
                </Image>
                <Classif0>Fraco</Classif0>
                <Classif1>Regular</Classif1>
                <Classif2>Bom</Classif2>
                <Classif3>Muito Bom</Classif3>
                <Classif4>Excelente</Classif4>
                <TEMPO>
                  <Input name="core" onChange={handleCore} />
                </TEMPO>
                <Classificacao>
                  <p>{core}</p>
                </Classificacao>
              </GridCore>
            </Section>
            <Button loading={loading} type="submit">
              Gravar
            </Button>
            <div style={{ visibility: 'hidden' }}>
              <Input name="aluno" type="number" />
            </div>
          </Form>
        </Schedule>
      </Content>
    </Container>
  );
};

export default FuncionalidadeBasicas;
