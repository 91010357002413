export function convertToDate(dateString: string) {
  let d = dateString.split('/');
  let dat = new Date(d[2] + '/' + d[1] + '/' + d[0]);
  return dat;
}

export function formtarData(dateString: string) {
  if (dateString === undefined) return '';
  const fullDate: Date = new Date(dateString);
  const year: number = fullDate.getFullYear();
  const month: number = fullDate.getMonth() + 1;
  const day: number = fullDate.getDate();

  const dateFormated: string = day.toString().padStart(2, '0') + '/' + month.toString().padStart(2, '0') + '/' + year;

  return dateFormated;
}
