import { RadioOption } from '../../model/Inputs';

export const options: RadioOption[] = [
  { id: '1', value: 'Sim', label: 'Sim' },
  { id: '2', value: 'Não', label: 'Não' },
];

export const options1: RadioOption[] = [
  { id: '1', value: 'Nenhum', label: 'Nenhum' },
  { id: '2', value: '1 Vez', label: '1 Vez' },
  { id: '3', value: '2 Vezes', label: '2 Vezes' },
  { id: '4', value: '3 Vezes', label: '3 Vezes' },
  { id: '5', value: '4 Vezes', label: '4 Vezes' },
  { id: '6', value: '5 a 7 Vezes', label: '5 a 7 Vezes' },
];

export const options2: RadioOption[] = [
  { id: '1', value: 'Entre 10 minutos e 15 minutos', label: 'Entre 10 minutos e 15 minutos' },
  { id: '2', value: 'Entre 15 minutos e 20 minutos', label: 'Entre 15 minutos e 20 minutos' },
  { id: '3', value: 'Entre 20 minutos e 30 minutos', label: 'Entre 20 minutos e 30 minutos' },
  { id: '4', value: 'Mais que 30 minutos', label: 'Mais que 30 minutos' },
];

export const options3: RadioOption[] = [
  { id: '1', value: 'Não', label: 'Não' },
  { id: '2', value: '1 dia por semana', label: '1 dia por semana' },
  { id: '3', value: '2 dias por semana', label: '2 dias por semana' },
  { id: '4', value: '3 dias por semana', label: '3 dias por semana' },
  { id: '5', value: '4 dias por semana', label: '4 dias por semana' },
  { id: '6', value: '5 a 7 dias por semana', label: '5 a 7 dias por semana' },
];

export const options4: RadioOption[] = [
  { id: '1', value: 'Nunca', label: 'Nunca' },
  { id: '2', value: '1x a 3x por semana', label: '1x a 3x por semana' },
  { id: '3', value: '4x a 6x por semana', label: '4x a 6x por semana' },
  { id: '4', value: 'Diariamente 1x por dia', label: 'Diariamente 1x por dia' },
  { id: '5', value: 'Diariamente 2x ou mais por dia', label: 'Diariamente 2x ou mais por dia' },
];

export const options5: RadioOption[] = [
  { id: '1', value: 'Nunca fumei', label: 'Nunca fumei' },
  { id: '2', value: 'Parei de fumar há menos de 2 anos', label: 'Parei de fumar há menos de 2 anos' },
  { id: '3', value: 'FUMO menos de 10 cigarros por dia', label: 'FUMO menos de 10 cigarros por dia' },
  { id: '4', value: 'FUMO de 10 a 20 cigarros por dia', label: 'FUMO de 10 a 20 cigarros por dia' },
  { id: '5', value: 'FUMO mais de 20 cigarros por dia', label: 'FUMO mais de 20 cigarros por dia' },
];

export const options6: RadioOption[] = [
  { id: '1', value: 'Sempre ou quase sempre', label: 'Sempre ou quase sempre' },
  { id: '2', value: 'Às vezes', label: 'Às vezes' },
  { id: '3', value: 'Nunca ou raramente', label: 'Nunca ou raramente' },
];

export const options7: RadioOption[] = [
  { id: '1', value: 'Muito satisfeito', label: 'Muito satisfeito' },
  { id: '2', value: 'Satisfeito', label: 'Satisfeito' },
  { id: '3', value: 'Nem insatisfeito e nem satisfeito', label: 'Nem insatisfeito e nem satisfeito' },
  { id: '4', value: 'Insatisfeito', label: 'Insatisfeito' },
  { id: '5', value: 'Muito insatisfeito', label: 'Muito insatisfeito' },
];

export const options8: RadioOption[] = [
  { id: '1', value: 'Sempre', label: 'Sempre' },
  { id: '2', value: 'Quase sempre', label: 'Quase sempre' },
  { id: '3', value: 'Às vezes', label: 'Às vezes' },
  { id: '4', value: 'Nunca ou raramente', label: 'Nunca ou raramente' },
];

export const options9: RadioOption[] = [
  { id: '1', value: 'Nada', label: 'Nada' },
  { id: '2', value: 'Muito pouco', label: 'Muito pouco' },
  { id: '3', value: 'Mais ou menos', label: 'Mais ou menos' },
  { id: '4', value: 'Bastante', label: 'Bastante' },
  { id: '5', value: 'Extremamente', label: 'Extremamente' },
];

export const options10: RadioOption[] = [
  { id: '1', value: 'Nada', label: 'Nada' },
  { id: '2', value: 'Muito pouco', label: 'Muito pouco' },
  { id: '3', value: 'Médio', label: 'Médio' },
  { id: '4', value: 'Muito', label: 'Muito' },
  { id: '5', value: 'Completamente', label: 'Completamente' },
];

export const options11: RadioOption[] = [
  { id: '1', value: 'Muito insatisfeito', label: 'Muito insatisfeito' },
  { id: '2', value: 'Insatisfeito', label: 'Insatisfeito' },
  { id: '3', value: 'Nem insatisfeito, nem satifeito', label: 'Nem insatisfeito, nem satifeito' },
  { id: '4', value: 'Satisfeito', label: 'Satisfeito' },
  { id: '5', value: 'Muito satisfeito', label: 'Muito satisfeito' },
];

export const options12: RadioOption[] = [
  { id: '1', value: 'Nunca', label: 'Nunca' },
  { id: '2', value: 'Algumas vezes', label: 'Algumas vezes' },
  { id: '3', value: 'Frequentemente', label: 'Frequentemente' },
  { id: '4', value: 'Muito frequentemente', label: 'Muito frequentemente' },
  { id: '5', value: 'Sempre', label: 'Sempre' },
];
