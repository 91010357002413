import React, { useCallback, useEffect, useRef, useState } from 'react';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { FormHandles } from '@unform/core';
import { Form } from '@unform/web';
import * as Yup from 'yup';

import Checkbox from '../../components/Checkbox/V2';
import Input from '../../components/Input';

import { Container, Content, Main, Row, Section, SectionOne, SectionRecomendacao, SubTitle, Title, Ul } from './styles';
import getValidationErrors from '../../utils/getValidationErrors';
import api from '../../services/api';
import Button from '../../components/Button';

import { useToast } from '../../hooks/toast';
import Option from '../../components/Option';
import { RadioOption } from '../../model/Inputs';
import { FiChevronLeft } from 'react-icons/fi';

// function arrayRemove(arr: any[], value: any) {
//   return arr.filter(function (ele: any) {
//     return ele != value;
//   });
// }

const radioOptions: RadioOption[] = [
  { id: '1', value: 'Não', label: 'Não' },
  { id: '2', value: 'Médico Particular', label: 'Médico Particular' },
  { id: '3', value: 'Médico do Trabalho', label: 'Médico do Trabalho' },
  { id: '4', value: 'Nutricionista', label: 'Nutricionista' },
  { id: '5', value: 'Psicólogo/Psiquiatra', label: 'Psicólogo/Psiquiatra' },
  { id: '6', value: 'Outros', label: 'Outros' },
];

const ObjetivosMetas: React.FC = () => {
  const { aluno, id } = useParams();
  const navigate = useNavigate();
  const formRef = useRef<FormHandles>(null);
  const { addToast } = useToast();
  const [loading, setLoading] = useState(false);
  const [perguntas, setPerguntas] = useState<any>([]);
  // const [objetivos, setobjetivos] = useState<any>([]);

  useEffect(() => {
    formRef.current?.setFieldValue('aluno', Number(aluno));
  }, [aluno]);

  useEffect(() => {
    api.get('objetivos').then((response) => {
      response.data.map((d: any) => (d.blocked = false));
      setPerguntas(response.data);
    });
  }, []);

  useEffect(() => {
    if (Number(id) > 0) {
      api.get(`ObjetivosAlunos/avaliacao/${id}`).then((response) => {
        response.data.forEach((d: any) => {
          formRef.current?.setFieldValue(`objetivos[${d.objetivo}].checked`, true);
          formRef.current?.setFieldValue(`objetivos[${d.objetivo}].id`, d.id);
          formRef.current?.setFieldValue(`objetivos[${d.objetivo}].valor`, d.valor);
        });
      });
    }
  }, [perguntas, id]);

  // const enable = (e: React.ChangeEvent<HTMLInputElement>) => {
  //   const allData = formRef.current?.getData();
  //   formRef.current?.setFieldValue(e.target.name, e.target.checked ? e.target.value : null);
  //   console.log(allData, e.target.name, e.target.checked, e.target.value);
  // };

  const handleSubmit = useCallback(
    async (data: any) => {
      try {
        // formRef.current?.setData({ Aluno: 3 });
        formRef.current?.setErrors({});
        const schema = Yup.object().shape({
          aluno: Yup.number().required('Erro ao recuperar Aluno'),
          ProfissionalSaude: Yup.string(),
        });

        await schema.validate(data, {
          abortEarly: false,
        });

        setLoading(true);
        // const objetivos: { objetivoId: any; valor: any }[] = [];
        // const objetivos = data.objetivos.concat(data.objetivos).concat(data.objetivos).concat(data.objetivos);
        // const fields = Object.keys(objs).filter((d) => d !== 'Aluno' && d !== 'ProfissionalSaude');
        // fields.forEach((f) => {
        //   if (data[f].objetivo !== undefined && data[f].objetivo !== null) {
        //     objetivos.push({ objetivoId: parseInt(data[f].objetivo, 10), valor: data[f].valor });
        //   }
        // });

        const avaliacao = {
          Aluno: data.aluno,
          objetivos: data.objetivos.filter((o: any) => o.checked),
          ProfissionalSaude: data.ProfissionalSaude,
        };

        if (Number(id) === 0) {
          await api.post('objetivosalunos', avaliacao);
          addToast({
            type: 'success',
            title: 'Cadastro realizado',
          });
        } else {
          await api.put('objetivosalunos/' + id, avaliacao);
          addToast({
            type: 'success',
            title: 'Cadastro atualizado',
          });
        }

        setLoading(false);
        formRef.current?.reset();
        navigate(`/cliente/${aluno}/questionarios`);
      } catch (err: any) {
        setLoading(false);
        const errors = getValidationErrors(err);
        formRef.current?.setErrors(errors);
        console.log(errors);
      }
    },
    [addToast, aluno, id, navigate]
  );
  return (
    <>
      <Container>
        <Main>
          <Content>
            <Title>
              <h1>Objetivos</h1>
              <Link to={`/cliente/${aluno}/questionarios`}>
                <FiChevronLeft size={20} />
                <span>Voltar</span>
              </Link>
            </Title>
            <Form
              ref={formRef}
              onSubmit={handleSubmit}
              placeholder={undefined}
              onPointerEnterCapture={undefined}
              onPointerLeaveCapture={undefined}
            >
              <SubTitle>Defina de 3 a 5 objetivos prioritaarios para os próximos 6 meses?</SubTitle>
              <SubTitle>Objetivo Geral: Melhorar os indicadores de saúde</SubTitle>
              <SubTitle>Objetivos Específicos:</SubTitle>
              <SectionOne>
                <Ul>
                  {perguntas
                    .filter((p: any) => p.categoriaGeral === 1)
                    .map((p: any, i: number) => (
                      <Row key={p.id}>
                        <div>
                          <label>{p.titulo}</label>
                        </div>
                        <div>
                          <div>
                            <Checkbox name={`objetivos[${p.id}].checked`} value={p.id} />
                          </div>
                          <div>
                            <Input name={`objetivos[${p.id}].valor`} placeholder="resposta" disabled={p.blocked} maxLength={150} />
                          </div>
                          <div style={{ visibility: 'hidden' }}>
                            <Input name={`objetivos[${p.id}].objetivo`} defaultValue={p.id} />
                            <Input name={`objetivos[${p.id}].id`} defaultValue={p.id} />
                          </div>
                        </div>
                      </Row>
                    ))}
                </Ul>
              </SectionOne>
              <SubTitle>Objetivo Geral: Melhorar o bem estar</SubTitle>
              <SubTitle>Objetivos Específicos:</SubTitle>
              <SectionOne>
                <Ul>
                  {perguntas
                    .filter((p: any) => p.categoriaGeral === 2)
                    .map((p: any, i: number) => (
                      <Row key={p.id}>
                        <div>
                          <label>{p.titulo}</label>
                        </div>
                        <div>
                          <div>
                            <Checkbox name={`objetivos[${p.id}].checked`} value={p.id} />
                          </div>
                          <div>
                            <Input name={`objetivos[${p.id}].valor`} placeholder="Resposta" disabled={p.blocked} maxLength={150} />
                          </div>
                          <div style={{ visibility: 'hidden' }}>
                            <Input name={`objetivos[${p.id}].objetivo`} defaultValue={p.id} />
                            <Input name={`objetivos[${p.id}].id`} defaultValue={p.id} />
                          </div>
                        </div>
                      </Row>
                    ))}
                </Ul>
              </SectionOne>
              <SubTitle>Objetivo Geral: Melhorar o condicionamento físico</SubTitle>
              <SubTitle>Objetivos Específicos:</SubTitle>
              <SectionOne>
                <Ul>
                  {perguntas
                    .filter((p: any) => p.categoriaGeral === 3)
                    .map((p: any, i: number) => (
                      <Row key={p.id}>
                        <div>
                          <label>{p.titulo}</label>
                        </div>
                        <div>
                          <div>
                            <Checkbox name={`objetivos[${p.id}].checked`} value={p.id} />
                          </div>
                          <div>
                            <Input name={`objetivos[${p.id}].valor`} placeholder="Resposta" disabled={p.blocked} maxLength={150} />
                          </div>
                          <div style={{ visibility: 'hidden' }}>
                            <Input name={`objetivos[${p.id}].objetivo`} defaultValue={p.id} />
                            <Input name={`objetivos[${p.id}].id`} defaultValue={p.id} />
                          </div>
                        </div>
                      </Row>
                    ))}
                </Ul>
              </SectionOne>
              <SubTitle>Objetivo Geral: Estética corporal</SubTitle>
              <SubTitle>Objetivos Específicos:</SubTitle>
              <SectionOne>
                <Ul>
                  {perguntas
                    .filter((p: any) => p.categoriaGeral === 4)
                    .map((p: any, i: number) => (
                      <Row key={p.id}>
                        <div>
                          <label>{p.titulo}</label>
                        </div>
                        <div>
                          <div>
                            <Checkbox name={`objetivos[${p.id}].checked`} value={p.id} />
                          </div>
                          <div>
                            <Input name={`objetivos[${p.id}].valor`} placeholder="Resposta" disabled={p.blocked} maxLength={150} />
                          </div>
                          <div style={{ visibility: 'hidden' }}>
                            <Input name={`objetivos[${p.id}].objetivo`} defaultValue={p.id} />
                            <Input name={`objetivos[${p.id}].id`} defaultValue={p.id} />
                          </div>
                        </div>
                      </Row>
                    ))}
                </Ul>
              </SectionOne>
              <SubTitle>Foi recomendação de profissional de saúde?</SubTitle>
              <SectionRecomendacao>
                <Option name="ProfissionalSaude" options={radioOptions} />
              </SectionRecomendacao>
              <Section>
                <Button loading={loading} disabled={loading} type="submit">
                  Gravar
                </Button>
              </Section>
              <div style={{ visibility: 'hidden' }}>
                <Input name="aluno" type="number" />
              </div>
            </Form>
          </Content>
        </Main>
      </Container>
    </>
  );
};

export default ObjetivosMetas;
