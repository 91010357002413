import { shade } from 'polished';
import styled from 'styled-components';
import { device } from '../../styles/global';

const borderRadiusSize = '14px';
const barbarian = '#EC9B3B';
// const archer = '#EE5487';
// const giant = '#F6901A';
// const goblin = '#82BB30';
// const wizard = '#4FACFF';
const buttonBg = '#1171ef';

export const Container = styled.div`
  width: 100%;
  max-width: 1152px;
  margin: 0px auto;
  padding-top: 4rem;
  padding-bottom: 4rem;
`;

export const Title = styled.div`
  display: flex;
  justify-content: space-between;
  margin-left: 25px;
  margin-right: 25px;

  h1 {
    color: #525f7f;
  }

  /* a {
    display: flex;
    flex-direction: row;
    justify-content: center;
    max-width: 120px;
    height: 40px;
    max-height: 40px;
    display: flex;
    text-align: center;
    background: #1171ef;
    height: 56px;
    border-radius: 10px;
    border: 0;
    padding: 0 16px;
    color: #ffffff;
    text-decoration: none;
    width: 100%;
    font-weight: 500;
    margin-left: 10px;
    transition: background-color 0.2s; */

    /* svg {
      margin-right: 15px;
    } */

    /* &:hover {
      background: ${shade(0.2, '#1171ef')};
    } */

    > * {
      margin: auto 0;
    }
  }
`;

export const Main = styled.main`
  width: 100%;
  /* 
  display: grid;
  grid-template-columns: auto auto auto auto auto;
  */
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
`;

export const Content = styled.main`
  max-width: 1120px;
  margin: 64px auto;
  display: flex;
`;

export const Wrapper = styled.div`
  /* padding-top: 40px;
  padding-bottom: 40px; */
  margin: 25px;

  &:focus {
    outline: 0;
  }

  /* & + div {
    margin-left: 100px;
  } */
`;

export const Card = styled.div`
  background: white;
  width: 180px;
  display: inline-block;
  margin: auto;
  border-radius: 20px;
  position: relative;
  text-align: center;
  box-shadow: -1px 15px 30px -12px black;
  z-index: 9999;

  @media ${device.mobileL_Max} {
    width: 150px;
  }
`;

export const ImageContainer = styled.div`
  position: relative;
  height: 100px;
  margin-bottom: 10px;
  border-top-left-radius: ${borderRadiusSize};
  border-top-right-radius: ${borderRadiusSize};
  /* background: url('https://s3-us-west-2.amazonaws.com/s.cdpn.io/195612/barbarian-bg.jpg'); */
  /* background: rgb(2, 0, 36);
  background: linear-gradient(130deg, rgba(2, 0, 36, 1) 10%, rgba(9, 66, 121, 0.7766456924566701) 70%, rgba(0, 212, 255, 1) 100%); */
  display: flex;
  align-items: center;
  justify-content: center;

  img {
    width: 75px;
    /* position: absolute; */
    /* top: 10px; */
    /* left: -70px; */
  }
`;

export const Level = styled.div`
  text-transform: uppercase;
  font-size: 20px;
  font-weight: 700;
  margin-bottom: 3px;
  color: ${barbarian};
`;

export const Unit = styled.div`
  font-size: 20px;
  color: black;
  font-weight: 900;
  margin-bottom: 5px;
  min-height: 20px;
`;

export const Description = styled.div`
  /* padding: 20px; */
  margin-bottom: 10px;
`;

export const Stats = styled.div`
  background: ${buttonBg};
  cursor: pointer;

  > div {
    border-right: 1px solid #bd7c2f;
  }

  color: white;
  font-weight: 700;
  border-bottom-left-radius: ${borderRadiusSize};
  border-bottom-right-radius: ${borderRadiusSize};

  > div {
    position: relative;
    font-size: 24px;
    margin-bottom: 10px;

    > div {
      width: 100%;
      float: left;
      padding: 10px 10px;

      & + div {
        border-top: 2px solid #ffff;
      }
    }

    a {
      text-decoration: none;
      color: white;
    }
  }

  :after {
    visibility: hidden;
    display: block;
    font-size: 0;
    content: ' ';
    clear: both;
    height: 0;
  }
`;

export const IconesContainer = styled.div`
  display: flex;

  svg {
    margin: 5px;
    cursor: pointer;
  }
`;
