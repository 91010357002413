import React, { createContext, useCallback, useState, useContext } from 'react';

import api from '../services/api';

type Props = {
  children: JSX.Element;
};

// interface User {
//   id: string;
//   avatar_url: string;
//   name: string;
//   email: string;
// }

interface SignCredentials {
  email: string;
  password: string;
}

interface SSOCredentials {
  tokenValid: string;
}

interface AuthState {
  token: string;
  nome: string;
  role: string;
  liberado: string;
  completed: boolean;
  locked: boolean;
  foto: string;
  cookie: boolean;
}

interface AuthContextData {
  nome: string;
  role: string;
  liberado: string;
  completed: boolean;
  locked: boolean;
  foto: string;
  cookie: boolean;
  signIn(credentials: SignCredentials): Promise<void>;
  sso(credentials: SSOCredentials): Promise<void>;
  signOut(): void;
}

const AuthContext = createContext<AuthContextData>({} as AuthContextData);

function getCookie(name: string) {
  const value = `; ${document.cookie}`;
  const parts = value.split(`; ${name}=`);
  // console.log(parts.pop()?.split(';').shift());
  // console.log(document.cookie);

  if (parts.length > 0) {
    const x = parts.pop()?.split(';').shift();
    if (x === undefined) return false;
    return true;
  } else {
    return false;
  }
}

export const AuthProvider: React.FC<Props> = ({ children }) => {
  const tempoCookie = 1000 * 24 * 7; // 1000 * horas * dias
  const [data, setData] = useState<AuthState>(() => {
    const token = localStorage.getItem('@atitudevidasaudavel:token');
    const nome = localStorage.getItem('@atitudevidasaudavel:nome');
    const role = localStorage.getItem('@atitudevidasaudavel:role');
    const liberado = localStorage.getItem('@atitudevidasaudavel:liberado');
    const completed = localStorage.getItem('@atitudevidasaudavel:completed') === 'true';
    const locked = localStorage.getItem('@atitudevidasaudavel:locked') === 'true';
    const foto = localStorage.getItem('@atitudevidasaudavel:foto');
    const cookie = getCookie('token');

    if (token && nome && role && liberado && foto) {
      api.defaults.headers.common['Authorization'] = `Bearer ${token}`;
      return { token, nome, role, foto, liberado, completed, locked, cookie };
    }

    return {} as AuthState;
  });

  const signIn = useCallback(
    async ({ email, password }: SignCredentials) => {
      const response = await api.post('authentication/authenticate', {
        email,
        password,
      });

      const { token, nome, role, foto, liberado, completed, locked } = response.data;

      localStorage.setItem('@atitudevidasaudavel:token', token);
      localStorage.setItem('@atitudevidasaudavel:nome', nome);
      localStorage.setItem('@atitudevidasaudavel:role', role);
      localStorage.setItem('@atitudevidasaudavel:liberado', liberado);
      localStorage.setItem('@atitudevidasaudavel:completed', completed);
      localStorage.setItem('@atitudevidasaudavel:locked', locked);
      if (foto) {
        localStorage.setItem('@atitudevidasaudavel:foto', foto);
      } else {
        localStorage.setItem('@atitudevidasaudavel:foto', 'semfoto');
      }

      api.defaults.headers.common['Authorization'] = `Bearer ${token}`;

      const expire = new Date(new Date().getTime() + tempoCookie * 3600);
      document.cookie = `token=${token}; expires=${expire.toUTCString()}`;
      const cookie = getCookie('token');

      setData({ token, nome, role, foto, liberado, completed, locked, cookie });
    },
    [tempoCookie]
  );

  const sso = useCallback(
    async ({ tokenValid }: SSOCredentials) => {
      const response = await api.post('account/authenticateSSO', {
        token: tokenValid,
      });
      const { token, nome, role, liberado, foto, completed, locked } = response.data;

      localStorage.setItem('@atitudevidasaudavel:token', token);
      localStorage.setItem('@atitudevidasaudavel:nome', nome);
      localStorage.setItem('@atitudevidasaudavel:role', role);
      localStorage.setItem('@atitudevidasaudavel:liberado', liberado);
      localStorage.setItem('@atitudevidasaudavel:completed', completed);
      localStorage.setItem('@atitudevidasaudavel:locked', locked);

      if (foto) {
        localStorage.setItem('@atitudevidasaudavel:foto', foto);
      } else {
        localStorage.setItem('@atitudevidasaudavel:foto', 'semfoto');
      }

      api.defaults.headers.common['Authorization'] = `Bearer ${token}`;

      const expire = new Date(new Date().getTime() + tempoCookie * 3600);
      document.cookie = `token=${token}; expires=${expire.toUTCString()}`;
      const cookie = getCookie('token');

      setData({ token, nome, role, foto, liberado, completed, locked, cookie });
    },
    [tempoCookie]
  );

  const signOut = useCallback(() => {
    localStorage.removeItem('@atitudevidasaudavel:token');
    localStorage.removeItem('@atitudevidasaudavel:name');
    localStorage.removeItem('@atitudevidasaudavel:role');
    localStorage.removeItem('@atitudevidasaudavel:foto');
    localStorage.removeItem('@atitudevidasaudavel:completed');
    localStorage.removeItem('@atitudevidasaudavel:locked');

    document.cookie = 'token=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;';

    setData({} as AuthState);
  }, []);

  return (
    <AuthContext.Provider
      value={{
        nome: data.nome,
        role: data.role,
        liberado: data.liberado,
        completed: data.completed,
        locked: data.locked,
        foto: data.foto,
        cookie: data.cookie,
        signIn,
        sso,
        signOut,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

export function useAuth(): AuthContextData {
  const context = useContext(AuthContext);

  return context;
}
