import { FormHandles } from '@unform/core';
import { Form } from '@unform/web';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useParams, useNavigate, Link } from 'react-router-dom';
import { useToast } from '../../hooks/toast';
import * as Yup from 'yup';
import { FiAlertCircle, FiChevronLeft } from 'react-icons/fi';
import api from '../../services/api';
import getValidationErrors from '../../utils/getValidationErrors';

import Input from '../../components/Input';
import Button from '../../components/Button';

import { Container, Content, DesempenhoInput, DivGroup, Referencia, Section, Title } from './styles';
import Modal from '../../components/Modal';

const refVO2max = [
  { valocidade: 0, met: 1.0, vo2max: 3.5 },
  { valocidade: 2, met: 1.8, vo2max: 6.3 },
  { valocidade: 3, met: 2.2, vo2max: 7.7 },
  { valocidade: 4, met: 2.7, vo2max: 9.45 },
  { valocidade: 5, met: 3.2, vo2max: 11.2 },
  { valocidade: 6, met: 4.2, vo2max: 14.7 },
  { valocidade: 7, met: 5.4, vo2max: 18.9 },
  { valocidade: 8, met: 7.1, vo2max: 24.85 },
  { valocidade: 9, met: 8.6, vo2max: 30.1 },
  { valocidade: 10, met: 10.1, vo2max: 35.35 },
  { valocidade: 11, met: 10.9, vo2max: 38.15 },
  { valocidade: 12, met: 12.0, vo2max: 42.0 },
  { valocidade: 13, met: 12.9, vo2max: 45.15 },
  { valocidade: 14, met: 13.8, vo2max: 48.3 },
  { valocidade: 15, met: 14.6, vo2max: 51.1 },
];

const listVO2max = refVO2max.map((r) => (
  <tr key={r.valocidade}>
    <td>{r.valocidade}</td>
    <td>{r.met.toFixed(1)}</td>
    <td>{r.vo2max.toFixed(1)}</td>
  </tr>
));

const Passos: React.FC = () => {
  const { alunoid, id } = useParams();
  const navigate = useNavigate();
  const { addToast } = useToast();
  const formRef = useRef<FormHandles>(null);
  const [loading, setLoading] = useState(false);
  const [peso, setPeso] = useState(0);
  const [referencia, setReferencia] = useState(false);

  useEffect(() => {
    api
      .get('antropometrias/aluno/' + alunoid)
      .then((response) => {
        setPeso(response.data.peso);
      })
      .catch((e) => {
        addToast({
          type: 'error',
          title: 'error',
        });
      });
    if (Number(id) !== 0) {
      api
        .get('passos/' + id)
        .then((response) => {
          formRef.current?.setData(response.data);
        })
        .catch((e) => {
          addToast({
            type: 'error',
            title: 'error',
          });
        });
    }
  }, [alunoid, id, addToast]);

  const calc1 = () => {
    const passos = formRef.current?.getFieldValue('quantidadepassos');
    const tamanho = 5 / passos;
    const qtdM = 1 / tamanho;
    formRef.current?.setFieldValue('tamanhopassos', tamanho.toFixed(1));
    formRef.current?.setFieldValue('passospormetro', qtdM);
  };

  const calc2 = () => {
    const passospormin = formRef.current?.getFieldValue('passospormin');
    const passospormetro = formRef.current?.getFieldValue('passospormetro');
    const metrospormin = passospormin * passospormetro;
    const kmporhora = passospormin / 16.67;
    const gcpormin = kmporhora * peso * 0.017;
    formRef.current?.setFieldValue('metrospormin', metrospormin.toFixed(1));
    formRef.current?.setFieldValue('kmporhora', kmporhora.toFixed(1));
    formRef.current?.setFieldValue('gastocaloricopormin', gcpormin.toFixed(1));
  };

  const calc3 = () => {
    const diasporsemana = formRef.current?.getFieldValue('diasporsemana');
    const passospormetro = formRef.current?.getFieldValue('passospormetro');
    const tempo = formRef.current?.getFieldValue('tempo');
    const metrospormin = formRef.current?.getFieldValue('metrospormin');
    const tamanhopassos = formRef.current?.getFieldValue('tamanhopassos');
    const gcpormin = formRef.current?.getFieldValue('gastocaloricopormin');

    const passospordia = metrospormin * tempo;
    const kmpordia = (passospordia * passospormetro) / 1000;
    const passosporsemana = passospordia * diasporsemana;
    const kmporsemana = (passosporsemana * tamanhopassos) / 1000;
    const passospormes = passosporsemana * 4.5;
    const kmpormes = (passospormes * tamanhopassos) / 1000;
    const gcpordia = gcpormin * tempo;
    const gcporsemana = diasporsemana * gcpordia;

    formRef.current?.setFieldValue('passospordia', passospordia);
    formRef.current?.setFieldValue('kmpordia', kmpordia.toFixed(1));
    formRef.current?.setFieldValue('passosporsemana', passosporsemana);
    formRef.current?.setFieldValue('kmporsemana', kmporsemana);
    formRef.current?.setFieldValue('passospormes', passospormes);
    formRef.current?.setFieldValue('kmpormes', kmpormes);
    formRef.current?.setFieldValue('gastocaloricopordia', gcpordia.toFixed(1));
    formRef.current?.setFieldValue('gastocaloricoporsemana', gcporsemana.toFixed(1));
  };

  const calc4 = () => {
    const velocidade = formRef.current?.getFieldValue('velocidade');
    const gcpormin = velocidade * peso * 0.017;
    const gcpordia = gcpormin * 8.6;
    const gcporsemana = gcpordia * 7.1;
    formRef.current?.setFieldValue('gastocaloricocorridamin', gcpormin.toFixed(1));
    formRef.current?.setFieldValue('gastocaloricocorridadia', gcpordia.toFixed(1));
    formRef.current?.setFieldValue('gastocaloricocorridasemana', gcporsemana.toFixed(1));
  };

  const calc5 = () => {
    const kmporhora = formRef.current?.getFieldValue('kmporhora');
    const passospormetro = formRef.current?.getFieldValue('passospormetro');
    const passospormin = kmporhora * 16.67;
    const metrospormin = passospormin * passospormetro;
    // const gcpormin = kmporhora * peso * 0.017;
    formRef.current?.setFieldValue('passospormin', passospormin.toFixed(0));
    formRef.current?.setFieldValue('metrospormin', metrospormin.toFixed(1));
  };

  const handleSubmit = useCallback(
    async (data: any) => {
      try {
        formRef.current?.setErrors({});
        const schema = Yup.object().shape({});

        await schema.validate(data, {
          abortEarly: false,
        });

        setLoading(true);
        if (Number(id) === 0) {
          await api.post('passos', data);
        } else {
          await api.put('passos/' + id, data);
        }
        setLoading(false);

        formRef.current?.reset();
        addToast({
          type: 'success',
          title: 'Gravado com sucesso!',
        });
        navigate(`/cliente/${alunoid}/questionarios`);
      } catch (error: any) {
        setLoading(false);
        const errors = getValidationErrors(error);
        formRef.current?.setErrors(errors);
        error.inner.forEach((er: any) => {
          addToast({
            type: 'error',
            title: er.message,
          });
        });
        setLoading(false);
        addToast({
          type: 'error',
          title: 'Verifique o formulário',
        });
      }
    },
    [alunoid, id, addToast, navigate]
  );
  return (
    <>
      <Container>
        <Content>
          <Title>
            <h1>Cálculo da caminhada</h1>
            <div style={{ display: 'flex', justifyContent: 'space-around', cursor: 'pointer' }} onClick={() => setReferencia(true)}>
              <h2 style={{ marginRight: 10 }}>Referencias</h2>
              <FiAlertCircle size={25} />
            </div>

            <Link to={`/cliente/${alunoid}/questionarios`}>
              <FiChevronLeft size={20} />
              <span>Voltar</span>
            </Link>
          </Title>

          <Form
            ref={formRef}
            onSubmit={handleSubmit}
            initialData={{ aluno: alunoid }}
            placeholder={undefined}
            onPointerEnterCapture={undefined}
            onPointerLeaveCapture={undefined}
          >
            <DivGroup>
              <div>
                <Section>
                  <strong>Cálculo</strong>
                  <DesempenhoInput>
                    <div>No. de passos em 5m</div>
                    <Input name="quantidadepassos" onChange={calc1} />
                  </DesempenhoInput>
                  <DesempenhoInput>
                    <div>Tamanho do passo em metros</div>
                    <Input name="tamanhopassos" readOnly />
                  </DesempenhoInput>
                  <DesempenhoInput>
                    <div>Qtd passos/metro</div>
                    <Input name="passospormetro" readOnly />
                  </DesempenhoInput>
                  <DesempenhoInput>
                    <div>Qtd passos/min</div>
                    <Input name="passospormin" onChange={calc2} />
                  </DesempenhoInput>
                  <DesempenhoInput>
                    <div>Metros/min</div>
                    <Input name="metrospormin" readOnly />
                  </DesempenhoInput>
                  <DesempenhoInput>
                    <div>Km/h</div>
                    <Input name="kmporhora" onChange={calc5} />
                  </DesempenhoInput>
                </Section>
                <Section>
                  <strong>Pescrição</strong>
                  <DesempenhoInput>
                    <div>Qtd de dias/semana</div>
                    <Input name="diasporsemana" />
                  </DesempenhoInput>
                  <DesempenhoInput>
                    <div>Tempo da caminhada(min)</div>
                    <Input name="tempo" onChange={calc3} />
                  </DesempenhoInput>
                  <DesempenhoInput>
                    <div>Passos/dia</div>
                    <Input name="passospordia" readOnly />
                  </DesempenhoInput>
                  <DesempenhoInput>
                    <div>Km/dia</div>
                    <Input name="kmpordia" readOnly />
                  </DesempenhoInput>
                  <DesempenhoInput>
                    <div>Passos/semana</div>
                    <Input name="passosporsemana" readOnly />
                  </DesempenhoInput>
                  <DesempenhoInput>
                    <div>Km/semana</div>
                    <Input name="kmporsemana" readOnly />
                  </DesempenhoInput>
                  <DesempenhoInput>
                    <div>Passos/mês</div>
                    <Input name="passospormes" readOnly />
                  </DesempenhoInput>
                  <DesempenhoInput>
                    <div>Km/mês</div>
                    <Input name="kmpormes" readOnly />
                  </DesempenhoInput>
                </Section>
                <Section>
                  <strong>Gasto calórico caminhada</strong>
                  <DesempenhoInput>
                    <div>GC(min)</div>
                    <Input name="gastocaloricopormin" />
                  </DesempenhoInput>
                  <DesempenhoInput>
                    <div>GC/dia</div>
                    <Input name="gastocaloricopordia" />
                  </DesempenhoInput>
                  <DesempenhoInput>
                    <div>GC/semana</div>
                    <Input name="gastocaloricoporsemana" />
                  </DesempenhoInput>
                </Section>
                <Section>
                  <DesempenhoInput>
                    <div>Velocidade média no treino</div>
                    <Input name="velocidade" onChange={calc4} />
                  </DesempenhoInput>
                  <DesempenhoInput>
                    <div>GC(min)</div>
                    <Input name="gastocaloricocorridamin" />
                  </DesempenhoInput>
                  <DesempenhoInput>
                    <div>GC/dia</div>
                    <Input name="gastocaloricocorridadia" />
                  </DesempenhoInput>
                  <DesempenhoInput>
                    <div>GC/semana</div>
                    <Input name="gastocaloricocorridasemana" />
                  </DesempenhoInput>
                </Section>
              </div>
            </DivGroup>
            <Button loading={loading} disabled={loading} type="submit">
              Gravar
            </Button>
            <div style={{ visibility: 'hidden' }}>
              <Input name="aluno" type="number" />
            </div>
          </Form>
          <Modal visible={referencia} close={() => setReferencia(false)}>
            <Section>
              <strong>Referência VO2máx - corrida</strong>
              <Referencia>
                <table>
                  <thead>
                    <tr>
                      <th>Intesidade</th>
                      <th>% VO2 Máx</th>
                      <th>% FC Máx</th>
                    </tr>
                  </thead>
                  <tbody>{listVO2max}</tbody>
                </table>
              </Referencia>
            </Section>
          </Modal>
        </Content>
      </Container>
    </>
  );
};

export default Passos;
