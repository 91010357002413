import React, { useCallback, useEffect, useRef, useState } from 'react';
import { FormHandles } from '@unform/core';
import { Form } from '@unform/web';
import { FiChevronLeft } from 'react-icons/fi';
import { Link, useNavigate, useParams } from 'react-router-dom';
import * as Yup from 'yup';

import { useToast } from '../../hooks/toast';

import { Container, Content, Main, SubTitle, Title } from './styles';
import Input from '../../components/Input';
import api from '../../services/api';
import Button from '../../components/Button';
import Option from '../../components/Option';
import getValidationErrors from '../../utils/getValidationErrors';
import { options, options1, options2, options3, options4, options5, options6, options7 } from './respostas';

const Anamnese: React.FC = () => {
  const { alunoid, id } = useParams();
  const navigate = useNavigate();
  const { addToast } = useToast();
  const formRef = useRef<FormHandles>(null);
  const [loading, setLoading] = useState(false);
  const [resp, setResp] = useState<any>({});

  useEffect(() => {
    if (Number(id) !== 0) {
      setLoading(true);
      api
        .get(`anamneses/${id}`)
        .then((response) => {
          formRef.current?.setData(response.data);
          setLoading(false);
        })
        .catch((e) => {
          addToast({
            type: 'error',
            title: 'error',
          });
        });
    }
  }, [id, addToast]);

  useEffect(() => {
    console.log(resp);
  }, [resp]);

  const handleSubmit = useCallback(
    async (data: any) => {
      try {
        formRef.current?.setErrors({});
        const schema = Yup.object().shape({
          aluno: Yup.number().required('Aluno obrigatorio'),
          ini01: Yup.string().required('Pergunta 1 obrigatório'),
          ini02: Yup.string(),
          ini03: Yup.string().required('Pergunta 3 obrigatório'),
          ini04: Yup.string(),
          ini05: Yup.string(),
          ini06: Yup.string().required('Pergunta 6 obrigatório'),
          ini07: Yup.string(),
          ini08: Yup.string().required('Pergunta 8 obrigatório'),
          ini09: Yup.string(),
          ini10: Yup.string().required('Pergunta 10 obrigatório'),
          ini11: Yup.string(),
          ini12: Yup.string().required('Pergunta 12 obrigatório'),
          ini13: Yup.string(),
          ini14: Yup.string().required('Pergunta 14 obrigatório'),
          ini15: Yup.string().required('Pergunta 15 obrigatório'),
          ini16: Yup.string().required('Pergunta 16 obrigatório'),
          ini17: Yup.string().required('Pergunta 17 obrigatório'),
          ini18: Yup.string().required('Pergunta 18 obrigatório'),
          ini19: Yup.string().required('Pergunta 19 obrigatório'),
          ini20: Yup.string().required('Pergunta 20 obrigatório'),
          ini21: Yup.string().required('Pergunta 21 obrigatório'),
          ini22: Yup.string().required('Pergunta 22 obrigatório'),
          ini23: Yup.string().required('Pergunta 23 obrigatório'),
          ini24: Yup.string().required('Pergunta 24 obrigatório'),
        });

        await schema.validate(data, {
          abortEarly: false,
        });

        setLoading(true);
        if (Number(id) === 0) {
          await api.post('anamneses', data);
        } else {
          await api.put('anamneses/' + id, data);
        }
        setLoading(false);

        formRef.current?.reset();
        addToast({
          type: 'success',
          title: 'Gravado com sucesso!',
        });
        navigate(`/cliente/${alunoid}/questionarios`);
      } catch (error: any) {
        setLoading(false);
        if (error.name === 'AxiosError') {
          addToast({
            type: 'error',
            title: error.message,
          });
          return;
        }
        const errors = getValidationErrors(error);
        formRef.current?.setErrors(errors);
        error.inner.forEach((er: any) => {
          addToast({
            type: 'error',
            title: er.message,
          });
        });
        addToast({
          type: 'error',
          title: 'Verifique o formulário',
        });
      }
    },
    [alunoid, id, addToast, navigate]
  );

  return (
    <>
      <Container>
        {/* <Header /> */}
        <Main>
          <Content>
            <Title>
              <h1>Anamnese</h1>
              <Link to={`/cliente/${alunoid}/questionarios`}>
                <FiChevronLeft size={20} />
                <span>Voltar</span>
              </Link>
            </Title>
            <Form
              ref={formRef}
              onSubmit={handleSubmit}
              initialData={{ aluno: alunoid }}
              placeholder={undefined}
              onPointerEnterCapture={undefined}
              onPointerLeaveCapture={undefined}
            >
              <div>
                <label>1) Faz algum acompanhamento/tratamento médico?</label>
                <Option name="ini01" options={options} onChange={(e) => setResp({ ...resp, ini01: e.target.value })} />
              </div>
              {resp.ini01 === '1' && (
                <div>
                  <label>1.1) Nos últimos 12 meses quantas vezes se consultou?</label>
                  <Option name="ini02" options={options1} />
                </div>
              )}
              <div>
                <label>2) Faz algum acompanhamento/tratamento psicológico/psiquiátrico?</label>
                <Option name="ini03" options={options} onChange={(e) => setResp({ ...resp, ini03: e.target.value })} />
              </div>
              {resp.ini03 === '1' && (
                <>
                  <div>
                    <label>2.1) Se sim, qual o motivo?</label>
                    <Option name="ini04" options={options2} />
                  </div>
                  <div>
                    <label>2.2) Nos últimos 12 meses quantas vezes se consultou?</label>
                    <Option name="ini05" options={options1} />
                  </div>
                </>
              )}
              <div>
                <label>3) Faz algum acompanhamento/tratamento odontológico?</label>
                <Option name="ini06" options={options} onChange={(e) => setResp({ ...resp, ini06: e.target.value })} />
              </div>
              {resp.ini06 === '1' && (
                <div>
                  <label>3.1) Qual foi a última vez que se consultou ao dentista?</label>
                  <Option name="ini07" options={options3} />
                </div>
              )}
              <div>
                <label>4) Faz algum acompanhamento nutricional por recomendação médica?</label>
                <Option name="ini08" options={options} onChange={(e) => setResp({ ...resp, ini08: e.target.value })} />
              </div>
              {resp.ini08 === '1' && (
                <div>
                  <label>4.1) Nos últimos 12 meses quantas vezes se consultou?</label>
                  <Option name="ini09" options={options1} />
                </div>
              )}
              <div>
                <label>5) Toma algum tipo de medicamento?</label>
                <Option name="ini10" options={options} onChange={(e) => setResp({ ...resp, ini10: e.target.value })} />
              </div>
              {resp.ini10 === '1' && (
                <div>
                  <label>5.1) Quem recomendou?</label>
                  <Option name="ini11" options={options4} />
                </div>
              )}
              <div>
                <label>6) Possui Plano de saúde?</label>
                <Option name="ini12" options={options} onChange={(e) => setResp({ ...resp, ini12: e.target.value })} />
              </div>
              {resp.ini12 === '1' && (
                <div>
                  <label>6.1) Se sim, qual?</label>
                  <Option name="ini13" options={options5} />
                </div>
              )}
              <div>
                <label>7) Como você classifica o seu estado de saúde atual?</label>
                <Option name="ini14" options={options6} />
              </div>
              <div>
                <label>8) Seu pai ou mãe, ou ainda, avó ou avô tiveram alguma dessas doenças?</label>
                <Option name="ini15" options={options7} />
              </div>
              <SubTitle>Algum médico já disse que você tem ou teve:</SubTitle>
              <div>
                <label>9) Diabetes?</label>
                <Option name="ini16" options={options} />
              </div>
              <div>
                <label>10) Hipertensão arterial?</label>
                <Option name="ini17" options={options} />
              </div>
              <div>
                <label>11) Obesidade?</label>
                <Option name="ini18" options={options} />
              </div>
              <div>
                <label>12) Doença Respiratória (bronquite, asma, rinite, sinusite ou outra)?</label>
                <Option name="ini19" options={options} />
              </div>
              <div>
                <label>13) Doença articular (artrite, artrose ou outra)?</label>
                <Option name="ini20" options={options} />
              </div>
              <div>
                <label>14) Problema de Coluna (costas)?</label>
                <Option name="ini21" options={options} />
              </div>
              <div>
                <label>15) Tendinite ou LER?</label>
                <Option name="ini22" options={options} />
              </div>
              <div>
                <label>16) Depressão?</label>
                <Option name="ini23" options={options} />
              </div>
              <div>
                <label>17) Transtorno de Ansiedade?</label>
                <Option name="ini24" options={options} />
              </div>

              <Button loading={loading} disabled={loading} type="submit">
                Gravar
              </Button>
              <div style={{ visibility: 'hidden' }}>
                <Input name="aluno" type="number" />
              </div>
            </Form>
          </Content>
        </Main>
      </Container>
    </>
  );
};

export default Anamnese;
