import { Check } from 'phosphor-react';
import * as Checkbox from '@radix-ui/react-checkbox';
import { FormEvent, useEffect, useState } from 'react';
import api from '../../../services/api';
import { useToast } from '../../../hooks/toast';
import { convertToDate } from '../../../utils/converter';
import * as RadioGroup from '@radix-ui/react-radio-group';

const availableDiasSemana = ['Domingo', 'Segunda-feira', 'Terça-feira', 'Quarta-feira', 'Quinta-feira', 'Sexta-feira', 'Sábado'];
// const tipoOperacao = ['Individual', 'Grupo'];

export function HabitoForm() {
  const { addToast } = useToast();
  const [modelos, setModelos] = useState([]);
  const [alunos, setAlunos] = useState([]);
  const [grupos, setGrupos] = useState([]);
  const [atividades, setAtividades] = useState([]);
  const [aluno, setAluno] = useState<string | undefined>(undefined);
  const [grupo, setGrupo] = useState<string | undefined>(undefined);
  const [modelo, setModelo] = useState<any | undefined>(undefined);
  const [titulo, setTitulo] = useState('');
  const [atividade, setAtividade] = useState<string | undefined>(undefined);
  const [diasSemana, setDiasSemana] = useState<number[]>([]);
  const [dataIni, setDataIni] = useState('');
  const [dataFim, setDataFim] = useState('');
  const [tipo, setTipo] = useState('1');

  useEffect(() => {
    api
      .get(`habitos/all`)
      .then((response) => {
        setModelos(response.data);
      })
      .catch((error) => {
        addToast({
          type: 'error',
          title: error.message,
        });
      });
  }, [addToast]);

  useEffect(() => {
    api
      .get('alunos/all')
      .then((response) => {
        // eslint-disable-next-line
        response.data.map((r: any) => ((r.value = r.id), (r.name = r.nomeCompleto)));
        setAlunos(response.data);
      })
      .catch((error) => {
        addToast({
          type: 'error',
          title: error.message,
        });
      });
  }, [addToast]);

  useEffect(() => {
    api
      .get('grupos/all')
      .then((response) => {
        setGrupos(response.data);
      })
      .catch((error) => {
        addToast({
          type: 'error',
          title: error.message,
        });
      });
  }, [addToast]);

  useEffect(() => {
    api
      .get('aulas/all')
      .then((response) => {
        setAtividades(response.data);
      })
      .catch((error) => {
        addToast({
          type: 'error',
          title: error.message,
        });
      });
  }, [addToast]);

  useEffect(() => {
    const _modelo: any = modelos.find((m: any) => m.id === Number(modelo));
    if (_modelo === undefined) return;

    setTitulo(_modelo.titulo);
    setAtividade(_modelo.atividade);
    const diasSemanaWithAddedOne: any = [];
    _modelo.diasSemana.map((d: any) => {
      diasSemanaWithAddedOne.push(Number(d.diaSemana));
      setDiasSemana(diasSemanaWithAddedOne);
    });
  }, [modelo]);

  async function createNewHabit(event: FormEvent) {
    event.preventDefault();

    if (!titulo || diasSemana.length === 0) {
      return;
    }

    const novaDataIni = convertToDate(dataIni);
    const novaDataFim = convertToDate(dataFim);

    if (novaDataFim <= novaDataIni || novaDataIni.toString() === 'Invalid Date' || novaDataFim.toString() === 'Invalid Date') {
      addToast({
        type: 'error',
        title: 'Data invalida!',
      });
      return;
    }

    await api.post('habitos', {
      titulo,
      diasSemana,
      dataInicio: novaDataIni,
      dataFim: novaDataFim,
      aluno,
      grupo,
      atividade,
      tipo,
    });

    setTitulo('');
    setAluno('');
    setGrupo('');
    setAtividade('');
    setDiasSemana([]);
    setDataIni('');
    setDataFim('');

    addToast({
      type: 'success',
      title: 'Hábito criado com sucesso!',
    });
  }

  function handleToggleWeekDay(weekDay: number) {
    if (diasSemana.includes(weekDay)) {
      const diasSemanaWithRemovedOne = diasSemana.filter((day) => day !== weekDay);
      setDiasSemana(diasSemanaWithRemovedOne);
    } else {
      const diasSemanaWithAddedOne = [...diasSemana, weekDay];
      setDiasSemana(diasSemanaWithAddedOne);
    }
  }

  const onOptionChange = (value: string) => {
    setTipo(value);
    setAluno('');
    setGrupo('');
  };

  return (
    <form onSubmit={createNewHabit} className="w-full flex flex-col mt-6">
      <RadioGroup.Root className="flex flex-row gap-2.5" defaultValue="1" onValueChange={(val) => onOptionChange(val)}>
        <div className="flex items-center">
          <RadioGroup.Item
            className="bg-white w-[25px] h-[25px] rounded-lg focus:bg-blue-600 outline-none cursor-pointer"
            value="1"
            id="r1"
          >
            <RadioGroup.Indicator className="flex items-center justify-center w-full h-full relative after:content-[''] after:block after:w-full after:h-full after:rounded-lg after:bg-blue-600" />
          </RadioGroup.Item>
          <label className="text-indigo-900 text-[15px] leading-none pl-[15px]" htmlFor="r1">
            Individual
          </label>
        </div>
        <div className="flex items-center">
          <RadioGroup.Item
            className="bg-white w-[25px] h-[25px] rounded-lg focus:bg-blue-600 outline-none cursor-pointer"
            value="2"
            id="r2"
          >
            <RadioGroup.Indicator className="flex items-center justify-center w-full h-full relative after:content-[''] after:block after:w-full after:h-full after:rounded-lg after:bg-blue-600" />
          </RadioGroup.Item>
          <label className="text-indigo-900 text-[15px] leading-none pl-[15px]" htmlFor="r2">
            Grupo
          </label>
        </div>
        <div className="flex items-center">
          <RadioGroup.Item
            className="bg-white w-[25px] h-[25px] rounded-lg focus:bg-blue-600 outline-none cursor-pointer"
            value="3"
            id="r3"
          >
            <RadioGroup.Indicator className="flex items-center justify-center w-full h-full relative after:content-[''] after:block after:w-full after:h-full after:rounded-lg after:bg-blue-600" />
          </RadioGroup.Item>
          <label className="text-indigo-900 text-[15px] leading-none pl-[15px]" htmlFor="r3">
            Modelo
          </label>
        </div>
      </RadioGroup.Root>

      {tipo !== '3' && (
        <>
          <label htmlFor="modelo" className="font-medium leading-tight mt-3">
            Modelo
          </label>
          <select
            id="modelo"
            className="p-4 rounded-lg mt-3 bg-white text-indigo-900 placeholder:text-indigo-900 focus:outline-none"
            autoFocus
            value={modelo}
            onChange={(event) => setModelo(event.target.value)}
          >
            <option></option>
            {modelos.map((o: any) => (
              <option key={o.id} value={o.id}>
                {o.titulo}
              </option>
            ))}
          </select>
        </>
      )}
      <label htmlFor="cliente" className="font-medium leading-tight mt-3">
        {tipo === '1' ? 'Cliente' : tipo === '2' ? 'Grupo' : 'Modelo'}
      </label>
      {tipo === '1' && (
        <select
          id="cliente"
          className="p-4 rounded-lg mt-3 bg-white text-indigo-900 placeholder:text-indigo-900 focus:outline-none"
          autoFocus
          value={aluno}
          onChange={(event) => setAluno(event.target.value)}
        >
          <option></option>
          {alunos.map((o: any) => (
            <option key={o.value} value={o.value}>
              {o.name}
            </option>
          ))}
        </select>
      )}
      {tipo === '2' && (
        <select
          id="cliente"
          className="p-4 rounded-lg mt-3 bg-white text-indigo-900 placeholder:text-indigo-900 focus:outline-none"
          autoFocus
          value={grupo}
          onChange={(event) => setGrupo(event.target.value)}
        >
          <option></option>
          {grupos.map((o: any) => (
            <option key={o.value} value={o.value}>
              {o.name}
            </option>
          ))}
        </select>
      )}

      <label htmlFor="titulo" className="mt-3 font-medium leading-tight">
        Qual o comportamento?
      </label>

      <input
        type="text"
        id="titulo"
        placeholder="ex.: Exercícios, dormir bem, etc..."
        className="p-4 rounded-lg mt-3 bg-white text-indigo-900 placeholder:text-indigo-900 focus:outline-none"
        value={titulo}
        onChange={(event) => setTitulo(event.target.value)}
      />

      <label htmlFor="titulo" className="mt-3 font-medium leading-tight">
        Atividade
      </label>

      <select
        id="atividade"
        className="p-4 rounded-lg mt-3 bg-white text-indigo-900 placeholder:text-indigo-900 focus:outline-none"
        autoFocus
        value={atividade}
        onChange={(event) => setAtividade(event.target.value)}
      >
        <option></option>
        {atividades.map((o: any) => (
          <option key={o.id} value={o.id}>
            {o.nome}
          </option>
        ))}
      </select>

      <label htmlFor="" className="font-medium leading-tight mt-4">
        Qual a recorrência?
      </label>

      <div className="flex flex-col gap-2 mt-3">
        {availableDiasSemana.map((weekDay, index) => (
          <Checkbox.Root
            key={weekDay}
            className="flex items-center gap-3 group focus:outline-none"
            checked={diasSemana.includes(index + 1)}
            onCheckedChange={() => handleToggleWeekDay(index + 1)}
          >
            <div className="h-8 w-8 rounded-lg flex items-center justify-center bg-white border-2 border-white group-data-[state=checked]:bg-blue-600 group-data-[state=checked]:border-blue-50 transition-colors group-focus:ring-2 group-focus:ring-blue-600 group-focus:ring-offset-2 group-focus:ring-offset-background">
              <Checkbox.Indicator>
                <Check size={20} className="text-white" />
              </Checkbox.Indicator>
            </div>

            <span className="text-indigo-900 leading-tight">{weekDay}</span>
          </Checkbox.Root>
        ))}
      </div>

      <label htmlFor="dtInicio" className="mt-3 font-medium leading-tight">
        Data Inicio
      </label>

      <input
        type="text"
        id="dtInicio"
        placeholder="ex.: 01/01/2024"
        maxLength={10}
        className="p-4 rounded-lg mt-3 bg-white text-indigo-900 placeholder:text-indigo-900 focus:outline-none"
        value={dataIni}
        onChange={(event) => setDataIni(event.target.value)}
      />

      <label htmlFor="dtTermino" className="mt-3 font-medium leading-tight">
        Data Termino
      </label>

      <input
        type="text"
        id="dtTermino"
        placeholder="ex.: 01/01/2024"
        maxLength={10}
        className="p-4 rounded-lg mt-3 bg-white text-indigo-900 placeholder:text-indigo-900 focus:outline-none"
        value={dataFim}
        onChange={(event) => setDataFim(event.target.value)}
      />
      <button
        type="submit"
        className="mt-6 rounded-lg p-4 flex items-center justify-center gap-3 font-medium text-white bg-blue-600 hover:bg-blue-500 transition-colors focus:outline-none focus:ring-2 focus:ring-blue-600 focus:ring-offset-2 focus:ring-offset-zinc-900"
      >
        {/* <Check size={20} weight="bold" /> */}
        Confirmar
      </button>
    </form>
  );
}
