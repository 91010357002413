import { Navigate, Outlet } from 'react-router-dom';

interface IProtectedRoute {
  isAllowed: boolean;
  isLiberado?: string;
  isCompleted?: boolean;
  isLocked?: boolean;
  redirectPath?: string;
  children?: JSX.Element;
}

export const ProtectedRoute = ({ isAllowed, isLiberado, isCompleted, redirectPath = '/login', children }: IProtectedRoute) => {
  if (!isAllowed) {
    return <Navigate to={redirectPath} replace />;
  }

  return children ? children : <Outlet />;
};

export const UnProtectedRoute = ({ isAllowed, redirectPath = '/', children }: IProtectedRoute) => {
  if (isAllowed) {
    return <Navigate to={redirectPath} replace />;
  }
  return children ? children : <Outlet />;
};
