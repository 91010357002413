import React, { useCallback, useEffect, useRef, useState } from 'react';
import { FormHandles } from '@unform/core';
import { Form } from '@unform/web';
import { FiChevronLeft } from 'react-icons/fi';
import { Link, useNavigate, useParams } from 'react-router-dom';
import * as Yup from 'yup';

import { useToast } from '../../hooks/toast';

import { Container, Content, Main, Title } from './styles';
import Input from '../../components/Input';
import api from '../../services/api';
import Button from '../../components/Button';
import getValidationErrors from '../../utils/getValidationErrors';

const Neuroq: React.FC = () => {
  const { alunoid, id } = useParams();
  const navigate = useNavigate();
  const { addToast } = useToast();
  const formRef = useRef<FormHandles>(null);
  const [loading, setLoading] = useState(false);
  // const [alerta, setAlerta] = useState(false);

  useEffect(() => {
    if (Number(id) !== 0) {
      setLoading(true);
      api
        .get(`neuroq/${id}`)
        .then((response) => {
          formRef.current?.setData(response.data);
          setLoading(false);
        })
        .catch((e) => {
          addToast({
            type: 'error',
            title: 'error',
          });
        });
    }
  }, [id, addToast]);

  const handleSubmit = useCallback(
    async (data: any) => {
      try {
        formRef.current?.setErrors({});
        const schema = Yup.object().shape({
          aluno: Yup.number().required('Aluno obrigatorio'),
          qesa01: Yup.string().required('Pergunta 1 obrigatório'),
          qesa02: Yup.string().required('Pergunta 2 obrigatório'),
          qesa03: Yup.string().required('Pergunta 3 obrigatório'),
          qesa04: Yup.string().required('Pergunta 4 obrigatório'),
          qesa05: Yup.string().required('Pergunta 5 obrigatório'),
          qesa06: Yup.string().required('Pergunta 6 obrigatório'),
          qesa07: Yup.string().required('Pergunta 7 obrigatório'),
          qesa08: Yup.string().required('Pergunta 8 obrigatório'),
          qesa09: Yup.string().required('Pergunta 9 obrigatório'),
          qesa10: Yup.string().required('Pergunta 10 obrigatório'),
          qesa11: Yup.string().required('Pergunta 11 obrigatório'),
          qesa12: Yup.string().required('Pergunta 12 obrigatório'),
          qesa13: Yup.string().required('Pergunta 13 obrigatório'),
          qesa14: Yup.string().required('Pergunta 14 obrigatório'),
          qesa15: Yup.string().required('Pergunta 15 obrigatório'),
          qesa16: Yup.string().required('Pergunta 16 obrigatório'),
          qesa17: Yup.string().required('Pergunta 17 obrigatório'),
          qesa18: Yup.string().required('Pergunta 18 obrigatório'),
          qesa19: Yup.string().required('Pergunta 19 obrigatório'),
          qesa20: Yup.string().required('Pergunta 20 obrigatório'),
          qesa21: Yup.string().required('Pergunta 21 obrigatório'),
          qesa22: Yup.string().required('Pergunta 22 obrigatório'),
          qesa23: Yup.string().required('Pergunta 23 obrigatório'),
          qesa24: Yup.string().required('Pergunta 24 obrigatório'),
          qesa25: Yup.string().required('Pergunta 25 obrigatório'),
          qesa26: Yup.string().required('Pergunta 26 obrigatório'),
        });

        await schema.validate(data, {
          abortEarly: false,
        });

        setLoading(true);

        if (Number(id) === 0) {
          await api.post('neuroq', data);
        } else {
          await api.put('neuroq/' + id, data);
        }
        setLoading(false);
        formRef.current?.reset();
        addToast({
          type: 'success',
          title: 'Gravado com sucesso!',
        });

        navigate(`/cliente/${alunoid}/questionarios`);
      } catch (error: any) {
        setLoading(false);
        const errors = getValidationErrors(error);
        formRef.current?.setErrors(errors);
        error.inner.forEach((er: any) => {
          addToast({
            type: 'error',
            title: er.message,
          });
        });
        addToast({
          type: 'error',
          title: 'Verifique o formulário',
        });
      }
    },
    [alunoid, id, addToast, navigate]
  );

  return (
    <Container>
      <Main>
        <Content>
          <Title>
            <h1>NeuroQ</h1>
            <Link to={`/cliente/${alunoid}/questionarios`}>
              <FiChevronLeft size={20} />
              <span>Voltar</span>
            </Link>
          </Title>
          <Form
            ref={formRef}
            onSubmit={handleSubmit}
            initialData={{ aluno: alunoid }}
            placeholder={undefined}
            onPointerEnterCapture={undefined}
            onPointerLeaveCapture={undefined}
          >
            <div>
              <label>1) Qual objetivo principal fez você querer praticar atividade física?</label>
              <Input type="textarea" name="qesa01" />
            </div>
            <div>
              <label>2) Que benefícios que você percebe que trará para sua vida?</label>
              <Input type="textarea" name="qesa02" />
            </div>
            <div>
              <label>3) Por quê estes objetivos e benefícios são importantes para você?</label>
              <Input type="textarea" name="qesa03" />
            </div>
            <div>
              <label>4) De 0 a 10 qual o grau de engajamento está para alcançar estes objetivos?</label>
              <Input type="textarea" name="qesa04" />
            </div>
            <div>
              <label>
                5) Você já pratica atividade física? No seu lazer, no seu trabalho, na sua casa ou deslocamento de casa para o trabalho e
                retorno.
              </label>
              <Input type="textarea" name="qesa05" />
            </div>
            <div>
              <label>5.1) Se sim, o que faz?</label>
              <Input type="textarea" name="qesa06" />
            </div>
            <div>
              <label>5.2) Aonde faz?</label>
              <Input type="textarea" name="qesa07" />
            </div>
            <div>
              <label>5.3) Quantas vezes na semana?</label>
              <Input type="textarea" name="qesa08" />
            </div>
            <div>
              <label>5.4) E quantas horas por dia?</label>
              <Input type="textarea" name="qesa09" />
            </div>
            <div>
              <label>6) Se não, já praticou?</label>
              <Input type="textarea" name="qesa10" />
            </div>
            <div>
              <label>6.1) Qual atividade?</label>
              <Input type="textarea" name="qesa11" />
            </div>
            <div>
              <label>6.2) Há quanto tempo está parada(o)?</label>
              <Input type="textarea" name="qesa12" />
            </div>
            <div>
              <label>7) Que tipo de atividade física é mais prazerosa para você praticar?</label>
              <Input type="textarea" name="qesa13" />
            </div>
            <div>
              <label>
                7.1) Qual(ais) atividade(s) física(s) poderia adicionar no seu lazer, em casa, no seu trabalho ou nos seus deslocamento para
                trabalho e retorno?
              </label>
              <Input type="textarea" name="qesa14" />
            </div>
            <div>
              <label>
                8) Que locais você tem oportunidade para fazer atividade física? Condomínio, praças, praias, academias, clubes, trabalho e
                etc.
              </label>
              <Input type="textarea" name="qesa15" />
            </div>
            <div>
              <label>9) Quais os dias da semana que você poderia praticar atividade física e que só depende de você se organizar?</label>
              <Input type="textarea" name="qesa16" />
            </div>
            <div>
              <label>10) Quanto tempo você consegue priorizar nestes dias para praticar atividade física?</label>
              <Input type="textarea" name="qesa17" />
            </div>
            <div>
              <label>11) Sente alguma dor no corpo? Aonde?</label>
              <Input type="textarea" name="qesa18" />
            </div>
            <div>
              <label>12) Possui alguma restrição a movimentos para as atividades da vida diária?</label>
              <Input type="textarea" name="qesa19" />
            </div>
            <div>
              <label>13) Qual a posição predominante no seu trabalho? (Sentado(a), em pé, outra)</label>
              <Input type="textarea" name="qesa20" />
            </div>
            <div>
              <label>13.1) Qual a posição predominante no seu trabalho? (Sentado(a), em pé, outra)</label>
              <Input type="textarea" name="qesa21" />
            </div>
            <div>
              <label>13.2) Qual a posição predominante no seu trabalho? (Sentado(a), em pé, outra)</label>
              <Input type="textarea" name="qesa22" />
            </div>
            <div>
              <label>14) Sua atividade profissional exige muita concentração?</label>
              <Input type="textarea" name="qesa23" />
            </div>
            <div>
              <label>14.1) É estressante?</label>
              <Input type="textarea" name="qesa24" />
            </div>
            <div>
              <label>15) Dorme bem?</label>
              <Input type="textarea" name="qesa25" />
            </div>
            <div>
              <label>15.1) Acorda disposta?</label>
              <Input type="textarea" name="qesa26" />
            </div>
            <Button loading={loading} disabled={loading} type="submit">
              Gravar
            </Button>
            <div style={{ visibility: 'hidden' }}>
              <Input name="aluno" type="number" />
            </div>
          </Form>
        </Content>
      </Main>
    </Container>
  );
};

export default Neuroq;
