import { shade } from 'polished';
import styled from 'styled-components';
import { device } from '../../styles/global';

export const Container = styled.div``;

export const Main = styled.main`
  max-width: 1120px;
  margin: 64px auto;
  display: flex;
`;

export const Content = styled.div`
  flex: 1;
  width: 100%;
  /* margin-right: 120px; */

  h1 {
    font-size: 36px;
  }

  p {
    margin-top: 8px;
    /* color: #ff9000; */
    display: flex;
    align-items: center;
    font-weight: 500;

    span {
      display: flex;
      align-items: center;
    }
    span + span::before {
      content: '';
      width: 1px;
      height: 12px;
      background: #ff9000;
      margin: 0 10px;
    }
  }

  form {
    margin-top: 15px;
    font-size: 15px;

    > div {
      > label {
        font-weight: 500;
      }

      & + div {
        margin-top: 15px;
      }
    }
  }

  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
`;

export const Section = styled.section`
  margin-top: 48px;
  > strong {
    color: #999591;
    font-size: 20px;
    line-height: 26px;
    border-bottom: 1px solid #3e3b47;
    display: block;
    padding-bottom: 16px;
    margin-bottom: 16px;
  }
`;

export const Title = styled.div`
  display: flex;
  justify-content: space-between;

  h1 {
    color: #525f7f;
  }

  a {
    display: flex;
    flex-direction: row;
    justify-content: center;
    max-width: 15%;
    max-height: 35px;
    display: flex;
    text-align: center;
    background: #1171ef;
    height: 56px;
    border-radius: 10px;
    border: 0;
    padding: 0 16px;
    color: #ffffff;
    text-decoration: none;
    width: 100%;
    font-weight: 500;
    margin-left: 10px;
    transition: background-color 0.2s;

    &:hover {
      background: ${shade(0.2, '#1171ef')};
    }

    > * {
      margin: auto 0;
    }
  }
`;

export const SubTitle = styled.h3`
  margin-bottom: 10px;
  color: #525f7f;
`;

export const Ul = styled.ul`
  display: grid;
`;

export const Row = styled.li`
  position: relative;
  display: flex;
  -webkit-box-align: center;
  align-items: center;
  background-color: #d1d1d1;
  color: #525f7f;
  border-radius: 5px;
  padding: 10px;
  margin-bottom: 5px;

  > div {
    display: flex;
    flex-direction: row;
    -webkit-box-align: center;
    align-items: center;
    place-self: stretch flex-start;
    width: 100%;

    div {
      > div {
        padding: 5px;
      }
    }
  }
`;

export const SectionOne = styled.div`
  display: grid;
  grid-auto-flow: column;
  grid-auto-columns: repeat(4, 1fr);
  gap: 16px;
  margin-bottom: 10px;
`;

export const SectionTwo = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  gap: 16px;
`;

export const SectionRecomendacao = styled.div`
  display: grid;
  grid: 'OP1 OP2 OP3 OP4 OP5 OP6';
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
  gap: 16px;

  @media ${device.mobileL_Max} {
    display: flex;
    flex-wrap: wrap;
    flex-direction: column;
  }
`;

export const SubSection = styled.div`
  display: grid;
  grid-auto-flow: row;
  grid-auto-columns: 1fr;
  gap: 16px;
`;

export const Label = styled.label`
  display: flex;
  -webkit-box-align: center;
  align-items: center;

  > div {
    display: flex;
    -webkit-box-align: center;
    align-items: center;
    cursor: pointer;
    max-width: 350px;
  }
`;
