import styled from 'styled-components';

export const Container = styled.div`
  display: block; /* Hidden by default */
  position: fixed; /* Stay in place */
  z-index: 9999; /* Sit on top */
  padding-top: 100px; /* Location of the box */
  left: 0;
  top: 0;
  width: 100%; /* Full width */
  height: 100%; /* Full height */
  overflow: auto; /* Enable scroll if needed */
  background-color: rgb(0, 0, 0); /* Fallback color */
  background-color: rgba(0, 0, 0, 0.4); /* Black w/ opacity */

  > div {
    background-color: #f1f1f1;
    margin: auto;
    /* padding: 20px; */
    border: 1px solid #f1f1f1;
    width: 60%;
    border-radius: 5px;

    @media only screen and (max-width: 600px) {
      width: 100%;
    }

    > span {
      color: #aaaaaa;
      float: right;
      font-size: 28px;
      font-weight: bold;
      margin-right: 15px;

      :hover,
      :focus {
        color: #000;
        text-decoration: none;
        cursor: pointer;
      }
    }
    > div {
      padding: 20px;
    }
  }
`;
