import styled from 'styled-components';

export const Container = styled.div`
  grid-area: ${(props) => props.id};
  > label {
    display: flex;
    -webkit-box-align: center;
    align-items: center;
  }

  input {
    flex-shrink: 0;
    position: relative;
    width: 20px;
    height: 20px;
    margin-right: 10px;
    appearance: none;
    padding: 0px;
    background: #fff;
    border: 1px solid #d1d1d1;
    border-radius: 4px;
    cursor: pointer;
    transition: all 0.2s ease 0s;
    overflow: hidden;
  }

  input:checked {
    background: #1171ef;
    border-color: #1171ef;
  }

  label {
    color: #525f7f;
  }
`;
