import { FormHandles } from '@unform/core';
import { Form } from '@unform/web';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { Link, useNavigate, useParams } from 'react-router-dom';
import * as Yup from 'yup';

import Input from '../../components/Input';
import { useToast } from '../../hooks/toast';
import api from '../../services/api';

import dobra1 from '../../assets/exercicios/dobras/dobras1.png';
import dobra2 from '../../assets/exercicios/dobras/dobras2.png';

import { Container, Content, Grid, Head, Text, Desc, Valor, Image, Title } from './styles';
import Button from '../../components/Button';
import { FiChevronLeft } from 'react-icons/fi';

const DobrasCutaneas: React.FC = () => {
  const { alunoid, id } = useParams();
  const navigate = useNavigate();
  const { addToast } = useToast();
  const formRef = useRef<FormHandles>(null);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (Number(id) !== 0) {
      api
        .get(`dobras/${id}`)
        .then((response) => {
          formRef.current?.setData(response.data);
        })
        .catch((e) => {
          addToast({
            type: 'error',
            title: 'error',
          });
        });
    }
  }, [alunoid, id, addToast]);

  const handleSubmit = useCallback(
    async (data: any) => {
      try {
        formRef.current?.setErrors({});
        const schema = Yup.object().shape({});
        await schema.validate(data, {
          abortEarly: false,
        });

        setLoading(true);
        Object.keys(data).forEach((p) => (data[p] = data[p].replace(',', '.')));
        Object.keys(data).forEach((p) => (data[p] === '' ? delete data[p] : null));

        if (Number(id) === 0) {
          await api.post('dobras', data);
        } else {
          await api.put('dobras/' + id, data);
        }
        setLoading(false);

        addToast({
          type: 'success',
          title: 'Gravado com sucesso!',
        });
        navigate(`/cliente/${alunoid}/questionarios`);
      } catch (error) {
        console.log(error);
        setLoading(false);
        addToast({
          type: 'error',
          title: 'Verifique o formulário',
        });
      }
    },
    [alunoid, id, addToast, navigate]
  );

  return (
    <>
      {/* <Header /> */}
      <Container>
        <Content>
          <Title>
            <h1>Dobras cutâneas</h1>
            <Link to={`/cliente/${alunoid}/questionarios`}>
              <FiChevronLeft size={20} />
              <span>Voltar</span>
            </Link>
          </Title>
          <Form
            ref={formRef}
            onSubmit={handleSubmit}
            initialData={{ aluno: alunoid, id: id }}
            placeholder={undefined}
            onPointerEnterCapture={undefined}
            onPointerLeaveCapture={undefined}
          >
            <Grid>
              <Head id="1">Dobra</Head>
              <Head id="2">Descrição</Head>
              <Head id="3">Valor</Head>
              <Head id="4">Imagem</Head>
              <Text id="1">
                Peitoral <br />
                (Homem)
              </Text>
              <Text id="2">
                Tricipital <br /> (Mulher)
              </Text>
              <Text id="3">
                Supra Iliaca
                <br /> (Mulher)
              </Text>
              <Text id="4">
                Abdominal
                <br />
                (Homem)
              </Text>
              <Text id="5">
                Coxa <br />
                (Homem e Mulher)
              </Text>
              <Desc id="1">
                Ponto de interseção entre a linha axilar média e uma linha imaginária transversal na altura do apêndice xifóide do esterno.
                A medida é realizada obliquamente ao eixo longitudinal, com braço do avaliado para trás ou para frente, para facilitar a
                obtenção e leitura da medida.
              </Desc>
              <Desc id="2">
                Face posterior do braço direito, paralelo ao eixo longitudinal, no ponto que compreende a distância média entre o acrômio e
                o processo do olécrano da ulna.
              </Desc>
              <Desc id="3">
                É obtida obliquamente em relação ao eixo longitudinal, na metade da distância entre o último arco costal e a crista ilíaca,
                sobre a linha axilar média. É necessário que o avaliado afaste o braço para trás para permitir uma boa execução e leitura da
                medida.
              </Desc>
              <Desc id="4">
                Medida a aproximadamente dois centímetros à direita da cicatriz umbilical, paralelamente ao eixo longitudinal do corpo.
              </Desc>
              <Desc id="5">
                É o ponto médio entre a prega inguinal e a borda superior da patela. O avaliado deve estar em pé com a perna relaxada. É
                medida sobre o músculo reto femoral.
              </Desc>
              <Valor id="1">
                <Input name="peitoral" />
              </Valor>
              <Valor id="2">
                <Input name="tricipital" />
              </Valor>
              <Valor id="3">
                <Input name="suprailiaca" />
              </Valor>
              <Valor id="4">
                <Input name="abdominal" />
              </Valor>
              <Valor id="5">
                <Input name="coxa" />
              </Valor>
              <Image id="1">
                <img src={dobra1} alt="Frente" />
              </Image>
              <Image id="2">
                <img src={dobra2} alt="Costa" />
              </Image>
            </Grid>
            <Button loading={loading} disabled={loading} type="submit">
              Gravar
            </Button>
            <div style={{ visibility: 'hidden' }}>
              <Input name="aluno" type="number" />
              <Input name="id" type="number" />
            </div>
          </Form>
        </Content>
      </Container>
    </>
  );
};

export default DobrasCutaneas;
