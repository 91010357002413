import React, { useEffect, useState } from 'react';
import { FiBookOpen, FiGlobe, FiHome, FiUsers, FiFile } from 'react-icons/fi';
import { FaBuilding, FaChartBar, FaFileExport, FaWalking } from 'react-icons/fa';
import { GiPodiumWinner } from 'react-icons/gi';
import { NavLink, useLocation } from 'react-router-dom';
import { useAuth } from '../../hooks/auth';

const adminMenu = [
  {
    name: 'Principal',
    path: '/dashboard',
    icon: <FiHome className="inline mr-5" size={25} />,
  },
  {
    name: 'Clientes',
    path: '/clientes',
    icon: <FiUsers className="inline mr-5" size={25} />,
  },
  {
    name: 'Exercicios',
    path: '/exercicios',
    icon: <FaWalking className="inline mr-5" size={25} />,
  },
  {
    name: 'Treinos',
    path: '/treinos',
    icon: <GiPodiumWinner className="inline mr-5" size={25} />,
  },
  {
    name: 'Habitos',
    path: '/habitos',
    icon: <GiPodiumWinner className="inline mr-5" size={25} />,
  },
  {
    name: 'Empresas',
    path: '/empresas',
    icon: <FaBuilding className="inline mr-5" size={25} />,
  },
  {
    name: 'Professores',
    path: '/professores',
    icon: <FiUsers className="inline mr-5" size={25} />,
  },
  {
    name: 'Grupos',
    path: '/grupos',
    icon: <FiGlobe className="inline mr-5" size={25} />,
  },
  {
    name: 'Atividades',
    path: '/atividades',
    icon: <FiBookOpen className="inline mr-5" size={25} />,
  },
  {
    name: 'Documentos',
    path: '/documentos',
    icon: <FiFile className="inline mr-5" size={25} />,
  },
  {
    name: 'Tutoriais',
    path: '/tutoriais',
    icon: <FiBookOpen className="inline mr-5" size={25} />,
  },
  {
    name: 'Exportar',
    path: '/exportar',
    icon: <FaFileExport className="inline mr-5" size={25} />,
  },
  {
    name: 'Relatorio',
    path: '/relatorio',
    icon: <FaChartBar className="inline mr-5" size={25} />,
  },
];

const profMenu = [
  {
    name: 'Principal',
    path: '/dashboard',
    icon: <FiHome className="inline mr-5" size={25} />,
  },
  {
    name: 'Clientes',
    path: '/clientes',
    icon: <FiHome className="inline mr-5" size={25} />,
  },
  {
    name: 'Grupos',
    path: '/grupos',
    icon: <FiGlobe className="inline mr-5" size={25} />,
  },
  {
    name: 'Exercicios',
    path: '/exercicios',
    icon: <FaWalking className="inline mr-5" size={25} />,
  },
  {
    name: 'Treinos',
    path: '/treinos',
    icon: <GiPodiumWinner className="inline mr-5" size={25} />,
  },
  {
    name: 'Habitos',
    path: '/habitos',
    icon: <GiPodiumWinner className="inline mr-5" size={25} />,
  },
  {
    name: 'Documentos',
    path: '/documentos',
    icon: <FiFile className="inline mr-5" size={25} />,
  },
  {
    name: 'Atividades',
    path: '/atividades',
    icon: <FiBookOpen className="inline mr-5" size={25} />,
  },
  {
    name: 'Recuperar aluno',
    path: '/cliente/recuperar',
    icon: <FiBookOpen className="inline mr-5" size={25} />,
  },
  {
    name: 'Exportar',
    path: '/exportar',
    icon: <FaFileExport className="inline mr-5" size={25} />,
  },
  // {
  //   name: 'Relatorio',
  //   path: '/relatorio',
  //   icon: <FaChartBar className="inline mr-5" size={25} />,
  // },
  {
    name: 'Tutoriais',
    path: '/tutoriais',
    icon: <FiBookOpen className="inline mr-5" size={25} />,
  },
];

const coordMenu = [
  {
    name: 'Principal',
    path: '/dashboard',
    icon: <FiHome className="inline mr-5" size={25} />,
  },
  {
    name: 'Clientes',
    path: '/clientes',
    icon: <FiHome className="inline mr-5" size={25} />,
  },
  {
    name: 'Professores',
    path: '/professores',
    icon: <FiHome className="inline mr-5" size={25} />,
  },
  {
    name: 'Empresas',
    path: '/empresas',
    icon: <FaBuilding className="inline mr-5" size={25} />,
  },
  {
    name: 'Grupos',
    path: '/grupos',
    icon: <FiGlobe className="inline mr-5" size={25} />,
  },
  {
    name: 'Exercicios',
    path: '/exercicios',
    icon: <FaWalking className="inline mr-5" size={25} />,
  },
  {
    name: 'Treinos',
    path: '/treinos',
    icon: <GiPodiumWinner className="inline mr-5" size={25} />,
  },
  {
    name: 'Habitos',
    path: '/habitos',
    icon: <GiPodiumWinner className="inline mr-5" size={25} />,
  },
  {
    name: 'Documentos',
    path: '/documentos',
    icon: <FiFile className="inline mr-5" size={25} />,
  },
  {
    name: 'Atividades',
    path: '/atividades',
    icon: <FiBookOpen className="inline mr-5" size={25} />,
  },
  {
    name: 'Recuperar aluno',
    path: '/cliente/recuperar',
    icon: <FiBookOpen className="inline mr-5" size={25} />,
  },
  {
    name: 'Exportar',
    path: '/exportar',
    icon: <FaFileExport className="inline mr-5" size={25} />,
  },
  // {
  //   name: 'Relatorio',
  //   path: '/relatorio',
  //   icon: <FaChartBar className="inline mr-5" size={25} />,
  // },
  {
    name: 'Tutoriais',
    path: '/tutoriais',
    icon: <FiBookOpen className="inline mr-5" size={25} />,
  },
];

const NavLinks: React.FC<{ classActive: string }> = ({ classActive }) => {
  const route = useLocation();
  const currentPath = route.pathname;
  const { role } = useAuth();
  const [links, setlinks] = useState(profMenu);

  useEffect(() => {
    if (role.toUpperCase() === 'ADMIN') {
      setlinks(adminMenu);
    }
    if (role.toUpperCase() === 'COORDENADOR') setlinks(coordMenu);
    if (role.toUpperCase() === 'PROFESSOR') setlinks(profMenu);
  }, [role]);

  return (
    <nav>
      <ul className="grid gap-2">
        {links.map((link) => (
          <li key={link.path}>
            <NavLink
              to={link.path}
              className={`px-4 py-2 w-full block transition hover:text-blue-600 dark:hover:text-slate-200 ${
                currentPath === link.path ? classActive : ''
              }`}
            >
              {link.icon}
              <span>{link.name}</span>
            </NavLink>
          </li>
        ))}
      </ul>
    </nav>
  );
};

export default NavLinks;
