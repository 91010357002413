import React from 'react';
import { Routes, Route } from 'react-router-dom';
import { useAuth } from '../hooks/auth';
import { ProtectedRoute } from './routes';

import Dashboard from '../pages/Dashboard';
import CadastroAluno from '../pages/CadastroAluno';
import ObjetivosMetas from '../pages/ObjetivosMetas';
import AlunosList from '../pages/Alunos/List';
import Questionarios from '../pages/Questionarios';
import Antropometria from '../pages/Antropometria';
import ComposicaoCorporal from '../pages/ComposicaoCorporal';
import FuncionalidadeBasicas from '../pages/FuncionalidadeBasicas';

import Login from '../pages/Login';
import NotFound from '../pages/NotFound';
import AvaliacoesList from '../pages/ObjetivosMetas/List';
import AntropometriaList from '../pages/Antropometria/List';
import ComposicaoCorporalList from '../pages/ComposicaoCorporal/List';
import FuncionalidadesBasicasList from '../pages/FuncionalidadeBasicas/List';
import SingleSignOn from '../pages/SingleSignOn';
import Atestado from '../pages/Atestado';
import AtestadoList from '../pages/Atestado/List';
import Anamnese from '../pages/Anamnese';
import AnamneseList from '../pages/Anamnese/List';
import ParqList from '../pages/Parq/List';
import Parq from '../pages/Parq';
import RadarList from '../pages/Radar/List';
import Radar from '../pages/Radar';
import DobrasList from '../pages/DobrasCutaneas/List';
import DobrasCutaneas from '../pages/DobrasCutaneas';
import Neuromuscular from '../pages/Neuromuscular';
import Flexiteste from '../pages/Flexiteste';
import CardioRespiratoria from '../pages/CardioRespiratoria';
import VO2Max from '../pages/VO2Max';
import NeuromuscularList from '../pages/Neuromuscular/List';
import CardioRespiratoriaList from '../pages/CardioRespiratoria/List';
import VO2MaxList from '../pages/VO2Max/List';
import Passos from '../pages/Passos';
import PassosList from '../pages/Passos/List';
import Intensidade from '../pages/Intensidade';
import IntensidadeList from '../pages/Intensidade/List';
import Relatorio from '../pages/Relatorio';
import Siderbar from '../components/SideBar';
import Header from '../components/Header';
import EmpresaList from '../pages/Empresa/List';
import AulaList from '../pages/Aula/List';
import VideoList from '../pages/Video/List';
import Empresa from '../pages/Empresa';
import Video from '../pages/Video';
import Aula from '../pages/Aula';
import ProfessoresList from '../pages/Professores/List';
import GrupoList from '../pages/Grupo/List';
import Grupo from '../pages/Grupo';
import TreinosList from '../pages/Treino/List';
import Treino from '../pages/Treino';
import ExercicioList from '../pages/Exercicio/List';
import Exercicio from '../pages/Exercicio';
import Signup from '../pages/Signup';
import Exportar from '../pages/Exportar';
import DocumentoList from '../pages/Documento/List';
import Documento from '../pages/Documento';
import Perfil from '../pages/Perfil';
import RecuperarAluno from '../pages/Alunos/Recuperar';
import AcessoNegado from '../pages/AcessoNegado';
import NeuroqList from '../pages/NeuroQ/List';
import Neuroq from '../pages/NeuroQ';
import FlexitesteList from '../pages/Flexiteste/List';
import GastoCaloricoList from '../pages/GastoCalorico/List';
import GastoCalorico from '../pages/GastoCalorico';
import Gdlam from '../pages/Gdlam';
import GdlamList from '../pages/Gdlam/List';
import EsqueciSenha from '../pages/EsqueciSenha';
import Habito from '../pages/Habito';
import HabitosList from '../pages/Habito/List';

const RootRoutes: React.FC = () => {
  const { cookie, liberado, completed, locked } = useAuth();
  return (
    <div className="bg-slate-200 min-h-screen text-slate-600 dark:bg-slate-900 dark:text-slate-400 xl:text-base sm:text-sm text-xs">
      {cookie && (
        <>
          <Header />
          <Siderbar />
        </>
      )}
      <main className=" pt-5 pb-8 sm:pb-16 px-3 md:px-8 md:w-full xl:w-10/12 xl:ml-64 sm:m-auto min-h-screen">
        <Routes>
          <Route path="/authorize/:token" element={<SingleSignOn />} />
          <Route element={<ProtectedRoute redirectPath="/dashboard" isAllowed={!cookie} />}>
            <Route path="/" element={<Login />} />
            <Route path="/login" element={<Login />} />
            <Route path="/registrar" element={<Signup />} />
            <Route path="/esqueci-minha-senha" element={<EsqueciSenha />} />
          </Route>
          <Route element={<ProtectedRoute redirectPath="/login" isAllowed={!!cookie} isLiberado={liberado} isCompleted={completed} />}>
            <Route path="/perfil" element={<Perfil />} />
            <Route path="/bloqueado" element={<AcessoNegado />} />
            <Route element={<ProtectedRoute redirectPath="/perfil" isAllowed={completed} />}>
              <Route element={<ProtectedRoute redirectPath="/bloqueado" isAllowed={!locked} />}>
                <Route path="/dashboard" element={<Dashboard />} />
                <Route path="/clientes" element={<AlunosList />} />
                <Route path="/clientes/professor/:prof" element={<AlunosList />} />
                <Route path="/cliente/cadastrar" element={<CadastroAluno />} />
                <Route path="/cliente/:id/editar" element={<CadastroAluno />} />
                <Route path="/cliente/:aluno/questionarios" element={<Questionarios />} />
                <Route path="/cliente/:aluno/avaliacoes" element={<AvaliacoesList />} />
                <Route path="/cliente/:aluno/objetivos/:id" element={<ObjetivosMetas />} />
                <Route path="/cliente/:alunoid/antropometria" element={<AntropometriaList />} />
                <Route path="/cliente/:alunoid/antropometria/:id" element={<Antropometria />} />
                <Route path="/cliente/:alunoid/composicaocorporatal/" element={<ComposicaoCorporalList />} />
                <Route path="/cliente/:alunoid/composicaocorporatal/:id" element={<ComposicaoCorporal />} />
                <Route path="/cliente/:alunoid/funcionalidadesbasicas" element={<FuncionalidadesBasicasList />} />
                <Route path="/cliente/:alunoid/funcionalidadesbasicas/:id" element={<FuncionalidadeBasicas />} />
                <Route path="/cliente/:alunoid/parq" element={<ParqList />} />
                <Route path="/cliente/:alunoid/parq/:id" element={<Parq />} />
                <Route path="/cliente/:alunoid/atestado" element={<AtestadoList />} />
                <Route path="/cliente/:alunoid/atestado/:id" element={<Atestado />} />
                <Route path="/cliente/:alunoid/anamnese" element={<AnamneseList />} />
                <Route path="/cliente/:alunoid/anamnese/:id" element={<Anamnese />} />
                <Route path="/cliente/:alunoid/radar" element={<RadarList />} />
                <Route path="/cliente/:alunoid/radar/:id" element={<Radar />} />
                <Route path="/cliente/:alunoid/dobras" element={<DobrasList />} />
                <Route path="/cliente/:alunoid/dobras/:id" element={<DobrasCutaneas />} />
                <Route path="/cliente/:alunoid/neuromuscular/" element={<NeuromuscularList />} />
                <Route path="/cliente/:alunoid/neuromuscular/:id" element={<Neuromuscular />} />
                <Route path="/cliente/:alunoid/neuroq/" element={<NeuroqList />} />
                <Route path="/cliente/:alunoid/neuroq/:id" element={<Neuroq />} />
                <Route path="/cliente/:alunoid/flexiteste/" element={<FlexitesteList />} />
                <Route path="/cliente/:alunoid/flexiteste/:id" element={<Flexiteste />} />
                <Route path="/cliente/:alunoid/cardio/" element={<CardioRespiratoriaList />} />
                <Route path="/cliente/:alunoid/cardio/:id" element={<CardioRespiratoria />} />
                <Route path="/cliente/:alunoid/vo2max/" element={<VO2MaxList />} />
                <Route path="/cliente/:alunoid/vo2max/:id" element={<VO2Max />} />
                <Route path="/cliente/:alunoid/passos/" element={<PassosList />} />
                <Route path="/cliente/:alunoid/passos/:id" element={<Passos />} />
                <Route path="/cliente/:alunoid/intensidade/" element={<IntensidadeList />} />
                <Route path="/cliente/:alunoid/intensidade/:id" element={<Intensidade />} />
                <Route path="/cliente/:alunoid/gastocalorico/" element={<GastoCaloricoList />} />
                <Route path="/cliente/:alunoid/gastocalorico/:id" element={<GastoCalorico />} />
                <Route path="/cliente/:alunoid/gdlam/" element={<GdlamList />} />
                <Route path="/cliente/:alunoid/gdlam/:id" element={<Gdlam />} />
                <Route path="/cliente/recuperar" element={<RecuperarAluno />} />
                <Route path="/relatorio/" element={<Relatorio />} />
                <Route path="/grupos/" element={<GrupoList />} />
                <Route path="/grupos/:id" element={<Grupo />} />
                <Route path="/empresas/" element={<EmpresaList />} />
                <Route path="/empresas/:page" element={<EmpresaList />} />
                <Route path="/empresa/:id" element={<Empresa />} />
                <Route path="/atividades/" element={<AulaList />} />
                <Route path="/atividades/:id" element={<Aula />} />
                <Route path="/atividades/novo" element={<Aula />} />
                <Route path="/documentos/" element={<DocumentoList />} />
                <Route path="/documentos/:id" element={<Documento />} />
                <Route path="/documentos/novo" element={<Documento />} />
                <Route path="/tutoriais/" element={<VideoList />} />
                <Route path="/tutoriais/:id" element={<Video />} />
                <Route path="/tutoriais/novo" element={<Video />} />
                <Route path="/professores" element={<ProfessoresList />} />
                <Route path="/professores/:prof/alunos" element={<AlunosList />} />
                <Route path="/treinos" element={<TreinosList />} />
                <Route path="/treinos/novo" element={<Treino />} />
                <Route path="/treinos/:id" element={<Treino />} />
                <Route path="/exercicios" element={<ExercicioList />} />
                <Route path="/exercicios/novo" element={<Exercicio />} />
                <Route path="/exercicios/:id" element={<Exercicio />} />
                <Route path="/exportar" element={<Exportar />} />
                <Route path="/habitos" element={<HabitosList />} />
                <Route path="/habito/novo" element={<Habito />} />
              </Route>
            </Route>
          </Route>
          <Route path="*" element={<NotFound />} />
        </Routes>
      </main>
    </div>
  );
};

export default RootRoutes;
