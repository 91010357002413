import React, { InputHTMLAttributes, useEffect, useRef } from 'react';
import { IconBaseProps } from 'react-icons';
import { useField } from '@unform/core';

import { Container } from './styles';

interface Option {
  value: string | number;
  name: string;
}

interface SelectProps extends InputHTMLAttributes<HTMLSelectElement> {
  name: string;
  placeholder?: string;
  options?: Option[];
  icon?: React.ComponentType<IconBaseProps>;
}

const Select: React.FC<SelectProps> = ({ name, placeholder = 'Selecione', options = [], icon: Icon, ...rest }) => {
  const inputRef = useRef(null);
  const { fieldName, defaultValue, error, registerField } = useField(name);

  useEffect(() => {
    registerField({
      name: fieldName,
      ref: inputRef.current,
      path: 'value',
    });
  }, [fieldName, registerField]);

  return (
    <Container>
      {Icon && <Icon size={20} />}
      <select defaultValue={defaultValue} ref={inputRef} {...rest}>
        <option value={''}>{placeholder}</option>
        {options.map((o, i) => (
          <option key={i} value={o.value}>
            {o.name}
          </option>
        ))}
      </select>
      {error}
    </Container>
  );
};

export default Select;
