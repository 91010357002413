import { useAuth } from '../../../hooks/auth';
import VideoListAdmin from '../ListAdmin';
import VideoListUser from '../ListUser';

const VideoList: React.FC = () => {
  const { role } = useAuth();

  return role === 'Admin' ? <VideoListAdmin /> : <VideoListUser />;
};

export default VideoList;
