import { shade } from 'polished';
import styled from 'styled-components';

export const Container = styled.div`
  max-width: 1120px;
  margin: 64px auto;
  display: flex;
`;

export const Content = styled.main`
  flex: 1;
`;

export const Title = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 20px;

  h1 {
    color: #525f7f;
  }

  a {
    display: flex;
    flex-direction: row;
    justify-content: center;
    max-width: 15%;
    max-height: 35px;
    display: flex;
    text-align: center;
    background: #1171ef;
    height: 56px;
    border-radius: 10px;
    border: 0;
    padding: 0 16px;
    color: #ffffff;
    text-decoration: none;
    width: 100%;
    font-weight: 500;
    margin-left: 10px;
    transition: background-color 0.2s;

    &:hover {
      background: ${shade(0.2, '#1171ef')};
    }

    > * {
      margin: auto 0;
    }
  }
`;

export const Grid = styled.div`
  width: 100%;
  display: grid;
  grid:
    'HEAD1 HEAD2 HEAD3 HEAD4' 0.2fr
    'TXT1  DESC1 VAL1  CLASS1' 0.5fr
    'TXT2  DESC2 VAL2  CLASS2' 0.5fr
    'TXT3  DESC3 VAL3  CLASS3' 0.5fr
    'TXT4  DESC4 VAL4  CLASS4' 0.5fr
    'TXT5  DESC5 VAL5  CLASS5' 0.5fr
    / 1fr 1.5fr 0.5fr 0.5fr;
  gap: 0px;

  div {
    border: 1px solid #3e3b47;
  }

  input {
    text-align: center;
  }
`;

export const Text = styled.div`
  grid-area: ${(props) => 'TXT' + props.id};
  text-align: center;
  justify-content: center;
  display: flex;
  flex-direction: column;
`;

export const Head = styled.div`
  grid-area: ${(props) => 'HEAD' + props.id};
  padding: 5px;
`;

export const Desc = styled.div`
  grid-area: ${(props) => 'DESC' + props.id};
  padding: 5px;
`;

export const Valor = styled.div`
  grid-area: ${(props) => 'VAL' + props.id};
  > div {
    height: 100%;
    border-radius: 0;
  }
`;

export const Classif = styled.div`
  grid-area: ${(props) => 'CLASS' + props.id};
  > div {
    height: 100%;
    border-radius: 0;
  }
`;

export const ResultContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-around;

  div {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-around;

    &:nth-child(1) {
      margin: auto;
    }

    > div {
      flex-direction: column;
    }
  }
`;
