import { FormHandles } from '@unform/core';
import { Form } from '@unform/web';
import React, { useEffect, useRef, useState } from 'react';
import { FiUserPlus, FiSearch, FiTrash } from 'react-icons/fi';
import { FaEye, FaList, FaStar } from 'react-icons/fa';
import { Link, useParams } from 'react-router-dom';

import Input from '../../../components/Input';
import Spinner from '../../../components/Spinner';
import { useToast } from '../../../hooks/toast';
import api from '../../../services/api';

import { Container, Content, RowContainer, Row, Schedule, Page, Pagination, Search, Title, ModalContent } from './styles';
import Modal from '../../../components/Modal';
import Button from '../../../components/Button';
import { convertToDate, formtarData } from '../../../utils/converter';
import View from '../view';
import { useAuth } from '../../../hooks/auth';

const TreinosList: React.FC = () => {
  const { prof } = useParams();
  const { role } = useAuth();
  const formRef = useRef<FormHandles>(null);
  const { addToast } = useToast();
  const [loading, setLoading] = useState(false);
  const [list, setList] = useState<any>([]);
  const [pages, setPages] = useState(1);
  const [currentPage, setCurrentPage] = useState(1);
  const [text, setText] = useState<string>('');
  const [professor, setProfessor] = useState(0);
  const [modal, setModal] = useState(false);
  const [viewAluno, setViewAluno] = useState(false);
  const [viewStars, setViewStars] = useState(false);
  const [treino, setTreino] = useState<any>({});
  const [refresh, setRefresh] = useState(0);
  const [reviews, setReviews] = useState<any>([]);

  useEffect(() => {
    if (prof) setProfessor(Number(prof));
  }, [prof]);

  useEffect(() => {
    setLoading(true);
    api
      .get(`treinos`)
      .then((response) => {
        setList(response.data.list);
        setCurrentPage(response.data.currentPage);
        setPages(response.data.pages);
        setLoading(false);
      })
      .catch((error) => {
        setLoading(false);
        addToast({
          type: 'error',
          title: error.message,
        });
      });
  }, [addToast, professor, refresh]);

  const handleGotoPage = (_page: number) => {
    if (_page > pages) return;
    setLoading(true);
    const query = text ? `&Text=${text}` : '';
    api
      .get(`treinos?PageNumber=${_page}${query}`)
      .then((resp) => {
        setList(resp.data.list);
        setCurrentPage(resp.data.currentPage);
        setPages(resp.data.pages);
        setLoading(false);
      })
      .catch((error) => {
        setLoading(false);
      });
  };

  const handleFilter = (e: React.ChangeEvent<HTMLInputElement>) => {
    setText(e.target.value || '');
  };

  const handleSearch = (e: any) => {
    if (e.key === 'Enter') {
      setLoading(true);
      const query = text ? `&Text=${text}` : '';
      api
        .get(`treinos?PageNumber=${1}${query}`)
        .then((resp) => {
          setList(resp.data.list);
          setCurrentPage(resp.data.currentPage);
          setPages(resp.data.pages);
          setLoading(false);
        })
        .catch((error) => {
          setLoading(false);
        });
    }
  };

  const handleSubmit = () => ({});

  const showModal = (item: any) => {
    setModal(true);
    setTreino(item);
  };

  const showView = (item: any) => {
    setTreino(item);
    setViewAluno(true);
  };

  const showViewStars = (item: any) => {
    api
      .get(`treinos/${item.id}/avaliacoes`)
      .then((resp) => {
        setReviews(resp.data);
      })
      .then(() => setViewStars(true));
  };

  const handleDelete = () => {
    try {
      setLoading(true);
      api.delete(`treinos/${treino.id}`).then(() => {
        setLoading(false);
        setModal(false);
        setRefresh(refresh + 1);
        addToast({
          type: 'success',
          title: 'Excluido com sucesso',
        });
      });
    } catch (error) {
      setLoading(false);
      addToast({
        type: 'error',
        title: 'Ocorreu um error ao excluir',
      });
    }
  };

  return (
    <>
      <Container>
        <Content>
          <Schedule>
            <Title>
              <h1>Treinos</h1>
              <Link to={'/treinos/cadastrar'}>
                <FiUserPlus size={20} />
                <span>Novo</span>
              </Link>
            </Title>
            <Form
              ref={formRef}
              onSubmit={handleSubmit}
              placeholder={undefined}
              onPointerEnterCapture={undefined}
              onPointerLeaveCapture={undefined}
            >
              <Search>
                <Input name="nome" placeholder="Buscar" icon={FiSearch} onChange={handleFilter} onKeyDown={handleSearch} />
              </Search>
            </Form>
            {!loading ? (
              <>
                <RowContainer>
                  {list.map((t: any) => (
                    <Row key={t.id}>
                      <div className="flex">
                        <div className="flex-none w-14"></div>
                        <div className="grow">
                          <strong>
                            <Link to={`/treinos/${t.id}`}>
                              {t.nomeAluno}
                              {` Treino - ${formtarData(t.dataFinal)}`}
                            </Link>
                          </strong>
                        </div>
                        <div className="flex-none w-14">
                          <div onClick={() => showViewStars(t)}>
                            <FaList size={25} />
                          </div>
                        </div>
                        <div className="flex-none w-14">
                          <div onClick={() => showView(t)}>
                            <FaEye size={25} />
                          </div>
                        </div>
                        <div className="flex-none w-14">
                          {(t.isCriador || role === 'Admin') && (
                            <div onClick={() => showModal(t)}>
                              <FiTrash size={25} color={'#ef1111'} />
                            </div>
                          )}
                        </div>
                      </div>
                    </Row>
                  ))}
                </RowContainer>
                <Pagination>
                  <Page onClick={() => handleGotoPage(currentPage - 1)}>Anterior</Page>
                  {Array.from({ length: pages < 20 ? pages : pages }, (_, i) => (
                    <Page key={i} selected={i + 1 === currentPage} onClick={() => handleGotoPage(i + 1)}>
                      <div>{i + 1}</div>
                    </Page>
                  ))}
                  <Page onClick={() => handleGotoPage(currentPage + 1)}>Próximo</Page>
                </Pagination>
              </>
            ) : (
              <>
                <br />
                <Spinner />
              </>
            )}
          </Schedule>
        </Content>
        <Modal visible={modal} close={() => setModal(false)}>
          <ModalContent>
            <p style={{ marginBottom: 20 }}>EXCLUIR</p>
            <div>
              <p>
                {`Deseja realmente excluir o treino`} <strong>{treino.nome}</strong>
              </p>
              <p>Essa operação não pode ser desfeita</p>
              <Button onClick={handleDelete} loading={loading}>
                Confirmar
              </Button>
            </div>
          </ModalContent>
        </Modal>
        <Modal visible={viewAluno} close={() => setViewAluno(false)}>
          <ModalContent>
            <div>
              <View id={treino.id} />
            </div>
          </ModalContent>
        </Modal>
        <Modal visible={viewStars} close={() => setViewStars(false)}>
          <ModalContent>
            <div>
              {reviews.map((r: any) => (
                <div className="flex">
                  <div className="grid place-content-center mr-4">{formtarData(r.data)}</div>
                  <div className="flex gap-4 w-52">
                    {Array.from({ length: r.nota }, (_, i) => (
                      <FaStar size={30} color="#e9ef00" />
                    ))}
                  </div>
                  <div className="grid place-content-center">{r.comentario}</div>
                </div>
              ))}
            </div>
          </ModalContent>
        </Modal>
      </Container>
    </>
  );
};

export default TreinosList;
