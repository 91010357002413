import { FormHandles } from '@unform/core';
import { Form } from '@unform/web';
import React, { useEffect, useRef, useState } from 'react';

import Modal from '../../components/Modal';
import Select from '../../components/Select';
import Spinner from '../../components/Spinner';
import { useAuth } from '../../hooks/auth';
import { useToast } from '../../hooks/toast';
import api from '../../services/api';
import * as S from './styles';

const Dashboard: React.FC = () => {
  const formRef = useRef<FormHandles>(null);
  const { nome, role } = useAuth();
  const { addToast } = useToast();
  const [board, setBoard] = useState<any>(null);
  const [professores, setProfessores] = useState<any>([]);
  const [professor, setProfessor] = useState(0);
  const [show, setshow] = useState(false);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);
  const [modal0, setModal0] = useState(false);
  const [modal1, setModal1] = useState(false);
  const [modal2, setModal2] = useState(false);
  const [modal3, setModal3] = useState(false);
  const [modal4, setModal4] = useState(false);
  const [modal5, setModal5] = useState(false);
  const [modal6, setModal6] = useState(false);
  const [modal7, setModal7] = useState(false);

  useEffect(() => {
    api
      .get('professores/all')
      .then((response) => {
        // eslint-disable-next-line
        response.data.map((r: any) => ((r.value = r.id), (r.name = `${r.nome} ${r.sobrenome}`.trim())));
        setProfessores(response.data);
      })
      .catch((error) => {
        addToast({
          type: 'error',
          title: error.message,
        });
      });
  }, [addToast]);

  useEffect(() => {
    setLoading(true);
    setError(false);
    api
      .get(`dashboard?professor=${professor}`)
      .then((response) => {
        setBoard(response.data);
      })
      .catch((error) => {
        setLoading(false);
        setError(true);
        addToast({
          type: 'error',
          title: error.message,
        });
      });
  }, [addToast, professor]);

  useEffect(() => {
    if (board !== null && board !== undefined) {
      setshow(true);
      setLoading(false);
    }
  }, [board]);

  const selectProfessor = (e: any) => {
    setProfessor(Number(e.target.value));
  };

  return (
    <S.Container>
      <S.Main>
        <S.Welcome>
          <div>
            <h2>Olá, {nome}</h2>
            {/* <div>
                <p>É bom ter você de volta!</p>
                <p>Continue firme, retorne para a atividade que parou.</p>
              </div> */}
          </div>
        </S.Welcome>
        {false && (
          <S.Pending>
            <S.BannerLeft>
              <h2>EM BREVE</h2>
              <span>NWL / Mission</span>
            </S.BannerLeft>
            <S.BannerRigth>
              <strong>Aguarde</strong>
              <span>
                <span>
                  <img src="https://app.rocketseat.com.br/_next/image?url=%2Fassets%2Ficons%2Fdashboard%2Fplay.svg&w=48&q=75" alt="Play" />
                </span>
              </span>
            </S.BannerRigth>
          </S.Pending>
        )}
        <S.Content>
          {role !== 'Professor' && (
            <Form
              ref={formRef}
              onSubmit={() => ({})}
              placeholder={undefined}
              onPointerEnterCapture={undefined}
              onPointerLeaveCapture={undefined}
            >
              <Select name="professor" placeholder="Todos" onChange={selectProfessor} options={professores} />
            </Form>
          )}
          {show && (
            <S.Indicadores>
              <S.Indicador onClick={() => setModal0(true)}>
                <div>
                  {board.alunos.length}/{board.vagas}
                </div>
                <div>
                  <S.AlunoIcon />
                  <span>Clientes</span>
                </div>
              </S.Indicador>
              <S.Indicador onClick={() => setModal1(true)}>
                <div>
                  <div>{board.alunosObjetivos.length}</div>
                </div>
                <div>
                  <S.ObjetivoIcon />
                  <span>Objetivo</span>
                </div>
              </S.Indicador>
              <S.Indicador onClick={() => setModal2(true)}>
                <div>
                  <div>{board.alunosParq.length}</div>
                </div>
                <div>
                  <S.ParqIcon />
                  <span>PAR-Q</span>
                </div>
              </S.Indicador>
              <S.Indicador onClick={() => setModal3(true)}>
                <div>{board.alunosAtestado.length}</div>
                <div>
                  <S.AtestadoIcon />
                  <span>Atestado</span>
                </div>
              </S.Indicador>
              <S.Indicador onClick={() => setModal4(true)}>
                <div>
                  <div>{board.alunosAnamnese.length}</div>
                </div>
                <div>
                  <S.AnamneseIcon />
                  <span>Anamnese</span>
                </div>
              </S.Indicador>
              <S.Indicador onClick={() => setModal5(true)}>
                <div>
                  <div>{board.alunosRadar.length}</div>
                </div>
                <div>
                  <S.RadarIcon />
                  <span>Radar</span>
                </div>
              </S.Indicador>
              <S.Indicador onClick={() => setModal6(true)}>
                <div>
                  <div>{board.alunosNeuroQ.length}</div>
                </div>
                <div>
                  <S.NeuroQIcon />
                  <span>NeuroQ</span>
                </div>
                {/* <span>Neuro-Q</span> */}
              </S.Indicador>
              <S.Indicador onClick={() => setModal7(true)}>
                <div>
                  <div>{board.alunosImc.length}</div>
                </div>
                <div>
                  <S.ImcIcon /> <span>IMC</span>
                </div>
              </S.Indicador>
            </S.Indicadores>
          )}
          {!show && loading && <Spinner />}
          {error && !loading && <p style={{ fontSize: 40, textAlign: 'center' }}>Ocorreu um erro!</p>}
        </S.Content>
      </S.Main>
      {show && (
        <>
          <Modal visible={modal0} close={() => setModal0(false)}>
            <p style={{ marginBottom: 20, fontSize: 25, color: 'black' }}>Clientes</p>
            <S.RowContainer>
              {board.alunos.map((a: any) => (
                <S.Row key={a.id} sexo={1}>
                  <div>
                    <div></div>
                    <strong>{a.nome}</strong>
                  </div>
                </S.Row>
              ))}
            </S.RowContainer>
          </Modal>
          <Modal visible={modal1} close={() => setModal1(false)}>
            <p style={{ marginBottom: 20, fontSize: 25, color: 'black' }}>Clientes</p>
            <S.RowContainer>
              {board.alunosObjetivos.map((a: any) => (
                <S.Row key={a.id} sexo={1}>
                  <div>
                    <div></div>
                    <strong>{a.nome}</strong>
                  </div>
                </S.Row>
              ))}
            </S.RowContainer>
          </Modal>
          <Modal visible={modal2} close={() => setModal2(false)}>
            <p style={{ marginBottom: 20, fontSize: 25, color: 'black' }}>Clientes</p>
            <S.RowContainer>
              {board.alunosParq.map((a: any) => (
                <S.Row key={a.id} sexo={1}>
                  <div>
                    <div></div>
                    <strong>{a.nome}</strong>
                  </div>
                </S.Row>
              ))}
            </S.RowContainer>
          </Modal>
          <Modal visible={modal3} close={() => setModal3(false)}>
            <p style={{ marginBottom: 20, fontSize: 25, color: 'black' }}>Clientes</p>
            <S.RowContainer>
              {board.alunosAtestado.map((a: any) => (
                <S.Row key={a.id} sexo={1}>
                  <div>
                    <div></div>
                    <strong>{a.nome}</strong>
                  </div>
                </S.Row>
              ))}
            </S.RowContainer>
          </Modal>
          <Modal visible={modal4} close={() => setModal4(false)}>
            <p style={{ marginBottom: 20, fontSize: 25, color: 'black' }}>Clientes</p>
            <S.RowContainer>
              {board.alunosAnamnese.map((a: any) => (
                <S.Row key={a.id} sexo={1}>
                  <div>
                    <div></div>
                    <strong>{a.nome}</strong>
                  </div>
                </S.Row>
              ))}
            </S.RowContainer>
          </Modal>
          <Modal visible={modal5} close={() => setModal5(false)}>
            <p style={{ marginBottom: 20, fontSize: 25, color: 'black' }}>Clientes</p>
            <S.RowContainer>
              {board.alunosRadar.map((a: any) => (
                <S.Row key={a.id} sexo={1}>
                  <div>
                    <div></div>
                    <strong>{a.nome}</strong>
                  </div>
                </S.Row>
              ))}
            </S.RowContainer>
          </Modal>
          <Modal visible={modal6} close={() => setModal6(false)}>
            <p style={{ marginBottom: 20, fontSize: 25, color: 'black' }}>Clientes</p>
            <S.RowContainer>
              {board.alunosNeuroQ.map((a: any) => (
                <S.Row key={a.id} sexo={1}>
                  <div>
                    <div></div>
                    <strong>{a.nome}</strong>
                  </div>
                </S.Row>
              ))}
            </S.RowContainer>
          </Modal>
          <Modal visible={modal7} close={() => setModal7(false)}>
            <p style={{ marginBottom: 20, fontSize: 25, color: 'black' }}>Clientes</p>
            <S.RowContainer>
              {board.alunosImc.map((a: any) => (
                <S.Row key={a.id} sexo={1}>
                  <div>
                    <div></div>
                    <strong>{a.nome}</strong>
                  </div>
                </S.Row>
              ))}
            </S.RowContainer>
          </Modal>
        </>
      )}
    </S.Container>
  );
};

export default Dashboard;
