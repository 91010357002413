import React, { useCallback, useEffect, useRef, useState } from 'react';
import { FormHandles } from '@unform/core';
import { Form } from '@unform/web';
import { FiChevronLeft } from 'react-icons/fi';
import { Link, useParams } from 'react-router-dom';
import * as Yup from 'yup';

import { useToast } from '../../hooks/toast';

import { Container, Content, Main, Title } from './styles';
import Input from '../../components/Input';
import api from '../../services/api';
import Button from '../../components/Button';
// import Option from '../../components/Option';
// import { RadioOption } from '../../model/Inputs';
import getValidationErrors from '../../utils/getValidationErrors';
// import Checkbox from '../../components/Checkbox/V2';
// import Modal from '../../components/Modal';
import Select from '../../components/Select';

// const options: RadioOption[] = [
//   { id: '1', value: 'Sim', label: 'Sim' },
//   { id: '2', value: 'Não', label: 'Não' },
// ];

const GastoCalorico: React.FC = () => {
  const { alunoid, id } = useParams();
  // const navigate = useNavigate();
  const { addToast } = useToast();
  const formRef = useRef<FormHandles>(null);
  const [loading, setLoading] = useState(false);
  // const [alerta, setAlerta] = useState(false);

  useEffect(() => {
    if (Number(id) !== 0) {
      setLoading(true);
      api
        .get(`parqs/${id}`)
        .then((response) => {
          formRef.current?.setData(response.data);
          setLoading(false);
        })
        .catch((e) => {
          addToast({
            type: 'error',
            title: 'error',
          });
        });
    }
  }, [id, addToast]);

  const handleSubmit = useCallback(
    async (data: any) => {
      try {
        formRef.current?.setErrors({});
        const schema = Yup.object().shape({
          aluno: Yup.number().required('Aluno obrigatorio'),
          pq01: Yup.string().required('Pergunta 1 obrigatório'),
          pq02: Yup.string().required('Pergunta 2 obrigatório'),
          pq03: Yup.string().required('Pergunta 3 obrigatório'),
          pq04: Yup.string().required('Pergunta 4 obrigatório'),
          pq05: Yup.string().required('Pergunta 5 obrigatório'),
          pq06: Yup.string().required('Pergunta 6 obrigatório'),
          pq07: Yup.string().required('Pergunta 7 obrigatório'),
          pq08: Yup.string().required('Pergunta 8 obrigatório'),
          pq09: Yup.string().required('Pergunta 9 obrigatório'),
          pq10: Yup.string().required('Pergunta 10 obrigatório'),
          check1: Yup.boolean().required(),
          check2: Yup.boolean().required(),
        });

        await schema.validate(data, {
          abortEarly: false,
        });

        setLoading(true);

        if (Number(id) === 0) {
          await api.post('parqs', data);
        } else {
          await api.put('parqs/' + id, data);
        }
        setLoading(false);

        formRef.current?.reset();
        addToast({
          type: 'success',
          title: 'Gravado com sucesso!',
        });
      } catch (error: any) {
        setLoading(false);
        const errors = getValidationErrors(error);
        formRef.current?.setErrors(errors);
        error.inner.forEach((er: any) => {
          addToast({
            type: 'error',
            title: er.message,
          });
        });
        addToast({
          type: 'error',
          title: 'Verifique o formulário',
        });
      }
    },
    [id, addToast]
  );

  // const handleClose = () => {
  //   setAlerta(false);
  // };

  return (
    <>
      <Container>
        <Main>
          <Content>
            <Title>
              <h1>Gasto Calorico</h1>
              <Link to={`/aluno/${alunoid}/questionarios`}>
                <FiChevronLeft size={20} />
                <span>Voltar</span>
              </Link>
            </Title>
            <Form
              ref={formRef}
              onSubmit={handleSubmit}
              initialData={{ aluno: alunoid }}
              placeholder={undefined}
              onPointerEnterCapture={undefined}
              onPointerLeaveCapture={undefined}
            >
              <div>Gasto Energético diário</div>
              <div>Gasto Energético diário repouso</div>
              <div>
                <div>Gasto energético basal diário - Método Harris-Benedict</div>
                <div>
                  <Input name="gdi" />
                </div>
              </div>
              <div>
                <div>GED por peso corporal- OMS,1985</div>
                <div>
                  <Input name="ged" />
                </div>
              </div>
              <div>Radar do Bem Estar</div>
              <div>
                <div>Dimensão física(WR):</div>
                <div>
                  <Select
                    name="df"
                    options={[
                      { name: 'Inativo', value: 'inativo' },
                      { name: 'Parcialmente ativo', value: 'parcialmente ativo' },
                      { name: 'Ativo', value: 'ativo' },
                      { name: 'Muito ativo', value: 'muito ativo' },
                    ]}
                  />
                </div>
              </div>
              <div>
                <div>Nível de Atividade Física(NAF)</div>
                <div>
                  <Input name="d" />
                </div>
              </div>
              <div>
                <div>GED por perfil atividade física</div>
                <div>
                  <Input name="ged" />
                </div>
              </div>
              <div className="flex">
                <Input name="ged" />
                <Input name="ged" />
                <Input name="ged" />
              </div>
              <div>
                <div>Gasto Energético diário por peso corporal e intensidade da Atividade Física</div>
                <div>GED</div>
              </div>
              <Button loading={loading} disabled={loading} type="submit">
                Gravar
              </Button>
              <div style={{ visibility: 'hidden' }}>
                <Input name="aluno" type="number" />
              </div>
            </Form>
          </Content>
        </Main>
      </Container>
    </>
  );
};

export default GastoCalorico;
