import styled from 'styled-components';
import { shade } from 'polished';

export const Container = styled.div`
  background: #fff;
  border-radius: 10px;
  border: 1px solid #f1f1f1;
  padding: 10px;
  width: 100%;
  color: #525f7f;
  display: flex;
  align-items: center;
  box-shadow: 0 1px 3px rgb(50 50 93 / 15%), 0 1px 0 rgb(0 0 0 / 2%);
  transition: box-shadow 0.15s ease;

  & + div {
    //margin-top: 8px;
  }

  input {
    flex: 1;
    background: transparent;
    border: 0;
    color: #525f7f;
    width: 100%;

    &::placeholder {
      color: #666360;
    }
  }

  svg {
    margin-right: 16px;
  }

  .rdp {
    background: #28262e;
    border-radius: 10px;
  }

  .rdp-caption_label {
    text-transform: capitalize;
  }

  .DayPicker-wrapper {
    padding-bottom: 0;
  }

  .DayPicker,
  .DayPicker-Month {
    width: 100%;
  }

  .rdp-month {
    border-collapse: separate;
    border-spacing: 8px;
    margin: 16px;
  }

  .DayPicker-Day {
    width: 40px;
    height: 40px;
  }

  .DayPicker-Day--available:not(.DayPicker-Day--outside) {
    background: #3e3b47;
    border-radius: 10px;
    color: #fff;
  }

  .DayPicker:not(.DayPicker--interactionDisabled)
    .rdp-button:not(.rdp-button--disabled):not(.rdp-button--selected):not(.rdp-button--outside):hover {
    background: ${shade(0.2, '#3e3b47')};
  }

  .rdp-button:hover {
    background: ${shade(0.2, '#3e3b47')};
  }

  .rdp-day_today {
    font-weight: bold;
  }

  .DayPicker-Day--disabled {
    color: #666360 !important;
    background: transparent !important;
  }

  .rdp-day_selected {
    background: #1171ef !important;
    border-radius: 10px;
    color: #232129 !important;
    font-weight: bold;
  }

  > div {
    z-index: 99;
  }
`;
