import React, { useRef, useEffect } from 'react';
import ReactInputMask, { Props as InputProps } from 'react-input-mask';

import { Container } from './styles';

import { useField } from '@unform/core';
import { IconBaseProps } from 'react-icons';

interface Props extends InputProps {
  name: string;
  icon?: React.ComponentType<IconBaseProps>;
}

export default function InputMask({ name, icon: Icon, ...rest }: Props) {
  const inputRef = useRef(null);
  const { fieldName, registerField, defaultValue, error } = useField(name);

  useEffect(() => {
    registerField({
      name: fieldName,
      ref: inputRef.current,
      path: 'value',
      setValue(ref: any, value: string) {
        ref.setInputValue(value);
      },
      clearValue(ref: any) {
        ref.setInputValue('');
      },
    });
  }, [fieldName, registerField]);

  return (
    <Container>
      {Icon && <Icon size={20} />}
      <ReactInputMask ref={inputRef} defaultValue={defaultValue} {...rest} />
      {error}
    </Container>
  );
}
