import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { Container, Content, Schedule, Section, InputGroup } from './styles';

import { Form } from '@unform/web';
import { FormHandles } from '@unform/core';
import * as Yup from 'yup';

import { useToast } from '../../hooks/toast';
import Input from '../../components/Input';
import Button from '../../components/Button';
import Select from '../../components/Select';
import InputMask from '../../components/InputMask';

import api from '../../services/api';
import { estados } from '../CadastroAluno/apoio';

const Empresa: React.FC = () => {
  const { id } = useParams();
  const { addToast } = useToast();
  const navigate = useNavigate();
  const formRef = useRef<FormHandles>(null);
  const [loading, setLoading] = useState(false);

  const [edit, setEdit] = useState(false);
  // const [confirmExcluir, setConfirmExcluir] = useState(false);

  useEffect(() => {
    if (Number(id) > 0) {
      api.get(`empresas/${id}`).then((response) => {
        formRef.current?.setData(response.data);
        setEdit(true);
      });
    }
  }, [id]);

  const handleSubmit = useCallback(
    async (data: any) => {
      try {
        formRef.current?.setErrors({});
        const schema = Yup.object().shape({
          nome: Yup.string().required('Nome obrigatorio'),
          razaoSocial: Yup.string().required('Razao Social obrigatorio'),
          cnpj: Yup.string().required('CNPJ obrigatorio'),
        });

        await schema.validate(data, {
          abortEarly: false,
        });

        setLoading(true);

        if (edit) {
          await api.put('empresas/' + id, data);
          addToast({
            type: 'success',
            title: 'Cadastro atualizado',
          });
          setLoading(false);
        } else {
          await api.post('empresas', data);
          addToast({
            type: 'success',
            title: 'Cadastro realizado',
          });

          setLoading(false);
          formRef.current?.reset();
          navigate(`/empresas`);
        }
      } catch (err: any) {
        setLoading(false);
        console.log(err);
        // const errors = getValidationErrors(err);
        // formRef.current?.setErrors(errors);
        addToast({
          type: 'error',
          title: 'Erro ao gravar cadastro',
        });
      }
    },
    [addToast, navigate, edit, id]
  );

  const getCEP = () => {
    const cep = formRef.current?.getFieldValue('cep').replaceAll('_', '');
    if (cep.length < 9) return;
    api
      .get(`https://viacep.com.br/ws/${cep}/json/`)
      .then((response) => {
        if (response.data.erro) {
          addToast({
            type: 'error',
            title: 'Ocorreu um error ao buscar o CEP',
          });
          return;
        }

        const { uf, logradouro, localidade } = response.data;
        const estado = estados.find((e) => e.name === uf);

        formRef.current?.setFieldValue('endereco', logradouro);
        formRef.current?.setFieldValue('estado', estado?.value);
        formRef.current?.setFieldValue('cidade', localidade);
      })
      .catch((error) => {
        addToast({
          type: 'error',
          title: 'Ocorreu um error ao buscar o CEP',
        });
      });
  };

  return (
    <>
      <Container>
        <Content>
          <Schedule>
            <h1>Empresa</h1>
            <Form
              ref={formRef}
              onSubmit={handleSubmit}
              initialData={{ id: id }}
              placeholder={undefined}
              onPointerEnterCapture={undefined}
              onPointerLeaveCapture={undefined}
            >
              <Section>
                <strong>Dados</strong>
                <InputGroup>
                  <Input name="nome" placeholder="Nome" />
                  <Input name="razaoSocial" placeholder="Razão Social" />
                </InputGroup>
                <InputGroup>
                  {/* <InputMask name="nascimento" placeholder="Nascimento" mask={'99/99/9999'} onChange={handleCalcIdade} /> */}
                  <InputMask name="cnpj" placeholder="CNPJ" mask={'99.999.999/9999-99'} />
                </InputGroup>
              </Section>
              <Section>
                <strong>Endereço</strong>
                <InputGroup>
                  <InputMask name="cep" placeholder="CEP" mask={'99999-999'} onChange={getCEP} />
                  <Input name="endereco" placeholder="Endereço" />
                </InputGroup>
                <InputGroup>
                  <Input name="enderecoComp" placeholder="Complemento" />
                  <Input name="enderecoNum" placeholder="Numero" />
                </InputGroup>
                <InputGroup>
                  <Select name="estado" id="estado" placeholder="Estado" options={estados} />
                  <Input name="cidade" placeholder="Cidade" />
                  <Input name="bairro" placeholder="Bairro" />
                </InputGroup>
              </Section>

              <div style={{ visibility: 'hidden' }}>
                <Input name="id" type="number" />
              </div>
              <Button loading={loading} type="submit">
                Gravar
              </Button>
            </Form>
          </Schedule>
        </Content>
      </Container>
    </>
  );
};

export default Empresa;
