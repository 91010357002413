import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { Container, Content, Schedule, Section, InputGroup } from './styles';

import { Form } from '@unform/web';
import { FormHandles } from '@unform/core';
import * as Yup from 'yup';

import { useToast } from '../../hooks/toast';
import Input from '../../components/Input';
import Button from '../../components/Button';
import Select from '../../components/Select';
//
import api from '../../services/api';

const categorias = [
  {
    id: '1',
    value: '1',
    label: 'Tutorial Cliente',
    name: 'Tutorial Cliente',
  },
  {
    id: '2',
    value: '2',
    label: 'Tutorial Professor',
    name: 'Tutorial Professor',
  },
  {
    id: '3',
    value: '3',
    label: 'Exercicio',
    name: 'Exercicio',
  },
];

const Video: React.FC = () => {
  const { id } = useParams();
  const { addToast } = useToast();
  const navigate = useNavigate();
  const formRef = useRef<FormHandles>(null);
  const [loading, setLoading] = useState(false);
  const [video_id, setVideo_id] = useState();

  const [edit, setEdit] = useState(false);

  useEffect(() => {
    if (Number(id) > 0) {
      api.get(`videos/${id}`).then((response) => {
        formRef.current?.setData(response.data);
        setVideo_id(response.data.url.split(`/`)[3]);
        setEdit(true);
      });
    }
  }, [id]);

  const handleSubmit = useCallback(
    async (data: any) => {
      try {
        formRef.current?.setErrors({});
        const schema = Yup.object().shape({
          titulo: Yup.string().required('Titulo obrigatorio'),
          categoria: Yup.string().required('Categoria obrigatorio'),
          url: Yup.string().required('URL obrigatorio'),
        });

        await schema.validate(data, {
          abortEarly: false,
        });

        setLoading(true);

        if (edit) {
          await api.put('videos/' + id, data);
          addToast({
            type: 'success',
            title: 'Cadastro atualizado',
          });
          setLoading(false);
        } else {
          await api.post('videos', data);
          addToast({
            type: 'success',
            title: 'Cadastro realizado',
          });

          setLoading(false);
          formRef.current?.reset();
          navigate(`/videos`);
        }
      } catch (err: any) {
        setLoading(false);
        console.log(err);
        // const errors = getValidationErrors(err);
        // formRef.current?.setErrors(errors);
        addToast({
          type: 'error',
          title: 'Erro ao gravar cadastro',
        });
      }
    },
    [addToast, navigate, edit, id]
  );

  return (
    <>
      {/* <Header /> */}
      <Container>
        <Content>
          <Schedule>
            <h1>Tutorial</h1>
            <Form
              ref={formRef}
              onSubmit={handleSubmit}
              initialData={{ id: id }}
              placeholder={undefined}
              onPointerEnterCapture={undefined}
              onPointerLeaveCapture={undefined}
            >
              <Section>
                <strong>Dados</strong>
                <InputGroup>
                  <Input name="titulo" placeholder="Titulo" />
                  <Select name="categoria" id="categoria" placeholder="Categoria" options={categorias} />
                </InputGroup>
                <InputGroup>
                  <Input name="url" placeholder="youtube ou vimeo url" />
                </InputGroup>
                {edit && (
                  <iframe
                    src={'https://player.vimeo.com/video/' + video_id}
                    width="626"
                    height="440"
                    allow="autoplay; fullscreen; picture-in-picture"
                    style={{ margin: 'auto' }}
                    title={formRef.current?.getFieldValue('titulo')}
                  ></iframe>
                )}
              </Section>
              <div style={{ visibility: 'hidden' }}>
                <Input name="id" type="number" />
              </div>
              <Button loading={loading} type="submit">
                Gravar
              </Button>
            </Form>
          </Schedule>
        </Content>
      </Container>
    </>
  );
};

export default Video;
