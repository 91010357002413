import { FormHandles } from '@unform/core';
import { Form } from '@unform/web';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { Link, useNavigate, useParams } from 'react-router-dom';
import * as Yup from 'yup';

import Input from '../../components/Input';
import { useToast } from '../../hooks/toast';
import api from '../../services/api';

import { Container, Content, Row, Title } from './styles';
import Button from '../../components/Button';
import { FiChevronLeft } from 'react-icons/fi';
import * as img from './icons';

const Flexiteste: React.FC = () => {
  const { alunoid, id } = useParams();
  const navigate = useNavigate();
  const { addToast } = useToast();
  const formRef = useRef<FormHandles>(null);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (Number(id) !== 0) {
      setLoading(true);
      api
        .get(`flexitestes/${id}`)
        .then((response) => {
          formRef.current?.setData(response.data);
          setLoading(false);
          classificacao();
        })
        .catch((e) => {
          addToast({
            type: 'error',
            title: 'error',
          });
        });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id, addToast]);

  const handleSubmit = useCallback(
    async (data: any) => {
      try {
        formRef.current?.setErrors({});
        const schema = Yup.object().shape({
          abducaoHorizontalOmbro: Yup.number().min(1).max(4).required('obrigatorio'),
          abducaoOmbro: Yup.number().min(1).max(4).required('obrigatorio'),
          flexaoCotovelo: Yup.number().min(1).max(4).required('obrigatorio'),
          hiperextensaoCotovelo: Yup.number().min(1).max(4).required('obrigatorio'),
          flexaoPunho: Yup.number().min(1).max(4).required('obrigatorio'),
          extensaoPunho: Yup.number().min(1).max(4).required('obrigatorio'),
          flexaoQuadril: Yup.number().min(1).max(4).required('obrigatorio'),
          hiperextensaoTronco: Yup.number().min(1).max(4).required('obrigatorio'),
          flexaoLateralTronco: Yup.number().min(1).max(4).required('obrigatorio'),
          flexaoCoxa: Yup.number().min(1).max(4).required('obrigatorio'),
          extensaoCoxa: Yup.number().min(1).max(4).required('obrigatorio'),
          flexaoPerna: Yup.number().min(1).max(4).required('obrigatorio'),
          hiperextensaoPerna: Yup.number().min(1).max(4).required('obrigatorio'),
          dorsoFlexaoPlantar: Yup.number().min(1).max(4).required('obrigatorio'),
          flexaoPlantar: Yup.number().min(1).max(4).required('obrigatorio'),
        });
        await schema.validate(data, {
          abortEarly: false,
        });
        setLoading(true);

        if (Number(id) === 0) {
          await api.post('flexitestes', data);
        } else {
          await api.put('flexitestes', data);
        }
        setLoading(false);

        addToast({
          type: 'success',
          title: 'Gravado com sucesso!',
        });
        navigate(`/cliente/${alunoid}/questionarios`);
      } catch (error) {
        console.log(error);
        setLoading(false);
        addToast({
          type: 'error',
          title: 'Verifique o formulário',
        });
      }
    },
    [alunoid, id, addToast, navigate]
  );

  function sum(obj: any) {
    var sum = 0;
    for (var el in obj) {
      if (obj.hasOwnProperty(el)) {
        sum += parseFloat(obj[el]);
      }
    }
    return sum;
  }

  const classificacao = () => {
    const data = formRef.current?.getData();
    if (!data) return;
    delete data.aluno;
    delete data.total;
    delete data.classificacao;
    const sumValues = sum(data);
    if (isNaN(sumValues)) return;
    formRef.current?.setFieldValue('total', sumValues);
    let classif = '';
    if (sumValues < 31) {
      classif = 'Baixo';
    } else if (sumValues < 41) {
      classif = 'Medio (-)';
    } else if (sumValues < 51) {
      classif = 'Medio (+)';
    } else if (sumValues < 60) {
      classif = 'Medio (+)';
    } else {
      classif = 'Muito Grande';
    }

    formRef.current?.setFieldValue('classificacao', classif);
  };

  return (
    <>
      {/* <Header /> */}
      <Container>
        <Content>
          <Title>
            <h1>Flexiteste</h1>
            <Link to={`/cliente/${alunoid}/questionarios`}>
              <FiChevronLeft size={20} />
              <span>Voltar</span>
            </Link>
          </Title>

          <Form
            ref={formRef}
            onSubmit={handleSubmit}
            initialData={{ aluno: alunoid }}
            placeholder={undefined}
            onPointerEnterCapture={undefined}
            onPointerLeaveCapture={undefined}
          >
            <Row>
              <div>Exercicio</div>
              <div style={{ textAlign: 'center' }}>1</div>
              <div style={{ textAlign: 'center' }}>2</div>
              <div style={{ textAlign: 'center' }}>3</div>
              <div style={{ textAlign: 'center' }}>4</div>
              <div style={{ textAlign: 'center' }}>Nivel</div>
            </Row>
            <Row>
              <div>Abdução Horizontal do Ombros</div>
              <div>
                <img src={img.img1} alt="" />
              </div>
              <div>
                <img src={img.img2} alt="" />
              </div>
              <div>
                <img src={img.img3} alt="" />
              </div>
              <div>
                <img src={img.img4} alt="" />
              </div>
              <div>
                <Input name="abducaoHorizontalOmbro" onChange={classificacao} />
              </div>
            </Row>
            <Row>
              <div>Abdução de Ombro</div>
              <div>
                <img src={img.img5} alt="" />
              </div>
              <div>
                <img src={img.img6} alt="" />
              </div>
              <div>
                <img src={img.img7} alt="" />
              </div>
              <div>
                <img src={img.img8} alt="" />
              </div>
              <div>
                <Input name="abducaoOmbro" onChange={classificacao} />
              </div>
            </Row>
            <Row>
              <div>Flexão de Cotovelo</div>
              <div>
                <img src={img.img9} alt="" />
              </div>
              <div>
                <img src={img.img10} alt="" />
              </div>
              <div>
                <img src={img.img11} alt="" />
              </div>
              <div>
                <img src={img.img12} alt="" />
              </div>
              <div>
                <Input name="flexaoCotovelo" onChange={classificacao} />
              </div>
            </Row>
            <Row>
              <div>Hiperextensão do Cotovelo</div>
              <div>
                <img src={img.img13} alt="" />
              </div>
              <div>
                <img src={img.img14} alt="" />
              </div>
              <div>
                <img src={img.img15} alt="" />
              </div>
              <div>
                <img src={img.img16} alt="" />
              </div>
              <div>
                <Input name="hiperextensaoCotovelo" onChange={classificacao} />
              </div>
            </Row>
            <Row>
              <div>Flexão de Punho</div>
              <div>
                <img src={img.img17} alt="" />
              </div>
              <div>
                <img src={img.img18} alt="" />
              </div>
              <div>
                <img src={img.img19} alt="" />
              </div>
              <div>
                <img src={img.img20} alt="" />
              </div>
              <div>
                <Input name="flexaoPunho" onChange={classificacao} />
              </div>
            </Row>
            <Row>
              <div>Extensão do Punho</div>
              <div>
                <img src={img.img21} alt="" />
              </div>
              <div>
                <img src={img.img22} alt="" />
              </div>
              <div>
                <img src={img.img23} alt="" />
              </div>
              <div>
                <img src={img.img24} alt="" />
              </div>
              <div>
                <Input name="extensaoPunho" onChange={classificacao} />
              </div>
            </Row>
            <Row>
              <div>Flexão do Quadril</div>
              <div>
                <img src={img.img25} alt="" />
              </div>
              <div>
                <img src={img.img26} alt="" />
              </div>
              <div>
                <img src={img.img27} alt="" />
              </div>
              <div>
                <img src={img.img28} alt="" />
              </div>
              <div>
                <Input name="flexaoQuadril" onChange={classificacao} />
              </div>
            </Row>
            <Row>
              <div>Hiperextensão de Tronco</div>
              <div>
                <img src={img.img29} alt="" />
              </div>
              <div>
                <img src={img.img30} alt="" />
              </div>
              <div>
                <img src={img.img31} alt="" />
              </div>
              <div>
                <img src={img.img32} alt="" />
              </div>
              <div>
                <Input name="hiperextensaoTronco" onChange={classificacao} />
              </div>
            </Row>
            <Row>
              <div>Flexão Lateral de Tronco</div>
              <div>
                <img src={img.img33} alt="" />
              </div>
              <div>
                <img src={img.img34} alt="" />
              </div>
              <div>
                <img src={img.img35} alt="" />
              </div>
              <div>
                <img src={img.img36} alt="" />
              </div>
              <div>
                <Input name="flexaoLateralTronco" onChange={classificacao} />
              </div>
            </Row>
            <Row>
              <div>Flexão da Coxa</div>
              <div>
                <img src={img.img37} alt="" />
              </div>
              <div>
                <img src={img.img38} alt="" />
              </div>
              <div>
                <img src={img.img39} alt="" />
              </div>
              <div>
                <img src={img.img40} alt="" />
              </div>
              <div>
                <Input name="flexaoCoxa" onChange={classificacao} />
              </div>
            </Row>
            <Row>
              <div>Extensão da Coxa</div>
              <div>
                <img src={img.img41} alt="" />
              </div>
              <div>
                <img src={img.img42} alt="" />
              </div>
              <div>
                <img src={img.img43} alt="" />
              </div>
              <div>
                <img src={img.img44} alt="" />
              </div>
              <div>
                <Input name="extensaoCoxa" onChange={classificacao} />
              </div>
            </Row>
            <Row>
              <div>Flexão da Perna</div>
              <div>
                <img src={img.img45} alt="" />
              </div>
              <div>
                <img src={img.img46} alt="" />
              </div>
              <div>
                <img src={img.img47} alt="" />
              </div>
              <div>
                <img src={img.img48} alt="" />
              </div>
              <div>
                <Input name="flexaoPerna" onChange={classificacao} />
              </div>
            </Row>
            <Row>
              <div>Hiperextensão da Perna</div>
              <div>
                <img src={img.img49} alt="" />
              </div>
              <div>
                <img src={img.img50} alt="" />
              </div>
              <div>
                <img src={img.img51} alt="" />
              </div>
              <div>
                <img src={img.img52} alt="" />
              </div>
              <div>
                <Input name="hiperextensaoPerna" onChange={classificacao} />
              </div>
            </Row>
            <Row>
              <div>Dorso Flexão</div>
              <div>
                <img src={img.img53} alt="" />
              </div>
              <div>
                <img src={img.img54} alt="" />
              </div>
              <div>
                <img src={img.img55} alt="" />
              </div>
              <div>
                <img src={img.img56} alt="" />
              </div>
              <div>
                <Input name="dorsoFlexaoPlantar" onChange={classificacao} />
              </div>
            </Row>
            <Row>
              <div>Flexão Plantar</div>
              <div>
                <img src={img.img57} alt="" />
              </div>
              <div>
                <img src={img.img58} alt="" />
              </div>
              <div>
                <img src={img.img59} alt="" />
              </div>
              <div>
                <img src={img.img60} alt="" />
              </div>
              <div>
                <Input name="flexaoPlantar" onChange={classificacao} />
              </div>
            </Row>
            <Row>
              <div></div>
              <div></div>
              <div></div>
              <div></div>
              <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', textAlign: 'center' }}>Total</div>
              <div>
                <Input name="total" readOnly />
              </div>
            </Row>
            <Row>
              <div></div>
              <div></div>
              <div></div>
              <div></div>
              <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', textAlign: 'center' }}>Classificação</div>
              <div>
                <Input name="classificacao" readOnly />
              </div>
            </Row>
            <Button loading={loading} disabled={loading} type="submit">
              Gravar
            </Button>
            <div style={{ visibility: 'hidden' }}>
              <Input name="aluno" type="number" />
            </div>
          </Form>
        </Content>
      </Container>
    </>
  );
};

export default Flexiteste;

// function classificacao(valor: number) {
//   if (valor < 31) return 'Baixo';

//   if (valor < 41) return 'Médio (-)';

//   if (valor < 51) return 'Médio (+)';

//   if (valor < 60) return 'Grande';

//   if (valor > 60) return 'Muito Grande';
// }
