import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  margin-top: 10px;
  flex-wrap: wrap;
`;

interface PageProps {
  selected?: boolean;
}

export const Page = styled.div<PageProps>`
  display: flex;

  align-items: center;
  background-color: ${(props) => (props.selected ? '#0357c6' : '#1171ef')}; // rgb(41, 41, 46);
  min-width: 40px;
  height: 40px;
  border-radius: 6px;
  padding-left: 10px;
  padding-right: 10px;
  cursor: pointer;
  color: #fff;

  &:hover {
    background-color: #0357c6;
  }

  > div {
    margin: auto;
  }

  & + div {
    margin-left: 10px;
  }
`;
